import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import Stepper from './Stepper';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //   border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 1, 1),
        minWidth: 900,
        minHeight: 610,
        // width: 776,
        // height: 472 
    },
    table: {
        minWidth: 600,        
      },
  }));

const index = ({funds, isLoadingfunds, getSeriesFund, seriesByFund, isLoadingSeries,
    addNewFund,
    removeNewFund,
    newFunds
}) => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onConfirm = (fundAndSerie) => {
        handleClose()
        addNewFund(fundAndSerie)      
    }

    const removeFund = fund => {
        removeNewFund(fund)
    }

    return (
        <div>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="fondos">
                    <TableHead>
                    <TableRow>  
                        <TableCell align="left">

                            <Fab size="small" color="primary" aria-label="add" onClick={handleOpen}>
                                <AddIcon />
                            </Fab>
                            
                        </TableCell>                      
                        <TableCell>Rut</TableCell>
                        <TableCell>Fondo</TableCell> 
                        <TableCell>Serie</TableCell>
                        <TableCell>Característica</TableCell>                            
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {newFunds.map((row, i) => (
                        <TableRow key={i}>
                            <TableCell>
                                <Fab size="small" color="secondary" aria-label="edit" onClick={() => removeFund(row)}>
                                    <DeleteIcon />
                                </Fab>
                            </TableCell>
                            <TableCell>{row.rut}</TableCell>
                            <TableCell>{row.entidad}</TableCell>
                            <TableCell>{row.serie}</TableCell> 
                            <TableCell>{row.caracteristicas}</TableCell>                   
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                <div className={classes.paper}>

                    <Stepper 
                        onConfirm={onConfirm} 
                        funds={funds} 
                        isLoadingfunds={isLoadingfunds}
                        getSeriesFund={getSeriesFund}
                        seriesByFund={seriesByFund}
                        isLoadingSeries={isLoadingSeries}
                        handleClose={handleClose}
                        />                                                        
                </div>
                </Fade>
            </Modal>
        
        </div>
    );
};

export default index;