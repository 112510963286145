import React from 'react';
import { PanelHeader, Header } from "../../components";
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";
import TableMaterialUi from '../TableMaterialUi';
import { rutFormat, patrimonyFormat, percentFormatOneDigit, patrimonyFormatNotSymbol } from '../../helpers/formatter';
import Chart, { Pie } from 'react-chartjs-2';

const headCells = [
    //id - numeric - disablePadding - label - padding(opcional) - align(opcional) - formatter(opcional)
    { id: 'representante_inf', numeric: false, disablePadding: false, label: 'Representante' },
    { id: 'des_group', numeric: false, disablePadding: false, label: 'Grupo', padding:"none" },  
    { id: 'agente', numeric: false, disablePadding: false, label: 'Agente' },  
    { id: 'account_balance_clp', numeric: true, disablePadding: false, label: 'Caja CLP', align:"right", formatter:patrimonyFormatNotSymbol },
    { id: 'aum_clp', numeric: true, disablePadding: false, label: 'AUM CLP', align:"right", padding:"none", formatter:patrimonyFormatNotSymbol },
    { id: 'ytd_clp', numeric: true, disablePadding: false, label: 'YTD CLP', align:"right", padding:"none", formatter:percentFormatOneDigit },
    { id: 'account_balance_usd', numeric: true, disablePadding: false, label: 'Caja USD', align:"right", padding:"none", formatter:patrimonyFormatNotSymbol },
    { id: 'aum_usd', numeric: true, disablePadding: false, label: 'AUM USD', align:"right", padding:"none", formatter:patrimonyFormatNotSymbol },    
    { id: 'ytd_usd', numeric: true, disablePadding: false, label: 'YTD USD', align:"right", formatter:percentFormatOneDigit },
        
];

let configPie = {    
    datasets: [{        
        backgroundColor: ['#001219', '#005f73', '#0a9396', '#94d2bd', 
        '#e9d8a6', '#ee9b00', '#ca6702', '#bb3e03', '#ae2012', '#9b2226'],
        hoverBackgroundColor: ['#001219', '#005f73', '#0a9396', '#94d2bd', 
        '#e9d8a6', '#ee9b00', '#ca6702', '#bb3e03', '#ae2012', '#9b2226']
    }]
}

const Grafico = ({data}) =>{
    if(data){
        configPie.labels = data.labels;
        configPie.datasets[0].data = data.datasets;
        return <Pie 
                    data={configPie}
                    width={'auto'}
                    height={'200px'}
                    options={{ 
                        maintainAspectRatio: false,
                        legend: {
                            position: 'right'
                        },
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    var dataLabel = data.labels[tooltipItem.index];                                    
                                    var value = ': ' + patrimonyFormat(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);   

                                    if (Chart.helpers.isArray(dataLabel)) {
                                        dataLabel = dataLabel.slice();
                                        dataLabel[0] += value;
                                    } else {
                                        dataLabel += value;
                                    }
                                    return dataLabel;
                                }
                            }
                        }
                    }}
                />
    }else{
        return <p>Sin datos</p>
    }
}

const dataFake = {
    labels: ['Patrimonio', 'Money Market', 'Renta Fija', 'Renta Variable', 'Alternativos', 'Comodities'],
    datasets: [1000, 2000, 3000, 4000, 1000, 3500]
}

function ListGroup({backgroundColor, groups, groupsLoading, selectHandler, tableState, getTableState, location}) {
    console.log("!!!!!!!!!",  getTableState)
   
    return (
        
        <div>
            <PanelHeader size="sm">
               
            </PanelHeader>
            <Header location={window.location} />
            <div className="content" id="listclienttop">
                <Card className="card-stats card-raised">
                    <CardBody>
                        <div>
                            <h3 style={{
                                color: backgroundColor, marginBottom: '.3em', fontSize:'30px',
                            }}>
                                Listado de Grupos
                            </h3>
                            <span style={{color: 'gray'}}>Haz clic en un grupo para visualizar el detalle por Cuentas</span>
                        </div>
                    </CardBody>
                </Card>

                <Row>
                    <Col xs={12} md={12}>
                        <TableMaterialUi 
                            title={'Grupos'}
                            rows={groups} 
                            headCells={headCells}// 
                            withCheckbox={false} 
                            onClickRow={selectHandler}
                            loading={groupsLoading}
                            updateState={getTableState}
                            initialState={tableState}
                        />
                    </Col>
                    {/* <Col xs={12} md={5}>
                        <Grafico data={dataFake} />
                    </Col> */}
                </Row>
            </div>
        </div>
    );
}

// ListClient.propTypes = {
//     clients: PropTypes.arrayOf(PropTypes.object),
//     selectHandler: PropTypes.func,
//     pending: PropTypes.bool,
//     searchText: PropTypes.string,
//     location: PropTypes.object,
//     backgroundColor: PropTypes.string
// }

export default ListGroup;