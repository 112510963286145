import React from 'react';
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";
import RebalanceoCard from './RebalanceoCard';

const Rebalanceo = ({weights, handleSelectedPort, getPortafolioSelected}) => {

    return (
        <div>
            <Row>
                <Col md={12}>
                    <div className="alert alert-primary mb-0">
                        <h5 className="mb-0">Rebalanceo de Portafolio</h5>
                    </div>
                </Col>                
            </Row>
            {weights.map((x, i)=> <RebalanceoCard key={i} data={x} 
                                        handleSelectedPort={handleSelectedPort}
                                        getPortafolioSelected={getPortafolioSelected}
                                    />
            )}
        </div>

    );
};

export default Rebalanceo;