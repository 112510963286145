import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: 600,
    },
});

const Rendimiento = ({data}) => {
    const classes = useStyles();

    const formatValue = (key, value) => {
        value = (value * 1).toFixed(2)
        return <p key={key}>{key == 'ratio' ? value : value  + '%'}</p> 
    }

    return (
        <div>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="portafolio_actual">
                    <TableHead>
                    <TableRow>
                        <TableCell>Métricas</TableCell>
                        <TableCell>Esperado</TableCell>                          
                        <TableCell>Últimos 12 M</TableCell> 
                        <TableCell>Últimos 3 años</TableCell>               
                    </TableRow>
                    </TableHead>
                    <TableBody>

                        <TableRow>
                            <TableCell>
                                {Object.keys(data.esperado).map((key) => <p key={key}>{key.toUpperCase()}</p>)}
                            </TableCell>
                            <TableCell>
                                {Object.keys(data.esperado).map((key) => formatValue(key, data.esperado[key]))}                                
                            </TableCell>
                            <TableCell>
                                {Object.keys(data.docemeses).map((key) => formatValue(key, data.docemeses[key]))}                                    
                            </TableCell> 
                            <TableCell>
                                {Object.keys(data.tresanios).map(key => formatValue(key, data.tresanios[key]))}
                            </TableCell>                           
                        </TableRow>
                    
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Rendimiento;