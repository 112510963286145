import React, {Component} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import sum from "lodash/sum";
import map from "lodash/map";

const formatCLP = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 0
});

const formatNumber = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 4
});

class DetalleCuentas extends Component {
    constructor(props) {
        super(props);

        this.state = {

            data: this.props.data.map((prop, key) => {
                return {
                    id: key,
                    cantidad: prop.Cantidad,
                    desc: prop.Descripcion,
                    renta: prop.Rentabilidad,
                    cot_act: prop.cotizacion_actual,
                    cot_compra: prop.cotizacion_compra,
                    moneda: prop.currency,
                    monto: prop.monto
                };
            })
            ,
            columns: [
                "Cantidad",
                "Descripción",
                "Moneda",
                "Cotización Actual",
                "Cotización Compra",
                "Monto"
            ]
        };
    }

    render() {
        const {data, columns} = this.state;
        return (
            <div>
              <ReactTable
                    style={{maxHeight: '25em'}}
                    data={this.state.data}
                    previousText= {'Anterior'}
                    nextText    = {'Siguiente'}
                    loadingText = {'Cargando...'}
                    noDataText  = {'Sin datos'}
                    pageText    = {'Página'}
                    ofText      = {'de'}
                    rowsText    = {'registros'}
                    columns={[
                        
                        {
                            Header: "Descripcion",
                            accessor: "desc"
                        },
                        {
                            Header: "Moneda",
                            accessor: "moneda"
                        },
                        {
                            Header: "Cantidad",
                            id: 'cantidad',
                            accessor: d=>Number(d.cantidad),
                            Cell: ({row, original}) => { return(<span>{formatNumber.format(original.cantidad)}</span>) },
                            Footer: (
                                <span>
                                    <strong>Total:</strong>
                                </span>
                            )
                        },
                        {
                            Header: "Cotizacion Actual",
                            id: 'cot_act',
                            accessor: d=>Number(d.cot_act),
                            Cell: ({row, original}) => { return(<span className="float-right">{formatNumber.format(original.cot_act)}</span>) }
                        },
                        {
                            Header: "Cotizacion Compra",
                            id: 'cot_compra',
                            accessor: d=>Number(d.cot_compra),
                            Cell: ({row, original}) => { return(<span className="float-right">{formatNumber.format(original.cot_compra)}</span>) }
                        },

                        {
                            Header: "Monto CLP",
                            id: "monto",
                            accessor: d=>Number(d.monto),
                            Cell: props => <span  className="float-right">$ {formatCLP.format(parseInt(props.value))}</span>,
                            Footer: (
                                <span>
                                    <strong className="float-right">$ {formatCLP.format(parseInt(sum(map(data, d => d.monto))))}</strong>
                                </span>)
                        },

                    ]}
                    defaultPageSize={5}
                    showPaginationBottom={true}
                    sortable={true}

                    className="-striped -highlight"
                />
            </div>
        );
    }
}

export default DetalleCuentas;
