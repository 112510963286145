import React, { useState, useEffect } from 'react';
import SidePanel from '../../../components/SidePanel';

import { Button } from '../../../components';
import UsersTable from './UsersTable';


function combine_users(a, b) {
    return [...a, ...b];
}


const AssignUsers = (props) => {
    let {
        uid,
        active,
        title,
        setOpenAssign,
        clients,
        staff,
        assignRolesToUsers
    } = props;

    const [users, setUsers] = useState([]);
    let [selectedUsers, setSelectedUsers] = useState([]);
    useEffect(() => {
        setUsers(combine_users(clients,staff));
    }, [clients,staff])

    const closeHandler = () => {
        document.getElementById(`${uid}`).style.width = "0";
        document.getElementById(`${uid}`).style.display = "none";
        document.getElementById("main").style.width = "100%";
        setOpenAssign(false);
    }

    const selectHandler = (val) => {    
        setSelectedUsers(val);
    }

    const onConfirm = () => {
        assignRolesToUsers(selectedUsers);
    }

    return(
        <SidePanel
            uid={uid}
            active={active}
            title={title}
            closeHandler={closeHandler}
            body={
                <div className={'permission-container'}>
                    <UsersTable 
                        data={users} 
                        selectHandler={selectHandler}
                    />

                </div>

            }
            footer={
                <div>
                    <Button onClick={onConfirm}  color={'success'}>Confirmar</Button>
                </div>
            }
        />
    )
}

export default AssignUsers;