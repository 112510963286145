import React, { Component, Fragment } from "react";
import HeaderComponent from '../../../components/Header/HeaderComponent';
import ListGroup from "../../../components/Cartola/ListGroup";
import { connect } from "react-redux";
import { groupList, selectGroupCartola, setTableStateListGroup } from '../../../actions/group';
import { withRouter } from 'react-router-dom';
import withParams from '../../../helpers/withParams';

import {select_date} from '../../../actions/cartola';
import originalMoment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(originalMoment);
const today = moment();

class ListGroupsContainer extends Component{

    constructor(props){
        super(props);
        const {adjust, by, order, page, per, search} = this.props.params;
        this.state = {
            tableState: {                		
                order: order,
                orderBy: by,
                page: page ? parseInt(page) : page,
                dense: adjust,
                searchValue: search,
                rowsPerPage: per ? parseInt(per) : per,
                selected : []
            }            
        }
        this.selectHandler = this.selectHandler.bind(this);
    }

    componentDidMount(){
        this.props.groupList()
        this.selectHandler = this.selectHandler.bind(this);
    }

    selectHandler(val){        
        this.props.selectGroupCartola(val.group_id)
        this.props.select_date(moment.range(today.clone().subtract(3, "months"), today.clone()))
    }

    render(){
        return (
            <Fragment>
                <HeaderComponent location={this.props.location}/>                
                <ListGroup
                    backgroundColor={this.props.backgroundColor}
                    groups={this.props.groups}
                    groupsLoading={this.props.groupsLoading}
                    selectHandler = {this.selectHandler}
                    tableState={this.props.params.order ? this.state.tableState : this.props.tableState}
                    getTableState={this.props.setTableStateListGroup}
                    location={this.props.location}
                />
            </Fragment> 
        )
    }
}

const mapStateToProps = state => ({
    backgroundColor: state.login.appColors,
    groups: state.group.groupList,
    groupsLoading: state.group.groupListLoading,
    tableState: state.group.tableState, 
})

const mapDispatchToProps = dispatch => ({
    groupList: ()=> dispatch(groupList()),
    selectGroupCartola: (groupId, name, representante) => dispatch(selectGroupCartola(groupId, name, representante)),
    setTableStateListGroup: state => dispatch(setTableStateListGroup(state)),
    select_date: (range) => dispatch(select_date(range))
})

export default  connect(mapStateToProps, mapDispatchToProps)(withRouter(withParams(ListGroupsContainer)));

