import React, { Component ,useEffect, useState} from 'react';
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableAccount from '../Resultado/TableAccount';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 600,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

const index = ({
    handleReturn, 
    code, 
    accountSelected, 
    selectedPortafolio,
    comparePortafolio,
    handleConfirm,
    getResponse
}) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    let linkFocus = null;
    useEffect(()=>{
        linkFocus.focus();
    }, [])

    const handleOpen = () => {
        handleConfirm()
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const volverButton = () =>(
        <button className="btn btn-primary btn-block" type="button" onClick={handleReturn}>
            VOLVER
        </button>
    )

    return (
        <div>
            <Card>
                <CardHeader>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="/" onClick={handleReturn} ref={(link) => { linkFocus = link; }}>
                            Rebalanceador
                        </Link>
                        <Typography color="textPrimary">Portafolio Seleccionado</Typography>
                    </Breadcrumbs>
                </CardHeader>
                <CardBody>
                    <h3 className="text-center">{selectedPortafolio[2]}</h3>
                    <Card>
                        <div className="alert alert-info">Cuenta</div>
                        <CardBody>
                            <TableAccount account={accountSelected} />
                        </CardBody>
                    </Card>


                    <Card>                        
                        <div className="alert alert-info mb-1 p-0">
                            <Row>
                                <Col md={6}>
                                    <h6 className="m-3 p-1">Portafolio a Rebalancear</h6>
                                </Col>
                                <Col md={6}>
                                <div className="text-right">
                                    <button className="btn btn-success mr-3" 
                                        onClick={handleOpen}>
                                        Seleccionar {selectedPortafolio[2]}
                                    </button>
                                </div>
                                </Col>
                            </Row>
                        </div>
                        <CardBody>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="portafolio_actual">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell style={{width: `5%`}}>ID</TableCell>
                                        <TableCell style={{width: `10%`}}>Rut</TableCell>
                                        <TableCell style={{width: `35%`}}>Nombres</TableCell>
                                        <TableCell style={{width: `20%`}}><div className="text-right">Actual</div></TableCell>
                                        <TableCell style={{width: `20%`}}>Nuevo</TableCell>                     
                                        <TableCell style={{width: `10%`}}>Dist.%</TableCell>               
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {comparePortafolio.map((row, i) => {
                                        
                                        return (<TableRow key={i}>                                                
                                            <TableCell>{row.f}</TableCell>
                                            <TableCell>{row.rut}</TableCell>                              
                                            <TableCell>{row.entidad}</TableCell>
                                            <TableCell className="pr-0">
                                            
                                                <table style={{width: `100%`}}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{width: `${100 - row.actualGraph}%`, backgroundColor: '#fff'}}></td>
                                                            <td className="text-right" style={{width: `${row.actualGraph}%`, backgroundColor: '#f36868'}}>{row.actualGraph != 0 ? row.actualGraph: ''}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>


                                            </TableCell>

                                            <TableCell className="pl-0">    

                                                <table style={{width: `100%`}}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{width: `${row.newGraph}%`, backgroundColor: '#19bd35'}}>{row.newGraph}</td>
                                                            <td style={{width: `${100 - row.newGraph}%`, backgroundColor: '#fff'}}></td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </TableCell>                
                                            <TableCell>{row.weight ? row.weight : '0%'}</TableCell>                                             
                                        </TableRow>)
                                        
                                    })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <div className="text-center">
                                <button className="btn btn-primary" type="button" onClick={handleReturn}>
                                    VOLVER
                                </button>
                            </div>                            

                        </CardBody>
                    </Card>


                </CardBody>
            </Card>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                <div className={classes.paper}>
                    {getResponse == null ?
                        <h4 className="text-center" id="transition-modal-title">Descargando Rebalanceo {selectedPortafolio[2]}</h4>
                        :
                        getResponse == '200' ?
                        <div>
                            <h4 className="text-center">El rebalanceo ha sido descargado</h4>
                            {volverButton()}
                        </div>
                        :
                        <div>
                            <h4 className="text-center">Error al descargar portafolio</h4>
                            {volverButton()}
                        </div>
                    }                    
                   
                </div>
                </Fade>
            </Modal>

        </div>
    );
};

export default index;