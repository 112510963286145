import React, { Component } from 'react';
import {connect} from 'react-redux';
import { 
getPatrimony 
} from '../../../actions/patrimony';
import { 
total,
pending
} from '../../../selectors/patrimony';
import Card from '../../../components/AdminDashboard/PatrimonioTotal/Card';

const data = 50000000000;

class CardContainer extends Component {

    componentDidMount(){
        this.props.getPatrimony();
    }

    render() {
        return(
            <div>
                <Card data={this.props.total} pending={this.props.pending}/>
            </div>
        )   
    }
}

const mapStateToProps = state =>({
    total: total(state),
    pending: pending(state)
})

const mapDispatchToProps = dispatch => ({    
    getPatrimony: () => dispatch(getPatrimony())
})

export default connect(mapStateToProps, mapDispatchToProps)(CardContainer);