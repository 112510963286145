import React from 'react';
import Chart, { Doughnut } from 'react-chartjs-2';
import {Card, CardTitle, Col, Row} from "reactstrap";
import 'react-table/react-table.css'
import { rutFormat, patrimonyFormat, percentFormat } from '../../../helpers/formatter'
import TableMaterialUi from '../../TableMaterialUi'

let configPie = {    
    datasets: [{        
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF3E10'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF3E10']
    }]
}

const headCells = [       
    //id - numeric - disablePadding - label - padding(opcional) - align(opcional) - formatter(opcional)
    { id: 'rut', numeric: false, disablePadding: false, label: 'Rut', formatter:rutFormat }, 
    { id: 'name', numeric: false, disablePadding: true, label: 'Nombre', padding:"none"},
    { id: 'last_name', numeric: false, disablePadding: true, label: 'Apellido', padding:"none"},    
    { id: 'administradora', numeric: false, disablePadding: true, label: 'AGF', padding:"none"},
    { id: 'patrimonio', numeric: true, disablePadding: false, label: 'Patrimonio', align:"right", formatter:patrimonyFormat },
    { id: 'porcent', numeric: false, disablePadding: false, label: '%', formatter:percentFormat},
    ];

const Grafico = ({data}) =>{
    if(data){
        configPie.labels = data.labels;
        configPie.datasets[0].data = data.datasets;
        return <Doughnut 
                    data={configPie}
                    width={100}
                    height={100}
                    options={{ 
                        maintainAspectRatio: false,
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    var dataLabel = data.labels[tooltipItem.index];                                    
                                    var value = ': ' + patrimonyFormat(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);   

                                    if (Chart.helpers.isArray(dataLabel)) {
                                        dataLabel = dataLabel.slice();
                                        dataLabel[0] += value;
                                    } else {
                                        dataLabel += value;
                                    }
                                    return dataLabel;
                                }
                            }
                        }
                    }}
                />
    }else{
        return <p>Sin datos</p>
    }
}

const ifDataNull = (data) =>{
    if(data){
        return data
    }else{
        return [];
    }
}

const AdminCard = ({dataPatrimonio, dataUsuario, onClick, pending}) =>  {

    const [tableState, setTableState] = React.useState({		
        order : "desc",
        orderBy : "patrimonio",
        page : 0,
        dense : false,
        searchValue : '',
        rowsPerPage : 5,
        selected : []
    })
    
    const getTableState = state => {
        setTableState(state)
    }

    return (
        <div>           

            <Row>
                <Col sm="4">
                    <Card body style={{height: '25rem'}}>
                        <h6>Patrimonio por Administradora</h6>
                        <Grafico data={dataPatrimonio} />
                    </Card>
                </Col>
                <Col sm="8">

                    <TableMaterialUi 
                        title={'Patrimonio por Cliente'}
                        rows={ifDataNull(dataUsuario)} 
                        headCells={headCells} 
                        withCheckbox={false} 
                        onClickRow={onClick}
                        loading={pending}
                        updateState={getTableState}    //TableMaterialUi State
                        initialState={tableState}
                    />
                        
                </Col>
            </Row>

        </div>
    );
}

export default AdminCard;