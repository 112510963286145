//import Pages from "../layouts/Pages/Pages.jsx";
// import DashboardContainer from "containers/DashboardContainer/DashboardContainer";
import Main from '../containers/Main';
import LoginAnimation from "../LoginAnimation"
import LoginContainer from "../containers/Login/LoginContainer";
import Onboarding from "../views/Onboarding/Onboarding.jsx"
import Landing from "../views/Landing";
import OnboardingAnimation from "../views/OnboardingAnimation/OnboardingAnimation.jsx";
import PasswordResetContainer from '../containers/Login/PasswordResetContainer';

var indexRoutes = [
    {path: "/password/recovery", public: true, name: "Password Reset", component: PasswordResetContainer},
    {path: "/onboarding/update-password", public: true, name: "Login", component: OnboardingAnimation},    
    {path: "/onboarding", public: true, name: "Onboarding", component: Onboarding},    
    {path: "/login", public: true, name: "Login", component: LoginContainer},
    //{path: "/qip", public: true, name: "Pages", component: Pages},
    {path: "/dashboard", public: false, name: "Home", component: Main}, 
    {path: "/", public: false, name: "Landing", component: Landing}
];

export default indexRoutes;
