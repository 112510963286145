import React from 'react';
import { connect } from 'react-redux';
import {
    handleCreateRole,
    setCreateActive,
    setOpenCreate
} from '../../../actions/permissions';
import CreateRole from '../../../components/RolesUsers/CreateRole/CreateRole';


class CreateRoleContainer extends React.Component {
    
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <CreateRole 
                uid='createPanel'
                active={this.props.open_create}
                title={'Crear Rol'}
                permissions={this.props.permissions}
                editactive={this.props.create_active}
                handleCreateRole={this.props.create_role}
                create_active={this.props.create_active}
                open_create={this.props.open_create}
                create_role={this.props.create_role}
                setOpenCreate={this.props.set_open_create}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        create_active: state.permissions.createActive,
        open_create: state.permissions.openCreate,
        create_success: state.permissions.createSuccess,
        permissions: state.permissions.permissions
    }
}

const mapDispatchToProps = dispatch => ({
    create_role: roleObject => dispatch(handleCreateRole(roleObject)),
    set_open_create: isOpen => dispatch(setOpenCreate(isOpen))
})


export default connect(mapStateToProps, mapDispatchToProps)(CreateRoleContainer);