import {ADMINISTRADORA_TOTAL_INIT, ADMINISTRADORA_TOTAL_SUCCESS, ADMINISTRADORA_TOTAL_ERROR,
    ADMINISTRADORA_INFO_GET_INIT, ADMINISTRADORA_INFO_GET_SUCCESS, ADMINISTRADORA_INFO_GET_ERROR,
    ADMINISTRADORAS_GET_INIT, ADMINISTRADORAS_GET_SUCCESS, ADMINISTRADORAS_GET_ERROR, 
    ACCOUNTS_GET_INIT, ACCOUNTS_GET_SUCCESS, ACCOUNTS_GET_ERROR
} from '../constants';

const initialState = {
    dataPatrimonio  : null,
    dataUsuario     : null,
    pendingInfo     : false,
    pending         : false,
    error           : null,
    administradoras : [],
    cuentasAdministradoras : [],  
    pendingAccounts: false
}

const activos = (state = initialState, action) => {
    switch (action.type) {
        case ADMINISTRADORA_TOTAL_INIT:
            return {
                ...state,
                pending : true,
                error   : null
            };            

        case ADMINISTRADORA_TOTAL_SUCCESS:
            return {
                ...state,
                dataPatrimonio  : action.payload.response.data.dataPatrimonio,                
                pending : false,                
            };            

        case ADMINISTRADORA_TOTAL_ERROR:
            return {
                ...state,
                pending : false,
                error   : action.payload.error
            };   

            
        case ADMINISTRADORAS_GET_INIT:
            return {
                ...state,
                pending : true,
                error   : null
            };            

        case ADMINISTRADORAS_GET_SUCCESS:
            return {
                ...state,
                administradoras  : action.payload.response.data.administradoras,                
                pending : false,                
            };            

        case ADMINISTRADORAS_GET_ERROR:
            return {
                ...state,
                pending : false,
                error   : action.payload.error
            };  


        case ACCOUNTS_GET_INIT:
            return {
                ...state,
                pendingAccounts: true,
                error   : null
            };            

        case ACCOUNTS_GET_SUCCESS:
            return {
                ...state,
                cuentasAdministradoras  : action.payload.response.data.accounts,                
                pendingAccounts : false,                
            };            

        case ACCOUNTS_GET_ERROR:
            return {
                ...state,
                pendingAccounts : false,
                error   : action.payload.error
            };


        case ADMINISTRADORA_INFO_GET_INIT:
            return {
                ...state,
                pendingInfo : true,
                error   : null
            };            

        case ADMINISTRADORA_INFO_GET_SUCCESS:
            return {
                ...state,                
                dataUsuario : action.payload.response.data.dataUsuario,
                pendingInfo : false,                
            };            

        case ADMINISTRADORA_INFO_GET_ERROR:
            return {
                ...state,
                pendingInfo : false,
                error   : action.payload.error
            };            
    
        default:
            return state;
    }
}

export default activos;