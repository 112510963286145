import React, { Component } from 'react';
import BulkData from '../../components/BulkData';
import { connect } from 'react-redux';
import {getUpload, uploadFile} from '../../actions/bulkData';
import {uploadInput, uploadAdmins} from '../../selectors/bulkData';

const limitFileSize = 30000000;

class index extends Component {

    constructor(){
        super()
        this.state ={
            fileColumn : null,
            dataColumn : null,
            data    : null,
            fileName: null,
            fileSize: null,
            fileType: null,
            label: null,
            inputFile: 0,
            columnMap: [],
            delimiter: ",",
        }
        this.preUpload = this.preUpload.bind(this);
        this.upload = this.upload.bind(this);
        // this.reset = this.reset.bind(this);
        this.setDelimiter = this.setDelimiter.bind(this);
        this.setInputFile = this.setInputFile.bind(this);
        this.setMapColumn = this.setMapColumn.bind(this);
        
    }

    componentDidMount(){
        this.props.getUpload();
    }

    // reset(){
    //     this.setState({
    //         fileColumn: null,
    //         dataColumn : null,
    //         data: null,
    //         fileName: null,
    //         fileSize: null,
    //         fileType: null,
    //         label: null,
    //         inputFile: 0,
    //         columnMap: [],
    //     })
    // }

    setDelimiter(e){
        this.setState({
            delimiter: e.target.value,
            fileColumn : null,
            columnMap: [],
        })
    }

    setInputFile(e){              
        this.setState({
            inputFile: e.target.value,
            fileColumn : null,
            columnMap: [],
        })
    }

    setMapColumn(obj){        
        let columnMap = [...this.state.columnMap];
        var exist = false;
        var remove= false;
        columnMap.forEach(old => {
            if(old.tableColumn == obj.tableColumn){
                if(obj.fileColumn != ""){
                    old.fileColumn = obj.fileColumn;                    
                }else{
                    obj = old;
                    remove = true;
                }
                exist = true;
            }
        });
        if(!exist)
            columnMap.push(obj);

        if(remove){
            const id = columnMap.indexOf(obj);
            columnMap.splice(id, 1);
        }

        console.log(columnMap)

        this.setState({
            columnMap
        })
    }

    preUpload(id, file, dataColumn, label){
        //Id identificara en backend accion a realizar (importar clientes, staff, etc)
        //desde un mismo controlador, identificando la tabla a cargar data
        if(!file){
            alert('Debes seleccionar un archivo'); 
            // this.reset()
        }else{
            const fileName = file.name;
            const fileSize = file.size;
            const fileType = file.type;

            if(fileType != "application/vnd.ms-excel"){
                alert('Debes seleccionar un archivo de tipo CSV'); 
                return
            }
            if(fileSize > limitFileSize){
                alert('Excede el tamaño de 30MB'); 
                return
            }            
            
            console.log(fileName)
            console.log(fileSize)
            console.log(fileType)

            const data = {id, file}
            console.log(data)

            let reader = new FileReader();
            reader.onload = (e) => {
                const firstRow = e.target.result;
                const fileColumn = firstRow.slice(0, firstRow.indexOf('\n')).split(this.state.delimiter);
                this.setState({
                    fileColumn,
                    dataColumn,
                    data,
                    fileName,
                    fileSize,
                    fileType,
                    label
                })
            };
            reader.readAsText(file);
        }
    }

    upload(){
        if(this.state.columnMap.length != this.state.dataColumn.length){
            alert('Debes seleccionar todas las columnas')
            return
        }

        // let reader = new FileReader();        
        // reader.onload = (e) => {
        //     const data = {id: this.state.data.id, file: e.target.result, columnMap: this.state.columnMap} 
        //     this.props.uploadFile(data)
        // };
        // reader.readAsText(this.state.data.file);
        // reader.readAsDataURL(this.state.data.file);

        const data = {id: this.state.data.id, file: this.state.data.file, columnMap: this.state.columnMap} 
        this.props.uploadFile(data)
    }

    render() {
        return (
            <BulkData 
                uploadInput={this.props.uploadInput}
                uploadAdmins={this.props.uploadAdmins}
                // reset={this.reset}
                upload={this.upload} 
                preUpload={this.preUpload} 
                fileColumn={this.state.fileColumn}
                dataColumn={this.state.dataColumn}
                fileName={this.state.fileName}
                fileSize={this.state.fileSize}
                fileType={this.state.fileType}
                label={this.state.label}
                setDelimiter={this.setDelimiter}
                setInputFile={this.setInputFile}
                inputFile={this.state.inputFile}
                setMapColumn={this.setMapColumn}
            />           
        );
    }
}

const mapStateToProps = state => ({
    uploadInput: uploadInput(state),
    uploadAdmins: uploadAdmins(state)
})

const mapDispatchToProps = dispatch => ({
    getUpload: () => dispatch(getUpload()),
    uploadFile: data => dispatch(uploadFile(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(index);