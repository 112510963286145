import React, { Component } from 'react';
import {connect} from 'react-redux';
import Activos from '../../../components/AdminDashboard/Activos';

import { 
    getActivosCardInfo,
} from '../../../actions/activos';
import { 
    dataPatrimonio,
    dataMes,
    pending
} from '../../../selectors/activos';


// const dataPatrimonio= {
//     labels: ['Mixtos', 'Renta Fija', 'Renta Variable', 'Bonos'],
//     datasets: [10, 20, 30, 40]
// };

// const dataMes= {
//     labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],    
//     datasets: {
//         mixto   : [80, 81, 56, 65, 55, 40, 59, 80, 81, 56, 65, 55],
//         fija    : [55, 40, 59, 80, 80, 81, 56, 65, 81, 56, 65, 55],
//         variable: [80, 81, 56, 65, 55, 80, 81, 56, 65, 55, 40, 59],
//         bonos   : [80, 81, 56, 65, 55, 40, 59, 80, 65, 55, 81, 56],
//     }
// };
class index extends Component {

    componentDidMount(){
        this.props.getActivosCardInfo();
    }

    render() {
        return (
            <div>
                <Activos 
                    dataPatrimonio={this.props.dataPatrimonio} 
                    dataMes={this.props.dataMes}
                    pending={this.props.pending}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    dataPatrimonio: dataPatrimonio(state),
    dataMes: dataMes(state),
    pending: pending(state)
})

const mapDispatchToProps = dispatch => ({
    getActivosCardInfo: () => dispatch(getActivosCardInfo()),
})

export default connect(mapStateToProps, mapDispatchToProps)(index);