import React, { Component } from 'react';
import {connect} from 'react-redux';
import Card from '../../../components/AdminDashboard/Clientes/Card';
import { 
    getClientsCard 
} from '../../../actions/client';
import { 
    totalClients,
    pending
} from '../../../selectors/client';

// const data = 1200;

class CardContainer extends Component {

    componentDidMount(){
        this.props.getClientsCard();
    }

    render() {
        return (
            <div>
                <Card data={this.props.totalClients} pending={this.props.pending}/>
            </div> 
        );
    }
}

const mapStateToProps = state => ({
    totalClients: totalClients(state),
    pending: pending(state)
})

const mapDispatchToProps = dispatch => ({
    getClientsCard: () => dispatch(getClientsCard()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CardContainer);