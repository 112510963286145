import React from "react";
import {connect} from 'react-redux';
import { logout, doNotLogout } from '../../actions/login';
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";
import SidebarContainer from '../SidebarContainer/SidebarContainer';
import dashboardRoutes from "../../routes/dashboard.js";
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import NotificationAlert from "react-notification-alert";
import RouteWithSubRoutes from '../../helpers/RouteWithSubRoutes';
import withTour from '../../helpers/withTour';
import { urlCartolasList, urlCartolasClient } from '../../api/urls';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import { Disclaimer } from '../../components/Disclaimer';

let ps;

const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(orange[500]),
      backgroundColor: orange[500],
      '&:hover': {
        backgroundColor: orange[700],
      },
    },
}))(Button);

let timer, timer2;

class DashboardContainer extends React.Component {

    constructor(props) {
        super(props);
        const { appColors } = this.props;
        const colors = appColors ? appColors.split(';') : ['blue', '#2c2c2c', '#ffb236'];

        this.state ={
            tickId: this.props.timeNotify,
            textMessage: '',
            colorPrimary: colors[0],
            colorSecondary: colors[1],
            colorTernary: colors[2],
            assignedPermissions: [],
            showNotification: true,
            logoutCancel: true
        }

        this.counter = React.createRef();

        /* Notifications */
        this.notify = this.notify.bind(this);
        this.updateTimer = this.updateTimer.bind(this);
        this.endSessionNotified = this.endSessionNotified.bind(this);
        this.endSessionNotification = this.endSessionNotification.bind(this);
        this.cancelLogout = this.cancelLogout.bind(this);
        this.counterSessionTime = this.counterSessionTime.bind(this);

    }

    componentDidMount() {
        this.updateTitle();
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            ps = new PerfectScrollbar(this.refs.mainPanel);
        }
        this.counterSessionTime();
    }

    counterSessionTime(){
        timer = setTimeout(() => {
            console.log('Send notify end session');
            this.updateTimer();
            this.endSessionNotification();
            if(this.state.logoutCancel) this.endSessionNotified();
        }, this.props.timeSession * 1000);
    }

    endSessionNotification(){
        this.setState({
            logoutCancel: true
        })
        this.notify('tc', 'warning',
        <div className="text-center">La sesión expira en <span ref={this.counter}>{this.props.timeNotify}</span> segundos
            <span>
                <ColorButton fullWidth disableElevation
                    variant="contained" size="small" color="primary"
                    onClick={this.cancelLogout}>
                    No cerrar sesión
                </ColorButton>
            </span>
        </div>,
        this.props.timeNotify)
    }

    cancelLogout(){
        this.setState({
            logoutCancel: false
        })
        clearTimeout(timer);
        clearTimeout(timer2)
        this.setState({showNotification: false})
        this.props.doNotLogout()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.updateTitle();
        }

        if(prevProps.token !== this.props.token) {
            this.setState({showNotification: true, tickId: this.props.timeNotify})
            this.counterSessionTime();
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.tickId)
    }

    updateTimer(){
        if(this.state.tickId>=0){
            setTimeout(() => {
                this.setState({tickId:this.state.tickId - 1});
                this.counter.current ? (this.counter.current.innerHTML = this.state.tickId):
                this.setState({tickId:-1});
                this.updateTimer();
            }, 1000);
        }
    }

    endSessionNotified(){
        timer2 = setTimeout(() => {
            console.log('Time out')
            this.props.logout();
        }, this.props.timeNotify * 1000)
    }

    notify(place, type, message, autoDismiss = 7) {
        let options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss
        };
        this.refs.notify.notificationAlert(options);
    }

    updateTitle() {
        dashboardRoutes.map(route => {
            let path = route.path;
            if(this.props.location.pathname.includes(path)){
                let title = this.props.appName;

                let subRoute = '';
                if(this.props.location.pathname === urlCartolasList){
                    subRoute = 'Listado Clientes';
                }else if(this.props.location.pathname.indexOf(urlCartolasClient) > -1){
                    subRoute =  this.props.clientName;
                }

                // if(subRoute){
                //     title = `${subRoute} | ${route.name} | ${this.props.appName}`
                // }else{
                    title = `${route.name} | ${this.props.appName}`
                // }

                window.document.title = title;
            }

        })

    }

    render() {
        return(
            <div className="wrapper">
            {this.state.showNotification &&
                <NotificationAlert ref="notify" zIndex={5000}/>
            }
            <SidebarContainer location={this.props.location} routes={dashboardRoutes} onUserNavigate={this.onUserNavigate} />
            <div className="main-panel" ref="mainPanel" style={{ "overflowAnchor": "none" }}>

                <Switch>
                    {dashboardRoutes.map((route, key) => {
                        if (this.props.userLoading) {
                            return (
                            <div key={key}>
                                <LoadingAnimation loadingMessages={[
                                    'Posicionando los satelites...',
                                    'Capturando los alfas...',
                                    'Dandole de comer a los monos...'
                                    ]} />
                            </div>)
                        }
                        // if (this.props.assignedPermissions.includes(route.permission)) {
                            // console.log('route.permission', route.permission);
                            if (route.collapse) {
                                // console.log('Route Collapse');
                                return route.views.map((viewRoute, key2) => {
                                    return (
                                        <Route
                                        path={viewRoute.path}
                                        render={() => <viewRoute.component notify={this.notify} key={key2} />}
                                        key={key2}
                                    />
                                );
                            });
                            }
                            if (route.redirect) {
                                console.log('redirect');
                                return <Redirect from={route.path} to={route.pathTo} key={key} />;
                            } if (route.routes) {
                                // console.log('RouteWithSubRoutes! ');
                                return(<RouteWithSubRoutes key={key} notify={this.notify} {...route} />)
                            } else {
                                // console.log('else');
                                // console.log(route.path);
                                return (
                                    <Route path={route.path} render={() => <route.component notify={this.notify} />} key={key} />
                                    );
                                }
                        // } else {
                        //     return(<div key={key}> You dont have permissions. </div>);
                        // }
                    })}
                </Switch>
                {/* Componente pie de pagina */}
                <Disclaimer />
            </div>
        </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        userName: state.user.name,
        userLoading: state.user.loadingUserData,
        assignedPermissions: state.user.permissions,
        appName: state.login.appName,
        clientName: state.cartola.clientName,
    }
}

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
    doNotLogout: () => dispatch(doNotLogout())
});


export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
