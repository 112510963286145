import {REMINDERS_ERROR, REMINDERS_GET_SUCCESS, REMINDERS_GET_INIT, 
REMINDERS_STORE_SUCCESS, REMINDERS_STORE_INIT, REMINDERS_CLEAR} from '../constants';

const initialState = {
    data: null,
    error: null,
    pending: false,
    saving: false,
    count: 0,
}

const reminder = (state = initialState, action) =>{
    switch(action.type){
        case REMINDERS_GET_INIT:
            return {
                ...state,
                pending: true
            };
        case REMINDERS_GET_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                error: null,
                pending: false,
                count: action.payload.count
            };
        case REMINDERS_STORE_INIT:
            return {
                ...state,
                pending: false,
                saving: true
            };
        case REMINDERS_STORE_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                error: null,
                saving: false,
                count: action.payload.count
            };
        case REMINDERS_ERROR:
            return {
                ...state,
                error: action.payload.error,
                pending: false
            };
        case REMINDERS_CLEAR:            
            return {
                data: null,
                error: null,
                pending: false,
                saving: false,
                count: 0,              
            }
        default:
                return state;
    }
}

export default reminder;