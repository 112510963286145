import React, { Fragment } from 'react';
import {connect} from 'react-redux';
import Cartola from '../../../components/Cartola';
import Client from '../../../components/Cartola/Client';
import { withRouter } from 'react-router-dom';
import {
    fetch_composition,
    fetch_breakdown, 
    fetchCartolaAdvisorClient, 
    resetClientCartola,
    getListClientsCartola,
    selectClientCartolaList
    
    // selectClient,
} from '../../../actions/cartola';
import {
    getClientCartola, 
    listClientsCartolaLoading, 
    listClientsCartola,
    getClientName
} from '../../../selectors/cartola';
import { urlCartolasList } from '../../../api/urls';
import withParams from '../../../helpers/withParams';
import withTour from '../../../helpers/withTour';
import { urlFormatFromParams } from '../../../helpers/formatter';


class CartolaClientContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handlerClientCartola = this.handlerClientCartola.bind(this);
    
    }

    componentDidMount() {
        
        console.log('CartolaClientContainer', this.props.params);
        if (this.props.assignedPermissions.includes('view-advisor-dashboard')) {
            this.props.fetch_composition(this.props.params.id);
            this.props.get_clients(); 
            // this.props.selectClient(this.props.params.id);
        } else {
            this.props.fetch_composition(this.props.userId);
        }
    }

    handlerClientCartola(clientId){
        this.props.selectClientCartolaList(clientId)//Update URL
        this.props.fetch_composition(clientId);//Get data
    }
    
    render() {
        if (this.props.assignedPermissions.includes('view-advisor-dashboard')) {
            const {clients, clientName, params, ...rest} = this.props;  
            const urlParams = urlFormatFromParams(params);
            return (
                <div>
                    <Client getClientCartola={this.handlerClientCartola} clients={clients} clientName={clientName} params={urlParams} cartolaId={params.id} cartolaProps={rest}/>
                </div>
            )
        } else {
            return (
                <div>
                    
                    <Cartola {...this.props}/>
                </div>
            )
        }
        
    }
}


const mapStateToProps = state => {
    return {
        userId: state.user.id,
        // clients: getAllClientsNoFilter(state),
        pending: listClientsCartolaLoading(state),
        clients: listClientsCartola(state),       
        
        // Start of the selected period.
        periodStart: state.cartola.periodStart,
        // End of the selected period
        periodEnd: state.cartola.periodEnd,

        patrimonioFinal: state.cartola.patrimonioFinal,
        patrimonioFinalStartDate: state.cartola.patrimonioFinalStartDate,
        patrimonioFinalEndDate: state.cartola.patrimonioFinalEndDate,

        cartolaLoading: state.cartola.cartolaLoading,

        // Account balance at the start of the period
        initial_amount: state.cartola.initial_amount,
        // Account balance at the end of the period
        final_amount: state.cartola.final_amount,

        // Inflation rate
        ipc: state.cartola.ipc,
        // Cartera data 
        cartera: state.cartola.cartera,
        cash: state.cartola.cash,
        parsedCartera: state.cartola.parsedCartera,
        // Parsed cartera data, formatted for use in components
        movimientos: state.cartola.movimientos,

        parsedMovimientos: state.cartola.parsedMovimientos,
        portfolioComposition: state.cartola.portfolioComposition,
        asset_type: state.cartola.asset_type,
        percentage_type: state.cartola.percentage_type,
        currencies: state.cartola.currencies,
        percentage_curr: state.cartola.percentage_curr,
        percentage_admin: state.cartola.percentage_admin,
        adminSelectValue: state.cartola.adminSelectValue,
        adminSelectOptions: state.cartola.adminSelectOptions,
        accountSelectValue: state.cartola.accountSelectValue,
        accountSelectOptions: state.cartola.accountSelectOptions,
        adminValues: state.cartola.adminValues,
        accountValues: state.cartola.accountValues,
        datePickerValue: state.cartola.datePickerValue,
        fetchCompositionLoading: state.cartola.fetchCompositionLoading,
        fetchBreakdownLoading: state.cartola.fetchBreakdownLoading,
        fetchBreakdownFailed: state.cartola.fetchBreakdownFailed,
        fetchCartolaPDFLoading: state.cartola.fetchCartolaPDFLoading,
        saldos: state.cartola.saldos,
        parsedSaldos: state.cartola.parsedSaldos,
        consolidated: state.cartola.consolidated,
        consolidatedByAccount: state.cartola.consolidatedByAccount,
        consolidatedByCurrency: state.cartola.consolidatedByCurrency,
        consolidatedByType: state.cartola.consolidatedByType,
        percentageAssetTypes: state.cartola.percentageAssetTypes,
        percentageCurrency: state.cartola.percentageCurrency,

        montoTotal: state.cartola.montoTotal,

        assignedPermissions: state.user.permissions,
        clientCartola: getClientCartola(state),
        clientId: state.cartola.clientId,
        clientName: getClientName(state),
    }
}

const mapDispatchToProps = dispatch => ({    
    fetch_composition: (userId) => dispatch(fetch_composition(userId)),
    fetch_breakdown: (userId) => dispatch(fetch_breakdown(userId)),
    fetchCartolaAdvisorClient : client => dispatch(fetchCartolaAdvisorClient(client)),
    resetClientCartola: () => dispatch(resetClientCartola()),
    get_clients: () => dispatch(getListClientsCartola()),
    selectClientCartolaList: (clientId) => dispatch(selectClientCartolaList(clientId)),
    // selectClient: (clientId) => dispatch(selectClient(clientId))
});




// export default withParams(connect(mapStateToProps, mapDispatchToProps)(withTour(CartolaClientContainer))); //<--Tour habilitado
export default withParams(connect(mapStateToProps, mapDispatchToProps)(CartolaClientContainer)); //<--Tour deshabilitado