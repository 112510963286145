import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '../../../components/AdminDashboard/Administradoras/Card';
import { 
    getAdministradoraCard
} from '../../../actions/administradora';
import { 
pending,
dataPatrimonio,
} from '../../../selectors/administradora';

// const data= {
//     labels: ['Adm 1', 'Adm 2', 'Adm 3'],
//     datasets: [10, 20, 30]
// };

class CardContainer extends Component {

    componentDidMount(){
        this.props.getAdministradoraCard();
    }

    render() {
        return (
            <div>
                <Card data={this.props.dataPatrimonio} pending={this.props.pending}/>
            </div>
        );
    }
}

const mapStateToProps = state =>({
    pending: pending(state),
    dataPatrimonio: dataPatrimonio(state)
})

const mapDispatchToProps = dispatch => ({
    getAdministradoraCard: () => dispatch(getAdministradoraCard())
})

export default connect(mapStateToProps, mapDispatchToProps)(CardContainer);