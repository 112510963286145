import React, {useState, useEffect} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Fab from '@material-ui/core/Fab';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

  
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: '3%', // theme.spacing(4),
        paddingRight: '1%',
        backgroundColor: theme.palette.background.default,
        justifyContent: 'space-between'
    },
    img: {
        maxHeight: 240, 
        maxWidth: 400,
        overflow: 'hidden',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '96%',
    },
}));
  
function TourCarousel({tourSteps, step, save, paneActive, setPaneActive}) {

    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(step);
    
    useEffect(()=>{
        setActiveStep(0);
        console.log('reset active step', activeStep);
    }, [])

    const maxSteps = tourSteps.length;



    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //    window.location = tourSteps[activeStep + 1].ref;
        
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    //    window.location = tourSteps[activeStep - 1].ref;
    };

    const togglePaneActive = () => {
        setPaneActive();
		if(paneActive) save();
    };

    

    const toggleEndTutorial = () => {
        setPaneActive();
		save();
        setActiveStep(0);
    }


    const renderTour = () => {

        return(<div style={{
            borderRadius: "10px",
            height: 'auto', 
            width: 'auto', 
            display: 'flex',
            bottom: '3%', 
            right: '3%',
            position: 'fixed', 
            justifyContent: 'center', 
            alignItems: 'center',
            zIndex: 2000}}
        >
            {
            
            paneActive ? 
                <div className={classes.root} style={{
                        borderRadius: "10px",
                        backgroundColor: "#fafafa",
                        boxShadow: '0 20px 200px 0 rgba(70,70,70,0.8)',
                    }}>
                        <Paper square elevation={1} 
                            className={classes.header} 
                            style={{ 
                                borderRadius: "10px 10px 0px 0px",
                                }}>
                            <Typography
                                style={{
                                    marginTop: '4%',
                                    marginLeft: '3%',
                                    marginBottom: '2%',
                                    }}
                            >{tourSteps[activeStep].title}</Typography>
                            <Button 
                                style={{ 
                                    padding: '0px',
                                //    marginRight: '5%'
                                    }}
                                onClick={togglePaneActive}                                
                                >—</Button>
                        </Paper>
                        <Paper square elevation={0} className={classes.header} style={{  marginBottom: '2%' }}>
                            <Typography
                                style={{ 
                                    fontSize: '0.9rem',
                                    marginBottom: '1%',
                                    }}
                            >{tourSteps[activeStep].label}</Typography>
                        </Paper>
                        <img
                            className={classes.img}
                          //  style={{border:'solid 1px grey'}}
                            src={tourSteps[activeStep].imgPath}
                            alt={tourSteps[activeStep].label}
                        />  
                        <MobileStepper
                            steps={maxSteps}
                            style={{ padding: "1px", borderRadius: "10px" }}
                            position="static"
                            variant="text"
                            activeStep={activeStep}
                            nextButton={
                                activeStep === maxSteps - 1 ? 
                                <Button onClick={toggleEndTutorial}>Finalizar</Button>
                                :
                                <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                    Siguiente
                                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                </Button>
                            }
                            backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                Anterior
                            </Button>
                            }
                        />
                    </div> 
                
                : 
                
                <Fab color="primary" onClick={togglePaneActive} aria-label="add">
                    <InfoOutlined />
                </Fab>
            
                        }
        </div>);
    }

    if (tourSteps.length > 0) {
        return renderTour();
    } else {
        console.log('no steps!');
        return (<div>{" "}</div>)
    }
    
}


export default TourCarousel;