import React from 'react';
import {Card, CardBody,	CardHeader} from "reactstrap";

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function FilterMenu ({data, filters, setFilters, isLoading}) {

    const classes = useStyles();

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    // React.useEffect(() => {
    //     setLabelWidth(inputLabel.current.offsetWidth * 2);
    // }, []);

    const handleChange = name => event => {
        setFilters({key: name, value: event.target.value})
    };

    const handleChangeAutoComplete = name => value => {
        setFilters({key: name, value: value})
    };

    return (
        <Card>   
            {isLoading ?
            <CardHeader><h5>Cargando Filtros</h5></CardHeader> 
            :               
            <div>
            <CardHeader><h5>FILTROS</h5></CardHeader>      
            <CardBody>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="adminSelect-label">
                        Admin
                    </InputLabel>
                    <Select
                        labelId="adminSelect-label"
                        id="adminSelect"
                        value={filters.get('admin')}
                        onChange={handleChange('admin')}
                        labelWidth={labelWidth}
                    >   
                        <MenuItem value=""><em>Todo</em></MenuItem>
                        {isLoading ? <MenuItem value=""><em>Cargando...</em></MenuItem>:                         
                            data.admins.map(name=> <MenuItem key={name} value={name}>{name}</MenuItem>)
                        }

                    </Select>
                </FormControl>

                 <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="apvSelect-label">
                        Tipo Cuenta
                    </InputLabel>
                    <Select
                        labelId="apvSelect-label"
                        id="apvSelect"
                        value={filters.get('apv')}
                        onChange={handleChange('apv')}
                        labelWidth={labelWidth}
                    >
                        <MenuItem value=""><em>Todo</em></MenuItem>
                        {isLoading ? <MenuItem value=""><em>Cargando...</em></MenuItem>:                         
                            // data.apv.map(obj=> <MenuItem key={obj.id} value={obj.id}>{obj.name}</MenuItem>)
                            data.apv.map(name=> <MenuItem key={name} value={name}>{name}</MenuItem>)
                        }
                        
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="tipoCuentaSelect-label">
                        Mercado
                    </InputLabel>
                    <Select
                        labelId="tipoCuentaSelect-label"
                        id="tipoCuentaSelect"
                        value={filters.get('tipoCuenta')}
                        onChange={handleChange('tipoCuenta')}
                        labelWidth={labelWidth}
                    >
                        <MenuItem value=""><em>Todo</em></MenuItem>
                        {isLoading ? <MenuItem value=""><em>Cargando...</em></MenuItem>:                         
                            data.type.map(name=> <MenuItem key={name} value={name}>{name}</MenuItem>)
                        }
                        
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="currencySelect-label">
                        Moneda
                    </InputLabel>
                    <Select
                        labelId="currencySelect-label"
                        id="currencySelect"
                        value={filters.get('currency')}
                        onChange={handleChange('currency')}
                        labelWidth={labelWidth}
                    >
                        <MenuItem value=""><em>Todo</em></MenuItem>
                        {isLoading ? <MenuItem value=""><em>Cargando...</em></MenuItem>:                         
                            data.currency.map(name=> <MenuItem key={name} value={name}>{name}</MenuItem>)
                        }
                        
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="countrySelect-label">
                        Pais
                    </InputLabel>
                    <Select
                        labelId="countrySelect-label"
                        id="countrySelect"
                        value={filters.get('country')}
                        onChange={handleChange('country')}
                        labelWidth={labelWidth}
                    >
                        <MenuItem value=""><em>Todo</em></MenuItem>
                        {isLoading ? <MenuItem value=""><em>Cargando...</em></MenuItem>:                         
                            data.country.map(name=> <MenuItem key={name} value={name}>{name}</MenuItem>)
                        }
                       
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="regionSelect-label">
                        Región
                    </InputLabel>
                    <Select
                        labelId="regionSelect-label"
                        id="regionSelect"
                        value={filters.get('region')}
                        onChange={handleChange('region')}
                        labelWidth={labelWidth}
                    >
                        <MenuItem value=""><em>Todo</em></MenuItem>
                        {isLoading ? <MenuItem value=""><em>Cargando...</em></MenuItem>:                         
                            data.region.map(name=> <MenuItem key={name} value={name}>{name}</MenuItem>)
                        }
                       
                    </Select>
                </FormControl>
                
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="serie-label">
                        Serie
                    </InputLabel>
                    <Select
                        labelId="serie-label"
                        id="serieSelect"
                        value={filters.get('serie')}
                        onChange={handleChange('serie')}
                        labelWidth={labelWidth}
                    >
                        <MenuItem value=""><em>Todo</em></MenuItem>
                        {isLoading ? <MenuItem value=""><em>Cargando...</em></MenuItem>:                         
                            data.serie.map(name=> <MenuItem key={name} value={name}>{name}</MenuItem>)
                        }
                       
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="patrimonioSelect-label">
                        Patrimonio
                    </InputLabel>
                    <Select
                        labelId="patrimonioSelect-label"
                        id="patrimonioSelect"
                        value={filters.get('patrimonio')}
                        onChange={handleChange('patrimonio')}
                        labelWidth={labelWidth}
                    >
                        <MenuItem value=""><em>Todo</em></MenuItem>
                        {isLoading ? <MenuItem value=""><em>Cargando...</em></MenuItem>:                                                     
                            data.patrimonio.map(obj=> <MenuItem key={obj.id} value={obj.id}>{obj.name}</MenuItem>)
                        }
                       
                    </Select>
                </FormControl>

               <FormControl variant="outlined" className={classes.formControl}>
                    <Autocomplete
                        id="combo-box-demo"
                        value={filters.get('nombre_instrumento')}
                        options={data.nombre_instrumento ? data.nombre_instrumento: ""}
                        getOptionLabel={option => option}
                        style={{ width: 255 }}
                        loading={isLoading}
                        loadingText="Cargando..."
                        onChange={(name, value)=> handleChangeAutoComplete('nombre_instrumento')(value)}
                        renderInput={params => (
                            <TextField {...params} label="Instrumento" variant="outlined" fullWidth />
                        )}
                    />
                </FormControl>

            </CardBody>
            </div>
            }
        </Card>
    );

}

export default FilterMenu;