import React from 'react'
import ReactDOM from 'react-dom'
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons'
import Particles from 'react-particles-js';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  Row,
  Col
} from "reactstrap";
import { PagesHeader, Footer } from "../../components";

const features = [
  'ps1',
  'ps2',
  'ps3'
]

class Landing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      pageSubcategories: "ps1",

    };
  }
  
  render() {
    return (
      <div>
        <PagesHeader {...this.props} />
        <Parallax ref={ref => (this.parallax = ref)} pages={5}>
          <ParallaxLayer offset={1} speed={1} style={{ backgroundColor: '#805E73' }} />
          <ParallaxLayer offset={2} speed={1} style={{ backgroundColor: '#87BCDE' }} />

          {/* Particles */}
          <ParallaxLayer offset={0} speed={0} factor={4} style={{ backgroundSize: 'cover', background: 'linear-gradient(45deg, #fff 0%, #fff 100%)', filter: 'blur(1px)' }}>
            <Particles params={{
              "particles": {
                "number": {
                  "value": 60,
                  "density": {
                    "enable": true,
                    "value_area": 1500
                  }
                },
                "color": {
                  "value": "#3498db"
                },
                "line_linked": {
                  "enable": true,
                  "opacity": 0.7,
                  "color": "#7f8c8d"
                },
                "move": {
                  "direction": "right",
                  "speed": 0.1
                },
                "size": {
                  "value": 3
                },
                "opacity": {
                  "anim": {
                    "enable": true,
                    "speed": 1,
                    "opacity_min": 0.05
                  }
                }
              },
              "interactivity": {
                "events": {
                  "onhover": {
                    "enable": true,
                    "mode": "repulse"
                  }
                },
                "modes": {
                  "push": {
                    "particles_nb": 1
                  }
                }
              },
              "retina_detect": true
            }} />
          </ParallaxLayer>

          <ParallaxLayer
            offset={0}
            speed={0.1}
            onClick={() => this.parallax.scrollTo(1)}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div>
              <h1 style={{ color: '#34495e' }}> Conectando al mundo de inversion. </h1>
            </div>

          </ParallaxLayer>
          <ParallaxLayer offset={0.5} speed={-0.2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={'/images/hero-screen.png'} style={{ display: 'block', width: '60%'}} />
          </ParallaxLayer>
          <ParallaxLayer offset={0.5} speed={0.2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={'/images/screen-1.png'} style={{ display: 'block', width: '60%'}} />
          </ParallaxLayer>
          <ParallaxLayer offset={0.5} speed={0.4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={'/images/screen-3.png'} style={{ display: 'block', width: '60%'}} />
          </ParallaxLayer>
          <ParallaxLayer offset={0.5} speed={-0.3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={'/images/screen-2.png'} style={{ display: 'block', width: '60%'}} />
          </ParallaxLayer>
          
          <ParallaxLayer offset={2} speed={0.4}>
            <div style={{display: 'block', margin: '0 auto', content: '', height: '3px', width: '600px', backgroundColor: '#34495e'}}></div>
          </ParallaxLayer>
          <ParallaxLayer offset={2.1} speed={0.1}>
            <h2 style={{textAlign: 'center'}}>Un portal para todos tus clientes</h2>
          </ParallaxLayer>
          <ParallaxLayer offset={2.2} speed={-0.4}>
            <div style={{display: 'block', margin: '0 auto', content: '', height: '3px', width: '300px', backgroundColor: '#34495e'}}></div>
          </ParallaxLayer>  
          
          <ParallaxLayer offset={2.5} speed={0.1}>
            <img src={'/images/SVG/Recurso 1.svg'} style={{ display: 'block', width: '60%', margin: '0 auto'}}/>
          </ParallaxLayer>
          <ParallaxLayer offset={2.5} speed={-0.1}>
            <p style={{width: '40%', fontSize: '1.4em', color: '#fff', textAlign: 'center', margin: '0 auto'}}>Fusce egestas scelerisque nisi vitae consequat. Suspendisse a justo erat. Quisque faucibus a lectus in tempor. Maecenas vitae convallis enim. Pellentesque id felis molestie, convallis tortor vel, hendrerit nisi. Etiam lacinia ultrices molestie. Cras sed erat maximus, venenatis augue a, suscipit leo.</p>
          </ParallaxLayer>

          <ParallaxLayer
            offset={3}
            speed={-0.1}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Row style={{padding: '0 15%'}}>

              <Col md={8} xs={12} className="ml-auto mr-auto">
                <TabContent
                  style={{boxShadow: 'none', background:'transparent'}}
                  className="tab-space tab-subcategories"
                  activeTab={this.state.pageSubcategories}
                >
                  <TabPane tabId="ps1">
                    <img src={'/images/1x/panel-1.jpg'} style={{
                      width: '100%', boxShadow: '0 20px 50px 0 rgba(0,0,0,0.2)',
                      borderRadius: '7px'
                    }} />
                  </TabPane>

                  <TabPane tabId="ps2">
                    <img src={'/images/1x/panel-2.jpg'} style={{
                      width: '100%', boxShadow: '0 20px 50px 0 rgba(0,0,0,0.2)',
                      borderRadius: '7px'
                    }} />

                  </TabPane>
                  <TabPane tabId="ps3">
                    <img src={'/images/1x/panel-3.jpg'} style={{
                      width: '100%', boxShadow: '0 20px 50px 0 rgba(0,0,0,0.2)',
                      borderRadius: '7px'
                    }} />
                  </TabPane>
                </TabContent>
              </Col>
              <Col md={4} xs={12} className="ml-auto mr-auto">
                <Card className="card-plain card-subcategories">
                  <CardHeader>
                    <CardTitle tag="h2">
                      Portal Cliente
                    </CardTitle>
                    <br />
                    <p style={{fontSize: '1.4em', color: '#7f8c8d', textAlign: 'justify'}}>
                      Tus clientes obtienen visibilidad de su cartola, consolidando los datos de las administradoras. 
                      Todo esto se muestra junto con la rentabilidad y otras metricas.
                    </p>
                  </CardHeader>
                </Card>


                <Nav
                  style={{flexDirection: 'column', padding: '15px'}}
                  pills
                  className="nav-pills-primary nav-pills-icons justify-content-center"
                >
                  <NavItem>
                    <NavLink style={{ 'cursor': 'pointer', width: '200px', margin: '1em 0'  }}
                      className={
                        this.state.pageSubcategories === "ps1" ? "active" : ""
                      }
                      onClick={() =>
                        this.setState({ pageSubcategories: "ps1" })
                      }
                    >
                      Patrimonio
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink style={{ 'cursor': 'pointer', width: '200px', margin: '1em 0' }}
                      className={
                        this.state.pageSubcategories === "ps2" ? "active" : ""
                      }
                      onClick={() =>
                        this.setState({ pageSubcategories: "ps2" })
                      }
                    >
                      Rentabilidad
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink style={{ 'cursor': 'pointer', width: '200px', margin: '1em 0' }}
                      className={
                        this.state.pageSubcategories === "ps3" ? "active" : ""
                      }
                      onClick={() =>
                        this.setState({ pageSubcategories: "ps3" })
                      }
                    >
                      Reportes
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>

          </ParallaxLayer>

    
          <ParallaxLayer
            offset={4}
            speed={-0}
            style={{ display: 'flex', alignItems: 'flex-end' }}
            onClick={() => this.parallax.scrollTo(0)}>
            <Footer />
          </ParallaxLayer>

        </Parallax>

      </div>
    )
  }
}

export default Landing;