import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Container,
    Row,
    Col
} from "reactstrap";

import {Doughnut} from 'react-chartjs-2';

import {Button, Instructions} from "../../components";

import bgImage from "../../assets/img/2x/reb-bg@2x.png";

import img1 from "../../assets/img/bg1.jpg";
import img2 from "../../assets/img/bg3.jpg";

const asset_type = {}

class Rebalancer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            modalNotice: false
        }
        this.onDismiss = this.onDismiss.bind(this);
        this.toggleModalNotice = this.toggleModalNotice.bind(this);
    }

    onDismiss() {
    }

    toggleModalNotice() {
        this.setState({
            modalNotice: !this.state.modalNotice
        });
    }

    render() {
        return (
            <div>
                <div className="full-page-content">
                    <div className="pricing-page">
                        <Container>
                            <Row>
                                <Breadcrumb>
                                    <BreadcrumbItem><a href="/dashboard/cartolas">Cartola</a></BreadcrumbItem>
                                    <BreadcrumbItem active>Simulador de Cartera</BreadcrumbItem>
                                </Breadcrumb>
                            </Row>
                            <Row>
                                <Col style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "start",
                                    textAlign: "left",
                                    alignItems: "center",
                                    margin: "4em 0",
                                    width: "100%",
                                    maxWidth: "100%",
                                }}
                                     xs={12} md={6}>
                                    <h2 style={{
                                        display: "flex",
                                        alignItems: "center",
                                        margin: "0",
                                    }} className="rebalancer title">Por favor elige un objetivo de inversion.</h2>
                                    <h5 style={{
                                        margin: "auto",
                                        fontWeight: "500",
                                        textShadow: "0 2px 3px rgb(137, 137, 137)",
                                    }} className="description">
                                        Hemos generado estos 3 portafolios para ti.
                                    </h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Card className="card-pricing" style={{padding: "0 2em 0 0"}}>
                                        <CardBody style={{
                                            display: "flex",
                                            width: "100%",
                                            justifyContent: "space-evenly",
                                            alignItems: "center",
                                            padding: "15px 15px 0px 15px"
                                        }}>
                                            <div style={{
                                                maxHeight: "200px",
                                                width: "200px",
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}>
                                                <Doughnut data={asset_type.data} options={asset_type.options}/>

                                            </div>

                                            <CardTitle tag="h3">15%</CardTitle>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start"
                                            }}>
                                                <ul style={{
                                                    display: "flex",
                                                    alignItems: "start",
                                                    flexFlow: "column",
                                                    width: "100%",
                                                    maxWidth: "100%",
                                                    paddingLeft: "4em",
                                                    margin: "0",
                                                }}>
                                                    <li style={{
                                                        color: "#666666",
                                                        fontWeight: "700"
                                                    }}>
                                                        Red de seguridad
                                                    </li>
                                                    <li style={{textAlign: "left"}}
                                                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                                                        rhoncus
                                                        faucibus facilisis. Sed tellus orci, pellentesque eget molestie
                                                        et,
                                                        rhoncus nec quam. Praesent feugiat ligula ut urna sagittis
                                                        pretium.
                                                    </li>
                                                </ul>

                                                <div style={{
                                                    paddingLeft: "4em",
                                                    display: "inline-flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    maxWidth: "100%",
                                                    width: "100%",
                                                    margin: "0"
                                                }}>
                                                    <div>
                                                        <Button color="primary" simple onClick={this.toggleModalNotice}>
                                                            Detalle
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        <Button href="#" color="primary" round>
                                                            Elegir este portafolio    &gt;
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Card className="card-pricing" style={{padding: "0 2em 0 0"}}>
                                        <CardBody style={{
                                            display: "flex",
                                            width: "100%",
                                            justifyContent: "space-evenly",
                                            alignItems: "center",
                                            padding: "15px 15px 0px 15px"
                                        }}>
                                            <div style={{
                                                maxHeight: "200px",
                                                width: "200px",
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}>
                                                <Doughnut data={asset_type.data} options={asset_type.options}/>

                                            </div>

                                            <CardTitle tag="h3">15%</CardTitle>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start"
                                            }}>
                                                <ul style={{
                                                    display: "flex",
                                                    alignItems: "start",
                                                    flexFlow: "column",
                                                    width: "100%",
                                                    maxWidth: "100%",
                                                    paddingLeft: "4em",
                                                    margin: "0",
                                                }}>
                                                    <li style={{
                                                        color: "#666666",
                                                        fontWeight: "700"
                                                    }}>
                                                        Red de seguridad
                                                    </li>
                                                    <li style={{textAlign: "left"}}
                                                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                                                        rhoncus
                                                        faucibus facilisis. Sed tellus orci, pellentesque eget molestie
                                                        et,
                                                        rhoncus nec quam. Praesent feugiat ligula ut urna sagittis
                                                        pretium.
                                                    </li>
                                                </ul>

                                                <div style={{
                                                    paddingLeft: "4em",
                                                    display: "inline-flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    maxWidth: "100%",
                                                    width: "100%",
                                                    margin: "0"
                                                }}>
                                                    <div>
                                                        <Button color="primary" simple onClick={this.toggleModalNotice}>
                                                            Detalle
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        <Button href="#" color="primary" round>
                                                            Elegir este portafolio    &gt;
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <Card className="card-pricing" style={{padding: "0 2em 0 0"}}>
                                        <CardBody style={{
                                            display: "flex",
                                            width: "100%",
                                            justifyContent: "space-evenly",
                                            alignItems: "center",
                                            padding: "15px 15px 0px 15px"
                                        }}>
                                            <div style={{
                                                maxHeight: "200px",
                                                width: "200px",
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}>
                                                <Doughnut data={asset_type.data} options={asset_type.options}/>

                                            </div>

                                            <CardTitle tag="h3">15%</CardTitle>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start"
                                            }}>
                                                <ul style={{
                                                    display: "flex",
                                                    alignItems: "start",
                                                    flexFlow: "column",
                                                    width: "100%",
                                                    maxWidth: "100%",
                                                    paddingLeft: "4em",
                                                    margin: "0",
                                                }}>
                                                    <li style={{
                                                        color: "#666666",
                                                        fontWeight: "700"
                                                    }}>
                                                        Red de seguridad
                                                    </li>
                                                    <li style={{textAlign: "left"}}
                                                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                                                        rhoncus
                                                        faucibus facilisis. Sed tellus orci, pellentesque eget molestie
                                                        et,
                                                        rhoncus nec quam. Praesent feugiat ligula ut urna sagittis
                                                        pretium.
                                                    </li>
                                                </ul>

                                                <div style={{
                                                    paddingLeft: "4em",
                                                    display: "inline-flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    maxWidth: "100%",
                                                    width: "100%",
                                                    margin: "0"
                                                }}>
                                                    <div>
                                                        <Button color="primary" simple onClick={this.toggleModalNotice}>
                                                            Detalle
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        <Button href="#" color="primary" round>
                                                            Elegir este portafolio    &gt;
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            {/*
                                Modals
                            */}
                            <Modal
                                isOpen={this.state.modalNotice}
                                toggle={this.toggleModalNotice}
                                className="modal-notice text-center"
                            >
                                <ModalHeader toggle={this.toggleModalNotice}>
                                    How Do You Become an Affiliate?
                                </ModalHeader>
                                <ModalBody>
                                    <Instructions
                                        title="1. Register"
                                        description={
                                            <span>
                                                                The first step is to create an account at{" "}
                                                <a href="https://www.creative-tim.com/">
                                                                  Creative Tim
                                                                </a>. You can choose a social network or go for
                                                                the classic version, whatever works best for
                                                                you.
                                                              </span>
                                        }
                                        img={img1}
                                    />
                                    <Instructions
                                        title="2. Apply"
                                        description={
                                            <span>
                                                                The first step is to create an account at{" "}
                                                <a href="https://www.creative-tim.com/">
                                                                Creative Tim
                                                                </a>. You can choose a social network or go for
                                                                the classic version, whatever works best for
                                                                you.
                                                            </span>
                                        }
                                        img={img2}
                                    />
                                    <p>
                                        If you have more questions, don't hesitate to
                                        contact us or send us a tweet @creativetim. We're
                                        here to help!
                                    </p>
                                </ModalBody>
                                <ModalFooter className="justify-content-center">
                                    <Button
                                        color="info"
                                        round
                                        onClick={this.toggleModalNotice}
                                    >
                                        Sounds good!
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </Container>
                    </div>
                </div>
                <div
                    className="full-page-background"
                    style={{
                        backgroundImage: "linear-gradient(60deg, rgba(0,0,0,0.1), rgba(0,0,0,0.1)), url(" + bgImage + ")",
                        backgroundAttachment: "fixed",
                        zIndex: 2
                    }}
                />
            </div>
        );
    }
}

export default Rebalancer;
