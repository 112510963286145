import React from 'react';
import {Card, CardBody,	CardHeader, Label} from "reactstrap";
import {AccountCircleTwoTone, AccountBalance, Message, NoteAdd, PermIdentity, Warning} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { urlCartolasClient, urlRebalanceHistorialRebalance } from '../../../api/urls'
import { NavLink } from "react-router-dom";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));
  
function QuickView({user, pending, lastRebalance, clientStaff, clientId}) {
    const classes = useStyles();

    return (
        <Card>
            <CardBody>
            <div className={classes.root}>
                <List component="nav" aria-label="main mailbox folders">
                    <ListItem button>
                        <ListItemIcon>
                            <AccountCircleTwoTone />
                        </ListItemIcon>
                        <ListItemText primary="Ultima Sesión" 
                            secondary={user.last_seen_at ? user.last_seen_at : 'No ha ingresado al portal'} 
                        />
                    </ListItem>

                    <Divider />

                    

                        {
                        lastRebalance && lastRebalance.date != "Sin rebalanceo" ?
                            <NavLink to={`${urlRebalanceHistorialRebalance}?rut_client=${user.rut}&cuenta=${lastRebalance.account}&rebalance=${lastRebalance.id}`} >
                                <ListItem button>
                                    <ListItemIcon>
                                        <AccountBalance />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary="Ultimo Rebalanceo" 
                                        secondary={pending ? 'Obteniendo información' : 
                                            lastRebalance ? lastRebalance.date : "" 
                                        } 
                                    />
                                </ListItem>
                            </NavLink>
                            :
                            <ListItem button>
                                <ListItemIcon>
                                    <AccountBalance />
                                </ListItemIcon>
                                <ListItemText 
                                    primary="Ultimo Rebalanceo" 
                                    secondary={pending ? 'Obteniendo información' : 
                                        lastRebalance ? lastRebalance.date : "" 
                                    } 
                                />
                            </ListItem>
                        }
                        
                    


                    <Divider />
{/** 
                    <ListItem button>
                        <ListItemIcon>
                            <Warning />
                        </ListItemIcon>
                        <ListItemText primary="Perfil de Riesgo" secondary={user.fantasy_name_profile} />
                    </ListItem>
                    <Divider />
*/}

                    <ListItem button>
                        <ListItemIcon>
                            <PermIdentity />
                        </ListItemIcon>
                        <ListItemText 
                            primary="Asesores" 
                            secondary={pending ? 'Obteniendo información' : 
                            clientStaff.length > 0 ? 
                            clientStaff.map((x, i) => <p key={i}>{++i}-{x.name.toUpperCase()}</p>) 
                            : "Sin asesor"
                        } 
                        />
                    </ListItem>
                    
                    <Divider />

                <NavLink to={`${urlCartolasClient}${clientId ? '?id='.concat(clientId) : ''}`} >
                    <ListItem button>
                        <ListItemIcon>
                            <i className="now-ui-icons files_single-copy-04" style={{fontSize:"1.4em"}}></i>
                        </ListItemIcon>
                        <ListItemText primary="Cartola" 
                            secondary={'Ver Cartola Consolidada'} 
                        />
                    </ListItem>
                </NavLink>
                </List>

            </div>
            </CardBody>
        </Card>       

    );
}

export default QuickView;