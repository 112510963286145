import React from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { handle_address, toggle_modal } from '../../actions/user';
import User from '../../components/User/User';


const UserContainer = (notify) => {
    const {user, cartola, modal} = useSelector(state => ({
        user: state.user, 
        cartola: state.cartola,
        modal: state.user.modal}), shallowEqual);
    const dispatch = useDispatch();

    return(
        <User 
            user={user} 
            notify={notify} 
            cartola={cartola} 
            toggleModal={() => dispatch(toggle_modal())}        
            onSubmit={userData => dispatch(handle_address(userData))} 
            modal={modal}
        /> 
    )
}

export default UserContainer;
