export default {
    'view-advisor-dashboard': 'Visualizar panel de asesor',
    'view-client-dashboard': 'Visualizar panel de clientes',
    'list-clients': 'Listar clientes',
    'edit-clients': 'Editar clientes',
    'assign-clients': 'Asignar clientes',
    'create-roles': 'Crear roles',
    'rebalance-accounts': 'Rebalancear carteras de clientes',
    'view-client-details': 'Visualizar detalles de clientes'

};