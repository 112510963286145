import React from 'react';
import {Card, CardBody,	CardHeader, Row, Col} from "reactstrap";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Table from './Table';
import Actions from './Actions';
import QuickView from './QuickView';
import { NavLink } from "react-router-dom";
import {urlStaffHome} from '../../../api/urls'
import {rutFormat, patrimonyFormat} from '../../../helpers/formatter'
import TableMaterialUi from '../../TableMaterialUi'
import { PanelHeader, Header } from "../../../components"

import { AccountCircle, AddCircle, CancelRounded } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


const headCells = [       
    { id: 'rut', numeric: false, disablePadding: false, label: 'Rut', formatter:rutFormat }, 
    { id: 'name', numeric: false, disablePadding: true, label: 'Nombre', padding:"none" },
    { id: 'last_name', numeric: false, disablePadding: true, label: 'Apellido', padding:"none" },
    { id: 'email', numeric: false, disablePadding: true, label: 'Email', padding:"none" },
    { id: 'patrimony', numeric: true, disablePadding: false, label: 'Patrimonio', align:"right", formatter:patrimonyFormat }
    ];

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function MainStaffComponent({data, staffData, pending, params, onClick, staff, onClickClientsOut,
    message}) {

    const classes = useStyles();
    const [tableState, setTableState] = React.useState({		
        order : "desc",
        orderBy : "patrimonio",
        page : 0,
        dense : false,
        searchValue : '',
        rowsPerPage : 5,
        selected : []
    })
    const [showIconButtons, setShowIconButtons] = React.useState(true);
    const [text, setText] = React.useState('');
	const [show, setButton] = React.useState(false);
	const [showConfirm, setButtonConfirm] = React.useState(false);
	// const [staff, setStaff] = React.useState(null);
	const [openConfirm, setOpenConfirm] = React.useState(false);

    // const getTableState = state => {
    //     setTableState(state)
    // }

    React.useEffect(() => {
        if(message){
            setButtonConfirm(false)
            setButton(true)
            setText(message);
        } 
    }, [message]);

    const getTableState = state => {	
		if (state.selected.length > 0){
			showButtons();
		}else{
			hideButtons();
		}
		setTableState(state)
    }

    const showButtons = () => {
		setShowIconButtons(false);
	}

	const hideButtons = () => {
		setShowIconButtons(true);
	}

    const handleAssignOpenModal = () => {
        // console.log(staff, tableState.selected)
		// setStaff(staffData);
		setButtonConfirm(true);
		setButton(true);	
        setText('Confirmar desasignación de los clientes');
        setOpenConfirm(true);
    };

    const onClickConfirm = () => {

		console.log('Desasignando Cliente(s)...', staff)

		setButton(false);
		setText('Desasignando Cliente(s)...');

		onClickClientsOut(staff, tableState.selected)
	}

    const handleClose = () => {
		setOpenConfirm(false);
		// reset()
	};

    return (        
        <div>
            <CardHeader>
                <Breadcrumbs aria-label="breadcrumb">                        
                    <NavLink to={`${urlStaffHome}${params.search ? '?search='.concat(params.search) : ''}`} >Equipo</NavLink>                        
                    <Typography color="textPrimary">Detalle Asesor</Typography>
                </Breadcrumbs>
            </CardHeader>
            <PanelHeader size="sm">
                
            </PanelHeader>
            <Header location={location} />
            <div className="content" id="listclienttop">
                <Card className="card-stats card-raised">
                    <CardBody>
                        <div>
                            <h3 style={{
                                color: '#f96332', marginBottom: '.3em'
                            }}>
                                {data.name} {data.last_name}
                            </h3>
                            <span style={{color: 'gray'}}>Clientes Asignados</span>
                        </div>
                    </CardBody>
                </Card>        
                
                <Row>
                    <Col md={12}>

                        <Tooltip title="Desasignar Clientes" >
                            <IconButton
                                disabled = {showIconButtons}
                                aria-label="Desasignar Clientes"
                                color="inherit"
                                size="medium"
                                // onClick= {() => {handleAssignOpenModal(tableState.selected) }}
                                onClick= {() => {handleAssignOpenModal() }}
                            >
                                <CancelRounded fontSize="inherit" />
                            </IconButton>
                        </Tooltip>



                        {/* <CardHeader>{data.name} {data.last_name}</CardHeader> */}
                        <TableMaterialUi 
                            title={''}
                            rows={staffData} 
                            headCells={headCells} 
                            withCheckbox={true} 
                            onClickRow={onClick}
                            loading={pending}
                            updateState={getTableState}    //TableMaterialUi State
                            initialState={tableState}
                        />
                    </Col>
                    {/* <Col md={3}>
                        <QuickView />
                        <Actions />
                    </Col> */}
                </Row>
            </div>

            <Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={openConfirm}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
				timeout: 500,
				}}
				>
				<Fade in={openConfirm}>
				<div className={classes.paper}>
					<h4 className="text-center" id="transition-modal-title">{text}</h4>
					{show &&
						<div>
							{showConfirm &&
							<button className="btn btn-danger btn-block" type="button" onClick={onClickConfirm}>
								Confirmar
							</button>
							}
							<button className="btn btn-primary btn-block" type="button" onClick={handleClose}>
								Volver
							</button>
						</div>
					}
				</div>
				</Fade>
			</Modal>



        </div>

    );
    
}

export default MainStaffComponent;