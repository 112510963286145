import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

const RestrictionInfo = ({restrictionsSelected}) => {

    const {n_years, recom_ports, n_portafolios, libreRiesgo} = restrictionsSelected
    
    return (
        <Grid item xs={12} md={12}>
            <List>                     
                <ListItem>  
                    <FiberManualRecord fontSize="small"/>                     
                    <ListItemText primary="Cantidad de Años" />
                    <div className="pull-right">
                        <ListItemText primary={n_years} />      
                    </div>                                                    
                </ListItem>
                <ListItem>  
                    <FiberManualRecord fontSize="small"/>                     
                    <ListItemText primary="Portafolios Sugeridos" />
                    <div className="pull-right">
                        <ListItemText primary={recom_ports} />      
                    </div>                                                    
                </ListItem>
                {/* <ListItem>  
                    <ArrowRight />                     
                    <ListItemText primary="Número de Portafolios" />
                    <div className="pull-right">
                        <ListItemText primary={n_portafolios} />      
                    </div>                     
                </ListItem>
                <ListItem>  
                    <ArrowRight />                     
                    <ListItemText primary="Libre Riesgo" />
                    <div className="pull-right">
                        <ListItemText primary={libreRiesgo} />      
                    </div>               
                </ListItem>                         */}
            </List>            
        </Grid>
    );
};

export default RestrictionInfo;