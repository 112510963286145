import React, { Component } from 'react';
import {connect} from 'react-redux';
import {
    getSeriesByFund,
    addNewFundRebalance,
    removeNewFundRebalance    
} from '../../../actions/rebalance';
import {
    getAllFundsByAdmins,
    getAllSeriesByFund,
    fundsLoading,
    seriesLoading,
    getNewFundsRebalance
} from '../../../selectors/rebalance';
import AgregarFondosComp from '../../../components/Rebalance/Restricciones/AgregarFondos';

class index extends Component {

    constructor(props){
        super(props)
        this.getSeriesFund = this.getSeriesFund.bind(this);
    }

    getSeriesFund(fund){      
        this.props.getSeriesByFund({'fund': fund})  
    }

    render() {
        return (
            <div>
                <AgregarFondosComp 
                    funds           ={this.props.fundsByAdmins}
                    isLoadingfunds  ={this.props.fundsLoading}
                    getSeriesFund   ={this.getSeriesFund}
                    seriesByFund    ={this.props.seriesByFund}
                    isLoadingSeries ={this.props.seriesLoading}
                    addNewFund      ={this.props.addNewFund}
                    removeNewFund   ={this.props.removeNewFund}
                    newFunds        ={this.props.newFunds}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    fundsByAdmins: getAllFundsByAdmins(state),
    fundsLoading: fundsLoading(state),
    seriesByFund: getAllSeriesByFund(state),
    seriesLoading: seriesLoading(state),
    newFunds: getNewFundsRebalance(state)
})

const mapDispatchToProps = dispatch => ({
    getSeriesByFund: fund => dispatch(getSeriesByFund(fund)),
    addNewFund: fund => dispatch(addNewFundRebalance(fund)),
    removeNewFund: fund => dispatch(removeNewFundRebalance(fund))
})

export default connect(mapStateToProps, mapDispatchToProps)(index);