import React from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {MATERIAL_TABLE_LOCALIZATION} from './../../../constants';
import {AccountCircle, AccountBalance, Message, NoteAdd, PermIdentity, Warning} from '@material-ui/icons';
import { rutFormat, patrimonyFormat } from '../../../helpers/formatter'

const theme = createTheme({
    palette: {
    primary: {
        main: '#4caf50',
    },
    secondary: {
        light: '#01579b',
        main: '#ff9100',
      },       
    },      
});

const state = {	  
    columns: [
        // { title: 'Id', field: 'id' },
        { title: 'Rut', field: 'rut', width: 150, render: ({rut}) => <span>{rutFormat(rut)}</span> },
            { title: 'Nombre', field: 'name', width: 200},
            { title: 'Apellido', field: 'last_name', width: 200},
            { title: 'Email', field: 'email', width: 300},                
            { title: 'Patrimonio', type: 'currency', field: 'patrimony', render: ({patrimony}) => (
                <span>$ {patrimonyFormat(patrimony)}</span>
            ), width: 150},        
    ],
}

function Table ({data, pending}) {

    const handleRowClicked = (selectedRow) => {
        console.log(selectedRow)
    }

    const handleRowSelected = (rows) => {
        console.log(rows)     
    }
    
    return (
    <MuiThemeProvider theme={theme}>
        <MaterialTable
            title="Clientes"
            columns={state.columns}
            data = {data}  
            isLoading= {pending}
            options={{
                // selection: true,
                sorting: true,
                exportButton: true,
                rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#37b15933' : '' })
                // headerStyle: {
                //     backgroundColor: '#01579b',
                //     color: '#FFF'
                // }
            }}
            onRowClick={((evt, selectedRow) => handleRowClicked(selectedRow) )}                
            onSelectionChange={(rows) => handleRowSelected(rows)}
            // actions={[
            //     {
            //         icon: 'add',
            //         tooltip: 'Agregar Cuenta',
            //         isFreeAction: true,
            //         onClick: (event) => alert("Quieres agregar una cuenta?")
            //     },
            //     {
            //         tooltip: 'Rebalancear',
            //         icon: () => <AccountBalance />,
            //         onClick: (evt, data) => alert('Rebalancear ' + data.length + ' rows')
            //         // onClick: (evt, data) => console.log(data)
            //     },
            // ]}
            localization = {MATERIAL_TABLE_LOCALIZATION}
        />
    </MuiThemeProvider>
    );
   
}

export default Table;