import {CLIENTS_GET_INIT, CLIENTS_GET_SUCCESS, CLIENTS_GET_ERROR,
    CLIENTCARD_TOTAL_GET_INIT, CLIENTCARD_TOTAL_GET_SUCCESS, CLIENTCARD_TOTAL_GET_ERROR,
    CLIENTCARD_INFO_GET_INIT, CLIENTCARD_INFO_GET_SUCCESS, CLIENTCARD_INFO_GET_ERROR,   
    CLIENT_DATA_GET_INIT, CLIENT_DATA_GET_SUCCESS, CLIENT_DATA_GET_ERROR,
    SET_FILTERS_SELECTED_CLIENT, 
    CLIENT_SEND_INVITATION_INIT, CLIENT_SEND_INVITATION_SUCCESS, CLIENT_SEND_INVITATION_ERROR,
    RESET_MESSAGE_CLIENT,
    UPDATE_CLIENT_RISK_PROFILE_INIT, UPDATE_CLIENT_RISK_PROFILE_SUCCESS, UPDATE_CLIENT_RISK_PROFILE_ERROR,
    ADD_CLIENT_INIT, ADD_CLIENT_SUCCESS, ADD_CLIENT_ERROR,
    UPDATE_CLIENT_ACCOUNTS_INIT, UPDATE_CLIENT_ACCOUNTS_SUCCESS, UPDATE_CLIENT_ACCOUNTS_ERROR,
    REMOVE_CLIENT_INIT, REMOVE_CLIENT_SUCCESS, REMOVE_CLIENT_ERROR, STAFF_ASSIGN_LIST
} from './../constants';
import httpClient from '../api/httpClient';
import {urlClients, urlclientsCard, urlclientsCardInfo, urlSendInvitation, urlUpdateClientAccounts, 
    urlClientsDetails, urlClientsAssign, urlUpdateClientUser
} from '../api/urls';
import { updateRouterHistory } from './updateUrl';

export const getClients = (staffList = []) =>{
    return (dispatch, getState) => {
        dispatch(handleStaffAssign(staffList));
        if(getState().client.clients.length == 0){       
            dispatch(getClientsInit());
            httpClient.getAll(urlClients)
            .then(response => dispatch(getClientsSuccess(response)))
            .catch(error => dispatch(getClientsError(error)))
        }
    }
}

export const getClientData = clientId =>{
    return (dispatch, getState) => {
        dispatch(getClientDataInit());
        // httpClient.get(urlClients, client.rut)
        httpClient.get(urlClients, clientId)
        .then(response => dispatch(getClientDataSuccess(response)))
        .catch(error => dispatch(getClientDataError(error))) 
    }
}

export const setFiltersSelected = filters => {
    return dispatch => {
        dispatch(setFiltersSelectedClients(filters))
    }
}

export const sendInvitation = data => {
    return dispatch => {
        dispatch(sendInvitationInit())
        httpClient.create(urlSendInvitation, data)
        .then(response => dispatch(sendInvitationSuccess(response)))
        .catch(error => dispatch(sendInvitationError(error)))        
    }
    
}

export const setResetMessage = () => {
    return (dispatch) => {
        dispatch(resetMessage())
    }
}

export const updateClientUser = (data) => {
    return(dispatch) => {
        console.log(data)
        httpClient.create(urlUpdateClientUser, data)
        .then(response =>
                console.log(response)
            )
        .catch(error => console.log(error))

    }
}

export const handleUpdateRiskProfile = data => {
    return (dispatch, getState) => {
        const clients = getState().client.clients;
        const newClients = clients.reduce((acc, elem)=> {
            if(elem.id == data.id) {
                elem.riskId = data.riskId;
                elem.is_active = data.is_active;
            };
            acc.push(elem)
            return acc
        }, [])
        dispatch(handleUpdateRiskProfileInit())
        //Hacer la logica de fetch para actualizar el registro en la bd
        httpClient.update(urlClients, data.id, data)
        .then(response => dispatch(handleUpdateRiskProfileSuccess(response, newClients)))
        .catch(error => dispatch(handleUpdateRiskProfileError(error)))

    }
}

export const handleAddClient = data => {
    return (dispatch, getState) => {    
        const clients = getState().client.clients;
        const newClients = [...clients];
        data.hasStaff= "No";  
        data.patrimony= 0; 
        data.is_active= 1;
        newClients.push(data)
        dispatch(handleAddClientInit())
        httpClient.create(urlClients, data)
        .then(response => {
            dispatch(handleAddClientSuccess(response, newClients))
        })
        .catch(error => dispatch(handleAddClientError(error)))
    }
}

export const handleRemoveClient = data => {
    return (dispatch, getState) => {    
        const clients = getState().client.clients;
        // const newClients = clients.filter(x => x.rut != data.rut)
        const newClients = clients.reduce((acc, elem)=> {
            if(elem.id == data.id) {
                elem.is_active = 0;
            };
            acc.push(elem)
            return acc
        }, [])
        dispatch(handleRemoveClientInit())
        httpClient.del(urlClients, data.rut)
        .then(response => {
            dispatch(handleRemoveClientSuccess(response, newClients))
        })
        .catch(error => dispatch(handleRemoveClientError(error)))
    }
}

export const handleUpdateClientAccounts = data => {
    return (dispatch, getState) => {   
        console.log('En actions')
        console.log(data)         
        dispatch(handleUpdateClientAccountsInit())        
        httpClient.create(urlUpdateClientAccounts, data)
        .then(response => {
            dispatch(handleUpdateClientAccountsSuccess(response))
        })
        .catch(error => dispatch(handleUpdateClientAccountsError(error)))
    }
}

export const selectClientHomeContainer = (url, clientId, search = '') => {
    return dispatch => {
        if(search){
            dispatch(updateRouterHistory(`${url}?id=${clientId}&search=${search}`));
        }else{
            dispatch(updateRouterHistory(`${url}?id=${clientId}`));
        }        
    }
}

//#region ############# CARD ADVISOR ###############################
export const getClientsCard = () => {
    return dispatch => {
        dispatch(_getClientsCardInit());
        httpClient.getAll(urlclientsCard)
        .then(res => {            
            dispatch(_getClientsCardSuccess(res));
        })
        .catch(error => {            
            dispatch(_getClientsCardError(error));
        })
    }
}

export const getClientsCardInfo = () => {
    return dispatch => {
        dispatch(_getClientsCardInfoInit());
        httpClient.getAll(urlclientsCardInfo)
        .then(res => {            
            dispatch(_getClientsCardInfoSuccess(res));
        })
        .catch(error => {            
            dispatch(_getClientsCardInfoError(error));
        })
    }
}
//#endregion

//#region ########## CARD ADVISOR PAGINATION #######################
export const getNextPage = () => {   
    return (dispatch, getState) => {
        dispatch(_getClientsCardInfoInit());
        httpClient.getAll(getState().client.pagination.next_page_url)
        .then(res => dispatch(_getClientsCardInfoSuccess(res)))
        .catch(error => dispatch(_getClientsCardInfoError(error)))
    }
}

export const getPrevPage = () => {   
    return (dispatch, getState) => {
        dispatch(_getClientsCardInfoInit());
        httpClient.getAll(getState().client.pagination.prev_page_url)
        .then(res => dispatch(_getClientsCardInfoSuccess(res)))
        .catch(error => dispatch(_getClientsCardInfoError(error)))
    }
}

export const getFirstPage = () => {   
    return (dispatch, getState) => {
        dispatch(_getClientsCardInfoInit());
        httpClient.getAll(getState().client.pagination.first_page_url)
        .then(res => dispatch(_getClientsCardInfoSuccess(res)))
        .catch(error => dispatch(_getClientsCardInfoError(error)))
    }
}

export const getLastPage = () => {   
    return (dispatch, getState) => {
        dispatch(_getClientsCardInfoInit());
        httpClient.getAll(getState().client.pagination.last_page_url)
        .then(res => dispatch(_getClientsCardInfoSuccess(res)))
        .catch(error => dispatch(_getClientsCardInfoError(error)))
    }
}
//#endregion

const handleStaffAssign = (staffList) => ({
    type: STAFF_ASSIGN_LIST,
    payload: {
        staffList
    }
})

const handleRemoveClientInit = () => ({
    type: REMOVE_CLIENT_INIT
})

const handleRemoveClientSuccess = (response, newClients) => ({
    type: REMOVE_CLIENT_SUCCESS,
    payload: {
        response,
        newClients
    }
})

const handleRemoveClientError = (error) => ({
    type: REMOVE_CLIENT_ERROR,
    payload: {
        error
    }
})


const handleUpdateClientAccountsInit = () => ({
    type: UPDATE_CLIENT_ACCOUNTS_INIT,
})

const handleUpdateClientAccountsSuccess = (response) => ({
    type: UPDATE_CLIENT_ACCOUNTS_SUCCESS,
    payload: {response}
})

const handleUpdateClientAccountsError = (error) => ({
    type: UPDATE_CLIENT_ACCOUNTS_ERROR,
    payload: {error}
})


const handleAddClientInit = () => ({
    type: ADD_CLIENT_INIT,
})

const handleAddClientSuccess = (response, newClients) => ({
    type: ADD_CLIENT_SUCCESS,
    payload: {
        response,
        newClients
    }
})

const handleAddClientError = (error) => ({
    type: ADD_CLIENT_ERROR,
    payload: {
        error
    }
})

const handleUpdateRiskProfileInit = () => ({
    type: UPDATE_CLIENT_RISK_PROFILE_INIT,
})

const handleUpdateRiskProfileSuccess = (response, newClients) => ({
    type: UPDATE_CLIENT_RISK_PROFILE_SUCCESS,
    payload: {
        response,
        newClients
    }
})

const handleUpdateRiskProfileError = (error) => ({
    type: UPDATE_CLIENT_RISK_PROFILE_ERROR,
    payload: {
        error
    }
})


const resetMessage = () => ({
    type: RESET_MESSAGE_CLIENT,
})


const sendInvitationInit = () => ({
    type: CLIENT_SEND_INVITATION_INIT,
})

const sendInvitationSuccess = (response) => ({
    type: CLIENT_SEND_INVITATION_SUCCESS,
    payload: {
        response
    }
})

const sendInvitationError = (error) => ({
    type: CLIENT_SEND_INVITATION_ERROR,
    payload: {
        error
    }
})



const _getClientsCardInfoInit = () => ({
    type: CLIENTCARD_INFO_GET_INIT
})

const _getClientsCardInfoSuccess = (clients) => ({
    type: CLIENTCARD_INFO_GET_SUCCESS,
    payload: {
        clients
    }
})

const _getClientsCardInfoError = (error) => ({
    type: CLIENTCARD_INFO_GET_ERROR,
    payload: {
        error
    }
})

const _getClientsCardInit = () => ({
    type: CLIENTCARD_TOTAL_GET_INIT
})

const _getClientsCardSuccess = (clients) => ({
    type: CLIENTCARD_TOTAL_GET_SUCCESS,
    payload: {
        clients
    }
})

const _getClientsCardError = (error) => ({
    type: CLIENTCARD_TOTAL_GET_ERROR,
    payload: {
        error
    }
})


const getClientsInit = () => ({
    type: CLIENTS_GET_INIT
})

const getClientsSuccess = clients => ({
    type: CLIENTS_GET_SUCCESS,
    payload: {
        clients
    }
})

const getClientsError = error => ({
    type: CLIENTS_GET_ERROR,
    payload: {
        error
    }
})

const getClientDataInit = client => ({
    type: CLIENT_DATA_GET_INIT,
    payload:{
        client
    }
})

const getClientDataSuccess = clientAccounts => ({
    type: CLIENT_DATA_GET_SUCCESS,
    payload: {
        clientAccounts
    }
})

const getClientDataError = error => ({
    type: CLIENT_DATA_GET_ERROR,
    payload: {
        error
    }
})

const setFiltersSelectedClients = filters => ({
    type: SET_FILTERS_SELECTED_CLIENT,
    payload: {
        filters
    }
})