import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RebalanceHistorial from '../../components/RebalanceHistorial';
import {getRebalanceHistorial,
getAccountHistorial, setTableStateHistorial
} from '../../actions/rebalanceHistorial'
import {accounts, isLoading, 
} from '../../selectors/rebalanceHistorial';
import { urlRebalanceHistorialAccounts } from '../../api/urls';
import { urlParamsFormat } from '../../helpers/formatter';
import withParams from '../../helpers/withParams';

class HomeContainer extends Component {

    constructor(props){
        super(props)
        const {adjust, by, order, page, per, search} = this.props.params;
        this.state = {
            tableState: {                		
                order: order,
                orderBy: by,
                page: page ? parseInt(page) : page,
                dense: adjust,
                searchValue: search,
                rowsPerPage: per ? parseInt(per) : per,
                selected : []
            }            
        }
        this.handleOnClickAccount = this.handleOnClickAccount.bind(this);
    }

    componentDidMount(){
        this.props.getRebalanceHistorial();
    }

    handleOnClickAccount(data){  
        const urlTableParams = urlParamsFormat(this.props.tableState);
        this.props.history.push(`${urlRebalanceHistorialAccounts}?rut_client=${data.rut_client}&cuenta=${data.cuenta}&${urlTableParams}`)
        
    }

    render() {
        return (
            <div>
                <RebalanceHistorial 
                    data={this.props.accounts} 
                    onClick={this.handleOnClickAccount} 
                    isLoading={this.props.isLoading} 
                    getTableState={this.props.setTableStateHistorial}
                    tableState={this.props.params.order ? this.state.tableState : this.props.tableState}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    accounts: accounts(state),
    isLoading: isLoading(state),
    tableState: state.rebalanceHistorial.tableState
})

const mapDispatchToProps = dispatch => ({
    getRebalanceHistorial: () => dispatch(getRebalanceHistorial()),
    getAccountHistorial: data => dispatch(getAccountHistorial(data)),  
    setTableStateHistorial: state => dispatch(setTableStateHistorial(state))  
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withParams(HomeContainer)));