import React from 'react';
import { Card, CardBody, CardHeader } from "reactstrap";

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function FilterMenu({ data, filters, setFilters, isLoading }) {

    const classes = useStyles();

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth * 2);
    }, []);

    const handleChange = name => event => {
        console.log(name)
        console.log(event.target.value)
        setFilters({ key: name, value: event.target.value })
    };

    return (
        <div>
            <h5>FILTROS</h5>

            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabel} id="perfilRiesgoSelect-label">
                    Perfil Riesgo
                </InputLabel>
                <Select
                    labelId="perfilRiesgoSelect-label"
                    id="perfilRiesgoSelect"
                    value={filters.get('perfilRiesgo')}
                    onChange={handleChange('perfilRiesgo')}
                    labelWidth={labelWidth}
                >
                    <MenuItem value=""><em>Todo</em></MenuItem>
                    {isLoading ? <MenuItem value=""><em>Cargando...</em></MenuItem> :
                        // <MenuItem value="">Ready</MenuItem>                        
                        data.riskProfile.map(name => <MenuItem key={name} value={name}>{name}</MenuItem>)
                    }
                </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabel} id="patrimonioSelect-label">
                    Patrimonio
                    </InputLabel>
                <Select
                    labelId="patrimonioSelect-label"
                    id="patrimonioSelect"
                    value={filters.get('patrimonio')}
                    onChange={handleChange('patrimonio')}
                    labelWidth={labelWidth}
                >
                    <MenuItem value=""><em>Todo</em></MenuItem>
                    {isLoading ? <MenuItem value=""><em>Cargando...</em></MenuItem> :
                        // <MenuItem value="">Ready</MenuItem>                                                        
                        data.patrimony.map(obj => <MenuItem key={obj.id} value={obj.id}>{obj.name}</MenuItem>)
                    }

                </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabel} id="asesoresSelect-label">
                    Con Asesor
                    </InputLabel>
                <Select
                    labelId="asesoresSelect-label"
                    id="asesoresSelect"
                    value={filters.get('hasStaff')}
                    onChange={handleChange('hasStaff')}
                    labelWidth={labelWidth}
                >
                    <MenuItem value=""><em>Todo</em></MenuItem>
                    {isLoading ? <MenuItem value=""><em>Cargando...</em></MenuItem> :
                        // <MenuItem value="">Ready</MenuItem>                                                        
                        data.hasStaff.map(name => <MenuItem key={name} value={name}>{name}</MenuItem>)
                    }

                </Select>
            </FormControl>

        </div>
    );

}

export default FilterMenu;