import {
    ADD_ROUTER_HISTORY,
    UPDATE_ROUTER_HISTORY,
    ROUTER_HISTORY_ERROR
} from '../constants';

export const addRouterHistory = history => {
    return (dispatch) => {
        dispatch(_addRouterHistory(history));
    }
}

export const updateRouterHistory = url => {
    return (dispatch, getState) => {
        try {
            getState().updateUrl.history.push(url)
            dispatch(_updateRouterHistory());
        } catch (error) {
            console.log(error);
            dispatch(_error(error))
        }
    }
}

const _addRouterHistory = history => ({
    type: ADD_ROUTER_HISTORY,
    payload: {
        history
    }
})

const _updateRouterHistory = () => ({
    type: UPDATE_ROUTER_HISTORY
})

const _error = error => ({
    type: ROUTER_HISTORY_ERROR,
    payload: {
        error
    }
})