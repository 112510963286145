export const LOGIN_INIT = 'LOGIN_INIT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const FORGOT_PASSWORD_INIT = 'FORGOT_PASSWORD_INIT';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const PASSWORD_RESET_INIT = 'PASSWORD_RESET_INIT';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';

export const NOT_LOGOUT_INIT    = 'NOT_LOGOUT_INIT';
export const NOT_LOGOUT_SUCCESS = 'NOT_LOGOUT_SUCCESS';
export const NOT_LOGOUT_ERROR   = 'NOT_LOGOUT_ERROR';

export const LOGIN_FAKE_INIT = 'LOGIN_FAKE_INIT';
export const LOGIN_FAKE_SUCCESS = 'LOGIN_FAKE_SUCCESS';

export const LOGOUT_INIT = 'LOGOUT_INIT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const USER_INIT = 'USER_INIT';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILED = 'USER_FAILED';

export const UPDATE_ADDRESS_INIT = 'UPDATE_ADDRESS_INIT'; 
export const UPDATE_ADDRESS_POST = 'UPDATE_ADDRESS_POST';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';

export const HANDLE_USER_MODAL = 'HANDLE_USER_MODAL'; 

export const GET_TOKEN = 'GET_TOKEN';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_ERROR = 'GET_TOKEN_ERROR';

//#region ################# HOME VIEW ############################################

export const PATRIMONY_TOTAL_GET_INIT    = 'PATRIMONY_TOTAL_GET_INIT';
export const PATRIMONY_TOTAL_GET_SUCCESS = 'PATRIMONY_TOTAL_GET_SUCCESS';
export const PATRIMONY_TOTAL_GET_ERROR   = 'PATRIMONY_TOTAL_GET_ERROR';
export const PATRIMONY_INFO_GET_INIT    = 'PATRIMONY_INFO_GET_INIT';
export const PATRIMONY_INFO_GET_SUCCESS = 'PATRIMONY_INFO_GET_SUCCESS';
export const PATRIMONY_INFO_GET_ERROR   = 'PATRIMONY_INFO_GET_ERROR';

export const CLIENTCARD_TOTAL_GET_INIT    = 'CLIENTCARD_TOTAL_GET_INIT';
export const CLIENTCARD_TOTAL_GET_SUCCESS = 'CLIENTCARD_TOTAL_GET_SUCCESS';
export const CLIENTCARD_TOTAL_GET_ERROR   = 'CLIENTCARD_TOTAL_GET_ERROR';
export const CLIENTCARD_INFO_GET_INIT    = 'CLIENTCARD_INFO_GET_INIT';
export const CLIENTCARD_INFO_GET_SUCCESS = 'CLIENTCARD_INFO_GET_SUCCESS';
export const CLIENTCARD_INFO_GET_ERROR   = 'CLIENTCARD_INFO_GET_ERROR';

export const STAFFCARD_TOTAL_INIT   = 'STAFFCARD_TOTAL_INIT';
export const STAFFCARD_TOTAL_SUCCESS= 'STAFFCARD_TOTAL_SUCCESS';
export const STAFFCARD_TOTAL_ERROR  = 'STAFFCARD_TOTAL_ERROR';
export const STAFFCARD_INFO_GET_INIT    = 'STAFFCARD_INFO_GET_INIT';
export const STAFFCARD_INFO_GET_SUCCESS = 'STAFFCARD_INFO_GET_SUCCESS';
export const STAFFCARD_INFO_GET_ERROR   = 'STAFFCARD_INFO_GET_ERROR';

// export const FONDOMUTUO_TOTAL_INIT       = 'FONDOMUTUO_TOTAL_INIT';
// export const FONDOMUTUO_TOTAL_SUCCESS    = 'FONDOMUTUO_TOTAL_SUCCESS';
// export const FONDOMUTUO_TOTAL_ERROR      = 'FONDOMUTUO_TOTAL_ERROR';
export const FONDOMUTUO_INFO_GET_INIT    = 'FONDOMUTUO_INFO_GET_INIT';
export const FONDOMUTUO_INFO_GET_SUCCESS = 'FONDOMUTUO_INFO_GET_SUCCESS';
export const FONDOMUTUO_INFO_GET_ERROR   = 'FONDOMUTUO_INFO_GET_ERROR';

// export const ACTIVO_TOTAL_INIT       = 'ACTIVO_TOTAL_INIT';
// export const ACTIVO_TOTAL_SUCCESS    = 'ACTIVO_TOTAL_SUCCESS';
// export const ACTIVO_TOTAL_ERROR      = 'ACTIVO_TOTAL_ERROR';
export const ACTIVO_INFO_GET_INIT    = 'ACTIVO_INFO_GET_INIT';
export const ACTIVO_INFO_GET_SUCCESS = 'ACTIVO_INFO_GET_SUCCESS';
export const ACTIVO_INFO_GET_ERROR   = 'ACTIVO_INFO_GET_ERROR';

export const ADMINISTRADORA_TOTAL_INIT       = 'ADMINISTRADORA_TOTAL_INIT';
export const ADMINISTRADORA_TOTAL_SUCCESS    = 'ADMINISTRADORA_TOTAL_SUCCESS';
export const ADMINISTRADORA_TOTAL_ERROR      = 'ADMINISTRADORA_TOTAL_ERROR';
export const ADMINISTRADORA_INFO_GET_INIT    = 'ADMINISTRADORA_INFO_GET_INIT';
export const ADMINISTRADORA_INFO_GET_SUCCESS = 'ADMINISTRADORA_INFO_GET_SUCCESS';
export const ADMINISTRADORA_INFO_GET_ERROR   = 'ADMINISTRADORA_INFO_GET_ERROR';

export const REMINDERS_GET_INIT = 'REMINDERS_GET_INIT';
export const REMINDERS_GET_SUCCESS = 'REMINDERS_GET_SUCCESS';
export const REMINDERS_STORE_INIT = 'REMINDERS_STORE_INIT';
export const REMINDERS_STORE_SUCCESS = 'REMINDERS_STORE_SUCCESS';
export const REMINDERS_CLEAR = 'REMINDERS_CLEAR';
export const REMINDERS_ERROR = 'REMINDERS_ERROR';

//#endregion

//#region #################### CLIENT & STAFF #######################################

export const CLIENTS_GET_INIT = 'CLIENTS_GET_INIT';
export const CLIENTS_GET_SUCCESS = 'CLIENTS_GET_SUCCESS';
export const CLIENTS_GET_ERROR = 'CLIENTS_GET_ERROR';

export const CLIENTS_ASSIGN_LIST = 'CLIENTS_ASSIGN_LIST';
export const STAFF_ASSIGN_LIST = 'STAFF_ASSIGN_LIST';

export const CLIENT_DATA_GET_INIT   = 'CLIENT_DATA_GET_INIT';
export const CLIENT_DATA_GET_SUCCESS= 'CLIENT_DATA_GET_SUCCESS';
export const CLIENT_DATA_GET_ERROR  = 'CLIENT_DATA_GET_ERROR';

export const SET_FILTERS_SELECTED_CLIENT  = 'SET_FILTERS_SELECTED_CLIENT';
export const CLIENT_SEND_INVITATION_INIT    = 'CLIENT_SEND_INVITATION_INIT';
export const CLIENT_SEND_INVITATION_SUCCESS = 'CLIENT_SEND_INVITATION_SUCCESS';
export const CLIENT_SEND_INVITATION_ERROR   = 'CLIENT_SEND_INVITATION_ERROR';

export const STAFF_GET_INIT = 'STAFF_GET_INIT';
export const STAFF_GET_SUCCESS = 'STAFF_GET_SUCCESS';
export const STAFF_GET_ERROR = 'STAFF_GET_ERROR';

export const STAFF_DATA_GET_INIT   = 'STAFF_DATA_GET_INIT';
export const STAFF_DATA_GET_SUCCESS= 'STAFF_DATA_GET_SUCCESS';
export const STAFF_DATA_GET_ERROR  = 'STAFF_DATA_GET_ERROR';

export const STAFF_ASSIGN_CLIENT_INIT = 'STAFF_ASSIGN_CLIENT_INIT';
export const STAFF_ASSIGN_CLIENT_SUCCESS = 'STAFF_ASSIGN_CLIENT_SUCCESS';
export const STAFF_ASSIGN_CLIENT_ERROR = 'STAFF_ASSIGN_CLIENT_ERROR';

export const RESET_STATUS_RESPONSE = 'RESET_STATUS_RESPONSE';
export const RESET_MESSAGE_CLIENT = 'RESET_MESSAGE_CLIENT';

export const UPDATE_CLIENT_RISK_PROFILE_INIT    = 'UPDATE_CLIENT_RISK_PROFILE_INIT';
export const UPDATE_CLIENT_RISK_PROFILE_SUCCESS = 'UPDATE_CLIENT_RISK_PROFILE_SUCCESS';
export const UPDATE_CLIENT_RISK_PROFILE_ERROR   = 'UPDATE_CLIENT_RISK_PROFILE_ERROR';

export const ADD_CLIENT_INIT    = 'ADD_CLIENT_INIT';
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_ERROR   = 'ADD_CLIENT_ERROR';
export const ADD_STAFF_INIT    = 'ADD_STAFF_INIT';
export const ADD_STAFF_SUCCESS = 'ADD_STAFF_SUCCESS';
export const ADD_STAFF_ERROR   = 'ADD_STAFF_ERROR';

export const REMOVE_CLIENT_INIT    = 'REMOVE_CLIENT_INIT';
export const REMOVE_CLIENT_SUCCESS = 'REMOVE_CLIENT_SUCCESS';
export const REMOVE_CLIENT_ERROR   = 'REMOVE_CLIENT_ERROR';
export const REMOVE_STAFF_INIT    = 'REMOVE_STAFF_INIT';
export const REMOVE_STAFF_SUCCESS = 'REMOVE_STAFF_SUCCESS';
export const REMOVE_STAFF_ERROR   = 'REMOVE_STAFF_ERROR';

export const ADMINISTRADORAS_GET_INIT    = 'ADMINISTRADORAS_GET_INIT';
export const ADMINISTRADORAS_GET_SUCCESS = 'ADMINISTRADORAS_GET_SUCCESS';
export const ADMINISTRADORAS_GET_ERROR   = 'ADMINISTRADORAS_GET_ERROR';
export const ACCOUNTS_GET_INIT    = 'ACCOUNTS_GET_INIT';
export const ACCOUNTS_GET_SUCCESS = 'ACCOUNTS_GET_SUCCESS';
export const ACCOUNTS_GET_ERROR   = 'ACCOUNTS_GET_ERROR';

export const UPDATE_CLIENT_ACCOUNTS_INIT    = 'UPDATE_CLIENT_ACCOUNTS_INIT';
export const UPDATE_CLIENT_ACCOUNTS_SUCCESS = 'UPDATE_CLIENT_ACCOUNTS_SUCCESS';
export const UPDATE_CLIENT_ACCOUNTS_ERROR   = 'UPDATE_CLIENT_ACCOUNTS_ERROR';

//#endregion

//#region #################### REBALANCE ##########################################

export const CARTERA_GET_INIT = 'CARTERA_GET_INIT';
export const CARTERA_GET_SUCCESS = 'CARTERA_GET_SUCCESS';
export const CARTERA_GET_ERROR = 'CARTERA_GET_ERROR';

export const FUNDS_BY_ACCOUNT_GET_INIT = 'FUNDS_BY_ACCOUNT_GET_INIT';
export const FUNDS_BY_ACCOUNT_GET_SUCCESS= 'FUNDS_BY_ACCOUNT_GET_SUCCESS';
export const FUNDS_BY_ACCOUNT_GET_ERROR= 'FUNDS_BY_ACCOUNT_GET_ERROR';

export const SERIES_BY_FUND_GET_INIT   = 'SERIES_BY_FUND_GET_INIT';
export const SERIES_BY_FUND_GET_SUCCESS= 'SERIES_BY_FUND_GET_SUCCESS';
export const SERIES_BY_FUND_GET_ERROR  = 'SERIES_BY_FUND_GET_ERROR';

export const ADD_NEW_FUND_REBALANCE   = 'ADD_NEW_FUND_REBALANCE';
export const REMOVE_NEW_FUND_REBALANCE= 'REMOVE_NEW_FUND_REBALANCE';

export const SET_ACCOUNT_SELECTED_REBALANCE= 'SET_ACCOUNT_SELECTED_REBALANCE';
export const SET_FILTERS_SELECTED_REBALANCE= 'SET_FILTERS_SELECTED_REBALANCE';

export const REBALANCE_GET_INIT = 'REBALANCE_GET_INIT';
export const REBALANCE_GET_SUCCESS= 'REBALANCE_GET_SUCCESS';
export const REBALANCE_GET_ERROR= 'REBALANCE_GET_ERROR';
export const REBALANCE_RESET= 'REBALANCE_RESET';

export const REBALANCE_GET_PDF_INIT     = 'REBALANCE_GET_PDF_INIT';
export const REBALANCE_GET_PDF_SUCCESS  = 'REBALANCE_GET_PDF_SUCCESS';
export const REBALANCE_GET_PDF_ERROR    = 'REBALANCE_GET_PDF_ERROR';

export const REBALANCE_SAVE_SELECTED_PORTAFOLIO_INIT     = 'REBALANCE_SAVE_SELECTED_PORTAFOLIO_INIT';
export const REBALANCE_SAVE_SELECTED_PORTAFOLIO_SUCCESS  = 'REBALANCE_SAVE_SELECTED_PORTAFOLIO_SUCCESS';
export const REBALANCE_SAVE_SELECTED_PORTAFOLIO_ERROR    = 'REBALANCE_SAVE_SELECTED_PORTAFOLIO_ERROR';

//#endregion

//#region #################### REBALANCE HISTORIAL  ###############################

export const REBHISTORIAL_GET_CARTERA_INIT     = 'REBHISTORIAL_GET_CARTERA_INIT';
export const REBHISTORIAL_GET_CARTERA_SUCCESS  = 'REBHISTORIAL_GET_CARTERA_SUCCESS';
export const REBHISTORIAL_GET_CARTERA_ERROR    = 'REBHISTORIAL_GET_CARTERA_ERROR';

export const REBHISTORIAL_GET_ACCOUNT_INIT     = 'REBHISTORIAL_GET_ACCOUNT_INIT';
export const REBHISTORIAL_GET_ACCOUNT_SUCCESS  = 'REBHISTORIAL_GET_ACCOUNT_SUCCESS';
export const REBHISTORIAL_GET_ACCOUNT_ERROR    = 'REBHISTORIAL_GET_ACCOUNT_ERROR';

export const REBHISTORIAL_ACCOUNT_RESET = 'REBHISTORIAL_ACCOUNT_RESET';
export const REBHISTORIAL_HISTORIAL_RESET = 'REBHISTORIAL_HISTORIAL_RESET';
export const REBHISTORIAL_PORTAFOLIO_RESET = 'REBHISTORIAL_PORTAFOLIO_RESET';

export const REBHISTORIAL_SELECT_HISTORIAL_INIT = 'REBHISTORIAL_SELECT_HISTORIAL_INIT';
export const REBHISTORIAL_SELECT_HISTORIAL_SUCCESS = 'REBHISTORIAL_SELECT_HISTORIAL_SUCCESS';
export const REBHISTORIAL_SELECT_HISTORIAL_ERROR = 'REBHISTORIAL_SELECT_HISTORIAL_ERROR';

export const REBHISTORIAL_SELECT_PORTAFOLIO = 'REBHISTORIAL_SELECT_PORTAFOLIO';


export const HIST_REBALANCE_GET_PDF_INIT     = 'HIST_REBALANCE_GET_PDF_INIT';
export const HIST_REBALANCE_GET_PDF_SUCCESS  = 'HIST_REBALANCE_GET_PDF_SUCCESS';
export const HIST_REBALANCE_GET_PDF_ERROR    = 'HIST_REBALANCE_GET_PDF_ERROR';

export const UPDATE_REBHISTORIAL_DONE_INIT    = 'UPDATE_REBHISTORIAL_DONE_INIT';
export const UPDATE_REBHISTORIAL_DONE_SUCCESS = 'UPDATE_REBHISTORIAL_DONE_SUCCESS';
export const UPDATE_REBHISTORIAL_DONE_ERROR   = 'UPDATE_REBHISTORIAL_DONE_ERROR';

export const REBALANCE_HISTORIAL_TABLE_STATE   = 'REBALANCE_HISTORIAL_TABLE_STATE';

export const REBALANCE_ACCOUNT_TABLE_STATE = 'REBALANCE_ACCOUNT_TABLE_STATE';

//#endregion

//#region ##### CARTOLA CLIENT ADVISOR ######################################
export const CARTOLA_ADVISOR_CLIENT_INIT     = 'CARTOLA_ADVISOR_CLIENT_INIT';
export const CARTOLA_ADVISOR_CLIENT_SUCCESS  = 'CARTOLA_ADVISOR_CLIENT_SUCCESS';
export const CARTOLA_ADVISOR_CLIENT_ERROR    = 'CARTOLA_ADVISOR_CLIENT_ERROR';

export const RESET_CARTOLA_CLIENT    = 'RESET_CARTOLA_CLIENT';

export const LIST_CLIENTS_CARTOLA_INIT      = 'LIST_CLIENTS_CARTOLA_INIT';
export const LIST_CLIENTS_CARTOLA_SUCCESS   = 'LIST_CLIENTS_CARTOLA_SUCCESS';
export const LIST_CLIENTS_CARTOLA_ERROR     = 'LIST_CLIENTS_CARTOLA_ERROR';
export const LIST_CLIENTS_TABLE_STATE = 'LIST_CLIENTS_TABLE_STATE';

//#endregion

//#region ##### UPLOAD FILE ######################################
export const GET_UPLOAD_INIT     = 'GET_UPLOAD_INIT';
export const GET_UPLOAD_SUCCESS  = 'GET_UPLOAD_SUCCESS';
export const GET_UPLOAD_ERROR    = 'GET_UPLOAD_ERROR';

export const UPLOAD_FILE_INIT     = 'UPLOAD_FILE_INIT';
export const UPLOAD_FILE_SUCCESS  = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR    = 'UPLOAD_FILE_ERROR';

//#endregion

//#########################################################################

export const START_TIMER = 'START_TIMER';
export const STOP_TIMER = 'STOP_TIMER';
export const TICK = 'TICK';

//#region ######################################### PERMISSIONS ####################
export const PERMISSIONS_GET_INIT = 'PERMISSIONS_GET_INIT';
export const PERMISSIONS_GET_SUCCESS = 'PERMISSIONS_GET_SUCCESS';

export const ROLES_GET_INIT = 'ROLES_GET_INIT';
export const ROLES_GET_SUCCESS = 'ROLES_GET_SUCCESS';
export const ROLES_UPDATE_INIT = 'ROLES_UPDATE_INIT';
export const ROLES_UPDATE_SUCCESS = 'ROLES_UPDATE_SUCCESS';
export const ROLE_PERMISSIONS_INIT = 'ROLE_PERMISSIONS_INIT';
export const ROLE_PERMISSIONS_SUCCESS = 'ROLE_PERMISSIONS_SUCCESS';
export const ROLE_CREATE_INIT = 'ROLE_CREATE_INIT';
export const ROLE_CREATE_SUCCESS = 'ROLE_CREATE_SUCCESS';
export const ROLE_DELETE_INIT = 'ROLE_DELETE_INIT';
export const ROLE_DELETE_SUCCESS = 'ROLE_DELETE_SUCCESS';
export const SET_SELECTED_ROLE = 'SET_SELECTED_ROLE';
export const SET_APP_PERMISSIONS = 'SET_APP_PERMISSIONS';
export const SET_EDIT_ACTIVE_ROLE = 'SET_EDIT_ACTIVE_ROLE';
export const SET_CREATE_ACTIVE_ROLE = 'SET_CREATE_ACTIVE_ROLE';
export const SET_OPEN_EDIT_ROLE = 'SET_OPEN_EDIT_ROLE';
export const SET_OPEN_CREATE_ROLE = 'SET_OPEN_CREATE_ROLE';
export const SET_OPEN_ASSIGN_ROLE = 'SET_OPEN_ASSIGN_ROLE';
export const SHOW_ROLE_CREATE_SUCCESS = 'SHOW_ROLE_CREATE_SUCCESS';
export const SHOW_ROLE_DELETION_WARNING = 'SHOW_ROLE_DELETION_WARNING';
export const SHOW_ROLE_MODIFICATION_WARNING = 'SHOW_ROLE_MODIFICATION_WARNING';
export const SHOW_ROLE_MODIFICATION_CONFIRM = 'SHOW_ROLE_MODIFICATION_CONFIRM';
export const SET_ASSIGN_ACTIVE = 'SET_ASSIGN_ACTIVE';
export const ASSIGN_ROLES_TO_USERS = 'ASSIGN_ROLES_TO_USERS';
export const ASSIGN_ROLES_TO_USERS_INIT = 'ASSIGN_ROLES_TO_USERS_INIT';
export const ASSIGN_ROLES_TO_USERS_SUCCESS = 'ASSIGN_ROLES_TO_USERS_SUCCESS';
export const GET_USERS_WITH_ROLES = 'GET_USERS_WITH_ROLES';
export const GET_USERS_WITH_ROLES_SUCCESS = 'GET_USERS_WITH_ROLES_SUCCESS';

export const FETCH_BREAKDOWN_INIT = 'FETCH_BREAKDOWN_INIT';
export const FETCH_BREAKDOWN_ERROR = 'FETCH_BREAKDOWN_ERROR';
export const FETCH_BREAKDOWN_SUCCESS = 'FETCH_BREAKDOWN_SUCCESS';

export const FETCH_COMPOSITION_INIT = 'FETCH_COMPOSITION_INIT';
export const FETCH_COMPOSITION_SUCCESS = 'FETCH_COMPOSITION_SUCCESS';
export const FETCH_COMPOSITION_ERROR = "FETCH_COMPOSITION_ERROR";

export const SET_CLIENT_ID = 'SET_CLIENT_ID';

export const FETCH_CARTOLAPDF_INIT = 'FETCH_CARTOLAPDF_INIT';
export const FETCH_CARTOLAPDF_ERROR = 'FETCH_CARTOLAPDF_ERROR';
export const FETCH_CARTOLAPDF_SUCCESS = 'FETCH_CARTOLAPDF_SUCCESS';

//#endregion

//#region ############## MATERIAL_TABLE_LOCALIZATION ########################

export const MATERIAL_TABLE_LOCALIZATION = {
    pagination: {
        labelRowsSelect: 'registros',
        labelDisplayedRows: '{from}-{to} de {count}',
        firstTooltip: 'Primero',
        previousTooltip: 'Anterior',
        nextTooltip: 'Siguiente',
        lastTooltip: 'Último'
    },
    toolbar: {
        nRowsSelected: '{0} registro(s)',
        searchTooltip: 'Buscar',
        searchPlaceholder: 'Buscar',
        exportTitle: 'Exportar',
        exportAriaLabel: 'Exportar',
        exportName: 'Exportar a CSV'
    },
    header: {
        actions: 'Acciones'
    },
    body: {
        emptyDataSourceMessage: 'No hay registros que mostrar',
        filterRow: {
            filterTooltip: 'Filtro'
        },
        deleteTooltip: 'Eliminar',
        editTooltip: 'Editar',
        editRow: { 
            deleteText: '¿Estás seguro de eliminar este registro?',
            cancelTooltip: 'Cancelar',
            saveTooltip: 'Confirmar'
        }
    }
};

//#endregion

//#########################################################################

export const SET_DATE_CARTOLA = 'SET_DATE_CARTOLA';

export const SELECT_ACCOUNT_INIT = 'SELECT_ACCOUNT_INIT';
export const SELECT_ACCOUNT = 'SELECT_ACCOUNT';
export const SELECT_ACCOUNT_SUCCESS = 'SELECT_ACCOUNT_SUCCESS';

export const SELECT_DATE_INIT = 'SELECT_DATE_INIT';
export const SELECT_DATE_SUCCESS = 'SELECT_DATE_SUCCESS';


//#region ############## ROUTER HISTORY ###################################
export const ADD_ROUTER_HISTORY     = "ADD_ROUTER_HISTORY_INIT";
export const UPDATE_ROUTER_HISTORY  = "UPDATE_ROUTER_HISTORY_INIT";
export const ROUTER_HISTORY_ERROR   = "UPDATE_ROUTER_HISTORY_ERROR";
//#endregion

//#region ############## ROUTER HISTORY ###################################
export const UPDATE_ROUTE_PARAMS = "UPDATE_ROUTE_PARAMS";

//#region ############## TUTORIALSTEPS ###################################
export const FETCH_TOUR_STEPS_INIT = "FETCH_TOUR_STEPS_INIT";
export const FETCH_TOUR_STEPS_SUCCESS = "FETCH_TOUR_STEPS_SUCCESS";
export const FETCH_TOUR_STEPS_FAILED = "FETCH_TOUR_STEPS_FAILED";

export const TOUR_SAVE_INIT    = "TOUR_SAVE_INIT";
export const TOUR_SAVE_SUCCESS = "TOUR_SAVE_SUCCESS";
export const TOUR_SAVE_ERROR   = "TOUR_SAVE_ERROR";
//#endregion


//#region ############## GROUPS ###################################
export const GROUP_LIST_INIT    = "GROUP_LIST_INIT";
export const GROUP_LIST_SUCCESS = "GROUP_LIST_SUCCESS";
export const GROUP_LIST_ERROR   = "GROUP_LIST_ERROR";
export const LIST_GROUPS_TABLE_STATE = 'LIST_GROUPS_TABLE_STATE';

//#endregion

// TENANT

export const GET_TENANT_INIT = "GET_TENANT_INIT";
export const GET_TENANT_SUCCESS = "GET_TENANT_SUCCESS";
export const GET_TENANT_ERROR = "GET_TENANT_ERROR";
