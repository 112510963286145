import React, { Component } from 'react';
import { connect } from 'react-redux';
import MainClient from './../../components/Clients/MainClient';
import { getClientData } from './../../actions/client';
import {clientData, lastRebalance, pendingInfo, clientStaff, clientSelected
} from './../../selectors/client';
import withParams from '../../helpers/withParams';

class MainClientContainer extends Component {

    constructor(props){
        super(props)
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount(){
        this.props.getClientData(this.props.params.id);
    }

    handleClick(){
      //  console.log("Click")
    }

    render() {
        return <MainClient 
                user={this.props.clientSelected} 
                pending={this.props.pendingInfo}
                data={this.props.clientData}
                lastRebalance={this.props.lastRebalance}
                clientStaff={this.props.clientStaff}
                params={this.props.params}
                onClick={this.handleClick}
            />
    }
}

const mapStateToProps = state => ({
    pendingInfo: pendingInfo(state),
    clientData: clientData(state),
    lastRebalance: lastRebalance(state),
    clientStaff: clientStaff(state), 
    clientSelected: clientSelected(state),  
})

const mapDispatchToProps = dispatch => ({
    getClientData: client => dispatch(getClientData(client))
})

export default withParams(connect(mapStateToProps, mapDispatchToProps)(MainClientContainer));