import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {MATERIAL_TABLE_LOCALIZATION} from './../../../../constants';
import {AccountCircle, AccountBalance, Message, NoteAdd, PermIdentity, Warning, CancelRounded} from '@material-ui/icons';

class Table extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            selectedRow: null,		  
            columns: [
				// { title: 'Id', field: 'id' },
				{ title: 'Rut', field: 'rut' },
                { title: 'Serie', field: 'serie' },
                { title: 'Caracteristica', field: 'caracteristicas' },
                // { title: 'Vigencia', field: 'vigencia' },        
			],
        }
        this.theme = createMuiTheme({
            palette: {
              primary: {
                main: '#4caf50',
              },
              secondary: {
                light: '#01579b',
                main: '#ff9100',
              },       
            },      
        });
        this.handleRowClicked = this.handleRowClicked.bind(this);
        this.handleRowSelected = this.handleRowSelected.bind(this);
    }

    // componentDidMount(){
    //     // this.props.getSeries()
    // }

    handleRowClicked(selectedRow){
        // this.props.onClick(selectedRow)
        console.log(selectedRow)
        this.props.onClick(selectedRow)
    }

    handleRowSelected(rows){
        // this.props.onClick(rows)
        // console.log(rows)
    }
    
    render() {
        
        return (            
        <MuiThemeProvider theme={this.theme}>
            <MaterialTable
                title="Series"
                columns={this.state.columns}
                data = {this.props.data}   
                isLoading= {this.props.isLoading}              
                options={{
                    // selection: true,
                    sorting: true,
                    // exportButton: true,
                    rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#37b15933' : '' })
                }}
                // onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}                
                onRowClick={((evt, selectedRow) => this.handleRowClicked(selectedRow) )}                
                // onSelectionChange={(rows) => alert('You selected ' + rows.length + ' rows ')}
                onSelectionChange={(rows) => this.handleRowSelected(rows)}
                localization = {MATERIAL_TABLE_LOCALIZATION}
                options={{
                    pageSize: 5,
                    pageSizeOptions: [5]
                }}
            />
        </MuiThemeProvider>
        );
    }
}

export default Table;