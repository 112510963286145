import React, { Component } from 'react';
import {connect} from 'react-redux';
import {getUserReminders} from '../../../actions/reminder'
import Card from '../../../components/AdminDashboard/Recordatorios/Card';

class CardContainer extends Component {

    componentDidMount(){
        if(!this.props.reminders) this.props.getUserReminders();
    }

    render() {
        return (            
            <div>
                <Card data={this.props.count} pending={this.props.pending}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    reminders: state.reminder.data,
    count: state.reminder.count,
    pending: state.reminder.pending,
})

const mapDispatchToProps = dispatch => ({
    getUserReminders: () => dispatch(getUserReminders()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CardContainer);