import React from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {MATERIAL_TABLE_LOCALIZATION} from './../../../constants';
import { rutFormat, patrimonyFormat } from '../../../helpers/formatter';

const theme = createTheme({
    palette: {
    primary: {
        main: '#4caf50',
    },
    secondary: {
        light: '#01579b',
        main: '#ff9100',
    },       
    },      
});

const state = {		  
    columns: [        
        { title: 'Rut', field: 'rut', editable: 'onAdd', width: 150, render: ({rut}) => <span>{rutFormat(rut)}</span> },
        { title: 'Cuenta', field: 'account_name', width: 100 },
        { title: 'Tipo', field: 'account_category_name', width: 100 },        
        { title: 'Administradora', field: 'administrator_name', width: 100 },
        { title: 'Patrimonio', field: 'patrimony', type: 'currency', width: 100, 
            render: ({patrimony}) => (<span>$ {patrimonyFormat(patrimony)}</span>)
        },          
    ],
}

function Table ({pending, data}) {

    const handleRowClicked = selectedRow => {
        console.log(selectedRow)
    }

    const handleRowSelected = rows => {
        console.log(rows)     
    }
    
    return (
    <MuiThemeProvider theme={theme}>
        <MaterialTable
            title="Cuentas"
            columns={state.columns}             
            data = {data}  
            isLoading= {pending}
            options={{
                sorting: true,
                exportButton: true,
                rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#37b15933' : '' })
            }}             
            onRowClick={((evt, selectedRow) => handleRowClicked(selectedRow) )}                
            onSelectionChange={(rows) => handleRowSelected(rows)}
            localization = {MATERIAL_TABLE_LOCALIZATION}
        />
    </MuiThemeProvider>
    );
    
}

export default Table;