import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';


const titleStyles = {
    fontSize: '2rem',
    marginBottom: '0px',
    display: 'flex',
    alignItems: 'center'
}

const Card = ({data, pending}) => {
    return(
        <div>  
            {!pending ? 
                <span style={titleStyles}>
                    <i className={'now-ui-icons users_single-02'} style={{marginRight: '.5em'}}></i>
                        {data}
                </span>
                :
                <div className="text-center mt-5">
                    <CircularProgress size={50} thickness={5}/>
                </div>
            }
        </div>
        
    )
}

export default Card;