import {combineReducers} from 'redux';
import login from './login';
import reminder from './reminder';
import client from './client';
import cartola from './cartola';
import user from './user';
import staff from './staff';
import rebalance from './rebalance';
import permissions from './permissions';
import rebalanceHistorial from './rebalanceHistorial';
import patrimony from './patrimony';
import fondoMutuo from './fondoMutuo';
import activos from './activos';
import administradora from './administradora';
import bulkData from './bulkData';
import updateUrl from './updateUrl';
import withParams from './withParams';
import withTour from './withTour';
import group from './group';


export default combineReducers({
    login,
    reminder,
    cartola,
    user,
    client,
    cartola,
    staff,
    rebalance,
    permissions,
    rebalanceHistorial,
    patrimony,
    fondoMutuo,
    activos,
    administradora,
    bulkData,
    updateUrl,
    withParams,
    withTour,
    group
})