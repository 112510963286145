import {STAFF_GET_INIT, STAFF_GET_SUCCESS, STAFF_GET_ERROR,
STAFF_ASSIGN_CLIENT_INIT,  STAFF_ASSIGN_CLIENT_SUCCESS, STAFF_ASSIGN_CLIENT_ERROR,
RESET_STATUS_RESPONSE,
STAFFCARD_TOTAL_INIT, STAFFCARD_TOTAL_SUCCESS, STAFFCARD_TOTAL_ERROR,
STAFFCARD_INFO_GET_INIT, STAFFCARD_INFO_GET_SUCCESS, STAFFCARD_INFO_GET_ERROR,
STAFF_DATA_GET_INIT, STAFF_DATA_GET_SUCCESS, STAFF_DATA_GET_ERROR,
ADD_STAFF_INIT, ADD_STAFF_SUCCESS, ADD_STAFF_ERROR,
REMOVE_STAFF_INIT, REMOVE_STAFF_SUCCESS, REMOVE_STAFF_ERROR, STAFF_ASSIGN_LIST
} from './../constants';

const initialState = {
    staff: [], 
    staffSelected: {
        name: '',
        rut: '0-0',
        patrimony: '0'
    }, 
    staffClients: [],
    error: null,
    status: null,
    actionMessage: null,
    total: 0,
    pending: false,
    pendingInfo: false,
    staffCard: [],
    staffList: [],
    //Pagination
    pagination: {
        current_page    : 0,
        first_page_url  : "",
        from            : 0,
        last_page       : 0,
        last_page_url   : "",
        next_page_url   : "",
        path            : "",
        per_page        : 0,
        prev_page_url   : null,
        to              : 0,
        total           : 0
    },
    messageStaffAssign: ''    
}

const staff = (state = initialState, action) => {
    switch(action.type){
        case STAFF_GET_INIT:
            return {
                ...state,
                status: null,
                pending: true,                
            };
        case STAFF_GET_SUCCESS:
            return {
                ...state,
                staff: action.payload.staff.data.staff,
                pending: false,
                error: null
            };
        case STAFF_GET_ERROR:
            return {
                ...state,
                error: action.payload.error,
                pending: false,
            };


        case STAFF_ASSIGN_LIST:
            return {
                ...state,
                staffList: action.payload.staffList
            }


        case STAFF_DATA_GET_INIT:
            return {
                ...state,
                pendingInfo : true,
                staffSelected: action.payload.staff,
            };
        case STAFF_DATA_GET_SUCCESS:       
            return {
                ...state,
                staffClients: action.payload.staffClients.data.clients,
                staffSelected: action.payload.staffClients.data.staffSelected,  
                pendingInfo : false,
            };
        case STAFF_DATA_GET_ERROR:
            return {
                ...state,
                error: action.payload.error,
                staffClients: [],               
                pendingInfo : false,
            };


        case ADD_STAFF_INIT:
            return {
                ...state,                
                actionMessage: null,
                status: null,
                error: null
            };
        case ADD_STAFF_SUCCESS:
            return {
                ...state,
                staff: action.payload.response.data.errorCount == 0 ? action.payload.newStaff: state.staff ,  
                actionMessage: action.payload.response.data.message,
                status: action.payload.response.status,
                error: action.payload.response.data.errorCount              
            };
        case ADD_STAFF_ERROR:
            return {
                ...state,
                actionMessage: "Error al crear nuevo asesor",
                status: "500",
                error: 1               
            };

        
        case REMOVE_STAFF_INIT:
            return {
                ...state,                
                actionMessage: null,
                status: null,
                error: null
            };
        case REMOVE_STAFF_SUCCESS:
            return {
                ...state,
                staff: action.payload.newStaff,  
                actionMessage: action.payload.response.data.message,
                status: action.payload.response.status,
                error: action.payload.response.data.errorCount              
            };
        case REMOVE_STAFF_ERROR:
            return {
                ...state,
                actionMessage: "Error al remover asesor",
                status: "500",
                error: 1               
            };




        case STAFF_ASSIGN_CLIENT_INIT:
            return {
                ...state,
                status: null,
                messageStaffAssign: action.payload.message
            };
        case STAFF_ASSIGN_CLIENT_SUCCESS:
            return {
                ...state,
                status: action.payload.status,
                messageStaffAssign: action.payload.message,
                staffClients: action.payload.newStaffClients,
                error: null
            };
        case STAFF_ASSIGN_CLIENT_ERROR:
            return {
                ...state,
                messageStaffAssign: action.payload.message,
                error: action.payload.error
            };

        case RESET_STATUS_RESPONSE:
            return {
                ...state,
                status: null,
                message: null,
                actionMessage: null,
                error: null,
                messageStaffAssign: ''
            };  
            
            
        case STAFFCARD_TOTAL_INIT:
            return {
                ...state,
                pending : true,
                error   : null
            };
        case STAFFCARD_TOTAL_SUCCESS:
            return {
                ...state,
                total       : action.payload.staff.data.total,    
                pending     : false,
            };
        case STAFFCARD_TOTAL_ERROR:
            return {
                ...state,
                total       : 0,                
                error       : action.payload.error,
                pending     : false,
            }; 
           
        case STAFFCARD_INFO_GET_INIT:
            return {
                ...state,
                // pendingInfo: true,              
                error   : null
            };
        case STAFFCARD_INFO_GET_SUCCESS:
            const {current_page, data, first_page_url, from, last_page, last_page_url,
                next_page_url, path, per_page, prev_page_url, to, total } = action.payload.staff.data.info;            
            return {
                ...state,
                staffCard: data, 
                pendingInfo: false, 
                pagination: {
                    current_page, 
                    first_page_url, 
                    from, 
                    last_page, 
                    last_page_url,
                    next_page_url, 
                    path, 
                    per_page, 
                    prev_page_url, 
                    to, 
                    total
                }                 
            };
        case STAFFCARD_INFO_GET_ERROR:
            return {
                ...state,
                staffCard  : [],  
                pendingInfo: false,          
                error      : action.payload.error,   
            }; 

        default:
            return state;
    }
}

export default staff;