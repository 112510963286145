import React, { Component } from 'react';
import { connect } from 'react-redux';
import PatrimonioTotal from '../../../components/AdminDashboard/PatrimonioTotal';
import { 
    getPatrimonyInfo 
} from '../../../actions/patrimony';
import { 
total,
invertido,
caja,
pendingInfo,
dataPatrimonio,
dataUsuario
} from '../../../selectors/patrimony';


const dataPatrimonioTest= {
    labels: ['Mixtos', 'Renta Fija', 'Renta Variable', 'Bonos'],
    datasets: [10, 20, 30, 40]
};

// const dataUsuario = [
//     {
//         name: 'Juan Poblete',
//         rut: 13456789-6,
//         correo: 'demo@email.com',
//         patrimonio: '$ 153.321.321',
//         porcent: 60, 
//     },
//     {
//         name: 'Alicia Gonzalez',
//         rut: 12456234-4,
//         correo: 'demo@email.com',
//         patrimonio: '$ 153.321.321',
//         porcent: 18, 
//     },
//     {
//         name: 'Pedro Ramirez',
//         rut: 13456453-2,
//         correo: 'demo@email.com',
//         patrimonio: '$ 153.321.321',
//         porcent: 50, 
//     },
//     {
//         name: 'Raul Tapia',
//         rut: 13456567-7,
//         correo: 'demo@email.com',
//         patrimonio: '$ 153.321.321',
//         porcent: 49, 
//     },
// ]

class index extends Component {

    componentDidMount(){
        this.props.getPatrimonyInfo();
    }

    render() {
        return (
            <div>
                <PatrimonioTotal 
                    total={this.props.total} 
                    invertido={this.props.invertido} 
                    caja={this.props.caja} 
                    dataPatrimonio={this.props.dataPatrimonio} 
                    // dataPatrimonio={dataPatrimonioTest}
                    dataUsuario={this.props.dataUsuario}
                />
            </div>
        );
    }
}

const mapStateToProps = state =>({
    total: total(state),
    invertido: invertido(state),
    caja: caja(state),
    dataUsuario: dataUsuario(state),
    dataPatrimonio: dataPatrimonio(state)
})

const mapDispatchToProps = dispatch => ({
    getPatrimonyInfo: () => dispatch(getPatrimonyInfo())
})

export default connect(mapStateToProps, mapDispatchToProps)(index);