import React from 'react';
import {FormGroup, Label, Input
} from "reactstrap";

const index = ({name, data, setMapColumn, isLoading}) => {

    const handleChange = name => event => {
        // console.log(name)
        // console.log(event.target.value) 
        setMapColumn({tableColumn: name, fileColumn: event.target.value})       

    };

    return (
        <div>
            <FormGroup>
                <Label for="tableSelect">{name}</Label>
                <Input type="select" name="tableSelect" id="tableSelect" onChange={handleChange(name)}>
                    <option value="">Seleccionar columna</option>                       
                    {data.map((column, id)=> <option key={column} value={id}>{column}</option>)}
                </Input>
            </FormGroup>
        </div>
    );
};

export default index;