import React from 'react';
import {connect} from 'react-redux';
import {
    fetchPermissions, 
    fetchRolePermissions, 
    fetchRoles,
    handleCreateRole,
    handleUpdateRole,
    handleDeleteRole,
    setEditActive,
    setCreateActive,
    setOpenCreate,
    setOpenEdit,
    setOpenAssign,
    setAssignActive
} from '../../actions/permissions';
import SkeletonLoader from '../../components/SkeletonLoader';
import RoleUsersLoader from '../../components/SkeletonLoader/Loaders/RoleUsersLoader';
import RolesUsersComponent from '../../components/RolesUsers/RolesUsersComponent';


class RolesContainer extends React.Component {

    constructor(props) {
        super(props);

        this.getRolesHandler = this.getRolesHandler.bind(this);
    }

    componentDidMount() {
        this.props.fetch_permissions();
        this.props.fetch_roles(1);
        
    }

    componentDidUpdate(prevProps, props) {
        if (prevProps.roles !== this.props.roles || prevProps.rolePermissionsLoading !== this.props.rolePermissionsLoading) {
            console.log('RolesUsersContainer Updated!')
        }
    }

    getRolesHandler() {
        this.props.fetch_roles();
    }

    render() {
        if (this.props.rolesLoading) {
            return(
                <SkeletonLoader component={RoleUsersLoader} />
            )
        } else {
            return(
                    
                <RolesUsersComponent 
                    permissions={this.props.permissions}
                    roles={this.props.roles}
                    roleName={this.props.roleName}
                    rolePermissions={this.props.rolePermissions}
                    roleDescription={this.props.roleDescription}
                    roleDescriptionCreatedAt={this.props.roleDescriptionCreatedAt}
                    roleDescriptionUpdatedAt={this.props.roleDescriptionUpdatedAt}
                    roleRequires={this.props.roleRequires}
                    fetchRolePermissions={this.props.fetch_role_permissions}
                    rolePermissionsLoading={this.props.rolePermissionsLoading}
                    updateRole={this.props.update_role}
                    create_role={this.props.create_role}
                    update_role={this.props.update_role}
                    delete_role={this.props.delete_role}
                    getRolesHandler={this.getRolesHandler}
                    setEditActive={this.props.set_edit_active}
                    setCreateActive={this.props.set_create_active}
                    setOpenCreate={this.props.set_open_create}
                    setOpenEdit={this.props.set_open_edit}
                    rolesLoading={this.props.rolesLoading}
                    totalRoles={this.props.totalRoles}
                    fetchRoles={this.props.fetch_roles}
                    setOpenAssign={this.props.set_open_assign}
                    setAssignActive={this.props.set_assign_active}
                    assignSuccess={this.props.assignSuccess}
                    showNotification={this.props.notify}
                />
            )  
        }
        
    }
    
}

const mapStateToProps = state => {
    return {
        permissions: state.permissions.permissions,
        rolesLoading: state.permissions.rolesLoading,
        roles: state.permissions.roles,
        roleName: state.permissions.roleName,
        rolePermissions: state.permissions.rolePermissions,
        roleDescription: state.permissions.roleDescription,
        roleDescriptionCreatedAt: state.permissions.roleDescriptionCreatedAt,
        roleDescriptionUpdatedAt: state.permissions.roleDescriptionUpdatedAt,
        roleRequires: state.permissions.roleRequires,
        rolePermissionsLoading: state.permissions.rolePermissionsLoading,
        totalRoles: state.permissions.total,
        assignSuccess: state.permissions.assignSuccess
    }
}

const mapDispatchToProps = dispatch => ({
    fetch_permissions: () => dispatch(fetchPermissions()),
    fetch_roles: () => dispatch(fetchRoles()),
    fetch_role_permissions: roleName => dispatch(fetchRolePermissions(roleName)),
    create_role: roleObject => dispatch(handleCreateRole(roleObject)),
    update_role: roleObject => dispatch(handleUpdateRole(roleObject)),
    delete_role: roleObject => dispatch(handleDeleteRole(roleObject)),
    set_edit_active: roleObject => dispatch(setEditActive(roleObject)),
    set_create_active: roleObject => dispatch(setCreateActive(roleObject)),
    set_open_create: isOpen => dispatch(setOpenCreate(isOpen)),
    set_open_edit: isOpen => dispatch(setOpenEdit(isOpen)),
    set_assign_active: roleObject => dispatch(setAssignActive(roleObject)),
    set_open_assign: isOpen => dispatch(setOpenAssign(isOpen))
})

export default connect(mapStateToProps, mapDispatchToProps)(RolesContainer);