import React, { useEffect, useState} from 'react';
import SidePanel from '../../../components/SidePanel';
import Switch from "react-switch";
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Row } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import roleInfo from '../../../components/RolesUsers/roleInfo';


const useStyles = makeStyles({
    underline: {
        "&&&:before": {
            borderBottom: "none"
        }
    }
});

const CreateRole = (props) => {
    
    let {
        uid,
        active,
        title,
        permissions,
        editactive,
        handleCreateRole,
        setOpenCreate
    } = props;
    
    let [roleName, setRoleName] = useState();
    let [roleDescription, setRoleDescription] = useState();
    let [editActive, setEditActive] = useState(editactive);
    let [rolePermissions, setRolePermissions] = useState([]);
    let [createRole, setCreateRole] = useState({});

    useEffect(() => {
        setEditActive(editactive);
        setRoleName(editactive.name);
        setRoleDescription(editActive.desc ? editActive.desc.desc : '');
        console.log('CreateRoles!', active);

    }, [editactive, active])

    const classes = useStyles();

    const handleDesc = event => {
        setRoleDescription(event.target.value);
    }

    const handleName = event => {
        setRoleName(event.target.value);
    }

    const handlePermissionChange = (permission) => {
        console.log(!editactive.permissions[permission]);
        console.log('rolePermissions', rolePermissions);
        let newRolePerms = {...rolePermissions};
        newRolePerms[permission] = !newRolePerms[permission];
        setRolePermissions(newRolePerms);

        // build role object
        let role = {
            name: editActive.name,
            permissions: Object.keys(rolePermissions),
            desc: roleDescription,
            requires: ''
        }
        setCreateRole(role);
    }

    const handleCancel = () => {
        
    }

    const closeHandler = () => {
        document.getElementById(`${uid}`).style.width = "0";
        document.getElementById(`${uid}`).style.display = "none";
        document.getElementById("main").style.width = "100%";
        setOpenCreate(false);
    }


    return(
        <SidePanel 
            uid={uid}
            active={active}
            title={title}
            closeHandler={closeHandler}
            body={
                <div>
                    <Row>
                        <TextField 
                            style={{width: '40%'}} 
                            id="standard-basic" 
                            value={roleName} 
                            label="Nombre" 
                            onChange={(e) => handleName(e)}
                            InputProps={{
                                style: {
                                    fontSize: '1.5em'
                                },
                                classes
                            }}
                        />  
                    </Row>
                    <Row>
                    <TextField
                        id="filled-multiline-flexible"
                        label="Descripcion de Rol"
                        value={roleDescription}
                        onChange={(e) => handleDesc(e)}
                        multiline
                        rowsMax="4"
                        InputProps={{
                            classes
                        }}
                    />
                    </Row>
                    <div className={'permission-container'}>
                        {/* <div className={'permission-value'} ><Switch onChange={(e) => handlepermissionchange(e)} id={'permission'} checked={checkStatus} /></div> */}
                        {permissions ? Object.keys(permissions).map((permission, key) => {
                            return(
                                <div className={'permission-tile'} key={key}>
                                    <div className={'permission-name'}>{roleInfo[permission]}</div>
                                    <div className={'permission-value'} ><Switch onClick={() => handlePermissionChange(permission)} onChange={() => handlePermissionChange(permission)} id={permission} checked={rolePermissions ? rolePermissions[permission] : false} /></div>
                                </div> 
                            )
                        }):null}
                    </div>
                </div>
            }
            footer={
                <div>
                    <Button className={'save-button'} onClick={() => handleCreateRole(createRole)}  color={'success'}>Crear Rol</Button>
                    {/* {showwarning ? 
                    <SweetAlert
                        warning
                        style={{display: "block",marginTop: "-100px"}}
                        title="Seguro que deseas cambiar el Rol?"
                        confirmBtnText="Si, deseo modificar el rol."
                        confirmBtnBsStyle="danger"
                        onConfirm={() => handleconfirm(editActive)}
                        onCancel={() => handleconfirm()}
                        confirmBtnBsStyle="info"
                    >
                    </SweetAlert> : null}
                    {showsuccess ? 
                    <SweetAlert
                        success
                        style={{display: "block",marginTop: "-100px"}}
                        title="El rol fue actualizado correctamente!"
                        onConfirm={() => handlesuccess()}
                        onCancel={() => handlesuccess()}
                    >
                        El rol fue actualizado exitosamente!
                    </SweetAlert> : null} */}
                </div>
            }
        />
    )
}

export default CreateRole;