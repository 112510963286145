function hasPermission(permissions, action) {
    if(!permissions) {
        // No permissions provided
        
        return false;
    }

    if(permissions.includes(action)) {
        return true;
    }

}

export default hasPermission;