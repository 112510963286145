import decode from 'jwt-decode';


const setToken = (token) => {
    // sessionStorage.setItem('token', token);
    localStorage.setItem('token', token);
    const decoded = decode(token);
}

const getToken = () => {
    return localStorage.getItem('token');
}

const logout = () => {
    localStorage.clear()
}

const loggedIn = () => {
    const token = getToken()
    return !!token && !isTokenExpired(token)
}

const isTokenExpired = () => {
    const token = getToken();
    try {
        const decoded = decode(token);
        console.log("Session limit: " + decoded.exp)
        console.log("Session now  : " + Date.now() / 1000)
        if (decoded.exp < Date.now() / 1000) {
            console.log('Expired session')
            return true;
        }
        else
            console.log('Active Session')
        return false;
    }
    catch (err) {
        console.log("expired check failed");
        return true;
    }
}

const getSecondsExpToken = () =>{
    const token = getToken();
    const decoded = decode(token);
    const exp =  decoded.exp * 1000;
    const time = new Date(exp).getTime() - new Date().getTime();
    return Math.round(time / (1000*60)) * 60;
}

const setPermissions = (permissions) => {
    localStorage.setItem('permissions', permissions);
}

const getPermissions = () => {
    return localStorage.getItem('permissions');
}

const setRoles = (roles) => {
    localStorage.setItem('roles', roles);
}

const getRoles = () => {
    return localStorage.getItem('roles');
}

const setAccess = (access) => {
    localStorage.setItem('access', access);
}

const getAccess = () => {
    return localStorage.getItem('access');
}

const setUser = (user) => {
    localStorage.setItem('user', user);
}

const getUser = () => {
    return localStorage.getItem('user');
}



export default {setToken, getToken, logout, 
    loggedIn, isTokenExpired, setPermissions, 
    getPermissions, setRoles, getRoles, setAccess, getAccess,
    setUser, getUser, getSecondsExpToken};
