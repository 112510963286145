import {CARTERA_GET_INIT, CARTERA_GET_SUCCESS, CARTERA_GET_ERROR,
FUNDS_BY_ACCOUNT_GET_INIT, FUNDS_BY_ACCOUNT_GET_SUCCESS, FUNDS_BY_ACCOUNT_GET_ERROR,
REBALANCE_GET_INIT, REBALANCE_GET_SUCCESS, REBALANCE_GET_ERROR, REBALANCE_RESET,
SERIES_BY_FUND_GET_INIT, SERIES_BY_FUND_GET_SUCCESS, SERIES_BY_FUND_GET_ERROR,
ADD_NEW_FUND_REBALANCE, REMOVE_NEW_FUND_REBALANCE,
SET_ACCOUNT_SELECTED_REBALANCE, SET_FILTERS_SELECTED_REBALANCE,
REBALANCE_GET_PDF_INIT, REBALANCE_GET_PDF_SUCCESS, REBALANCE_GET_PDF_ERROR,
REBALANCE_SAVE_SELECTED_PORTAFOLIO_INIT, REBALANCE_SAVE_SELECTED_PORTAFOLIO_SUCCESS,
REBALANCE_SAVE_SELECTED_PORTAFOLIO_ERROR, REBALANCE_ACCOUNT_TABLE_STATE   
} from '../constants';
import httpClient from '../api/httpClient';
import {urlAccounts, urlFundsByAccount, urlSetRebalance, 
    urlSeriesByFund, urlRebalancePdf, urlSaveSelectedPortafolio} from '../api/urls';
import moment from 'moment';
import download from 'downloadjs';

export const getAccounts = () => {
    return (dispatch, getState) => {
        if(getState().rebalance.accounts.length == 0){       
            dispatch(getAccountsInit())
            httpClient.getAll(urlAccounts)
            .then(response => dispatch(getAccountsSuccess(response)))
            .catch(error => dispatch(getAccountsError(error)))
        }
    }
}

export const getPdfRebalance = (data) => {
    return (dispatch, getState) => {
        dispatch(getPdfInit())

        const {appColors, logo} = getState().login;
        data.colors = appColors;
        data.logo = logo;   
        data.generated_date = moment().format('YYYY-MM-DD')  

        httpClient.downloadPdf(urlRebalancePdf, data)
        .then(response => {
            const content = response.headers['content-type'];                
            download(response.data, 'Rebalanceo '
            .concat(getState().rebalance.accountsSelected.rut, ' ', moment().format('YYYYMMDD') ,'.pdf'), content);

            dispatch(getPdfSuccess(response.status))
        })
        .catch(error => {
            console.log(error)
            dispatch(getPdfError(error, 500))
        })

    }
}

// export const getFundsByAccount = () => {
//     return (dispatch, getState) => { 
//         if(getState().rebalance.fundsLoading){

//             const accounts = {'accounts': getState().rebalance.accountsSelected}

//             dispatch(getFundsByAccountInit())
//             httpClient.create(urlFundsByAccount, accounts)
//             .then(response => dispatch(getFundsByAccountSuccess(response)))
//             .catch(error => dispatch(getFundsByAccountError(error)))
//         }
//     }
// }

export const getSeriesByFund = fund => {
    return dispatch => {        
        dispatch(getSeriesByFundInit())
        httpClient.create(urlSeriesByFund, fund)
        .then(response => dispatch(getSeriesByFundSuccess(response)))
        .catch(error => dispatch(getSeriesByFundError(error)))
    }
}

export const rebalanceAccounts = restrictionsSelected => {
    return (dispatch, getState) => {        
        dispatch(rebalanceAccountsInit(restrictionsSelected))       

        const data = {
            'account': getState().rebalance.accountsSelected,
            'funds': getState().rebalance.fundsByAccount,
            'newFunds': getState().rebalance.newFunds,
            'restrictions': restrictionsSelected
        }

        console.log(data)

        httpClient.create(urlSetRebalance, data)
        .then(response => {
            console.log(response)
            dispatch(rebalanceAccountsSuccess(response))
        })
        .catch(error => {
            console.log(error)
            dispatch(rebalanceAccountsError(error))
        })
    }
}

export const resetRebalance = () => {
    console.log('En reset rebalance...')
    return dispatch => {
        dispatch(resetRebalanceState())
    }
}

export const addNewFundRebalance = fund => {
    return dispatch => {
        dispatch(addNewFund(fund))
    }
}

export const removeNewFundRebalance = fund => {
    return dispatch => {
        dispatch(removeNewFund(fund))
    }
}

export const setAccountSelected = account => {
    return (dispatch, getState) => {
        dispatch(setAccountSelectedReb(account))
        dispatch(getFundsByAccountInit())
        httpClient.create(urlFundsByAccount,{ 'accounts':  account})
        .then(response => {
            if(response.status == '204'){
                const msg = "Sin fondos para rebalancear, los fondos de esta cuenta están en cantidad cero"
                dispatch(getFundsByAccountError(`Error: 204, ${msg}`))
                // alert(msg) //Pasar al componente de presentación
            }else{
                dispatch(getFundsByAccountSuccess(response))
            }
        })
        .catch(error => dispatch(getFundsByAccountError(error)))      
    }
}

export const setFiltersSelected = filters => {
    return dispatch => {
        dispatch(setFiltersSelectedReb(filters))
    }
}

export const saveSelectedPortafolio = data => {    
    return (dispatch, getState) => {
        data.code = getState().rebalance.rebalance.rebalance.code;
        dispatch(saveSelectedPortafolioInit(data))
        httpClient.create(urlSaveSelectedPortafolio, data)
        .then(response => {
            try {
                const content = response.headers['content-type'];                
                download(atob(response.data), 'Rebalanceo '
                .concat(data.account.rut, ' ', moment().format('YYYYMMDD') ,'.xlsx'), content);

                dispatch(saveSelectedPortafolioSuccess(response.status))
            } catch (error) {
                console.log('Error en Exportar Excel')
                console.log(error)

                dispatch(saveSelectedPortafolioError(error, 500))
            }
            
        })
        .catch(error => {
            console.log(error)
            dispatch(saveSelectedPortafolioError(error, 500))
        })

    }

}

export const setTableStateAccount = state => {
    return dispatch => {
        dispatch(_setTableStateAccount(state))
    }
}

const _setTableStateAccount = state => ({
    type: REBALANCE_ACCOUNT_TABLE_STATE,
    payload: {
        state
    }
})

const getPdfInit = () => ({
    type: REBALANCE_GET_PDF_INIT
})

const getPdfSuccess = response => ({
    type: REBALANCE_GET_PDF_SUCCESS,
    payload: {
        response
    }
})

const getPdfError = (error, response) => ({
    type: REBALANCE_GET_PDF_ERROR,
    payload: {
        error,
        response
    }
})

const saveSelectedPortafolioInit = portafolio => ({
    type: REBALANCE_SAVE_SELECTED_PORTAFOLIO_INIT,
    payload: {
        portafolio
    }
})

const saveSelectedPortafolioSuccess = response => ({
    type: REBALANCE_SAVE_SELECTED_PORTAFOLIO_SUCCESS,
    payload: {
        response
    }
})

const saveSelectedPortafolioError = (error, response) => ({
    type: REBALANCE_SAVE_SELECTED_PORTAFOLIO_ERROR,
    payload: {
        error,
        response
    }
})




const setFiltersSelectedReb = filters => ({
    type: SET_FILTERS_SELECTED_REBALANCE,
    payload: {
        filters
    }
})

const setAccountSelectedReb = account => ({
    type: SET_ACCOUNT_SELECTED_REBALANCE,
    payload: {
        account
    }
})

const addNewFund = fund => ({
    type: ADD_NEW_FUND_REBALANCE,
    payload: {
        fund
    }
})

const removeNewFund = fund => ({
    type: REMOVE_NEW_FUND_REBALANCE,
    payload: {
        fund
    }
})

const getSeriesByFundInit = () => ({
    type: SERIES_BY_FUND_GET_INIT
})

const getSeriesByFundSuccess = series => ({
    type: SERIES_BY_FUND_GET_SUCCESS,
    payload: {
        series
    }
})

const getSeriesByFundError = error => ({
    type: SERIES_BY_FUND_GET_ERROR,
    payload: {
        error
    }
})

const resetRebalanceState = () => ({
    type: REBALANCE_RESET
})

const rebalanceAccountsInit = (restrictions) => ({
    type: REBALANCE_GET_INIT,
    payload: {
        restrictions
    }
})

const rebalanceAccountsSuccess = rebalance => ({
    type: REBALANCE_GET_SUCCESS,
    payload: {
        rebalance
    }
})

const rebalanceAccountsError = error => ({
    type: REBALANCE_GET_ERROR,
    payload: {
        error
    }
})


const getFundsByAccountInit = () => ({
    type: FUNDS_BY_ACCOUNT_GET_INIT
})

const getFundsByAccountSuccess = funds => ({
    type: FUNDS_BY_ACCOUNT_GET_SUCCESS,
    payload: {
        funds
    }
})

const getFundsByAccountError = error => ({
    type: FUNDS_BY_ACCOUNT_GET_ERROR,
    payload: {
        error
    }
})

const getAccountsInit = () => ({
    type: CARTERA_GET_INIT
})

const getAccountsSuccess = accounts => ({
    type: CARTERA_GET_SUCCESS,
    payload: {
        accounts
    }
})

const getAccountsError = error => ({
    type: CARTERA_GET_ERROR,
    payload: {
        error
    }
})



