import React from 'react'
import {
    Card,
    CardBody,
    Row,
    Col    
} from "reactstrap";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {
    Statistics
} from "../../../../components";

import Tooltip from '@material-ui/core/Tooltip';

const cartolaStatsStyles = {
    statsTitle: {
        color: "grey",
        fontWeight: "bold"   
    }
}

class CartolaStats extends React.Component {
    constructor(props) {
        super(props)  
    }

    render() {
        return (
            <Row>
                <Col xs={12} md={12}>
                    <Card className="card-stats card-raised">
                        <CardBody>
                            <Row>
                                <Col xs={12} md={3}>
                                    <div className="nineth">
                                    <Statistics
                                        title={this.props.fetchBreakdownLoading ?
                                            <SkeletonTheme color="#fff" highlightColor="#bdc3c7">
                                                <Skeleton duration={2} />
                                            </SkeletonTheme>
                                            :
                                            this.props.fetchBreakdownFailed ?
                                                <div style={cartolaStatsStyles.statsTitle}>
                                                    --
                                                    </div>
                                                :
                                                <span>
                                                    <small>$</small>{this.props.clpFormatter.format(Math.trunc(parseFloat(this.props.consolidated[0]['patrimonio_inicio_periodo'])))}
                                                </span>
                                        }
                                        subtitle="Patrimonio Inicio Periodo"
                                    />
                                    </div>
                                </Col>
                                <Col xs={12} md={3}>
                                    <div className="tenth">
                                    <Statistics
                                        title={this.props.fetchBreakdownLoading ?
                                            <SkeletonTheme color="#fff" highlightColor="#bdc3c7">
                                                <Skeleton duration={2} />
                                            </SkeletonTheme>
                                            :
                                            this.props.fetchBreakdownFailed ?
                                                <div style={cartolaStatsStyles.statsTitle}>
                                                    --
                                                    </div>
                                                :
                                                <span>
                                                    <small>$</small>{this.props.clpFormatter.format(Math.trunc(parseFloat(this.props.consolidated[0]['aportes'])))}
                                                </span>
                                        }
                                        subtitle={
                                            <span>Aportes
                                                <Tooltip title={<span style={{fontSize: "1.3em"}}>Aportes considerados son de naturaleza externa</span>}>
                                                    <span> <i className="fa fa-question-circle-o"></i></span>                                                
                                                </Tooltip>
                                            </span>}
                                    />
                                    </div>
                                </Col>
                                <Col xs={12} md={3}>
                                    <div className="eleventh">
                                    <Statistics
                                        title={this.props.fetchBreakdownLoading ?
                                            <SkeletonTheme color="#fff" highlightColor="#bdc3c7">
                                                <Skeleton duration={2} />
                                            </SkeletonTheme>
                                            :
                                            this.props.fetchBreakdownFailed ?
                                                <div style={cartolaStatsStyles.statsTitle}>
                                                    --
                                                    </div>
                                                :
                                                <span>
                                                    <small>$</small>{this.props.clpFormatter.format(Math.trunc(parseFloat(this.props.consolidated[0]['retiros'])))}
                                                </span>
                                        }
                                        subtitle={
                                            <span>Retiros
                                                <Tooltip title={<span style={{fontSize: "1.3em"}}>Retiros considerados son de naturaleza externa</span>}>
                                                    <span> <i className="fa fa-question-circle-o"></i></span>                                                
                                                </Tooltip>
                                            </span>}
                                    />
                                    </div>
                                </Col>
                                <Col xs={12} md={3}>
                                    <div className="twelfth">
                                    <Statistics
                                        title={this.props.fetchBreakdownLoading ?
                                            <SkeletonTheme color="#fff" highlightColor="#bdc3c7">
                                                <Skeleton duration={2} />
                                            </SkeletonTheme>
                                            :
                                            this.props.fetchBreakdownFailed ?
                                                <div style={cartolaStatsStyles.statsTitle}>
                                                    --
                                                    </div>
                                                :
                                                <span>
                                                    <small>$</small>{this.props.clpFormatter.format(Math.trunc(parseFloat(this.props.consolidated[0]['cambio_valor_cartera'])))}
                                                </span>
                                        }
                                        subtitle="Cambio Valor Cartera"
                                    />
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}


export default CartolaStats;