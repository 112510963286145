import {createSelector} from 'reselect';

export const getFilters = state => {
    return new Map(state.rebalance.filters);
};

export const getFilterData = state => state.rebalance.filterData;

export const getAllAccounts = state => {
    const filters = new Map(state.rebalance.filters);
    const accounts = state.rebalance.accounts;
    let accountsFiltered = [...accounts]
    let filterBy = [];
    filters.forEach((value, key) => {
        if(value){
            filterBy.push({key, value})                              
        }        
    });

    if(filterBy.length > 0){
        filterBy.forEach(filter=> {

            if(filter.key === 'admin'){
                accountsFiltered = accountsFiltered.filter(x=> x.administradora === filter.value)
            }

            if(filter.key === 'apv'){
                accountsFiltered = accountsFiltered.filter(x=> x.categoria === filter.value)
            }

            if(filter.key === 'currency'){
                accountsFiltered = accountsFiltered.filter(x=> x.currency === filter.value)
            }

            if(filter.key === 'tipoCuenta'){
                accountsFiltered = accountsFiltered.filter(x=> x.type === filter.value)
            }

            if(filter.key === 'country'){
                accountsFiltered = accountsFiltered.filter(x=> x.country === filter.value)
            }

            if(filter.key === 'region'){
                accountsFiltered = accountsFiltered.filter(x=> x.region === filter.value)
            }

            if(filter.key === 'nombre_instrumento'){
                accountsFiltered = accountsFiltered.filter(x=> x.nombre_instrumento === filter.value)
            }

            if(filter.key === 'serie'){
                accountsFiltered = accountsFiltered.filter(x=> x.serie === filter.value)
            }

            if(filter.key === 'patrimonio'){
                var a, b = 0
                switch (filter.value) {                    
                    case "1":
                        a=0;            
                        b=2500000;
                        break;
                    case "2":
                        a=2500000;            
                        b=5000000;
                        break;
                    case "3":
                        a=5000000;            
                        b=25000000;
                        break;
                    case "4":
                        a=25000000;            
                        b=50000000;
                        break;
                    case "5":
                        a=50000000;            
                        b=100000000;
                        break;
                    case "6":
                        a=100000000;            
                        b=250000000;
                        break;
                    case "7":
                        a=250000000;            
                        b=500000000;
                        break;
                    case "8":
                        a=500000000;            
                        b=100000000000;
                        break;
                    default:
                        break;
                }
                accountsFiltered = accountsFiltered.
                filter(x=> x.patrimonio >= a && x.patrimonio <= b)
                
            }

        })        
    }

    accountsFiltered = accountsFiltered.reduce((accumulator, element) => {
        if (!accumulator.find(el => el['rut'] === element['rut'] && el['cuenta'] === element['cuenta'])) {
             accumulator.push(element);
         }
        return accumulator;
      },[]);

    return accountsFiltered;        
};

export const getAllFundsByAccount = state => state.rebalance.fundsByAccount;

export const infoAccount = state => state.rebalance.infoAccount

export const getAllFundsByAdmins = state => state.rebalance.fundsByAdmins;

export const getNewFundsRebalance = state => state.rebalance.newFunds;

export const accountSelected = state => state.rebalance.accountsSelected;

export const getAllSeriesByFund = state => state.rebalance.seriesByFund;

export const getRebalanceResponse = state => state.rebalance.rebalance;

export const isLoading = state => state.rebalance.isLoading;

export const accountsLoading = state => state.rebalance.accountsLoading;

export const fundsLoading = state => state.rebalance.fundsLoading;

export const seriesLoading = state => state.rebalance.seriesLoading;

export const getError = state => state.rebalance.error;

export const getResponse = state => state.rebalance.response;

export const getCodeRebalance = state => {
    if(state.rebalance.rebalance.length !== 0){
        if(state.rebalance.rebalance.Error){
            return null
        }
        return state.rebalance.rebalance.rebalance.code;
    }else{
        return null
    }    
}

export const getPortafolioActual = state => {
    if(state.rebalance.rebalance.length !== 0){
        if(state.rebalance.rebalance.Error){
            return null
        }
        return state.rebalance.rebalance.rebalance.fondos;
    }else{
        return null
    }    
}

export const getPortafolioSelected = state => {
    if(state.rebalance.portafolioSelected.length !== 0){
        return state.rebalance.portafolioSelected.selected[2]
    }else{
        return "";
    }
};