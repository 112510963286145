import {
    ADD_ROUTER_HISTORY,
    UPDATE_ROUTER_HISTORY,
    ROUTER_HISTORY_ERROR
} from '../constants';

const initialState = {
    history: null,
    error: null
}

const router = (state = initialState, action) => {
    switch (action.type){
        case ADD_ROUTER_HISTORY:
            return {
                ...state,
                history: action.payload.history
            }
        case UPDATE_ROUTER_HISTORY:
            return {
                ...state
            }
        case ROUTER_HISTORY_ERROR:
            return {
                ...state,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export default router;