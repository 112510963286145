import React, { Component } from 'react';
import {connect} from 'react-redux';
import Card from '../../../components/AdminDashboard/FondosMutuos/Card';
// import data from './data.js'

import { 
    getFondosCardInfo,
} from '../../../actions/fondoMutuo';
import { 
    filteredFondos,
    pending
} from '../../../selectors/fondoMutuo';

// Sort by absolute values to get 
// top 5 biggest differences
// const filtered_data = data.sort(function(a, b){ return(Math.abs(b.rentabilidad) - Math.abs(a.rentabilidad))}).slice(0, 5);

class CardContainer extends Component {

    componentDidMount(){
        this.props.getFondosCardInfo();    
    }

    render() {
        return (
            <div>               
                <Card data={this.props.filteredFondos} pending={this.props.pending}/>
            </div> 
        );
    }
}

const mapStateToProps = state => ({
    filteredFondos: filteredFondos(state),
    pending: pending(state)
})

const mapDispatchToProps = dispatch => ({
    getFondosCardInfo: () => dispatch(getFondosCardInfo()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CardContainer);