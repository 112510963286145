import httpClient from '../api/httpClient';
import {
    FETCH_COMPOSITION_INIT,
    FETCH_COMPOSITION_SUCCESS,
    FETCH_COMPOSITION_ERROR,
    FETCH_BREAKDOWN_INIT,
    FETCH_BREAKDOWN_SUCCESS,
    FETCH_BREAKDOWN_ERROR,
    SELECT_ACCOUNT_INIT,
    SELECT_ACCOUNT_SUCCESS,
    SELECT_DATE_INIT,
    SELECT_DATE_SUCCESS,
    CARTOLA_ADVISOR_CLIENT_INIT,
    CARTOLA_ADVISOR_CLIENT_SUCCESS,
    CARTOLA_ADVISOR_CLIENT_ERROR,
    RESET_CARTOLA_CLIENT,
    SET_CLIENT_ID,
    LIST_CLIENTS_CARTOLA_INIT,
    LIST_CLIENTS_CARTOLA_SUCCESS,
    LIST_CLIENTS_CARTOLA_ERROR,
    FETCH_CARTOLAPDF_INIT,
    FETCH_CARTOLAPDF_ERROR,
    FETCH_CARTOLAPDF_SUCCESS,
    LIST_CLIENTS_TABLE_STATE
} from '../constants';
import client from '../api/httpClient';
import { urlFetchComposition, urlGroupComposition, urlFetchBreakdown, urlGroupBreakdown, urlCartolaClientAdvisor, urlListClientsCartola } from '../api/urls';
import auth from '../api/auth';
import { updateRouterHistory } from './updateUrl';
import download from 'downloadjs';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { urlParamsFormat, rutFormat } from '../helpers/formatter';
import { filter } from 'lodash';

const moment = extendMoment(originalMoment);

const today = moment();

const formatter = new Intl.NumberFormat('es-ES', {
    minimumFractionDigits: 0
});

export const fetchCartolaAdvisorClient = obj => {
    return dispatch => {
        dispatch(fetchCartolaAdvisorClientInit())
        client.create(urlCartolaClientAdvisor, obj)
            .then(res => {
                dispatch(fetchCartolaAdvisorClientSuccess(res))
            })
            .catch(error => {
                dispatch(fetchCartolaAdvisorClientError(error))
            })
    }
}

export const resetClientCartola = () => {
    return dispatch => {
        dispatch(_resetClientCartola());
    }
}


export const fetch_breakdown = (id, isGroup = false) => {

    const url = isGroup ? urlGroupBreakdown : urlFetchBreakdown;

    return (dispatch, getState) => {

        dispatch(fetch_breakdown_init());

        let cartola = getState().cartola;
        let { start, end } = cartola.datePickerValue;

        // const selected = cartola.accountSelectValue.map(account => account.value.substring(0,2));
        // const accounts = [];

        // selected.forEach(select => {
        //     var condition = new RegExp(`^${select}`);
        //     var result = cartola.accountSelectOptions.filter(x => condition.test(x.value))
        //     result.forEach(r => accounts.push(r))
        // })

        const accounts = cartola.accountSelectValue; //<-- Evitar duplicados de cuentas en payload

        let body = {
            'id': id,
            'account': accounts.map(x => x.value),
            'start': moment(start).format("YYYY-MM-DD"),
            'end': moment(end).format("YYYY-MM-DD"),
        }

        client.create(url, body)
            .then(breakdownResp => {
                console.log('fetch_breakdown OK <---------------------------------------------')
                console.log(breakdownResp);
                const breakdown = breakdownResp.data;

                //############## PARSE ALL ##################
                const resp = breakdownResp.data.consolidated;

                if (breakdown.cartera.length === 0) {
                    // Stop loading state and enable failed state
                    let error = "Breakdown no data in cartera";
                    console.log('failedCartolaState', error);
                    return dispatch(fetch_breakdown_error(error));
                } else {

                    let newCartolaState = {};
                    newCartolaState.parsedCartera = resp.admin;
                    newCartolaState.periodStart = breakdown.periodStart;
                    newCartolaState.periodEnd = breakdown.periodEnd;
                    if (resp.Cash !== []) {
                        newCartolaState.cash = resp.Cash;
                    }

                    newCartolaState.cartera = breakdown.cartera;

                    // If rentabilidad is null
                    if (!resp.consolidated) {
                        if (!resp.consolidated[0]['rentabilidadReal']) {
                            resp.consolidated[0] = {
                                'rentabilidadReal': 0
                            }
                        }
                    } else {
                        newCartolaState.consolidated = resp.consolidated;
                    }
                    newCartolaState.equity_curve = resp.equity_curve;
                    newCartolaState.montoTotal = formatter.format(Math.trunc(parseFloat(resp.consolidated[0]["patrimonio_fin_periodo"]))).replace(/\D00$/, '');
                    newCartolaState.montoInicial = formatter.format(resp.consolidated[0]["patrimonio_inicio_periodo"]).replace(/\D00$/, '');
                    newCartolaState.consolidatedByAccount = resp.account;
                    newCartolaState.movimientos = resp.movements;

                    if (resp.account !== []) {
                        let accountTotal = resp.account.filter(function (total) {
                            return total['cuenta'] === "Total";
                        });

                        let accountTotalInt = parseInt(accountTotal[0]['monto']);

                        let accounts = resp.account.filter(function (accounts) {
                            return accounts['cuenta'] !== 'Total';
                        });

                        console.log({ 'accounts': accounts });
                        newCartolaState.percentageAccount = accounts.map(account => [
                            account['cuenta'],
                            (parseInt(account['monto']) / accountTotalInt) * 100
                        ]);
                    }

                    console.log({ percentageAccount: newCartolaState.percentageAccount });

                    if (resp.currency !== []) {
                        newCartolaState.consolidatedByCurrency = resp.currency;

                        let currencyTotal = resp.currency.filter(function (total) {
                            return total['moneda'] === "Total";
                        });

                        let currencyTotalInt = parseInt(currencyTotal[0]['monto']);

                        let currencies = resp.currency.filter(function (accounts) {
                            return accounts['moneda'] !== 'total';
                        });

                        newCartolaState.percentageCurrency = currencies.map(curr => [
                            curr['moneda'],
                            (parseInt(curr['monto']) / currencyTotalInt) * 100
                        ]);
                    }

                    if (resp.type !== []) {
                        newCartolaState.consolidatedByType = resp.type;
                        let typeTotal = resp.type.filter(function (total) {
                            return total['activos'] === "Total";
                        });

                        let typeTotalInt = parseInt(typeTotal[0]['monto']);

                        let asset_types = resp.type.filter(function (asset) {
                            return asset['activos'] !== 'total';
                        });

                        newCartolaState.percentageAssetTypes = asset_types.map(asset => [
                            asset['activos'],
                            (parseInt(asset['monto']) / typeTotalInt) * 100
                        ]);
                    }

                    newCartolaState.periodStart = breakdown.periodStart;
                    newCartolaState.periodEnd = breakdown.periodEnd;

                    return dispatch(fetch_breakdown_success(newCartolaState));
                }
            }).catch(error => {

                console.log('fetch_breakdown ERROR <---------------------------------------------')
                console.log(error)
                dispatch(fetch_breakdown_error(error));
            })
    }
}

export const fetch_composition = (id, isGroup = false) => {

    const url = isGroup ? urlGroupComposition : urlFetchComposition;

    return (dispatch, getState) => {
        console.log('fetch_composition', id);
        if (getState().cartola.fetchCompositionLoading) return;
        dispatch(fetch_composition_init());
        let dateTime = moment.range(today.clone().subtract(3, "months"), today.clone());
        let { start, end } = dateTime;
        let dates = {
            "id": id,
            "start": moment(start).format("YYYY-MM-DD"),
            "end": moment(end).format("YYYY-MM-DD")
        }
        client.create(url, dates)
            .then(resp => {
                const composition = resp.data.composition;
                console.log('fetch_composition OK <---------------------------------------------')
                console.log({ Composition: composition })

                const adminSelectOptions = composition.administradoras.map(admin => ({
                    value: admin.administradora,
                    label: admin.administradora
                }));

                const groupAccounts = composition.accounts.map(acct => ({
                    value: acct.cuenta,
                    label: acct.cuenta,
                    administradora: acct.administradora
                }));

                const groupAdmins = composition.administradoras.map(admin => ({
                    label: admin.administradora,
                    options: groupAccounts.filter(function (acct) {
                        return acct.administradora == admin.administradora
                    }
                    )
                }));

                const accountSelectOptions = composition.accounts.map(acct => ({
                    value: acct.cuenta,
                    label: acct.cuenta
                }));

                // Update Cartola state composition
                let acctComp = {
                    clientName: resp.data.clientName,
                    adminSelectOptions: adminSelectOptions,
                    adminSelectValue: adminSelectOptions,
                    accountSelectOptions: accountSelectOptions,
                    accountSelectValue: accountSelectOptions,
                    groupSelectOption: groupAdmins
                }

                dispatch(fetch_composition_success(acctComp));
                dispatch(fetch_breakdown(id, isGroup))
            })
            .catch(err => dispatch(fetch_composition_error(err)))
    }
}

function fetchIndicadoresEconomicos(fechaEnd) {
    // LA FECHA ES EN FORMATO "dd-mm-aaaa"
    const fecha = moment(fechaEnd).format('DD-MM-YYYY');
    let indic = ['uf', 'dolar', 'euro']
    var newHeaders = new Headers();
    var miInit = {
        method: 'GET',
        headers: newHeaders,
        mode: 'cors',
        cache: 'default'
    };
    return indic.map(ind => {
        return fetch("https://mindicador.cl/api/" + ind + "/" + fecha, miInit)
            .then(resp => resp.json())
            .then(data => ({ [ind]: data.serie }))
            .catch(err => console.log(err))
    })
}

const clpFormatter = new Intl.NumberFormat('es-ES', {
    minimumFractionDigits: 0
});

export const fetchCartolaPDF = (id, isGroup = false) => {

    return (dispatch, getState) => {
        dispatch(fetch_cartolapdf_init());
        let cartolaState = getState().cartola;
        let groupState = getState().group;
        let clientName = "";
        let clientLastName = "";
        let clientRut = "";
        let shortId = "";
        let site_name = getState().login.appName;

        if (cartolaState.listClientsCartola.length > 0 && !isGroup) {
            shortId = "8iXlQgX";
            let clientData = cartolaState.listClientsCartola.filter(x => x.id == id)[0];
            clientName = clientData.name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
            clientRut = clientData.rut;
            clientLastName = clientData.last_name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
        }

        if (groupState.groupList.length > 0 && isGroup) {
            shortId = "BJM45dg2F";
            let groupData = groupState.groupList.filter(x => x.group_id == id)[0];
            clientName = groupData.representante_inf ?
                groupData.representante_inf.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
                : '';
            clientRut = groupData.rut_representante_inf;
        }

        //Cartola perfil cliente
        if (!cartolaState.listClientsCartola.length > 0 && !groupState.groupList.length > 0) {
            shortId = isGroup ? "BJM45dg2F" : "8iXlQgX";
            clientName = getState().user.name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
            clientRut = `${getState().user.rut}-${getState().user.dv}`;
        }

        const startPeriod = moment(cartolaState.periodStart).locale('es').format('YYYY/MM/DD');
        const endPeriod = moment(cartolaState.periodEnd).locale('es').format('YYYY/MM/DD');

        client.downloadPdf('/api/auth/fetch-cartola-pdf', {
            endPeriod,
            template: {
                shortid: shortId
            },
            data: {
                site_name: site_name,
                client_rut: rutFormat(clientRut),
                client_name: clientName,
                client_last_name: clientLastName,
                period_start: startPeriod,
                period_end: endPeriod,
                generated_date: today.format('YYYY/MM/DD'),
                initial_amount: clpFormatter.format(Math.trunc(parseFloat(cartolaState.consolidated[0]['patrimonio_inicio_periodo']))),
                final_amount: cartolaState.montoTotal,
                rentabilidad_real: (parseFloat(cartolaState.consolidated[0]['rentabilidadReal']) * 100).toFixed(2),
                aportes: clpFormatter.format(Math.trunc(parseFloat(cartolaState.consolidated[0]['aportes']))),
                retiros: clpFormatter.format(Math.trunc(parseFloat(cartolaState.consolidated[0]['retiros']))),
                cambio_valor: clpFormatter.format(Math.trunc(parseFloat(cartolaState.consolidated[0]['cambio_valor_cartera']))),
                parsedCartera: cartolaState.parsedCartera,
                movements: cartolaState.movimientos,
                consolidated: cartolaState.consolidated,
                consolidatedByAccount: cartolaState.consolidatedByAccount,
                percentageAccount: cartolaState.percentageAccount,
                consolidatedByCurrency: cartolaState.consolidatedByCurrency,
                percentageCurrency: cartolaState.percentageCurrency,
                consolidatedByType: cartolaState.consolidatedByType,
                percentageAssetTypes: cartolaState.percentageAssetTypes,
                // indicadores: indicadores
            },
        }).then(resp => {
            dispatch(fetch_cartolapdf_success());
            const content = resp.headers['content-type'];
            download(resp.data, 'Cartola Consolidada '.concat(today.format('YYYYMMDD'), '.pdf'), content);

        }).catch(error => {
            dispatch(fetch_cartolapdf_error(error));
            console.log(error)
        });
       
    }
}


export const selectClientCartolaList = (clientId, clientName = "") => {
    return (dispatch, getState) => {
        dispatch(set_client_id(clientId, clientName));
        const urlParams = urlParamsFormat(getState().cartola.tableState);
        dispatch(updateRouterHistory(`/dashboard/cartolas/client?id=${clientId}&${urlParams}`));
    }
}

// export const selectClient = (clientId) => {
//     return (dispatch, getState) => {
//         dispatch(set_client_id(clientId));
//     }
// }

export const select_account = (val) => {
    return (dispatch, getState) => {
        console.log('Account Select!!! ', val);
        dispatch(select_account_init());
        let account_select = {
            accountSelectValue: val
        }
        dispatch(select_account_success(account_select));

    }
}


// export const select_date = (range, states) => {
//     return (dispatch, getState) => {
//         dispatch(select_date_init());
//         let dates_selected = {
//             datePickerValue: range,
//             datePickerStates: states
//         }
//         dispatch(select_date_success(dates_selected));
//     }
// }

export const select_date = (range, states = "") => {
    return (dispatch, getState) => {
        const { start, end } = getState().cartola.datePickerValue;
        const dateFormat = "YYYY-MM-DD";

        if (!range.start || !range.end) {
            if (range.start) {
                moment(range.start).format(dateFormat);
                range.end = end;
            } else {
                range.start = start;
                moment(range.end).format(dateFormat);
            }
            if (range.start > range.end) {
                return
            }
        }

        dispatch(select_date_init());
        let dates_selected = {
            datePickerValue: range,
            datePickerStates: states
        }
        dispatch(select_date_success(dates_selected));
    }
}

export const getListClientsCartola = () => {
    return (dispatch, getState) => {
        if (getState().cartola.listClientsCartola.length == 0) {
            dispatch(getListClientsCartolaInit());
            httpClient.getAll(urlListClientsCartola)
                .then(response => dispatch(getListClientsCartolaSuccess(response)))
                .catch(error => dispatch(getListClientsCartolaError(error)))
        }
    }
}

export const setTableStateListClient = state => {
    return dispatch => {
        dispatch(_setTableStateListClient(state))
    }
}

const _setTableStateListClient = state => ({
    type: LIST_CLIENTS_TABLE_STATE,
    payload: {
        state
    }
})

const getListClientsCartolaInit = () => ({
    type: LIST_CLIENTS_CARTOLA_INIT
})

const getListClientsCartolaSuccess = (response) => ({
    type: LIST_CLIENTS_CARTOLA_SUCCESS,
    payload: {
        response
    }
})

const getListClientsCartolaError = (error) => ({
    type: LIST_CLIENTS_CARTOLA_ERROR,
    payload: {
        error
    }
})


const _resetClientCartola = () => ({
    type: RESET_CARTOLA_CLIENT
})


const fetchCartolaAdvisorClientInit = client => ({
    type: CARTOLA_ADVISOR_CLIENT_INIT,
    payload: {
        client
    }
})

const fetchCartolaAdvisorClientSuccess = response => ({
    type: CARTOLA_ADVISOR_CLIENT_SUCCESS,
    payload: {
        response
    }
})

const fetchCartolaAdvisorClientError = error => ({
    type: CARTOLA_ADVISOR_CLIENT_ERROR,
    payload: {
        error
    }
})

const select_account_init = () => ({
    type: SELECT_ACCOUNT_INIT
})

const select_account_success = acct => ({
    type: SELECT_ACCOUNT_SUCCESS,
    payload: {
        acct
    }
})

const select_date_init = () => ({
    type: SELECT_DATE_INIT
})

const select_date_success = dates => ({
    type: SELECT_DATE_SUCCESS,
    payload: {
        dates
    }
})

const fetch_composition_init = () => ({
    type: FETCH_COMPOSITION_INIT
})

const fetch_composition_success = composition => ({
    type: FETCH_COMPOSITION_SUCCESS,
    payload: {
        composition
    }
})

const fetch_composition_error = error => ({
    type: FETCH_COMPOSITION_ERROR,
    payload: {
        error
    }
})

const fetch_breakdown_init = () => ({
    type: FETCH_BREAKDOWN_INIT
})

const fetch_breakdown_success = breakdown => ({
    type: FETCH_BREAKDOWN_SUCCESS,
    payload: {
        breakdown
    }
})

const fetch_breakdown_error = error => ({
    type: FETCH_BREAKDOWN_ERROR,
    payload: {
        error
    }
})

const fetch_cartolapdf_init = () => ({
    type: FETCH_CARTOLAPDF_INIT
})

const fetch_cartolapdf_success = () => ({
    type: FETCH_CARTOLAPDF_SUCCESS
})

const fetch_cartolapdf_error = error => ({
    type: FETCH_CARTOLAPDF_ERROR,
    payload: {
        error
    }
})

const set_client_id = (clientId, clientName) => ({
    type: SET_CLIENT_ID,
    payload: {
        clientId,
        clientName
    }
})
