import React from 'react'
import {connect} from 'react-redux';
import QuickEdit from '../../../components/Clients/MainClient/QuickEdit';
import {updateClientUser, getClientData} from '../../../actions/client'
import withParams from '../../../helpers/withParams';

class index extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            active: false,
            
            name: this.props.data.name,
            last_name: this.props.data.last_name,
            email: this.props.data.email,
            id: this.props.data.id
            
        }

        this.setActive = this.setActive.bind(this)
        this.onChangeField = this.onChangeField.bind(this)
        this.save = this.save.bind(this)
    }

    componentDidUpdate(prevProps){
        if(prevProps != this.props) {
            this.setState({
                name: this.props.data.name,
                last_name: this.props.data.last_name,
                email: this.props.data.email,
                id: this.props.data.id
            })
        }
    }

    setActive() {
        this.setState({active: !this.state.active})
    }

    save(data) {
        this.props.save(data)
        this.props.getClientData(this.props.params.id)
    }

    onChangeField(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        console.log(name)
        this.setState({[name]: value})
    }



    render() {
        console.log("clientData", this.props.data)
        return(
            <QuickEdit 
                active={this.state.active}
                data={this.state}
                pending={this.props.pending}
                content={this.props.content}
                setActive={this.setActive}
                onChangeField={this.onChangeField}
                save={this.save}
            />
        )
    }
}

const mapStateToProps = state => ({
    
})

const mapDispatchToProps = dispatch => ({
    save: data => dispatch(updateClientUser(data)),
    getClientData: client => dispatch(getClientData(client))
})


export default withParams(connect(mapStateToProps, mapDispatchToProps)(index));