import {LOGIN_INIT, LOGIN_SUCCESS, LOGIN_ERROR, FORGOT_PASSWORD_INIT, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR,
    PASSWORD_RESET_INIT, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_ERROR,
    LOGOUT_INIT, LOGOUT_SUCCESS, LOGOUT_ERROR, LOGIN_FAKE_INIT, LOGIN_FAKE_SUCCESS,
    NOT_LOGOUT_INIT, NOT_LOGOUT_SUCCESS, NOT_LOGOUT_ERROR, GET_TENANT_INIT, GET_TENANT_SUCCESS, GET_TENANT_ERROR} from '../constants';

const initialState = {
    user: null,
    userLoggedIn: false,
    userUrl: '/',
    sessionTime: 60, //seconds
    permissions: [],
    roles: [],
    isFirstLogin: '',
    accessToken: '',
    appName: '',
    appColors: '',
    linkCompany: '',
    linkAbout: '',
    linkContact: '',
    channelBase: '',
    error: null,
    lastSeenAt: null,
    timeServer: null,
    logo: null,
    appVersion: null,
    rememberMe: false,
    pending: false,
    forgotPasswordMsg: '',
    passwordResetMsg: ''
}

const login = (state = initialState, action) =>{
    switch(action.type){
        /*case LOGIN_FAKE_INIT:
            return {
                ...state
            }
        case LOGIN_FAKE_SUCCESS:
            const {
                isFirstLogin,
                accessToken,
                userLoggedIn,
                appName,
                appColors,
                linkCompany,
                linkAbout,
                linkContact,
                channelBase,
                sessionTime,
                lastSeenAt,
                timeServer,
                logo,
                appVersion,

            } = action.payload.userInfo;
            return {
                ...state,
                isFirstLogin,
                accessToken,
                userLoggedIn,
                appName,
                appColors,
                linkCompany,
                linkAbout,
                linkContact,
                channelBase,
                sessionTime,
                lastSeenAt,
                timeServer,
                logo,
                appVersion,
            }*/
        case LOGIN_INIT:
            return {
                ...state,
                pending: true,
                error: null,
                forgotPasswordMsg: ''
            };
        case LOGIN_SUCCESS:
            console.log('LOGIN_SUCCESS', action.payload.userInfo);
            const {
                isFirstLogin,
                accessToken,
                userLoggedIn,
                appName,
                appColors,
                linkCompany,
                linkAbout,
                linkContact,
                channelBase,
                sessionTime,
                lastSeenAt,
                timeServer,
                logo,
                appVersion,
                rememberMe

            } = action.payload.userInfo;
            console.log('SESSION_TIME', sessionTime);
            return {
                ...state,
                isFirstLogin,
                accessToken,
                userLoggedIn,
                appName,
                appColors,
                linkCompany,
                linkAbout,
                linkContact,
                channelBase,
                sessionTime,
                lastSeenAt,
                timeServer,
                logo,
                appVersion,
                rememberMe,
                pending: false
            }

        case LOGIN_ERROR:
            return {
                ...state,
                error: action.payload.error,
                pending: false
            }

        case LOGOUT_INIT:
            return {
                ...state,
            }
        case LOGOUT_SUCCESS:
            return {
                state: undefined
            }
        case LOGOUT_ERROR:
            return {
                state: undefined
            }

        case NOT_LOGOUT_SUCCESS:
            return{
                ...state,
                sessionTime: action.payload.sessionTime,
                accessToken: action.payload.accessToken
            }
        case GET_TENANT_INIT:
            return{
                ...state,
            }
        case GET_TENANT_SUCCESS:
            console.log('TENANT PAYLOAD', action.payload);
            const {
                app_colors,
                app_name,
                link_about,
                link_company,
                link_contact,
                time_server,
            } = action.payload;
            return{
                ...state,
                appColors: app_colors,
                appName: app_name,
                linkAbout: link_about,
                linkCompany: link_company,
                linkContact: link_contact,
                logo: action.payload.logo,
                timeServer: time_server,

            }

        case FORGOT_PASSWORD_INIT:
            return {
                ...state,
                pending: true,
                error: null,
                forgotPasswordMsg: ''
            }
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                pending: false,
                forgotPasswordMsg: action.payload.msg
            }
        case FORGOT_PASSWORD_ERROR:            
            return {
                ...state,
                pending: false,
                forgotPasswordMsg: action.payload.msg
            }

        case PASSWORD_RESET_INIT:
            return {
                ...state,
                pending: true,
                error: null,
                passwordResetMsg: ''
            }
        case PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                pending: false,
                passwordResetMsg: action.payload.msg
            }
        case PASSWORD_RESET_ERROR:            
            return {
                ...state,
                pending: false,
                passwordResetMsg: action.payload.msg
            }

        default:
            return state;
    }

}


export default login;
