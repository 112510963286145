import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HistorialCuenta from '../../components/RebalanceHistorial/HistorialCuenta';
import { getAccountHistorial, handleUpdateRebalanceDone } from '../../actions/rebalanceHistorial'
import { accountHistorial, accountSelected, doneList, isLoadingHistorial
} from '../../selectors/rebalanceHistorial';
import withParams from '../../helpers/withParams';
import { urlRebalanceHistorialRebalance } from '../../api/urls';
import { urlParamsFormat, urlFormatFromParams } from '../../helpers/formatter';

class AccountsContainer extends Component {

    constructor(){
        super();
        this.handleOnclick = this.handleOnclick.bind(this);
    }

    componentDidMount(){
        const data = {
            rut_client: this.props.params.rut_client,
            cuenta:     this.props.params.cuenta
        }
        this.props.getAccountHistorial(data)
    }

    handleOnclick(data){   
        const urlTableParams = urlParamsFormat(this.props.tableState);     
        this.props.history.push(`${urlRebalanceHistorialRebalance}?rut_client=${this.props.params.rut_client}&cuenta=${this.props.params.cuenta}&rebalance=${data.id}&${urlTableParams}`)
    }

    render() {
        const urlParams = urlFormatFromParams(this.props.params);
        return (
            <div>
                <HistorialCuenta 
                    account = {this.props.accountSelected}
                    historial={this.props.accountHistorial} 
                    isLoading={this.props.isLoadingHistorial}
                    onClick ={this.handleOnclick}
                    doneList={this.props.doneList}
                    handleUpdateRebalanceDone={this.props.handleUpdateRebalanceDone}                   
                    paramsTable={urlParams}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    accountHistorial: accountHistorial(state),
    accountSelected: accountSelected(state),
    doneList: doneList(state),
    isLoadingHistorial: isLoadingHistorial(state),
    tableState: state.rebalanceHistorial.tableState
})

const mapDispatchToProps = dispatch => ({
    getAccountHistorial: data => dispatch(getAccountHistorial(data)),
    handleUpdateRebalanceDone: data => dispatch(handleUpdateRebalanceDone(data))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withParams(AccountsContainer)));