import {createSelector} from 'reselect';

export const accounts = state => state.rebalanceHistorial.accounts;
export const isLoading = state => state.rebalanceHistorial.isLoading;
export const isLoadingHistorial = state => state.rebalanceHistorial.isLoadingHistorial;
export const accountSelected = state => state.rebalanceHistorial.accountSelected;
export const accountHistorial = state => state.rebalanceHistorial.accountHistorial;
export const selectedHistorial = state => state.rebalanceHistorial.selectedHistorial;
export const generated_date = state => state.rebalanceHistorial.generated_date;
export const getResponse = state => state.rebalanceHistorial.response;
export const getError = state => state.rebalanceHistorial.error;

export const doneList = state => {
    const result = {};
    if(state.rebalanceHistorial.doneList){
        const arr = state.rebalanceHistorial.doneList;        
        for(var i = 0; i < arr.length; i++){
            result[arr[i].id] = arr[i].name;
        }  
    }
    return result  
};

export const getPortafolioActual = state => {
    if(state.rebalanceHistorial.selectedHistorial !== null){
        return state.rebalanceHistorial.selectedHistorial.fondos;
    }else{
        return null
    }    
}

export const compared = state => state.rebalanceHistorial.compared;
export const selected = state => state.rebalanceHistorial.selected;
export const portafolioRebalance = state => state.rebalanceHistorial.portafolioRebalance;