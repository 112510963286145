import {
    ROLES_GET_INIT,
    ROLES_GET_SUCCESS,
    ROLES_UPDATE_INIT,
    ROLES_UPDATE_SUCCESS,
    PERMISSIONS_GET_INIT,
    PERMISSIONS_GET_SUCCESS,
    ROLE_PERMISSIONS_INIT,
    ROLE_PERMISSIONS_SUCCESS,
    ROLE_CREATE_INIT,
    ROLE_CREATE_SUCCESS,
    ROLE_DELETE_INIT,
    ROLE_DELETE_SUCCESS,
    SET_SELECTED_ROLE,
    SET_APP_PERMISSIONS,
    SET_EDIT_ACTIVE_ROLE,
    SET_CREATE_ACTIVE_ROLE,
    SET_OPEN_EDIT_ROLE,
    SET_OPEN_CREATE_ROLE,
    SHOW_ROLE_CREATE_SUCCESS,
    SHOW_ROLE_DELETION_WARNING,
    SHOW_ROLE_MODIFICATION_WARNING,
    SHOW_ROLE_MODIFICATION_CONFIRM,
    SET_OPEN_ASSIGN_ROLE,
    SET_ASSIGN_ACTIVE,
    ASSIGN_ROLES_TO_USERS_INIT,
    ASSIGN_ROLES_TO_USERS,
    ASSIGN_ROLES_TO_USERS_SUCCESS,
    GET_USERS_WITH_ROLES,
    GET_USERS_WITH_ROLES_SUCCESS
    
} from '../constants';
import client from '../api/httpClient';
import {userPermissions, userRoles, rolePermissions, assignToAll, usersWithRolesUrl} from '../api/urls';
import auth from '../api/auth';


export const fetchPermissions = (obj) => {
    return (dispatch, getState) => {
        dispatch(fetch_permission_init());
        client.getAll(userPermissions)
        .then(resp => {
            let data = {...resp.data.data};
            let perms = {};
            resp.data.data.map(perm => {
                perms[perm.name] = false
            })
            console.log('fetch permissions', perms);
            dispatch(fetch_permissions_success(perms))
        })
    }
}

export const fetchRoles = (page) => {
    return (dispatch, getState) => {
        if (getState().permissions.roles.length == 0) {
            dispatch(fetch_roles_init());
            client.getAll(userRoles)
            .then(resp => {
                
                dispatch(fetch_roles_success(resp.data));
            })
        }
        
    }
}

export const fetchRolePermissions = (roleName) => {
    return (dispatch, getState) => {
        dispatch(fetch_role_permissions_init());
        client.create(rolePermissions, {name: roleName})
        .then(resp => {
            let rolePermissionsArray = [];
            resp.data.role_permissions.map(perms => {
                rolePermissionsArray.push({
                    name: perms.name,
                    id: perms.id
                })
            });
            let role_resp = {
                name: resp.data.role_name,
                rolePermissions: rolePermissionsArray,
                desc: resp.data.role_description
            }
            dispatch(fetch_role_permissions_success(role_resp));
        })
    }
}

export const fetchNextPage = (page) => {
    return (dispatch, getState) => {
        dispatch(fetch_roles_init());
        client.getAll(`${userRoles}?page=${page}`)
        .then(resp => {
            dispatch(fetch_roles_success(resp.data));
        })
    }
}

export const handleCreateRole = roleObject => {
    return (dispatch, getState) => {

        dispatch(create_role_init());
        client.create(userRoles, roleObject).then(resp => {
            
            dispatch(create_role_success());
        })
    }
}

export const handleUpdateRole = roleObject => {
    return (dispatch, getState) => {
        dispatch(role_update_init());
        client.update(userRoles, roleObject.id, {
            name: roleObject.name,
            permissions: Object.keys(roleObject.permissions)
        }).then(resp => {
            dispatch(role_update_success());
        });
    }
}

export const handleDeleteRole = roleObject => {
    return (dispatch, getState) => {
        dispatch(role_delete_init());
        client.del(userRoles, roleObject.id)
        .then(resp => {
            dispatch(role_delete_success());
        })
    }
}

export const setSelectedRole = role => {
    return (dispatch, getState) => {
        dispatch(set_selected_role(role))
    }
}

export const setAppPermission = perms => {
    return (dispatch, getState) => {
        dispatch(set_app_permissions(perms));
    }
}

export const setEditActive = role => {
    return (dispatch, getState) => {
        let edit_active = {
            editActive: {
                ...role
            }
        }
        console.log('editActive action', edit_active);
        dispatch(set_edit_active(edit_active));
    }
}

export const setCreateActive = role => {
    return (dispatch, getState) => {
        let newRole = {
            createActive: {
                ...role
            }
        }
        dispatch(set_create_active(newRole));
    }
}

export const setOpenEdit = isOpen => {
    return (dispatch, getState) => {
        let open = {
            openEdit: isOpen
        }
        console.log('setOpenEdit', open);

        dispatch(set_open_edit(open));
    }
}

export const setOpenCreate = isOpen => {
    return (dispatch, getState) => {
        let open = {
            openCreate: isOpen
        };
        dispatch(set_open_create(open))
    }
}

export const setAssignActive = role => {
    return (dispatch, getState) => {
        let newRole = {
            assignActive: {
                ...role
            }
        }
        console.log('setAssignActive action', newRole);
        dispatch(set_assign_active(newRole));
    }
}

export const setOpenAssign = isOpen => {
    return (dispatch, getState) => {
        let open = {
            openAssign: isOpen
        }
        console.log('setOpenAssign Action', open);
        dispatch(set_open_assign(open));
    }
}

export const assignRolesToUsers = users => {
    return(dispatch, getState) => {
        let state = getState();
        dispatch(assign_to_all_init());
        let selected_users = {
            users: users,
            roles: state.permissions.assignActive.name
        }

        client.create(assignToAll, {
            ...selected_users
        }).then(resp => {
            console.log(resp);
            dispatch(assign_to_all_success());
        })

    }
}

export const getUsersWithRoles = () => {
    return (dispatch, getState) => {
        if(getState().permissions.roleUsers.length == 0) {
            dispatch(get_users_with_roles());
            client.getAll(usersWithRolesUrl)
            .then(resp => {
                console.log(resp);
                dispatch(get_users_with_roles_success(resp.data));
            })
            .catch(error => {
                console.log(error);
            })
        }
        
        
    }
}


export const get_users_with_roles = () => ({
    type: GET_USERS_WITH_ROLES,   
});

export const get_users_with_roles_success = usersRoles => ({
    type: GET_USERS_WITH_ROLES_SUCCESS,
    payload: usersRoles
})


export const showCreateSuccess = isSuccess => {
    return (dispatch, getState) => {
        dispatch(show_create_success(isSuccess))
    }
}

export const showDeletionWarning = warn => {
    return (dispatch, getState) => {
        dispatch(show_deletion_warning(warn))
    } 
}

export const showModificationWarning = warn => {
    return (dispatch, getState) => {
        dispatch(show_modification_warning(warn)) 
    }
}

export const showModificationConfirm = confirm => {
    return (dispatch, getState) => {
        dispatch(show_modification_confirm(confirm))
    }
}

const fetch_permission_init = () => ({
    type: PERMISSIONS_GET_INIT
})

const fetch_permissions_success = permissions => ({
    type: PERMISSIONS_GET_SUCCESS,
    payload: {
        permissions
    }
})

const fetch_roles_init = () =>  ({
    type: ROLES_GET_INIT
})

const fetch_roles_success = roles => ({
    type: ROLES_GET_SUCCESS,
    payload: {
        roles
    }
})

const fetch_role_permissions_init = () => ({
    type: ROLE_PERMISSIONS_INIT,
})

const fetch_role_permissions_success = rolePermissions => ({
    type: ROLE_PERMISSIONS_SUCCESS,
    payload: {
        rolePermissions
    }
})

const create_role_init = () => ({
    type: ROLE_CREATE_INIT
})

const create_role_success = () => ({
    type: ROLE_CREATE_SUCCESS
})

const role_update_init = () => ({
    type: ROLES_UPDATE_INIT
})

const role_update_success = () => ({
    type: ROLES_UPDATE_SUCCESS
})

const role_delete_init = () => ({
    type: ROLE_DELETE_INIT
})

const role_delete_success = () => ({
    type: ROLE_DELETE_SUCCESS
})

const set_selected_role = role => ({
    type: SET_SELECTED_ROLE,
    payload: {
        role
    }
})

const set_app_permissions = appPermissions => ({
    type: SET_APP_PERMISSIONS,
    payload: {
        appPermissions
    }
})

const set_edit_active = activeEdit => ({
    type: SET_EDIT_ACTIVE_ROLE,
    payload: {
        activeEdit
    }
})

const set_create_active = activeCreate => ({
    type: SET_CREATE_ACTIVE_ROLE,
    payload: {
        activeCreate
    }
})

const set_open_edit = isEditOpen => ({
    type: SET_OPEN_EDIT_ROLE,
    payload: {
        isEditOpen
    }
})

const set_open_create = isCreateOpen => ({
    type: SET_OPEN_CREATE_ROLE,
    payload: {
        isCreateOpen
    }
})

const set_open_assign = isAssignOpen => ({
    type: SET_OPEN_ASSIGN_ROLE,
    payload: {
        isAssignOpen
    }
})

const show_create_success = isCreateSuccess => ({
    type: SHOW_ROLE_CREATE_SUCCESS,
    payload: {
        isCreateSuccess
    }
})

const show_deletion_warning = warn => ({
    type: SHOW_ROLE_DELETION_WARNING,
    payload: {
        warn
    }
})

const show_modification_warning = warn => ({
    type: SHOW_ROLE_MODIFICATION_WARNING,
    payload: {
        warn
    }
})

const show_modification_confirm = confirm => ({
    type: SHOW_ROLE_MODIFICATION_CONFIRM,
    payload: {
        confirm
    }
})

const set_assign_active = activeAssign => ({
    type: SET_ASSIGN_ACTIVE,
    payload: {
        activeAssign
    }
})

const assign_to_all_init = () => ({
    type: ASSIGN_ROLES_TO_USERS_INIT
})

const assign_to_all_success = () => ({
    type: ASSIGN_ROLES_TO_USERS_SUCCESS
})