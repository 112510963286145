import React from 'react';
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Graphic from './Graphic';
import Portafolio from './Portafolio';
import Rebalanceo from './Rebalanceo';


const index = ({data, resultIsLoading, accountSelected, 
    reset, error, pdfRebalance, handleSelectedPort, getResponse,
    getPortafolioSelected}) => {

    const [progress, setProgress] = React.useState(0);
    React.useEffect(() => {
        function tick() {
        // reset when reaching 100%
        setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
        }

        const timer = setInterval(tick, 20);
        return () => {
            clearInterval(timer);
        };
    }, []);

    const exportPdf = data =>{
        console.log('Exportando a PDF')
        console.log(data)
        pdfRebalance(data)
    }

    const showInfo = () => {
        if(data.rebalance){            
            const {code, fondos, info, labelMatriz, newCor_mat, 
                portafolioGraph, carteraGraph, weights,
                seriesNotFound, seriesNotFoundPesos} = data.rebalance;
            //Pdf Export Button
            const pdfData = {
                data: {                    
                    account     : accountSelected,
                    fondos      : fondos,
                    info        : info,
                    labelMatriz : labelMatriz,
                    newCor_mat  :  newCor_mat,
                    portafolioGraph : portafolioGraph,
                    carteraGraph    :carteraGraph,
                    weights         :weights
                }
            }

            return <div>               
                <Portafolio 
                    accountSelected={accountSelected}
                    funds={fondos}
                    info ={info} 
                    handleExportPdf={exportPdf} 
                    pdfData={pdfData}
                    getResponse={getResponse}
                    seriesNotFound={seriesNotFound}
                    seriesNotFoundPesos={seriesNotFoundPesos}
                />
                <Graphic label={labelMatriz} matriz={newCor_mat} portafolioGraph={portafolioGraph} carteraGraph={carteraGraph}/>
                <Rebalanceo 
                    weights={weights} 
                    handleSelectedPort={handleSelectedPort}
                    getPortafolioSelected={getPortafolioSelected}
                />
            </div>
        }else{           
            return <div>
                    <h4 className="text-center">Error al rebalancear</h4>
                    {error != null &&
                    <div className="text-center">   
                        <Button variant="contained" color="primary" onClick={reset}>                                
                            Terminar
                        </Button> 
                    </div>
                    }
                </div>
        }
    }

    return (
        <div>
            {resultIsLoading ? 
                <div className="text-center">
                    <h4>Obteniendo rebalanceo...</h4>
                    <CircularProgress variant="determinate" value={progress} />
                </div>
            :
                showInfo()
            }                    
        </div>
    );
};

export default index;