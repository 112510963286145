import React from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {MATERIAL_TABLE_LOCALIZATION} from '../../../constants';
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";

const theme = createTheme({
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        light: '#01579b',
        main: '#ff9100',
      },       
    },      
});

const state = {	  
    columns: [
        { title: 'Nombre', field: 'nombre' },
        { title: 'RUN', field: 'rut' },
        { title: 'Serie', field: 'serie' },
        { title: 'P. Neto', field: 'patrimonio_neto', type: 'currency' }, 
        { title: 'Inicio', field: 'inicio', type: 'date' }, 
        { title: 'Termino', field: 'termino' , type: 'date'},         
        { title: 'Cont.', field: 'continuador' },  
        { title: '%', field: 'rentabilidad', render: rowData => (rowData.rentabilidad > 0 ? 
            <span style={{color: '#178c10'}}>{rowData.rentabilidad}%</span> 
            :
            <span style={{color: '#fd0e0e'}}>{rowData.rentabilidad}%</span>)    
        },               
    ],
}

const index = ({filtered, data, pending}) =>  {

    const handleRowClicked = selectedRow =>{
        console.log(selectedRow)
    }

    const handleRowSelected = rows =>{       
      
    }  

    return (
        <div>
            <Row>
            {filtered.map(fund => 
                <Col md={2}>
                    <Card                     
                        style={fund.rentabilidad > 0 ? 
                            { backgroundColor: '#63f568', borderColor: '#63f568' }:
                            { backgroundColor: '#f97272', borderColor: '#f97272' }
                        }
                    >
                        <CardBody>
                            <h6>
                                <i className="now-ui-icons arrows-1_minimal-up"></i>{fund.rentabilidad}%
                                <span className="float-right">{fund.serie}</span>
                            </h6>                            
                            {fund.nombre}                             
                        </CardBody>
                    </Card>    
                </Col>            
            )}
            </Row>
            <p className="text-center">(Diferencia porcentual basada en los últimos dos días)</p>
                    
            <MuiThemeProvider theme={theme}>
                <MaterialTable
                    title="Fondos"
                    columns={state.columns}
                    data = {data}   
                    isLoading= {pending}              
                    options={{
                        // selection: true,
                        sorting: true,
                        // exportButton: true,
                        rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#37b15933' : '' })
                    }}                
                    onRowClick={((evt, selectedRow) => handleRowClicked(selectedRow) )}                
                    onSelectionChange={(rows) => handleRowSelected(rows)}
                    localization = {MATERIAL_TABLE_LOCALIZATION}
                />
            </MuiThemeProvider>
        </div>
    );
}

export default index;