import React, { Component } from "react";
import {connect} from 'react-redux';
import withParams from '../../../helpers/withParams';
import { urlFormatFromParams } from '../../../helpers/formatter';
import Group from '../../../components/Cartola/Group';
import { backgroundColor } from '../../../selectors/appColors';
import { groupList, fetchGroupComposition } from '../../../actions/group';
import { fetch_composition } from '../../../actions/cartola';
import { getClientName } from '../../../selectors/cartola';
import Cartola from '../../../components/Cartola';

class CartolaGroupContainer extends Component{

    componentDidMount(){
        console.log("############### CartolaGroupContainer ####################")
        console.log(this.props.params)   
        if (this.props.assignedPermissions.includes('view-advisor-dashboard')) {
            this.props.fetch_composition(this.props.params.id, true);
            this.props.groupList();             
        } else {
            this.props.fetch_composition(this.props.groupOwner, true);
        }    
    }

    render(){
        if (this.props.assignedPermissions.includes('view-advisor-groups')) {            
            const urlParams = urlFormatFromParams(this.props.params);
            return (
                <div>
                    <Group
                        cartolaId = {this.props.params.id}
                        backgroundColor={this.props.backgroundColor}
                        fetchBreakdownLoading={this.props.fetchBreakdownLoading}
                        fetchBreakdownFailed={this.props.fetchBreakdownFailed}
                        consolidated={this.props.consolidated}
                        periodStart={this.props.periodStart}
                        periodEnd={this.props.periodEnd}
                        cartera={this.props.cartera}
                        parsedCartera={this.props.parsedCartera}
                        cash={this.props.cash}
                        consolidatedByCurrency={this.props.consolidatedByCurrency}
                        consolidatedByAccount={this.props.consolidatedByAccount}
                        consolidatedByType={this.props.consolidatedByType}
                        movimientos={this.props.movimientos}
                        percentageAssetTypes={this.props.percentageAssetTypes}
                        percentageCurrency={this.props.percentageCurrency}
                        clientName={this.props.clientName}
                        urlParams={urlParams}
                        
                        />
                </div>
            )
        } else {
            return (
                <div>
                    <Cartola             
                        cartolaId = {this.props.groupOwner}
                        backgroundColor={this.props.backgroundColor}
                        fetchBreakdownLoading={this.props.fetchBreakdownLoading}
                        fetchBreakdownFailed={this.props.fetchBreakdownFailed}
                        consolidated={this.props.consolidated}
                        periodStart={this.props.periodStart}
                        periodEnd={this.props.periodEnd}
                        cartera={this.props.cartera}
                        parsedCartera={this.props.parsedCartera}
                        cash={this.props.cash}
                        consolidatedByCurrency={this.props.consolidatedByCurrency}
                        consolidatedByAccount={this.props.consolidatedByAccount}
                        consolidatedByType={this.props.consolidatedByType}
                        movimientos={this.props.movimientos}
                        percentageAssetTypes={this.props.percentageAssetTypes}
                        percentageCurrency={this.props.percentageCurrency}
                        isGroup={true}
                    />
                </div>
            )
        }
    }
}

const mapStateToProps = state =>({
    assignedPermissions: state.user.permissions,
    backgroundColor: backgroundColor(state),
    fetchBreakdownLoading: state.cartola.fetchBreakdownLoading,
    fetchBreakdownFailed: state.cartola.fetchBreakdownFailed,
    consolidated: state.cartola.consolidated,
    periodStart: state.cartola.periodStart,
    periodEnd: state.cartola.periodEnd,
    cartera: state.cartola.cartera,
    parsedCartera: state.cartola.parsedCartera,
    cash: state.cartola.cash,
    consolidatedByCurrency: state.cartola.consolidatedByCurrency,
    consolidatedByAccount: state.cartola.consolidatedByAccount,
    consolidatedByType: state.cartola.consolidatedByType,
    movimientos: state.cartola.movimientos,
    percentageAssetTypes: state.cartola.percentageAssetTypes,
    percentageCurrency: state.cartola.percentageCurrency,
    clientName: getClientName(state),
    groupOwner: state.user.groupOwner
})

const mapDispatchToProps = dispatch => ({
    groupList: () => dispatch(groupList()),
    fetch_composition: (id, isGroup) => dispatch(fetch_composition(id, isGroup))
})

export default withParams(connect(mapStateToProps, mapDispatchToProps)(CartolaGroupContainer));