import React, { Component } from 'react';
import {connect} from 'react-redux';
import FilterMenu from '../../../components/Clients/FilterMenu';
import {setFiltersSelected} from '../../../actions/client';
import {
    getFilters, 
    getFilterData, 
    pendingFilter
} from '../../../selectors/client';

class index extends Component {

    constructor(){
        super()
        this.setFilters = this.setFilters.bind(this);
    }
    
    setFilters(filters){
        // console.log(filters)
        this.props.setFiltersSelected(filters)
    }

    render() {
        return (
            <div>
                <FilterMenu 
                    data={this.props.filterData}
                    filters={this.props.filters} 
                    setFilters={this.setFilters}
                    isLoading={this.props.pendingFilter}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    filters: getFilters(state),
    filterData: getFilterData(state),
    pendingFilter: pendingFilter(state),
})

const mapDispatchToProps = dispatch => ({
    setFiltersSelected: filters => dispatch(setFiltersSelected(filters))
})

export default connect(mapStateToProps, mapDispatchToProps)(index);