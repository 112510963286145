import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const index = ({data, isValidated, restrictionsSelected}) => {
    const classes = useStyles();

    const {n_years, recom_ports, n_portafolios, libreRiesgo} = restrictionsSelected;
    const [state, setState] = React.useState({
        n_years,
        recom_ports,
        // n_portafolios,
        // libreRiesgo
      });

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth * 2);        
    }, []);

    React.useEffect(() => {
        validateInput()
    })

    const handleChange = name => event => {
        setState({
          ...state,
          [name]: event.target.value,
        });
    };

    const validateInput = () =>{
        if(state.n_years && state.recom_ports 
            // && state.n_portafolios && state.libreRiesgo
            ) isValidated(state); 
    }

    return (
        <div>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabel} id="n_years-label">
                    Cantidad de Años
                </InputLabel>
                <Select
                    labelId="n_years-label"
                    id="n_years-Select"
                    value={state.n_years}
                    onChange={handleChange('n_years')}
                    labelWidth={labelWidth}
                >
                    <MenuItem value=""><em>Ninguno</em></MenuItem>
                    {data[0].n_years.map((x, i) => <MenuItem key={i} value={x.id}>{x.name}</MenuItem>)}

                </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabel} id="recom_ports-label">
                    Portafolios sugeridos
                </InputLabel>
                <Select
                    labelId="recom_ports-label"
                    id="recom_ports-Select"
                    value={state.recom_ports}
                    onChange={handleChange('recom_ports')}
                    labelWidth={labelWidth}
                >
                    <MenuItem value=""><em>Ninguno</em></MenuItem>
                    {data[1].recom_ports.map((x, i) => <MenuItem key={i} value={x.id}>{x.name}</MenuItem>)}

                </Select>
            </FormControl>

            {/* <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabel} id="n_portafolios-label">
                    Número de Portafolios
                </InputLabel>
                <Select
                    labelId="n_portafolios-label"
                    id="n_portafolios-Select"
                    value={state.n_portafolios}
                    onChange={handleChange('n_portafolios')}
                    labelWidth={labelWidth}
                >
                    <MenuItem value=""><em>Ninguno</em></MenuItem>
                    {data[2].n_portafolios.map((x, i) => <MenuItem key={i} value={x.id}>{x.name}</MenuItem>)}
                </Select>
            </FormControl>

            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabel} id="libreRiesgo-label">
                    Libre Riesgo
                </InputLabel>
                <Select
                    labelId="libreRiesgo-label"
                    id="libreRiesgo-Select"
                    value={state.libreRiesgo}
                    onChange={handleChange('libreRiesgo')}
                    labelWidth={labelWidth}
                >
                    <MenuItem value=""><em>Ninguno</em></MenuItem>
                    {data[3].libreRiesgo.map((x, i) => <MenuItem key={i} value={x.id}>{x.name}</MenuItem>)}
                </Select>
            </FormControl> */}
        </div>
    );
};

export default index;