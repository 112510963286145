import React from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Container,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from "reactstrap";


class ResumenNav extends React.Component {
    constructor(props) {
        super(props);
        
    }

    render() {
        return(
            <Navbar expand="lg" color="primary" light>
                <Container>
                    <NavbarBrand style={{color: "white"}}>Resumen de Cuenta</NavbarBrand>
                    <NavbarToggler onClick={this.props.toggle}>
                        <span className="navbar-toggler-bar navbar-kebab"></span>
                        <span className="navbar-toggler-bar navbar-kebab"></span>
                        <span className="navbar-toggler-bar navbar-kebab"></span>
                    </NavbarToggler>
                    <Collapse isOpen={this.props.isOpen} navbar style={{justifyContent: "space-evenly"}}>
                        <Nav navbar>                            
                            <NavItem
                                className={this.props.hTabs === "ht1" ? "active" : ""}
                                onClick={() => this.props.setActiveTabHandler("ht1")}
                                style={{ cursor: 'pointer', color: "white" }}
                            >
                                <NavLink
                                style={{ cursor: 'pointer', color: "white" }}
                                >
                                    Cartera
                                </NavLink>
                            </NavItem>
                            <NavItem
                                className={this.props.hTabs === "ht2" ? "active" : ""}
                                onClick={() => this.props.setActiveTabHandler("ht2")}
                                style={{ cursor: 'pointer', color: "white" }}
                            >
                                <NavLink
                                style={{ cursor: 'pointer', color: "white" }}
                                >
                                    Movimientos
                                </NavLink>
                            </NavItem>
                            {/* 
                            <NavItem
                                className={this.props.hTabs === "ht3" ? "active" : ""}
                                onClick={() => this.props.setActiveTabHandler("ht3")}
                                style={{ cursor: 'pointer', color: "white" }}
                            >
                                <NavLink
                                style={{ cursor: 'pointer', color: "white" }}
                                >
                                    Cuentas
                                </NavLink>
                            </NavItem>
                            */}
                            <NavItem
                                className={this.props.hTabs === "ht4" ? "active" : ""}
                                onClick={() => this.props.setActiveTabHandler("ht4")}
                                style={{ cursor: 'pointer', color: "white" }}
                            >
                                <NavLink
                                style={{ cursor: 'pointer', color: "white" }}
                                >
                                    Monedas
                                </NavLink>
                            </NavItem>
                            {/* <NavItem
                                className={this.props.hTabs === "ht5" ? "active" : ""}
                                onClick={() => this.props.setActiveTabHandler("ht5")}
                                style={{ cursor: 'pointer', color: "white" }}
                            >
                                <NavLink
                                style={{ cursor: 'pointer', color: "white" }}
                                >
                                    Activos
                                </NavLink>
                            </NavItem> */}
                            <NavItem
                                className={this.props.hTabs === "ht6" ? "active" : ""}
                                onClick={() => this.props.setActiveTabHandler("ht6")}
                                style={{ cursor: 'pointer', color: "white" }}
                            >
                                <NavLink
                                style={{ cursor: 'pointer', color: "white" }}
                                >
                                    Caja
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <span className="navbar-text" style={{
                            color: "rgba(227, 227, 227, 0.5)"
                        }}>{this.props.startPeriod} - {this.props.endPeriod}</span>
                    </Collapse>
                </Container>
            </Navbar>
        )
    }
}

export default ResumenNav;