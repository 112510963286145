import {ACTIVO_INFO_GET_INIT, ACTIVO_INFO_GET_SUCCESS, ACTIVO_INFO_GET_ERROR
} from '../constants';
import httpClient from '../api/httpClient';
import {urlActivoCardInfo} from '../api/urls';

export const getActivosCardInfo = () => {
    return (dispatch, getState) => {
        dispatch(_getActivosCardInfoInit())
        httpClient.getAll(urlActivoCardInfo)
        .then(res => {            
            dispatch(_getActivosCardInfoSuccess(res))
        })
        .catch(error => {            
            dispatch(_getActivosCardInfoError(error))
        })
    }
}

const _getActivosCardInfoInit = () => ({
    type: ACTIVO_INFO_GET_INIT
})

const _getActivosCardInfoSuccess = response => ({
    type: ACTIVO_INFO_GET_SUCCESS,
    payload: {
        response
    }
})

const _getActivosCardInfoError = error => ({
    type: ACTIVO_INFO_GET_ERROR,
    payload: {
        error
    }
})