import React from 'react';
import {Card, CardBody,	CardHeader, Label} from "reactstrap";
import {AccountCircleTwoTone, AccountBalance, Message, NoteAdd, PermIdentity, Warning} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));
  
function QuickView(props) {
    const classes = useStyles();

    return (
        <Card>
            <CardBody>
            <div className={classes.root}>
                <List component="nav" aria-label="main mailbox folders">
                    <ListItem button>
                        <ListItemIcon>
                            <AccountCircleTwoTone />
                        </ListItemIcon>
                        <ListItemText primary="Ultima Sesión" />
                    </ListItem>

                    <Divider />

                    <ListItem button>
                        <ListItemIcon>
                            <AccountBalance />
                        </ListItemIcon>
                        <ListItemText primary="Ultimo Rebalanceo" />
                    </ListItem>

                    <Divider />

                    <ListItem button>
                        <ListItemIcon>
                            <Warning />
                        </ListItemIcon>
                        <ListItemText primary="Perfil de Riesgo" />
                    </ListItem>

                    <Divider />

                    <ListItem button>
                        <ListItemIcon>
                            <Message />
                        </ListItemIcon>
                        <ListItemText primary="Recordatorios" />
                    </ListItem>

                </List>

            </div>
            </CardBody>
        </Card>       

    );
}

export default QuickView;