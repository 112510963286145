import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {AccountCircle, AccountBalance, Message, NoteAdd, PermIdentity, Warning, CancelRounded} from '@material-ui/icons';
import {MATERIAL_TABLE_LOCALIZATION} from '../../../../constants';

class UsersTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            selectedRow: null,		  
            columns: [
				{ title: 'Nombre', field: 'name', width: 100 },
				{ title: 'Apellido', field: 'last_name', width: 100 },
				{ title: 'Correo', field: 'email', width: 100 },          
			],
        }
        this.theme = createMuiTheme({
            palette: {
              primary: {
                main: '#4caf50',
              },
              secondary: {
                light: '#01579b',
                main: '#ff9100',
              },       
            },      
        });
        this.handleRowClicked = this.handleRowClicked.bind(this);
        this.handleRowSelected = this.handleRowSelected.bind(this);
    }

    handleRowClicked(selectedRow){
        // this.setState({ selectedRow })
        //this.props.selectHandler(selectedRow)
    }

    handleRowSelected(rows){
        // this.setState({ rows })
        // alert("Usuarios seleccionados: "+ rows.length)
        this.props.selectHandler(rows)
        // rows.map(x=> console.log(x))     
        // console.log(this.state.selectedRow)   
    }
    
    render() {
        return (
        <MuiThemeProvider theme={this.theme}>
            <MaterialTable
                title="Usuarios"
                columns={this.state.columns}
                data = {this.props.data}  
                options={{
                    selection: true,
                    sorting: true,
                    rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#37b15933' : '' })
                    // headerStyle: {
                    //     backgroundColor: '#01579b',
                    //     color: '#FFF'
                    // }
                }}
                // onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}                
                onRowClick={((evt, selectedRow) => this.handleRowClicked(selectedRow) )}                
                // onSelectionChange={(rows) => alert('You selected ' + rows.length + ' rows ')}
                onSelectionChange={(rows) => this.handleRowSelected(rows)}
                
                localization = {MATERIAL_TABLE_LOCALIZATION}
            />
        </MuiThemeProvider>
        );
    }
}

export default UsersTable;