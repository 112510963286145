import {GROUP_LIST_INIT, GROUP_LIST_SUCCESS, GROUP_LIST_ERROR, LIST_GROUPS_TABLE_STATE
} from '../constants';

const initialState = {
    groupList: [],
    error: "",
    groupListLoading: true,
    tableState: {		
        order : "desc",
        orderBy : "aum_clp",
        page : 0,
        dense : false,
        searchValue : '',
        rowsPerPage : 5,
        selected : []
    }
}

const group = (state = initialState, action) => {
    switch(action.type){
        case GROUP_LIST_INIT:
            return {
                ...state,
            }        
        case GROUP_LIST_SUCCESS:
            return {
                ...state,
                groupList: action.payload.groupList,
                groupListLoading: false
            }
        case GROUP_LIST_ERROR:
            return {
                ...state,
                error: action.payload.error,
                groupListLoading: false
            }
        
        case LIST_GROUPS_TABLE_STATE:
            return {
                ...state,
                tableState: action.payload.state
            }
        
        default:
            return state;
    } 
}

export default group;