import React from 'react';
import { Bar } from 'react-chartjs-2';

var config = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
          label: 'Mixtos',
          data: [67.8],
          backgroundColor: '#D6E9C6' // green
        },
        {
          label: 'Renta Variable',
          data: [20.7],
          backgroundColor: '#FAEBCC' // yellow
        },
        {
          label: 'Renta Fija',
          data: [11.4],
          backgroundColor: '#EBCCD1' // red
        }
      ]
  };

const Grafico = ({data}) =>{
    if(data){
        config.datasets[0].data = data;
        return <Bar 
                    data={config}
                    width={'auto'}
                    height={250}
                    options={
                        { 
                            maintainAspectRatio: true,
                            scales: {
                                yAxes: [{ stacked: true }],
                                xAxes: [{ stacked: true }]
                            }
                        }
                    }
                />
    }else{
        return <p>Sin datos</p>
    }
}

const Card = ({data}) => {
    return (
        <div>
            <Grafico data={data}/>
        </div>
    );
}

export default Card;