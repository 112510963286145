import React from 'react';
import { Doughnut, Bar } from 'react-chartjs-2';
import {Card, CardBody, CardFooter, CardTitle, CardText, Col, Row} from "reactstrap";
import ReactTable from 'react-table'
import 'react-table/react-table.css'

const columns = [{
    Header: 'Nombre',
    accessor: 'name'
}, {
    Header: 'Rut',
    accessor: 'rut',
}, {    
    Header: 'Patrimonio',
    accessor: 'patrimonio'
}, {
    Header: props => <span className="pull-left">%</span>, 
    accessor: 'percent'
}]

const Table = ({data}) =>{
    if(data){
        return <ReactTable data={data} columns={columns} style={{height: '20rem'}}/>
    }else{
        return <p>Sin datos</p>
    }
}

const index = ({dataUsuario}) =>  {

    return (
        <div>           

            <Row>
                <Col sm="12">
                    <Card body  style={{height: '25rem'}}>
                        <h6>Patrimonio por Usuario</h6>                            
                        <Table data={dataUsuario} />
                    </Card>
                </Col>
            </Row>

        </div>
    );
}

export default index;