import React from 'react';
import PropTypes from 'prop-types';
import { PanelHeader } from "../../components";


function ContentWithPanelHeader({panelHeaderSize, panelHeaderContent, children}) {
    return(
        <div>
            <PanelHeader size={panelHeaderSize} content={panelHeaderContent} />
            <div className="content">
                {children}
            </div>
        </div>
    )
}


ContentWithPanelHeader.propTypes = {
    panelHeaderSize: PropTypes.string,
    children: PropTypes.any,
    panelHeaderContent: PropTypes.node
}

export default ContentWithPanelHeader;