import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {MATERIAL_TABLE_LOCALIZATION} from './../../../constants';

const theme = createTheme({
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        light: '#01579b',
        main: '#ff9100',
      },       
    },      
});

const state = {	  
    columns: [
        { title: 'Rut', field: 'rut_client' },
        { title: 'Cuenta', field: 'cuenta' },
        { title: 'Administradora', field: 'administradora' },
        { title: 'Nombre', field: 'name' },          
        { title: 'Email', field: 'email' }, 
        // { title: 'Fecha', field: 'fecha' },         
        { title: 'Patrimonio', field: 'patrimonio', type: 'currency' },         
    ],
}

function Table({data, onClick, isLoading}) {

    const handleRowClicked = selectedRow =>{
        // this.props.onClick(selectedRow)
        console.log(selectedRow)
        onClick(selectedRow)
    }

    const handleRowSelected = rows =>{       
        // this.props.onClick(rows)       
    }    

    return (
    <MuiThemeProvider theme={theme}>
        <MaterialTable
            title="Historial de Rebalanceos"
            columns={state.columns}
            data = {data}   
            isLoading= {isLoading}              
            options={{
                // selection: true,
                sorting: true,
                exportButton: true,
                rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#37b15933' : '' })
            }}
            // onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}                
            onRowClick={((evt, selectedRow) => handleRowClicked(selectedRow) )}                
            // onSelectionChange={(rows) => alert('You selected ' + rows.length + ' rows ')}
            onSelectionChange={(rows) => handleRowSelected(rows)}
            localization = {MATERIAL_TABLE_LOCALIZATION}
        />
    </MuiThemeProvider>
    );
    
}

export default Table;