import React from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {MATERIAL_TABLE_LOCALIZATION} from './../../../constants';
import { rutFormat, patrimonyFormat, dateFormat } from '../../../helpers/formatter';

const theme = createTheme({
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        light: '#01579b',
        main: '#ff9100',
      },       
    },      
});

function Table ({account, data, onClick, isLoading, doneList, handleUpdateRebalanceDone}) {  

    const title = isLoading ? 'Obteniendo información...':
    `${account.name} ${account.last_name}, Rut: ${rutFormat(account.rut_client)}, Cuenta: ${account.cuenta}`

    const state = {	  
        columns: [
            { title: 'Fecha', field: 'created_at', defaultSort:'desc', editable: 'never', width: 400 },
            { title: 'Realizado', field: 'done', editable: 'onUpdate', lookup: doneList, width: 400},
            // { title: 'Rut', field: 'rut_client' }                     
        ],
    }
    
    const handleRowClicked = (selectedRow) =>{
        // this.props.onClick(selectedRow)
        // console.log(selectedRow)
        onClick(selectedRow)
    }

    const handleRowSelected = (rows) => {       
        // this.props.onClick(rows)       
    }

    return (
    <MuiThemeProvider theme={theme}>
        <MaterialTable
            title={title}
            columns={state.columns}
            data = {data}   
            isLoading= {isLoading}    
            
            editable={{
                onRowUpdate: (newData, oldData) => 
                  new Promise((resolve, reject) => {
                    setTimeout(() => {                      
                        handleUpdateRebalanceDone(newData)
                        resolve()
                    }, 1000)
                  }),
            }}

            options={{
                // selection: true,
                sorting: true,
                exportButton: true,
                rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#37b15933' : '' })
            }}
            // onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}                
            onRowClick={((evt, selectedRow) => handleRowClicked(selectedRow) )}                
            // onSelectionChange={(rows) => alert('You selected ' + rows.length + ' rows ')}
            onSelectionChange={(rows) => handleRowSelected(rows)}
            localization = {MATERIAL_TABLE_LOCALIZATION}
        />
    </MuiThemeProvider>
    )
        
    
}

export default Table;