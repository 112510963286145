import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { select_account, select_date } from '../../actions/cartola';
import CartolaHeaderComponent from '../../components/Header/CartolaHeaderComponent';
import {fetchCartolaPDF, fetch_breakdown} from '../../actions/cartola';


class HeaderContainer extends React.Component {

    componentDidMount() {
        console.log('HeaderContainer', this.props )
    }

    render() {
        return(
            <CartolaHeaderComponent
                fetchCompositionLoading={this.props.fetchCompositionLoading}
                fetchBreakdownLoading={this.props.fetchBreakdownLoading}
                fetchCartolaPDFLoading={this.props.fetchCartolaPDFLoading}
                cartolaAccountSelectValue={this.props.cartolaAccountSelectValue}
                cartolaGroupSelectOption={this.props.cartolaGroupSelectOption}
                datePickerValue={this.props.datePickerValue}
                selectAccountHandler={this.props.selectAccountHandler}
                selectDateHandler={this.props.selectDateHandler}
                fetchCartolaPDF={this.props.fetchCartolaPDF}
                handleCartola={this.props.handleCartolaUpdt}
                fetchBreakdown={this.props.fetchBreakdown}
                clientId={this.props.clientId}
                cartolaId={this.props.cartolaId}
                userId={this.props.userId}
                location={this.props.location}
                history={this.props.history}
                isGroup={this.props.isGroup}
            />
        )
    }
}

const mapStateToProps = state => {
    
    return {
        fetchCompositionLoading: state.cartola.fetchCompositionLoading,
        fetchBreakdownLoading: state.cartola.fetchBreakdownLoading,
        fetchCartolaPDFLoading: state.cartola.fetchCartolaPDFLoading,
        cartolaAccountSelectValue: state.cartola.accountSelectValue,
        cartolaGroupSelectOption: state.cartola.groupSelectOption,
        datePickerValue: state.cartola.datePickerValue,
        clientId: state.cartola.clientId,
        userId: state.user.id
    };
};

const mapDispatchToProps = dispatch => ({
    fetchBreakdown: (id, isGroup) => dispatch(fetch_breakdown(id, isGroup)),
    selectAccountHandler: (val) => dispatch(select_account(val)),
    selectDateHandler: (range, states) => dispatch(select_date(range, states)),
    fetchCartolaPDF: (id, isGroup) => dispatch(fetchCartolaPDF(id, isGroup))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderContainer));