import {REBHISTORIAL_GET_CARTERA_INIT, REBHISTORIAL_GET_CARTERA_SUCCESS, REBHISTORIAL_GET_CARTERA_ERROR,
REBHISTORIAL_GET_ACCOUNT_INIT, REBHISTORIAL_GET_ACCOUNT_SUCCESS, REBHISTORIAL_GET_ACCOUNT_ERROR,
REBHISTORIAL_ACCOUNT_RESET,
REBHISTORIAL_SELECT_HISTORIAL_INIT,
REBHISTORIAL_SELECT_HISTORIAL_SUCCESS,
REBHISTORIAL_SELECT_HISTORIAL_ERROR,
REBHISTORIAL_HISTORIAL_RESET,
HIST_REBALANCE_GET_PDF_INIT, HIST_REBALANCE_GET_PDF_SUCCESS, HIST_REBALANCE_GET_PDF_ERROR,
REBHISTORIAL_SELECT_PORTAFOLIO, REBHISTORIAL_PORTAFOLIO_RESET,
UPDATE_REBHISTORIAL_DONE_INIT, UPDATE_REBHISTORIAL_DONE_SUCCESS, UPDATE_REBHISTORIAL_DONE_ERROR,
REBALANCE_HISTORIAL_TABLE_STATE
} from '../constants';

const initialState = {
    accounts        : [],    
    accountSelected : {
        name: '',
        last_name: '',
        rut_client: '',
        cuenta: ''
    },
    tableState: {		
        order : "desc",
		orderBy : "patrimonio",
		page : 0,
		dense : false,
		searchValue : '',
		rowsPerPage : 5,
		selected : []
    }, 
    accountHistorial: [], 
    doneList: null,
    selectedHistorial: null,  
    generated_date  : null,
    selected        : null,
    compared        : null,
    isLoading       : true,
    isLoadingHistorial: true,
    isLoadingSelected: true,
    error           : null,
    response        : null,
    portafolioRebalance: null
}

const reb = (state = initialState, action) => {
    switch (action.type) {
        case REBHISTORIAL_GET_CARTERA_INIT:
            return {
                ...state,
                isLoading: true,                
                error: null,
                accountHistorial : [],
                selectedHistorial: null
            };
        case REBHISTORIAL_GET_CARTERA_SUCCESS:
            return {
                ...state,
                accounts: action.payload.response.data.accounts,
                isLoading: false,
                error: null
            };
        case REBHISTORIAL_GET_CARTERA_ERROR:
            return {
                ...state,
                error: action.payload.error
            };


        case REBALANCE_HISTORIAL_TABLE_STATE:
            return {
                ...state,
                tableState: action.payload.state
            }

       
        case REBHISTORIAL_GET_ACCOUNT_INIT:
            return {
                ...state,
                accountSelected: {
                    name: '',
                    last_name: '',
                    rut_client: '',
                    cuenta: ''
                },
                accountHistorial : [],
                isLoadingHistorial: true,                
                error: null
            };
        case REBHISTORIAL_GET_ACCOUNT_SUCCESS:
            return {
                ...state,
                accountHistorial: action.payload.response.data.accounts,
                doneList: action.payload.response.data.doneList,
                accountSelected: action.payload.response.data.client,
                isLoadingHistorial: false,
                error: null
            };
        case REBHISTORIAL_GET_ACCOUNT_ERROR:
            return {
                ...state,
                error: action.payload.error
            };


        case UPDATE_REBHISTORIAL_DONE_INIT:
            return {
                ...state,
                accountHistorial: action.payload.newAccounts,
            };
        case UPDATE_REBHISTORIAL_DONE_SUCCESS:
            return {
                ...state,
                
            };
        case UPDATE_REBHISTORIAL_DONE_ERROR:
            return {
                ...state,
                
            };


        case REBHISTORIAL_ACCOUNT_RESET:
            return {
                ...state,
                accountSelected : null,
                accountHistorial : [],
                selectedHistorial: null,
                selected: null,
                compared: null
            };


        case REBHISTORIAL_SELECT_HISTORIAL_INIT:
            return {
                ...state,
                selectedHistorial : null,
                generated_date: null,
                portafolioRebalance: null,
                isLoadingSelected: true
            };

        case REBHISTORIAL_SELECT_HISTORIAL_SUCCESS:     
            console.log(action.payload.response.data)       
            const{rebalance, created_at, selected} = action.payload.response.data.historial
            const parseData = JSON.parse(rebalance)
            return {
                ...state,
                selectedHistorial : parseData,
                generated_date: created_at,
                portafolioRebalance: selected,
                isLoadingSelected: false
            };

        case REBHISTORIAL_SELECT_HISTORIAL_ERROR:
            return {
                ...state,
                error: action.payload.error,
                isLoadingSelected: false
            };
            
        case REBHISTORIAL_HISTORIAL_RESET:
            return {
                ...state,
                selectedHistorial : null,
                selected: null,
                compared: null
            };

        case HIST_REBALANCE_GET_PDF_INIT:
            return {
                ...state,
                error: null,
                response: null
            };
        case HIST_REBALANCE_GET_PDF_SUCCESS:
            return {
                ...state,
                response: action.payload.response,                
            };
        case HIST_REBALANCE_GET_PDF_ERROR:
            return {
                ...state,
                error: action.payload.error,
                response: action.payload.response
            };

            
        case REBHISTORIAL_SELECT_PORTAFOLIO:
            return {
                ...state,
                selected: action.payload.selected,
                compared: action.payload.compare
            };
           

        case REBHISTORIAL_PORTAFOLIO_RESET:
            return {
                ...state,
                selected: null,
                compared: null
            };
   
        default:
            return state;
    }
}

export default reb;