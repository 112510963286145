import React from 'react';
import { Doughnut, Bar } from 'react-chartjs-2';
import {Card, CardBody, CardFooter, CardTitle, CardText, Col, Row} from "reactstrap";

let configPie = {    
    datasets: [{        
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF3E10'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF3E10']
    }]
}

let configBar = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
        label: 'Mixtos',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        },
        {
        label: 'Renta Fija',
        backgroundColor: 'rgba(55,255,132,0.2)',
        borderColor: 'rgba(55,255,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(55,255,132,0.4)',
        hoverBorderColor: 'rgba(55,255,132,1)',
        },
        {
        label: 'Renta Variable',
        backgroundColor: 'rgba(55,99,132,0.2)',
        borderColor: 'rgba(55,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(55,99,132,0.4)',
        hoverBorderColor: 'rgba(55,99,132,1)',
        },
        {
        label: 'Bonos',
        backgroundColor: 'rgba(255,99,13,0.2)',
        borderColor: 'rgba(255,99,13,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,13,0.4)',
        hoverBorderColor: 'rgba(255,99,13,1)',
        },

    ]
};

const GraficoPatrimonio = ({data}) =>{
    if(data){
        configPie.labels = data.labels;
        configPie.datasets[0].data = data.datasets;
        return <Doughnut 
                    data={configPie}
                    width={100}
                    height={50}
                    options={{ maintainAspectRatio: true }}
                />
    }else{
        return <p>Sin datos</p>
    }
}

const GraficoMes = ({data}) =>{
    if(data){
        configBar.labels = data.labels;
        data.datasets.forEach((data, i)=> {
            configBar.datasets[i].data = Object.values(data);
        })
        return <Bar 
                    data={configBar}
                    width={100}
                    height={40}
                    options={{ maintainAspectRatio: true }}
                />
    }else{
        return <p>Sin datos</p>
    }
}

const index = ({dataPatrimonio, dataMes}) =>  {

    return (
        <div>           

            <Row>
                <Col sm="6">
                    <Card body style={{height: '25rem'}}>
                        <h6>Patrimonio por Activos</h6>
                        <GraficoPatrimonio data={dataPatrimonio} />
                    </Card>
                </Col>
                <Col sm="6">
                    {/* <Card body  style={{height: '25rem'}}>
                        <h6>Activos por Mes</h6>
                        <GraficoMes data={dataMes} />
                    </Card> */}
                </Col>
            </Row>

        </div>
    );
}

export default index;