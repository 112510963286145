import React from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {MATERIAL_TABLE_LOCALIZATION} from './../../../constants';
import Table from './Table';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

const theme = createTheme({
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        light: '#01579b',
        main: '#ff9100',
      },       
    },      
});

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Seleccionar Administradora', 'Seleccionar Tipo de Cuenta', 'Seleccionar Cuenta'];
}

function index({cuentasAdministradoras, pendingAccounts, handleRowAccountSelected,
    actionMessage, error, reset
}) {
    
    const [accounts, setAccounts] = React.useState([]);
    const [adminsSelected, setAdminsSelected] = React.useState([]);
    const [categoriesSelected, setCategoriesSelected] = React.useState([]);
    const [administrators, setAdministrators] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    React.useEffect(() => {
        setAdministrators(JSON.parse(JSON.stringify(cuentasAdministradoras.filterAdministrators)))
        setCategories(JSON.parse(JSON.stringify(cuentasAdministradoras.filterCategories)))        
    }, []);

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(activeStep === 1){
            const accountsFiltered = cuentasAdministradoras.data.reduce((acc, elem)=>{
                adminsSelected.forEach(x=>{
                    categoriesSelected.forEach(y=>{
                        if(elem.administrator_name == x.name && elem.account_category_name == y.name){
                            acc.push(elem);
                        }
                    })
                })
                return acc;
            },[])            
            setAccounts(accountsFiltered)
		}
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const state = {    
        columnsAccounts: [
            { title: 'Administradora', field: 'administrator_name'},  
            { title: 'Cuenta', field: 'account_name'}, 
            { title: 'Categoria', field: 'account_category_name'},         
        ],
    }

    const handleRowAdminSelected = rows => {
        console.log(rows)
        setAdminsSelected(rows)
    }

    const handleRowCategorieSelected = rows => {
        console.log(rows)
        setCategoriesSelected(rows)
    }

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
            return <Table 
                title={"Administradoras"} 
                data={administrators}
                pending={pendingAccounts}
                handleRowSelected={handleRowAdminSelected}
            />
                       
            case 1:
            return <Table 
                title={"Categorias"} 
                data={categories}
                pending={pendingAccounts}
                handleRowSelected={handleRowCategorieSelected}
            />
            
            case 2:
            return <Table 
                title={"Cuentas"} 
                data={accounts}
                pending={pendingAccounts}
                handleRowSelected={handleRowAccountSelected}
            />
                       
            default:
            return 'No especificado';
        }
    }

    return (
        <div  className={classes.root}>

            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            <div className="text-center">
                {activeStep === steps.length ? (
                <div>
                    <Typography className={classes.instructions}>Todo completado</Typography>
                    <Button onClick={handleReset}>Resetear</Button>
                </div>
                ) : (
                <div>                    
                    <div>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.backButton}
                    >
                        Atras
                    </Button>
                    {activeStep !== steps.length - 1 &&
                        <Button variant="contained" color="primary" onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Terminar' : 'Siguiente'}
                        </Button>
                    }                    
                    </div>
                    <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                </div>
                )}
            </div>

        </div>
    );
}

export default index;