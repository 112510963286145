import React from 'react';
import {
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Col,
    Table, Alert
} from "reactstrap";

const ReactHighcharts = require('react-highcharts');
require('highcharts-3d')(ReactHighcharts.Highcharts);


function ResumenCaja({administradoras, usdFormatter, clpFormatter}) {

    const [showRutNombre, setShowRutNombre] = React.useState(        
        administradoras ? 
                administradoras[0]?.cuentas[0]?.[0]?.cuenta.split('$').length === 3 ||
                administradoras[0]?.cuentas_psh[0]?.[0]?.cuenta.split('$').length === 3
        : false        
    );

    const data_tr = (item, i) => {
        const cuentaArr = item.cuenta.split('$');
        const nombre = cuentaArr[0];
        const rut = cuentaArr[1];        
        const cuenta = cuentaArr[2];
        const obj =  <tr key={i}>
                    {showRutNombre && <td>{rut}</td>}
                    {showRutNombre && <td>{nombre}</td>}                    
                    <td>
                        {showRutNombre ? cuenta : item.cuenta} 
                    </td>
                    <td>
                        {item.moneda}
                    </td>
                    <td>
                        ${clpFormatter.format(Math.trunc(parseFloat(item.saldo_retenido)))}
                    </td>    
                    <td>
                        ${clpFormatter.format(Math.trunc(parseFloat(item.saldo_disponible)))}
                    </td>
                    <td>
                        ${clpFormatter.format(Math.trunc(parseFloat(item.monto)))}
                    </td>
                </tr>
        return obj
    }

    return(
        <Col xs={12} md={12}>
            <Card className="card-plain">
                {administradoras ?
                    administradoras.length == 0 ?
                        <div>
                            <Alert color="info" isOpen={true}>
                                <i className="fa fa-info-circle fa-lg" aria-hidden="true"></i>{' '}
                                No hay caja para el periodo seleccionado.
                            </Alert>
                        </div>
                        :
                    administradoras.map((prop, i) => {

                        return (
                            <div key={i}>
                                <CardHeader>
                                    <CardTitle tag="h5">
                                        {prop.nombre_admin[0] == 'Principal' ?
                                            <img height="90" src="/images/principal-logo.png"  />
                                            : prop.nombre_admin[0] == 'LarrainVial' ?
                                            <img height="50" src="/images/lv-logo.png" />
                                                : <img height="50" src="/images/fynsa-logo.svg" />
                                        }
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                {showRutNombre && <th>Rut</th>}
                                                {showRutNombre && <th>Nombre</th>}
                                                <th>Cuenta</th>
                                                <th>Moneda</th>
                                                <th>Saldo Retenido</th>
                                                <th>Saldo Disponible</th>
                                                <th>Monto</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {prop.cuentas ?
                                                prop.cuentas.map((cuenta, i) => cuenta.map((item, i) => data_tr (item, i)))
                                            : null
                                            }
                                            {prop.cuentas_psh ?
                                                prop.cuentas_psh.map((cuenta, i) => cuenta.map((item, i) => data_tr (item, i)))
                                                : null
                                            }
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </div>
                        );
                    })
                    :
                    <div>

                        <Alert color="info" isOpen={true}>
                            <i className="fa fa-info-circle fa-lg" aria-hidden="true"></i>{' '}
                            No hay caja para el periodo seleccionado.
                        </Alert>

                    </div>
                }
            </Card>
        </Col>
    )
    
}

export default ResumenCaja;
