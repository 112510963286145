import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chart, { Pie } from 'react-chartjs-2';
import { patrimonyFormat } from '../../../helpers/formatter'

let configPie = {    
    datasets: [{        
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF3E10'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#FF3E10']
    }]
}

const Grafico = ({data}) =>{
    if(data){
        configPie.labels = data.labels;
        configPie.datasets[0].data = data.datasets;
        return <Pie 
                    data={configPie}
                    width={'auto'}
                    height={'200px'}
                    options={{ 
                        maintainAspectRatio: false, 
                        legend: false,
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    var dataLabel = data.labels[tooltipItem.index];                                    
                                    var value = ': ' + patrimonyFormat(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);   

                                    if (Chart.helpers.isArray(dataLabel)) {
                                        dataLabel = dataLabel.slice();
                                        dataLabel[0] += value;
                                    } else {
                                        dataLabel += value;
                                    }
                                    return dataLabel;
                                }
                            }
                        }
                    }}
                />
    }else{
        return <p>Sin datos</p>
    }
}

const Card = ({data, pending}) => {
    return (
        <div>  
            {!pending ? 
                <Grafico data={data}/>
                :
                <div className="text-center mt-5">
                    <CircularProgress size={50} thickness={5}/>
                </div>
            }
        </div>
    );
}

export default Card;