import React from 'react';
import PropTypes from "prop-types";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonGroup,
	UncontrolledDropdown,
	Col,
	Card
} from "reactstrap";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import CustomDatepicker from './CustomDatepicker';

const moment = extendMoment(originalMoment);
const today = moment();

const presetDates = {
    fiveDays: moment.range(today.clone().subtract(5, "days"), today.clone()),
    tenDays: moment.range(today.clone().subtract(10, "days"), today.clone()),
    fifteenDays: moment.range(today.clone().subtract(15, "days"), today.clone()),
    thirtyDays: moment.range(today.clone().subtract(30, "days"), today.clone()),
    threeMonths: moment.range(today.clone().subtract(3, "months"), today.clone()),
    sixMonths: moment.range(today.clone().subtract(6, "months"), today.clone()),
    lastTwelveMonths: moment.range(today.clone().subtract(12, "months"), today.clone()),
    yearToDate: moment.range(moment().startOf('year'), today.clone()),
    twentyNineteen:  moment.range("2019-01-01", "2019-12-31"),
    twentyTwenty:  moment.range("2020-01-01", "2020-12-31"),
    twentyTwentyOne:  moment.range("2021-01-01", "2021-12-31"),
    twentySeventeen: moment.range("2017-01-01", "2017-12-31"),
    twentySixteen: moment.range("2016-01-01", "2016-12-31"),
    monthToDate: moment.range(moment().startOf('month'), today.clone()),
};

function Datepicker({selectDateHandler, datePickerValue}) {

    const {start, end} = datePickerValue;
	
	const setDateStart = start => {
		selectDateHandler({start})
	}

	const setDateEnd = end => {
		selectDateHandler({end})
	}

    return (
        <div>

            <UncontrolledDropdown group>
                <DropdownToggle caret color="info">
                    <i className="now-ui-icons ui-1_calendar-60" />
                    <span style={{"marginLeft": "1em"}}>
                        {
                            moment(start).format('DD-MM-YYYY[ - ]') + moment(end).format('DD-MM-YYYY') 
                        }
                    </span>
                </DropdownToggle>
                <DropdownMenu style={{outline: 'none'}}>
                    <ButtonGroup size={'sm'} style={{"padding": "0 2em"}}>
                        <UncontrolledDropdown group>
                            <DropdownToggle caret color="default">
                                Meses
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => selectDateHandler(presetDates.monthToDate)}>
                                    MTD
                                </DropdownItem>
                                <DropdownItem onClick={() => selectDateHandler(presetDates.threeMonths)}>
                                    3 Meses
                                </DropdownItem>
                                <DropdownItem onClick={() => selectDateHandler(presetDates.sixMonths)}>
                                    6 Meses
                                </DropdownItem>
                                <DropdownItem onClick={() => selectDateHandler(presetDates.lastTwelveMonths)}>
                                    12 Meses
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown group>
                            <DropdownToggle caret color="default">
                                Años
                            </DropdownToggle>
                            <DropdownMenu>                                
                                <DropdownItem onClick={() => selectDateHandler(presetDates.yearToDate)}>
                                    YTD
                                </DropdownItem>
                                <DropdownItem onClick={() => selectDateHandler(presetDates.twentyTwentyOne)}>
                                    2021
                                </DropdownItem>
                                <DropdownItem onClick={() => selectDateHandler(presetDates.twentyTwenty)}>
                                    2020
                                </DropdownItem>
                                <DropdownItem onClick={() => selectDateHandler(presetDates.twentyNineteen)}>
                                    2019
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </ButtonGroup>

                    <Col xs={12} md={12}>
                        <Card>
							<CustomDatepicker title={"Desde"} value={start} setDate={setDateStart} />
							<CustomDatepicker title={"Hasta"} value={end} setDate={setDateEnd} lastDateOfMonth={true}/>	
                        </Card>
                    </Col>

                </DropdownMenu>
            </UncontrolledDropdown>


        </div>
    );
}

Datepicker.propTypes = {
    selectDateHandler: PropTypes.func.isRequired,
    datePickerValue: PropTypes.object.isRequired
}

export default Datepicker;