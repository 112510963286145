import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

function SearchField({value, setSearchValue}) {
    const classes = useStyles();

    const handleReset = () => {
        setSearchValue('');
    }

    return (
        <Paper component="form" className={classes.root}>
            <InputBase
                className={classes.input}
                placeholder="Buscar"
                inputProps={{ 'aria-label': 'Buscar' }}
                value={value}
                onChange={event => setSearchValue(event.target.value)}
                onKeyPress={(e) =>  e.key !== 'Enter' || e.preventDefault()}
            />
            <IconButton
                onClick={handleReset}
                className={classes.iconButton} aria-label="close">
                <CloseIcon />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
                disabled={true}
                className={classes.iconButton} aria-label="search">
                <SearchIcon />
            </IconButton>


        </Paper>
    );
}

SearchField.propTypes = {
    value: PropTypes.string, 
    setSearchValue: PropTypes.func.isRequired
}

export default SearchField;