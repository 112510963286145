import React from 'react'
import {
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Row,
    Col,
    Table,
    Badge
} from "reactstrap";

const ReactHighcharts = require('react-highcharts');
require('highcharts-3d')(ReactHighcharts.Highcharts);


class ResumenCuenta extends React.Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        console.log({ResumenCuentaWillMount: this.props })        
    }

    componentDidMount() {
        // console.log({ResumenCuentaDidMount: this.props })
    }

    render() {
        return(
            <Row>
                <Col xs={12} md={6}>
                    <Card className="card-plain">
                        <CardHeader>
                            <CardTitle tag="h5">
                                Cuentas
                        </CardTitle>
                            <br />
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead style={{
                                    "fontSize": ".8em",
                                    "textTransform": "uppercase"
                                }}>
                                    <tr>
                                        <th></th>
                                        <th>
                                            Cuenta
                                        </th>
                                        <th>
                                            Monto ($)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.consolidatedByAccount === [] ?
                                        <p> Sin datos para el periodo
                                    seleccionad </p>
                                        :
                                        this.props.consolidatedByAccount.map((prop, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td align="center" width="100">
                                                        {prop['tipo'] == 'Psh' ?
                                                            
                                                            <Badge color="success">Pershing</Badge>
                                                            :
                                                            prop['tipo'] == 'Estandar' ? <Badge color="primary">Cuenta</Badge>
                                                            : 
                                                            null
                                                        }
                                                   
                                                    </td>
                                                    <td>
                                                        {prop['cuenta']}
                                                    </td>
                                                    <td>
                                                        ${this.props.clpFormatter.format(Math.trunc(parseFloat(prop['monto'])))}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <ReactHighcharts config={this.props.cuentasConfig}>
                    </ReactHighcharts>
                </Col>
            </Row>
        )
    }
}

export default ResumenCuenta;