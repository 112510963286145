import {
    FETCH_TOUR_STEPS_INIT,
    FETCH_TOUR_STEPS_SUCCESS,
    FETCH_TOUR_STEPS_FAILED,
    TOUR_SAVE_INIT,
    TOUR_SAVE_SUCCESS,
    TOUR_SAVE_ERROR
} from '../constants';
import client from '../api/httpClient';
import { urlTourSteps, saveUserTour } from '../api/urls';


export const fetchTourSteps = () => {
    return (dispatch, getState) => {
        
        dispatch(fetch_tour_steps_init())
        client.getAll(urlTourSteps)
        .then(resp =>{
            console.log('fetchTourSteps', resp);
            
            dispatch(fetch_tour_steps_success(resp.data))
        }).catch(error => {
            dispatch(fetch_tour_steps_failed(error))
        })
    }
}

export const tourSave = (tourId, tourComplete) => {
    return (dispatch, getState) => {
        const id = getState().user.id;
        const data= { tourId, id, tourComplete }
        dispatch(tour_save_init());
        client.create(saveUserTour, data)
        .then(() => dispatch(tour_save_success({tour_view_id: tourId, tour_complete: tourComplete})))
        .catch(err => dispatch(tour_save_error(err)))        
    }    
}

const fetch_tour_steps_init = () => ({
    type: FETCH_TOUR_STEPS_INIT,
})

const fetch_tour_steps_success = (tourSteps) => ({
    type: FETCH_TOUR_STEPS_SUCCESS,
    payload: {
        tourSteps
    }
})

const fetch_tour_steps_failed = (error) => ({
    type: FETCH_TOUR_STEPS_FAILED,
    payload: {
        error
    }
})

const tour_save_init = () => ({
    type: TOUR_SAVE_INIT
})

const tour_save_success = data => ({
    type: TOUR_SAVE_SUCCESS,
    payload: {
        data
    }   
})

const tour_save_error = (error) => ({
    type: TOUR_SAVE_ERROR,
    payload: {
        error
    }
})