import React from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,

} from "reactstrap";


import {
    CardCategory,
} from "../../components";

import slide1bg from '../../assets/img/onboarding/4x/slide1-bg@4x.png';

export default function Slide1() {

    return (
        <div className="content">
            <Row style={{width: "50vw"}}>
                <Col xs={12} md={8} className="pull-left" style={{marginLeft: "20%"}}>
                    <Card className="card-plain">
                        <CardHeader>
                            <CardCategory>Cartola Consolidada</CardCategory>
                            <CardTitle tag="h3" style={{color: "#2980b8"}}>Ve tu universo de inversión desde un solo lugar.</CardTitle>
                       </CardHeader>
                        <CardBody>
                            
                            <img height="500" src={slide1bg}></img>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}