import React, { Component } from 'react';
import Card from '../../../components/AdminDashboard/Activos/Card';

const data= [65, 59, 80, 81, 56, 55, 40];
class CardContainer extends Component {
    render() {
        return (
            <div>
                <Card data={data}/>
            </div>
        );
    }
}

export default CardContainer;