import {
    FETCH_TOUR_STEPS_INIT,
    FETCH_TOUR_STEPS_SUCCESS,
    FETCH_TOUR_STEPS_FAILED,
    TOUR_SAVE_INIT,
    TOUR_SAVE_SUCCESS,
    TOUR_SAVE_ERROR
} from '../constants';


const initialState = {
    tourSteps: [],
    error: {}
}


const withParams = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TOUR_STEPS_INIT:
            return {
                ...state,
            }
        case FETCH_TOUR_STEPS_SUCCESS:
            const {tourSteps} = action.payload;
            console.log('FETCH_TOUR_STEPS_SUCCESS', action.payload)
            return {
                ...state,
                tourSteps

            }
        case FETCH_TOUR_STEPS_FAILED:
            const {error} = action.payload.error;
            return {
                ...state,
                error,
            }        

        default:
            return state;
    }

}

export default withParams;