import React, { Component } from "react";
import { Col } from "reactstrap";
// react plugin used to create a form with multiple steps
import ReactWizard from "react-bootstrap-wizard";
import Step1 from "./Step1.jsx";
import Step2 from "./Step2.jsx";
import Step3 from "./Step3.jsx";
import "./onb.css"


var steps = [
    {
        stepName: "Uno",
        stepIcon: "now-ui-icons business_money-coins",
        component: Step1
    },
    {
        stepName: "Dos",
        stepIcon: "now-ui-icons business_money-coins",
        component: Step2
    },
    {
        stepName: "Tres",
        stepIcon: "now-ui-icons business_money-coins",
        component: Step3
    },

];

class Onboarding extends Component {
    constructor(props) {
        super(props);

        this.postResults = this.postResults.bind(this);
    }

    postResults() {
        window.location.href = "/dashboard/cartolas";
    }

    render() {
        return (
            <div>
                <div className="content">
                    <Col xs={12} md={10} className="mr-auto ml-auto">
                        <ReactWizard
                            steps={steps}
                            navSteps
                            validate
                            title="Bienvenido a tu portal financiero"
                            description="Tengamos un breve recorrido por sus principales funciones."
                            headerTextCenter
                            color="blue"
                            nextButtonText={"Siguiente"}
                            previousButtonText={"Anterior"}
                            finishButtonText={"Finalizar"}
                            finishButtonClasses="btn-wd"
                            finishButtonClick={this.postResults}
                            nextButtonClasses="btn-wd"
                            previousButtonClasses="btn-wd"
                        />
                    </Col>
                </div>
            </div>
        );
    }
}

export default Onboarding;
