import React from 'react';
import {Card, CardBody,	CardHeader, Row, Col} from "reactstrap";
import AgregarRestricciones from './AgregarRestricciones';
import AgregarFondosContainer from '../../../containers/Rebalance/AgregarFondos';
import TableAccount from './TableAccount';

const index = ({data, isValidated, 
    // triggerGetFunds, 
    restrictionsSelected, 
    accountSelected
}) => {

    // React.useEffect(()=>{
    //     triggerGetFunds()
    // }, [])
    
    return (
        <Card>
            <Card>
                <CardHeader><h5>Cuenta</h5></CardHeader>
                <CardBody>
                    <TableAccount account={accountSelected} />
                </CardBody>
            </Card>
            <CardBody className="text-center">
                <Row>
                    <Col md={5}>

                        <p className="text-center">Restricciones</p>
                        <AgregarRestricciones 
                            data={data}
                            isValidated={isValidated}
                            restrictionsSelected={restrictionsSelected}
                        />

                    </Col>
                    <Col md={7}>

                        <p className="text-center mb-4">Nuevos fondos</p>        
                        <AgregarFondosContainer />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default index;