import React, { Component } from 'react';
import { connect } from 'react-redux';
import Clients from './../../components/Clients';
import {getClients, getClientData, sendInvitation, setResetMessage, 
    handleUpdateRiskProfile, handleAddClient, handleUpdateClientAccounts,
    handleRemoveClient, selectClientHomeContainer
} from './../../actions/client';
import {getStaff, staffAssignClient, setResetStatus} from './../../actions/staff';
import {getCuentasAdministradoras} from '../../actions/administradora';
import {getAllClients, pending, 
    sendInvitationResult, riskProfileList,  
    actionMessage, riskProfileArray, error, pendingFilter
} from './../../selectors/client';
import {cuentasAdministradoras, pendingAccounts} from '../../selectors/administradora';
import { withRouter } from 'react-router-dom';
import { urlClientsDetails, urlClientsAssign } from '../../api/urls';
import withParams from '../../helpers/withParams';

class HomeContainer extends Component {

    constructor(props){
        super(props)
        this.handleClickUser        = this.handleClickUser.bind(this);
        this.handleClickStaff       = this.handleClickStaff.bind(this);
        this.handleClickAssignStaff = this.handleClickAssignStaff.bind(this);
        this.handleClickStaffOut    = this.handleClickStaffOut.bind(this);
        this.handleResetStatus      = this.handleResetStatus.bind(this);
        this.onClickSendInvitation  = this.onClickSendInvitation.bind(this);
        
    }

    componentDidMount() {
        this.props.getClients();
        this.props.getCuentasAdministradoras();
    }

    handleClickUser(client){
        let inputSearchText = document.querySelector('.MuiInputBase-input').value;
        this.props.selectClientHomeContainer(urlClientsDetails, client.id, inputSearchText);
    }

    handleClickStaff(usersList){
        console.log('Staff');
        this.props.getStaff(usersList);
        this.props.history.push(urlClientsAssign);
    }

    handleClickAssignStaff(staff, clients){
        console.log('Asignando staff a clientes')
        const data = {
            'option': 1,
            'staff':staff,
            'clients':clients
        }
        this.props.staffAssignClient(data)        
    }

    handleClickStaffOut(staff, clients){

        const clientData = this.props.clients.filter(obj => clients.includes(obj.id));

        console.log('Desasignando staff a clientes')
        const data = {
            'option': 2,
            'staff': staff,
            'clients':clientData
        }
        this.props.staffAssignClient(data) 
    }

    handleResetStatus(){
        this.props.setResetStatus();
        this.props.setResetMessage();
    }

    onClickSendInvitation (data) {
        this.props.sendInvitation(data)
    }

    render() {
        let message = '';
        return <Clients 
                data={this.props.clients} 
                onClick={this.handleClickUser}
                onClickStaff={this.handleClickStaff}
                onClickStaffOut={this.handleClickStaffOut}
                onClickSendInvitation={this.onClickSendInvitation}
                message={this.props.messageStaffAssign} 
                reset={this.handleResetStatus}
                pending={this.props.pending}
                sendInvitationResult={this.props.sendInvitationResult} 
                riskProfileList={this.props.riskProfileList}   
                handleUpdateRiskProfile={this.props.handleUpdateRiskProfile}  
                handleAddClient={this.props.handleAddClient} 
                actionMessage={this.props.actionMessage}   
                riskProfileArray={this.props.riskProfileArray}  
                cuentasAdministradoras={this.props.cuentasAdministradoras}
                pendingAccounts={this.props.pendingAccounts}
                error={this.props.error}
                handleUpdateClientAccounts={this.props.handleUpdateClientAccounts}
                isLoading={this.props.pendingFilter}
                handleRemoveClient={this.props.handleRemoveClient}  
                searchText={this.props.params.search}              
            />
    }
}

const mapStateToProps = state => ({
    clients: getAllClients(state),
    pending: pending(state),
    sendInvitationResult: sendInvitationResult(state),
    riskProfileList: riskProfileList(state),
    actionMessage: actionMessage(state),
    riskProfileArray: riskProfileArray(state),
    cuentasAdministradoras: cuentasAdministradoras(state),
    pendingAccounts: pendingAccounts(state),
    error: error(state),
    pendingFilter: pendingFilter(state),
    messageStaffAssign: state.staff.messageStaffAssign
})

const mapDispatchToProps = dispatch => ({
    getClients: () => dispatch(getClients()),
    getCuentasAdministradoras: id => dispatch(getCuentasAdministradoras(id)),
    getStaff: (usersList) => dispatch(getStaff(usersList)),
    staffAssignClient: (data) => dispatch(staffAssignClient(data)),
    setResetStatus: () => dispatch(setResetStatus()),
    getClientData: client => dispatch(getClientData(client)),
    // sendInvitation: data => dispatch(sendInvitation(data)),
    setResetMessage: () => dispatch(setResetMessage()),
    handleUpdateRiskProfile: data => dispatch(handleUpdateRiskProfile(data)),
    handleAddClient: (data) => dispatch(handleAddClient(data)),    
    handleUpdateClientAccounts: data => dispatch(handleUpdateClientAccounts(data)),
    handleRemoveClient: data => dispatch(handleRemoveClient(data)),
    setClient: client => dispatch(setClient(client)),
    selectClientHomeContainer: (url, clientId, inputSearchText) => dispatch(selectClientHomeContainer(url, clientId, inputSearchText))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withParams(HomeContainer)));