import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const clpFormatter = new Intl.NumberFormat('es-ES', {
    minimumFractionDigits: 0
});

const titleStyles = {
    fontSize: '2rem',
    marginBottom: '0px'
}

const Card = ({data, pending}) => {
    let formattedData = clpFormatter.format(data);
    return(
        <div>  
        {!pending ? 
            <span style={titleStyles}>$ { formattedData.substring(0, formattedData.lastIndexOf(',')) }</span>
            :
            <div className="text-center mt-5">
                <CircularProgress size={50} thickness={5}/>
            </div>
        }
    </div>
        
    )
}

export default Card;