import React from 'react';
import { connect } from 'react-redux';
import AssignUsers from '../../../components/RolesUsers/AssignUsers/AssignUsers';
import {getAllClients} from '../../../selectors/client';
import {getAllStaff} from '../../../selectors/staff';
import {
    getClients
} from '../../../actions/client';
import {
    getStaff 
} from '../../../actions/staff';
import {
    setOpenAssign,
    assignRolesToUsers
} from '../../../actions/permissions';


class AssignUsersContainer extends React.Component {
    
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.get_clients();
        this.props.get_staff();
    }


    render() {
        return(
            <AssignUsers
                uid={'assignPanel'}
                active={this.props.openAssign}
                title={'Asignar Rol'}
                openAssign={this.props.openAssign}
                clients={this.props.clients}
                staff={this.props.staff}
                setOpenAssign={this.props.set_open_assign}
                assignRolesToUsers={this.props.assign_roles_to_users}
            />
        )
    }
}


const mapStateToProps = state => {
    return {
        clients: getAllClients(state),
        staff: getAllStaff(state),
        openAssign: state.permissions.openAssign
    }
}

const mapDispatchToProps = dispatch => ({
    get_clients: () => dispatch(getClients()),
    get_staff: () => dispatch(getStaff()),
    set_open_assign: isOpen => dispatch(setOpenAssign(isOpen)),
    assign_roles_to_users: users => dispatch(assignRolesToUsers(users))
})


export default connect(mapStateToProps, mapDispatchToProps)(AssignUsersContainer);