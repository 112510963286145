import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Doughnut } from 'react-chartjs-2';
import { 
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    CardTitle,
    Container,
    Row,
    Col,
    FormGroup,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
} from 'reactstrap'




const clientCardStyle = {
    header: {
        fontSize: '1.3em',
        fontWeight: '700'
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-evenly'
    }
}

let configPie = {    
    datasets: [{        
        backgroundColor: ['#36A2EB', '#b9b9b9'],
        hoverBackgroundColor: ['#36A2EB', '#b9b9b9']
    }]
}

const Grafico = ({data}) =>{
    if(data){
        configPie.labels = data.labels;
        configPie.datasets[0].data = data.datasets;
        //Clone object
        const myConfig = JSON.parse(JSON.stringify (configPie));
        return <Doughnut 
                    data={myConfig}                 
                    options={{
                        legend: {
                            display: false
                        }, 
                        cutoutPercentage: 75,
                        maintainAspectRatio: true }}
                />
    }else{
        return <p>Sin datos</p>
    }
}

const index = ({
    data = {}, 
    pendingInfo, pagination, nextPage, prevPage, firstPage, lastPage
}) =>  {

    const itemPagination = (onClick, title) => (
        <PaginationItem>
            <PaginationLink href="#" onClick={onClick}>{title}</PaginationLink>
        </PaginationItem>
    )

    return !pendingInfo ?
        <Container>
            <Row>
                {
                    data.map((staff, key) => {
                        return(
                            <Col lg={6} md={6} xs={12} className="mr-auto ml-auto" key={key}>
                                <Card className="card-lock text-center">
                                    <CardHeader>
                                        <Row style={clientCardStyle.header}>
                                            <Col style={{textAlign: 'left'}} md={12}>
                                                <h6>{staff.nombre} {staff.apellido}</h6>
                                            </Col>                                            
                                        </Row>
                                        <Row style={clientCardStyle.header}>
                                            <Col style={{textAlign: 'left'}} md={5}>
                                                <p>Patrimonio Total</p>
                                            </Col>
                                            <Col style={{textAlign: 'right'}} md={7}>
                                                <p style={{color: '#3abd22'}}>{staff.patrimonio}</p>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <hr/>
                                    <CardBody style={{textAlign: 'left'}}>                                        
                          
                                        <div style={{position: 'relative'}}>
                                            <Grafico data={staff.graphic} />    
                                            <div style={{
                                                position:'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                textAlign: 'center'
                                            }}>
                                                <p className="h1" style={{margin: '0px'}}>{staff.porcent}</p> 
                                                <p className="h4" style={{margin: '0px'}}>Invertido</p>                                               
                                            </div>
                                        </div>



                                        <Row style={clientCardStyle.header}>
                                            <Col style={{textAlign: 'left'}} md={6}>
                                                <p><FiberManualRecordIcon style={{color: '#36A2EB'}}/>
                                                Invertido</p>
                                                {staff.invertido}
                                            </Col>
                                            <Col style={{textAlign: 'right'}} md={6}>
                                                <p><FiberManualRecordIcon style={{color: '#b9b9b9'}}/>
                                                Caja</p>
                                                {staff.caja}
                                            </Col>
                                        </Row>

                                    </CardBody>                                  

                                </Card>
                            </Col>
                        )
                    })
                }
            </Row> 

            {pagination &&
                <Pagination>                  
                    {pagination.prev_page_url && pagination.current_page > 2 &&
                        itemPagination(firstPage, "1 ...")
                    } 
                    {pagination.prev_page_url &&
                        itemPagination(prevPage, pagination.current_page - 1)
                    }                    
                    <PaginationItem active>
                        <PaginationLink href="#">{pagination.current_page}</PaginationLink>
                    </PaginationItem>

                    {pagination.next_page_url && 
                        itemPagination(nextPage, pagination.current_page + 1)
                    }                   

                    {pagination.current_page < pagination.last_page - 1  &&
                        itemPagination(lastPage, `... ${pagination.last_page}`)
                    }
                </Pagination>       
                
            }
             </Container>
            :
            <div className="text-center mt-5">
                    <CircularProgress size={50} thickness={5}/>
            </div>
    ;
}

export default index;