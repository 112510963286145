import React, { Component } from 'react';
import RentabilidadCarteras from '../../../components/AdminDashboard/RentabilidadCarteras';

const dataUsuario = [
    {
        name: 'Juan Poblete',
        rut: 13456789-6,
        patrimonio: '$ 153.321.321',
        porcent: 60, 
    },
    {
        name: 'Alicia Gonzalez',
        rut: 12456234-4,
        patrimonio: '$ 153.321.321',
        porcent: 18, 
    },
    {
        name: 'Pedro Ramirez',
        rut: 13456453-2,
        patrimonio: '$ 153.321.321',
        porcent: 50, 
    },
    {
        name: 'Raul Tapia',
        rut: 13456567-7,
        patrimonio: '$ 153.321.321',
        porcent: 49, 
    },
]

class index extends Component {

    render() {
        return (
            <div>
                <RentabilidadCarteras dataUsuario={dataUsuario}/>
            </div>
        );
    }
}

export default index;