import {REMINDERS_ERROR, REMINDERS_GET_SUCCESS, REMINDERS_GET_INIT, REMINDERS_STORE_SUCCESS, REMINDERS_STORE_INIT} from '../constants';
import client from '../api/httpClient';
import {urlReminders} from '../api/urls';
import auth from '../api/auth';

export const getUserReminders = () =>{

    return(dispatch) => {
        dispatch(reminders_get_init());
        client.get(urlReminders, auth.getUser())
        .then(r => {
            let data =  r.data[0]
            if (typeof data === 'undefined') data = null;
            console.log(data);
            const countReminders = data ? data.length : 0;
            dispatch(reminders_get_success(data, countReminders));
        })
        .catch(e => {
            console.log(e);
            dispatch(reminders_error(e.message));
        })
    }

}

export const storeUserReminders = (obj) =>{
    
    return(dispatch) => {
        dispatch(reminders_store_init());

        client.create(urlReminders, obj)
        .then(r => {
            console.log(r.data.message);    
            dispatch(reminders_store_success(obj, obj.length));
        })
        .catch(e => {
            console.log(e);
            dispatch(reminders_error(e.message));
        })
    }
}


const reminders_get_init = () => ({
    type: REMINDERS_GET_INIT
}) 

const reminders_store_init = () => ({
    type: REMINDERS_STORE_INIT
}) 

const reminders_get_success = (data, count )=>({
    type: REMINDERS_GET_SUCCESS,
    payload: {
        data,
        count
    }
})

const reminders_store_success = (data, count) =>({
    type: REMINDERS_STORE_SUCCESS,
    payload:{
        data,
        count
    }
})

const reminders_error = error =>({
    type: REMINDERS_ERROR,
    payload: {
        error
    }
})