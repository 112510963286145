import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NavLink } from "react-router-dom";
import {CardHeader} from "reactstrap";



const BreadcrumbComponent = ({route, currDepth}) => {

    console.log('BreadcrumbComponent', route.ROUTES)
    console.log('BreadcrumbComponent currDepth', currDepth)
    return (
        <div>
            <CardHeader>
                <Breadcrumbs aria-label="breadcrumb">
                    {
                        route.ROUTES.map((route, key) => {
                            if(key <= currDepth){
                                return (
                                    <NavLink to={`${route.route}`} >
                                        {route.name}
                                    </NavLink>
                                )
                            }
                            
                        })
                    }
                        
                </Breadcrumbs>
            </CardHeader>
        </div>
    )
}

export default BreadcrumbComponent;