import React, { Component } from 'react';
import {connect} from 'react-redux';
import Clientes from '../../../components/AdminDashboard/Clientes';
import data from './data';

import { 
    getClientsCardInfo,
    getNextPage,
    getPrevPage,
    getFirstPage,
    getLastPage
} from '../../../actions/client';
import { 
    clientsCard,
    pendingInfo
} from '../../../selectors/client';

class Index extends Component {
    
    componentDidMount(){
        this.props.getClientsCardInfo();    
    }

    render() {
        return(
            <Clientes 
                data={this.props.clientsCard} 
                pendingInfo={this.props.pendingInfo} 
                pagination={this.props.pagination}
                nextPage={this.props.getNextPage}
                prevPage={this.props.getPrevPage}
                firstPage={this.props.getFirstPage}
                lastPage={this.props.getLastPage}
            />
        )
    }
}

const mapStateToProps = state => ({
    clientsCard: clientsCard(state),
    pendingInfo: pendingInfo(state),
    pagination    : state.client.pagination
})

const mapDispatchToProps = dispatch => ({
    getClientsCardInfo: () => dispatch(getClientsCardInfo()),
    getNextPage: url => dispatch(getNextPage()),
    getPrevPage: url => dispatch(getPrevPage()),
    getFirstPage: url => dispatch(getFirstPage()),
    getLastPage: url => dispatch(getLastPage()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Index);