import React from "react";
import { connect } from 'react-redux';

class PanelHeader extends React.Component {
  render() {
    return (
      <div
        className={
          "panel-header " +
          (this.props.size !== undefined
            ? "panel-header-" + this.props.size
            : "")
        }
        style={{
          "display": "flex", 
          "flexDirection": "column", 
          "justifyContent": "center", 
          "height": "auto!important",
          "background": this.props.colors.split(';')[0],
        }}
      >
        {this.props.content}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  colors: state.login.appColors
})

export default connect(mapStateToProps)(PanelHeader);
