import React from 'react';
export const Disclaimer = () =>{
   return(
    <p style={{

        textAlign: 'center'

    }}>La información de su cuenta es meramente informativa y de carácter referencial, correspondiendo solo a inversiones realizadas en la Bolsa de Comercio de Santiago y Pershing. En caso de necesitar mayor detalle de sus inversiones, solicitamos a usted contactarse con su asesor financiero.
        La rentabilidad es fluctuante por lo que nada garantiza que las rentabilidades pasadas se mantengan en el futuro.
        <br/>Copyright. Todos los derechos reservados.
    </p>
    )
}

// import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Label} from 'reactstrap'

// export const Disclaimer = () =>{
//     const [modalIsOpen, setModalIsOpen] = useState(false)

//     return (
//         <div>
//             <Button onClick={()=> setModalIsOpen(true)} >Disclaimer</Button>
//             <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}  >

//                 <ModalHeader>Disclaimer</ModalHeader>
//                 <ModalBody>
//                 La información de su cuenta es meramente informativa y de carácter referencial. En caso de necesitar mayor detalle de sus inversiones, solicitamos a usted contactarse con su asesor financiero.
//                 La rentabilidad es fluctuante por lo que nada garantiza que las rentebailidades pasadas se mantengan en el futuro.
//                 <br/>Copyright. Todos los derechos reservados.
//                 </ModalBody>

//                 <ModalFooter>
//                     <Button  onClick={()=> setModalIsOpen(false)}>Cerrar</Button>
//                 </ModalFooter>
//             </Modal>
//         </div>
//     );
// }
