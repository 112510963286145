import React, { Component } from 'react';
import {connect} from 'react-redux';
import FondosMutuos from '../../../components/AdminDashboard/FondosMutuos';
import { 
    filteredFondos,
    fondoMutuoCard,
    pending
} from '../../../selectors/fondoMutuo';

class index extends Component {
    render() {
        return(            
            <FondosMutuos 
                filtered={this.props.filteredFondos}
                data={this.props.fondoMutuoCard}
                pending={this.props.pending}       
            />                    
        )
    }
}

const mapStateToProps = state => ({
    filteredFondos: filteredFondos(state),
    fondoMutuoCard: fondoMutuoCard(state),
    pending: pending(state)
})

export default connect(mapStateToProps)(index);