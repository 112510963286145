import React, {Component} from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import sum from "lodash/sum";
import map from "lodash/map";


const formatCLP = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 0
});


const formatNumber = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 4
});

const formatUSD = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2
});

const formatUSDEspecial = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2
});



class DetalleMovimientos extends Component {
    constructor(props) {
        super(props);
        const formatData = this.props.data.map(function (obj) {
            return Object.keys(obj).sort().map(function (key) {
                return obj[key];
            });
        });

        this.state = {

            data: formatData.map((prop, key) => {
                return {
                    id: key,
                    cantidad: prop[0],
                    currency: prop[1],
                    desc: prop[2],
                    fecha: prop[3],
                    monto: prop[4],
                    nemo: prop[5],
                    precio: prop[6],
                    referencia: prop[7]
                };
            })
        };
    }

    render() {
        const {data} = this.state;

        return (
            <div>
                <ReactTable
                    style={{maxHeight: '19em'}}
                    data={this.state.data}
                    previousText= {'Anterior'}
                    nextText    = {'Siguiente'}
                    loadingText = {'Cargando...'}
                    noDataText  = {'Sin datos'}
                    pageText    = {'Página'}
                    ofText      = {'de'}
                    rowsText    = {'registros'}
                    columns={[
                        {
                            Header: "Fecha",
                            accessor: "fecha"
                        },
                        {
                            Header: "Descripcion",
                            accessor: "desc"
                        },
                        {
                            Header: "Moneda",
                            accessor: "currency"
                        },
                        {
                            Header: "Cantidad",
                            id: 'cantidad',
                            accessor: d=>Number(d.cantidad),
                            Cell: ({row, original}) => { return(<span>{original.cantidad === '-' ? '' : formatNumber.format(original.cantidad)}</span>) }
                        },
                        {
                            Header: "Monto",
                            id: 'monto',
                            accessor: d=>Number(d.monto),
                            Cell: ({row, original}) => { return(<span>{original.monto === '-' ? '' : original.currency === "USD" ? '$ ' + formatUSDEspecial.format(parseInt(original.monto)) : '$ ' + formatCLP.format(parseInt(original.monto))}</span>) }
                        },
                        {
                            Header: "Nemotecnico",
                            accessor: "nemo"
                        },
                        {
                            Header: "Precio",
                            id: 'precio',
                            accessor: d=>Number(d.precio),
                            Cell: props => <span>{props.value === '-' ? '' : '$ ' + formatCLP.format(parseInt(props.value))}</span>
                        },
                        {
                            Header: "Referencia",
                            accessor: "referencia"
                        },

                    ]}
                    defaultPageSize={5}
                    showPaginationBottom={true}
                    sortable={true}

                    className="-striped -highlight"
                />
            </div>
        );
    }
}

export default DetalleMovimientos;
