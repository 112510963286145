import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";
import indexRoutes from "./routes/index.js";

const hist = createBrowserHistory();

class AppRouter extends React.Component {

    
    componentDidMount() {

    }

    render() {
        return(            
            <Router history={hist}>
                <Switch>
                    {indexRoutes.map((prop, key) => {
                        return <Route 
                                    path={prop.path} 
                                    key={key} 
                                    render={
                                        () => <prop.component {...hist} {...this.props} history={hist}/>
                                    }
                                />;
                    })}
                </Switch>
            </Router>
        );
    }
}


export default AppRouter;