import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import HeaderComponent from '../../components/Header/HeaderComponent';

class RebalanceHistorialContainer extends Component {
    render() {
        return (
            <div>
                {/* <HeaderComponent
                location={this.props.location}
                /> */}
                <Switch>
                    {this.props.routes.map((route, i) => (
                        <Route path={route.path} render={() => <route.component />} key={i} />
                    ))}
                </Switch>
            </div>
        );
    }
}

export default withRouter(RebalanceHistorialContainer);