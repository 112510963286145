import React from "react";
import {Link} from "react-router-dom";
import DateRangePicker from 'react-daterange-picker';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Row,
    Col,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container,
    ButtonGroup,
    UncontrolledDropdown, FormGroup

} from "reactstrap";
import { Button } from "../../components";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import dashboardRoutes from "../../routes/dashboard";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import DatepickerNew from './Datepicker/DatepickerNew';

const moment = extendMoment(originalMoment);

const today = moment();

const stateDefinitions = {
    available: {
        color: null,
        label: 'Disponible',
    },
    enquire: {
        color: '#ffd200',
        label: 'Seleccionado',
    },
    unavailable: {
        selectable: false,
        color: '#78818b',
        label: 'No Disponible',
    },
};

const dateRanges = [
    {
        state: 'enquire',
        range: moment.range(
            moment(),
            moment().subtract(3, 'months')
        ),
    },
];

class CartolaHeaderComponent extends React.Component {    

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            iaDropdownOpen: false,
            pfDropdownOpen: false,
            color: "transparent"
        };
        this.handleCartolaUpdt = this.handleCartolaUpdt.bind(this);  
        this.toggle = this.toggle.bind(this);
        
    }    

    handleCartolaUpdt(){
        this.props.handleCartola(this.props.cartolaId)
    }

    toggle() {
        if (this.state.isOpen) {
            this.setState({
                color: "transparent"
            });
        } else {
            this.setState({
                color: "white"
            });
        }
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    iaDropdownToggle(e) {
        this.setState({
            iaDropdownOpen: !this.state.iaDropdownOpen
        });
    }

    pfDropdownToggle(e) {
        this.setState({
            pfDropdownOpen: !this.state.pfDropdownOpen
        });
    }

    getBrand() {
        var name;
        dashboardRoutes.map((prop, key) => {
            if (prop.collapse) {
                prop.views.map((prop, key) => {
                    if (prop.path === window.location.pathname) {
                        name = prop.name;
                    }
                    return null;
                });
            } else {
                if (prop.redirect) {
                    if (prop.path === window.location.pathname) {
                        name = prop.name;
                    }
                } else {
                    if (prop.path === window.location.pathname) {
                        name = prop.name;
                    }
                }
            }
            return null;
        });
        return name;
    }

    openSidebar() {
        document.documentElement.classList.toggle("nav-open");
        this.refs.sidebarToggle.classList.toggle("toggled");
    }

    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    updateColor() {
        if (window.innerWidth < 993 && this.state.isOpen) {
            this.setState({
                color: "white"
            });
        } else {
            this.setState({
                color: "transparent"
            });
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateColor.bind(this));
    }

    componentDidUpdate(prevProps) {
        console.log('componentDidUpdate', prevProps);
        if (
            window.innerWidth < 993 &&
            prevProps.location.pathname !== this.props.location.pathname &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            this.refs.sidebarToggle.classList.toggle("toggled");
        }
    }


    render() {
        const customStyles = {
            valueContainer: (provided, state) => ({
                ...provided,
                // textOverflow: "ellipsis",
                maxWidth: "190px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                display: "initial"
            })
        };

        const {start, end} = this.props.datePickerValue;
        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',           

        };
        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        };
        const formatGroupLabel = data => (
            <div style={groupStyles}>
              <span>{data.label}</span>
              <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
          );

        return (
            
            // add or remove classes depending if we are on full-screen-maps page or not
            <Navbar
                color={
                    window.location.pathname.indexOf("full-screen-maps") !== -1
                        ? "white"
                        : this.state.color
                }
                expand="lg"
                className={
                    window.location.pathname.indexOf("full-screen-maps") !== -1
                        ? "navbar-absolute "
                        : "navbar-absolute " +
                        (this.state.color === "transparent" ? "navbar-transparent " : "")
                }
            >
                <Container fluid>
                    <div className="navbar-wrapper">
                        <div className="navbar-toggle">
                            <button
                                type="button"
                                ref="sidebarToggle"
                                className="navbar-toggler"
                                onClick={() => this.openSidebar()}
                            >
                                <span className="navbar-toggler-bar bar1"/>
                                <span className="navbar-toggler-bar bar2"/>
                                <span className="navbar-toggler-bar bar3"/>
                            </button>
                        </div>
                        <NavbarBrand>{this.getBrand()}</NavbarBrand>
                    </div>
                    <NavbarToggler onClick={this.toggle}>
                        <span className="navbar-toggler-bar navbar-kebab"/>
                        <span className="navbar-toggler-bar navbar-kebab"/>
                        <span className="navbar-toggler-bar navbar-kebab"/>
                    </NavbarToggler>
                    <Collapse
                        isOpen={this.state.isOpen}
                        navbar
                        className="justify-content-end"
                    >
                        {window.location.pathname.indexOf('rebalancer-wizard') !== -1 ? <div></div>
                            : window.location.pathname.indexOf('user-page') !== -1 ? <div></div>
                                : <Row style={{
                                    "justifyContent": "space-evenly",
                                    "alignItems": "center",
                                    "width": "100%"
                                }}>
                                    <Col xs={12} md={4}
                                    className="second">
                                        <Select
                                            className="react-select warning"
                                            classNamePrefix="react-select"
                                            isMulti
                                            styles={customStyles}
                                            hideSelectedOptions={false}
                                            isLoading={this.props.fetchCompositionLoading}
                                            closeMenuOnSelect={false}
                                            placeholder="Cuentas..."
                                            name="singleSelect"
                                            value={
                                                this.props.cartolaAccountSelectValue
                                            }
                                            options={this.props.cartolaGroupSelectOption}
                                            formatGroupLabel={formatGroupLabel}
                                            onChange={value => this.props.selectAccountHandler(value)}
                                        />
                                    </Col>
                                    <Col xs={12} md={3} 
                                        style={{"display": "flex", "justifyContent": "center"}}
                                        className="third">

                                        <DatepickerNew 
                                            selectDateHandler={this.props.selectDateHandler}
                                            datePickerValue={this.props.datePickerValue}                                            
                                        />
                                    </Col>
                                    <Col 
                                        style={{"display": "flex", "justifyContent": "center"}} 
                                        xs={12} md={4}
                                        >

                                        <ButtonGroup>
                                            
                                            {this.props.fetchBreakdownLoading ?
                                                <Button
                                                    color="success"
                                                    disabled={true}
                                                    className="fourth"
                                                >
                                                    Actualizando...
                                                </Button>
                                                :
                                                <Button
                                                    color="success"
                                                    onClick={() => this.props.fetchBreakdown(this.props.cartolaId, this.props.isGroup)}
                                                    className="fourth"
                                                >
                                                    Actualizar
                                                </Button>

                                            }
                                            {this.props.fetchCartolaPDFLoading ?
                                                <Button
                                                    color="info"
                                                    disabled={true}
                                                    className="fifth"
                                                >
                                                    Exportando...
                                                </Button>
                                                :
                                                <Button color="info"
                                                        onClick={() => this.props.fetchCartolaPDF(this.props.cartolaId, this.props.isGroup)}
                                                        className="fifth"
                                                >
                                                    Exportar a PDF
                                                </Button>
                                            }
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                        }


                    </Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default CartolaHeaderComponent;