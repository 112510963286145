import React from "react";
import "react-daterange-picker/dist/css/react-calendar.css";
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Row,
    Col
} from "reactstrap";
import Skeleton from 'react-loading-skeleton';

// Import view components 
import Detalle from "../../components/Cartola/Sections/Detalle";
import CartolaStats from "../../components/Cartola/Sections/CartolaStats/CartolaStats";
import Resumen from "../../components/Cartola/Sections/Resumen/Resumen";
import HeaderPanelContainer from "../../containers/CartolaContainer/Sections/HeaderPanelContainer/HeaderPanelContainer";
import HeaderContainer from '../../containers/HeaderContainer/HeaderContainer';
import originalMoment from "moment";
import { extendMoment } from "moment-range";

require('moment/locale/es');

const moment = extendMoment(originalMoment);

const usdFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0
});

const clpFormatter = new Intl.NumberFormat('es-CL', {
    minimumFractionDigits: 0
});


function CartolaClient({ cartolaId, backgroundColor, fetchBreakdownLoading, fetchBreakdownFailed, consolidated,
    periodStart, periodEnd, cartera, parsedCartera, cash, consolidatedByCurrency, consolidatedByAccount,
    consolidatedByType, movimientos, percentageAssetTypes, percentageCurrency, isGroup = false }) {

    const [pageSubcategories, setPageSubcategories] = React.useState('ps1');
    
    const startPeriod =  moment(periodStart).locale('es').format('dddd Do [de] MMMM [de] YYYY');
    const endPeriod = moment(periodEnd).locale('es').format('dddd Do [de] MMMM [de] YYYY');
    const filterEndDate = periodEnd;

    return (            
            
        <div>
            <div id="mainpaneltop"></div>

            <HeaderContainer  
                cartolaId={cartolaId}
                isGroup={isGroup}
            />

            {/* Page Header */}
            <HeaderPanelContainer
                colorSecondary= {backgroundColor}
            /> 

            <div className="content">
                
                {/* Stats */}
                <CartolaStats
                    fetchBreakdownLoading={fetchBreakdownLoading}
                    fetchBreakdownFailed={fetchBreakdownFailed}
                    consolidated={consolidated}
                    clpFormatter={clpFormatter}
                />

                {/* Resumen de Cuenta */}
                <div id="mainpanelmiddle"></div>
                <Resumen 
                    parsedCartera={parsedCartera}
                    cash={cash}
                    consolidatedByCurrency={consolidatedByCurrency}
                    consolidatedByAccount={consolidatedByAccount}
                    consolidatedByType={consolidatedByType}
                    movimientos={movimientos}
                    fetchBreakdownLoading={fetchBreakdownLoading}
                    fetchBreakdownFailed={fetchBreakdownFailed}
                    percentageAssetTypes={percentageAssetTypes}
                    percentageCurrency={percentageCurrency}
                    clpFormatter={clpFormatter}
                    usdFormatter={usdFormatter}
                    startPeriod={startPeriod}
                    endPeriod={endPeriod}
                />
        
                {/* Movimientos */}
                {/* Detalle */}
                {fetchBreakdownLoading ?
                    <Row>
                        <Col xs={12} md={12}>
                            <Card className="card-plain card-subcategories">
                                <CardHeader>
                                    <CardTitle tag="h4" className="text-center">
                                        Detalle
                                    </CardTitle>
                                    <br />
                                </CardHeader>
                            </Card>
                            <Card className="card-stats card-raised">
                                <CardBody>
                                    <Skeleton count={12} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    :
                    fetchBreakdownFailed ?
                        <div></div>
                        :

                        <Row id="mainpanelbottom">
                            <Card className="card-plain card-subcategories">
                                <CardHeader>
                                    <div className="x">
                                        <CardTitle tag="h4" className="text-center">
                                            Cartera Consolidada
                                        </CardTitle>
                                    </div>
                                    <br />
                                </CardHeader>
                            </Card>
                            <Col md={12} xs={12} className="ml-auto mr-auto">

                                <Nav
                                    pills
                                    className="nav-pills-primary nav-pills-icons justify-content-center"
                                >
                                    <NavItem>
                                        <NavLink style={{ 'cursor': 'pointer' }}
                                            className={
                                                pageSubcategories === "ps1" ? "active" : ""
                                            }
                                            onClick={() =>
                                                setPageSubcategories("ps1")                                                
                                            }
                                        >
                                            <i className="now-ui-icons business_bank" />
                                            APV
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink style={{ 'cursor': 'pointer' }}
                                            className={
                                                pageSubcategories === "ps2" ? "active" : ""
                                            }
                                            onClick={() =>
                                                setPageSubcategories("ps2")
                                            }
                                        >
                                            <i className="now-ui-icons shopping_shop" />
                                            No APV
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent
                                    className="tab-space tab-subcategories"
                                    activeTab={pageSubcategories}
                                >
                                    <TabPane tabId="ps1">
                                        <Detalle data={cartera.length >= 0 ?
                                            cartera.filter(function (row) {
                                                if(row["categoria"] === "APV"){
                                                    return row["categoria"] === "APV" && row["fecha"] === filterEndDate;
                                                }else if(row["categoria"] === "57BIS"){
                                                    return row["categoria"] === "57BIS" && row["fecha"] === filterEndDate;
                                                }                                                    
                                            })
                                            : cartera[0].filter(function (row) {
                                                if(row["categoria"] === "APV"){
                                                    return row["categoria"] === "APV" && row["fecha"] === cartera[cartera.length - 1]['fecha'];
                                                }else if(row["categoria"] === "57BIS"){
                                                    return row["categoria"] === "57BIS" && row["fecha"] === cartera[cartera.length - 1]['fecha'];
                                                }
                                            })}
                                        />
                                    </TabPane>

                                    <TabPane tabId="ps2">

                                        <Detalle data={cartera.length >= 0 ?
                                            cartera.filter(function (row) {
                                                return row["categoria"] === "NOAPV" && row["fecha"] === cartera[cartera.length - 1]['fecha'];
                                            })
                                            : cartera[0].filter(function (row) {
                                                return row["categoria"] === "NOAPV" && row["fecha"] === cartera[cartera.length - 1]['fecha'];
                                            })}
                                        />
                                                

                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                }

            </div>

        </div>
 
    );
    
}

export default CartolaClient;