import React, { Component } from 'react';
import { connect } from 'react-redux';
import Administradoras from '../../../components/AdminDashboard/Administradoras';
import { 
    getAdministradoraCardInfo 
} from '../../../actions/administradora';
import { 
pendingInfo,
dataPatrimonio,
dataUsuario
} from '../../../selectors/administradora';


// const dataPatrimonio= {
//     labels: ['Mixtos', 'Renta Fija', 'Renta Variable', 'Bonos'],
//     datasets: [10, 20, 30, 40]
// };

// const dataUsuario = [
//     {
//         name: 'Juan Poblete',
//         rut: 13456789-6,
//         patrimonio: '$ 153.321.321',
//         porcent: 60, 
//     },
//     {
//         name: 'Alicia Gonzalez',
//         rut: 12456234-4,
//         patrimonio: '$ 153.321.321',
//         porcent: 18, 
//     },
//     {
//         name: 'Pedro Ramirez',
//         rut: 13456453-2,
//         patrimonio: '$ 153.321.321',
//         porcent: 50, 
//     },
//     {
//         name: 'Raul Tapia',
//         rut: 13456567-7,
//         patrimonio: '$ 153.321.321',
//         porcent: 49, 
//     },
// ]

class index extends Component {

    componentDidMount(){
        this.props.getAdministradoraCardInfo();
    }

    handleClick(){
        //  console.log("Click")
      }

    render() {
        return (
            <div>
                <Administradoras 
                    dataPatrimonio={this.props.dataPatrimonio} 
                    dataUsuario={this.props.dataUsuario}
                    pending={this.props.pendingInfo}
                    onClick={()=>{}}/>
            </div>
        );
    }
}

const mapStateToProps = state =>({
    pendingInfo: pendingInfo(state),
    dataUsuario: dataUsuario(state),
    dataPatrimonio: dataPatrimonio(state)
})

const mapDispatchToProps = dispatch => ({
    getAdministradoraCardInfo: () => dispatch(getAdministradoraCardInfo())
})

export default connect(mapStateToProps, mapDispatchToProps)(index);