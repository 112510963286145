import React, { useState, useCallback, useEffect } from 'react'
import { useTransition, animated } from 'react-spring'
import './onboardingStyles.css'
import Slide1 from './Slide1';
import Slide2 from './Slide2';
import Slide3 from './Slide3';


const pages = [
  ({ style }) => <animated.div style={{ ...style, background: 'white', border: "20px solid #2980b8" }}><Slide1 /></animated.div>,
  ({ style }) => <animated.div style={{ ...style, background: 'white', border: "20px solid #f1c40f" }}><Slide2 /></animated.div>,
  ({ style }) => <animated.div style={{ ...style, background: 'white', border: "20px solid #27ae60" }}><Slide3 /></animated.div>,
]

export default function OnboardingAnimation() {
  const [index, set] = useState(0)
  useEffect(() => void setInterval(() => set(state => (state + 1) % 3), 10000), [])
  const transitions = useTransition(index, p => p, {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
  })
  return (
    <div className="simple-trans-main">
      {transitions.map(({ item, props, key }) => {
        const Page = pages[item]
        return <Page key={key} style={props} />
      })}
    </div>
  )
}
