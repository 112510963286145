import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    message: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        color: 'black',
        backgroundColor: 'white',
        transform: 'translate(-50%,-50%)'
    }
}));

const TableMessage = ({loading, rowsLength}) => {
    const classes = useStyles();

    return (
        <tbody>
            {loading &&                         
            <tr className={classes.message}>
                <td><CircularProgress /></td>
            </tr>}
            {!loading && rowsLength === 0 && 
            <tr className={classes.message}>
                <td><b>No hay registros que mostrar</b></td>
            </tr>}
        </tbody>
    );
};

export default TableMessage;