import React from 'react';
import { PanelHeader, Header, Statistics } from "../../../components";
import {Card, CardBody,	CardHeader, Row, Col} from "reactstrap";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Table from './Table';
import QuickEditRisk from '../../../containers/Clients/QuickEditRisk';
import QuickView from './QuickView';
import QuickEdit from '../../../containers/Clients/QuickEdit';
import {rutFormat, patrimonyFormat} from '../../../helpers/formatter'
import { NavLink } from "react-router-dom";
import { urlClientsHome } from '../../../api/urls';
import TableMaterialUi from '../../TableMaterialUi'

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  

const headCells = [       
    { id: 'rut', numeric: false, disablePadding: false, label: 'Rut', formatter:rutFormat }, 
    { id: 'account_name', numeric: false, disablePadding: true, label: 'Cuenta', padding:"none" },
    { id: 'account_category_name', numeric: false, disablePadding: true, label: 'Clase', padding:"none" },
    { id: 'administrator_name', numeric: false, disablePadding: true, label: 'Administradora', padding:"none" },
    { id: 'patrimony', numeric: true, disablePadding: false, label: 'Patrimonio', align:"right", formatter:patrimonyFormat }
    ];

function index({user, pending, data, lastRebalance, clientStaff, params, onClick}) {
    const classes = useStyles();
    const [tableState, setTableState] = React.useState({		
        order : "desc",
        orderBy : "patrimonio",
        page : 0,
        dense : false,
        searchValue : '',
        rowsPerPage : 5,
        selected : [],
        cellCursor: 'default'
    })
    
    const getTableState = state => {
        setTableState(state)
    }
    console.log(user)
    return (
        <div>
            <CardHeader>
                <Breadcrumbs aria-label="breadcrumb">
                    <NavLink to={`${urlClientsHome}${params.search ? '?search='.concat(params.search) : ''}`} >Clientes</NavLink>
                    <Typography color="textPrimary">Detalle Cliente</Typography>
                </Breadcrumbs>
            </CardHeader>
            <PanelHeader size="md"
            content={
                <div style={{padding: "0 3em"}}>
                            <QuickEdit 
                                pending={pending}
                                data={user}
                                content={
                                <Row>
                                    <div style={{display: "block", width: "auto", margin: "0 2em 0 0"}}>
                                        
                                        <h3 style={{
                                            color: '#fff', marginBottom: '.3em'
                                        }}>
                                            {pending ? 
                                            <Skeleton count={1} />
                                            
                                            :
                                            user.name}
                                             
                                        </h3>
                                        <p style={{color: "rgb(126 168 222)"}}>Nombres</p>
                                    </div>
                                    <div style={{display: "block", width: "auto", margin: "0 2em 0 0"}}>
                                        <h3 style={{
                                            color: '#fff', marginBottom: '.3em'
                                        }}>{pending ? 
                                            <Skeleton count={1} />
                                            
                                            :user.last_name}
                                        </h3>
                                        <p style={{color: "rgb(126 168 222)"}}>Apellidos</p>
                                    </div>
                                    <div style={{display: "block", width: "auto", margin: "0 2em 0 0"}}>
                                        <h3 style={{
                                            color: '#fff', marginBottom: '.3em'
                                        }}>{pending ? 
                                            <Skeleton count={1} />
                                            
                                            :user.email}
                                        </h3>
                                        <p style={{color: "rgb(126 168 222)"}}>Email</p>
                                    </div>
                                </Row>
                                }
                            />
                            
                        </div>
            }
            >
                
            </PanelHeader>
            <Header location={location} />
            <div className="content">
                <Card className="card-stats card-raised" style={{maxHeight: '105px'}}>
                    <CardBody>
                    <Row style={{maxHeight: '100px'}}>
                        <Col md={4}className={"stat-details"} style={{padding: "0"}}>
                            <Statistics
                                horizontal
                                icon={
                                    "business_badge"
                                }
                                title={`${rutFormat(user.rut)}`}
                                subtitle="rut"
                                iconState="info"
                            >
                                
                            </Statistics>
                        </Col>
                        <Col md={4} className={"stat-details"} style={{padding: "0"}}>
                        <QuickEditRisk riskProfile={user.fantasy_name_profile} />
                        </Col>
                        <Col md={4} className={"stat-details"} style={{padding: "0"}}>
                            <Statistics
                                horizontal
                                icon={
                                    "business_money-coins"
                                }
                                title={`${patrimonyFormat(user.patrimony)}`}
                                subtitle="patrimonio"
                                iconState="success"
                            >
                                
                            </Statistics>
                            
                        </Col>
                    </Row>
                    </CardBody>
                </Card>
                    <div className="spacer" style={{height: "2em"}}></div>
                    <Row>
                        <Col md={9}>

                            <TableMaterialUi 
                                title={'Cuentas'}
                                rows={data} 
                                headCells={headCells} 
                                withCheckbox={false} 
                                onClickRow={onClick}
                                loading={pending}
                                updateState={getTableState}    //TableMaterialUi State
                                initialState={tableState}
                            />
                        </Col>
                        <Col md={3}>
                            <QuickView 
                                user={user}
                                pending={pending} 
                                lastRebalance={lastRebalance}
                                clientStaff={clientStaff}
                                clientId={user.id}
                            />
                            {/* <Actions /> */}
                        </Col>
                    </Row>
                
            </div>                        
        </div>

    );
    
}

export default index;