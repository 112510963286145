import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {MATERIAL_TABLE_LOCALIZATION} from './../../constants';
import {AccountCircle, AccountBalance, Message, NoteAdd, PermIdentity, Warning, CancelRounded} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";

import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { rutFormat, patrimonyFormat } from '../../helpers/formatter';


const theme = createTheme({
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        light: '#01579b',
        main: '#ff9100',
      },       
    },      
});

const useStyles = makeStyles(theme => ({    
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {      
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '115ch',
	},
	root: {
		width: '100%',
		'& > * + *': {
		  marginTop: theme.spacing(2),
        },
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '34ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '25ch',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    shadows: ["none"]
  }));

function Table({data, onClick, onClickClients, onClickClientsOut, pending,
    handleAddStaff, reset, actionMessage, error, handleRemoveStaff
}) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false); 
    const state = {	  
        columns: [
            { title: 'Rut', field: 'rut', editable: 'never', width: 150, render: ({rut}) => <span>{rutFormat(rut)}</span> },
            { title: 'Nombre', editable: 'never', field: 'name', width: 250},
            { title: 'Apellido', editable: 'never', field: 'last_name', width: 250},
            { title: 'Email', editable: 'never', field: 'email', width: 300},                
            { title: 'Patrimonio', editable: 'never', type: 'currency', field: 'patrimony', render: ({patrimony}) => (
                <span>$ {patrimonyFormat(patrimony)}</span>
            ), width: 100},   
        ],
    }

    const handleRowClicked = (selectedRow) => {
        onClick(selectedRow)
    }

    const handleRowSelected = (rows) => {
        console.log("Cuadrito", rows)
    }

    //#region Modal Nuevo Cliente
    const handleAddStaffModal = () => {
        console.log("Agregar Staff")
        setOpen(true);
    }

    const handleAddStaffConfirm = () => {
        if(error == 0) {
            handleCloseModal();
            return
        }
        const rut = document.getElementById("rutForm").value;
        const name = document.getElementById("nameForm").value;
        const last_name = document.getElementById("last_nameForm").value;
        const email = document.getElementById("emailForm").value;
        
        handleAddStaff({rut, name, last_name, email});        
    }

    const handleCloseModal = () => {
        setOpen(false);	
        reset()
    };
//#endregion
        
    return (
    <div>
    <MuiThemeProvider theme={theme}>
        <MaterialTable
            title="Asesores"
            columns={state.columns}
            data = {data}  
            isLoading= {pending}
            
            editable={{
                onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            handleRemoveStaff(oldData)
                            resolve()
                        }, 1000)
                    }),
            }}

            options={{
                selection: true,
                sorting: true,
                exportButton: true,
                rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#37b15933' : '' })
            }}
            // onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}                
            onRowClick={((evt, selectedRow) => handleRowClicked(selectedRow) )}                
            // onSelectionChange={(rows) => alert('You selected ' + rows.length + ' rows ')}
            onSelectionChange={(rows) => handleRowSelected(rows)}
            actions={[
                {
                    icon: 'add',
                    tooltip: 'Agregar Asesor',
                    isFreeAction: true,
                    onClick: (event) => handleAddStaffModal()
                },
                {
                    tooltip: 'Asignar Clientes',
                    icon: () => <AccountCircle />,
                    onClick: (evt, data) => onClickClients(data)
                    
                },
                {
                    tooltip: 'Desasignar Clientes',
                    icon: () => <CancelRounded />,
                    onClick: (evt, data) => onClickClientsOut(data)
                    
                },
            ]}
            localization = {MATERIAL_TABLE_LOCALIZATION}
        />
    </MuiThemeProvider>

    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
        timeout: 500,
        }}
        >
        <Fade in={open}>
        <div className={classes.paper}>			
            <h5 id="transition-modal-title">Agregar Asesor</h5>
            <hr />

            <Collapse in={actionMessage ? true : false}>
                <Alert
                    severity={error ? "error": "success"}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            reset();
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {actionMessage}
                </Alert>
            </Collapse>

            <div>
                <form className={classes.root} noValidate autoComplete="off">
                    <TextField id="rutForm" label="Rut"/> 
                    <TextField id="nameForm" label="Nombre"/>
                    <TextField id="last_nameForm" label="Apellido"/>
                    <TextField id="emailForm" label="Email" type="email"/>                    
                </form>                
            </div>

            <hr />

            <Row>
                <Col md={12}>
                    <Button variant="contained" onClick={handleCloseModal}>Cerrar</Button>
                    <Button variant="contained" color="primary" 
                        onClick={handleAddStaffConfirm}
                        className="float-right"
                    >Aceptar</Button>
                </Col>
            </Row>
            
        </div>
        </Fade>
    </Modal>

    </div>
    );
    
}

export default Table;