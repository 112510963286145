import React, {useState, useEffect} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string'
import {updateRouteParams} from '../actions/withParams';


const withParams = WrappedComponent => {
    class WithParams extends React.Component {
        
        render() {
            let params = queryString.parse(this.props.location.search);
            console.log('this.props.params', this.props.params);
            return(
                <WrappedComponent {...this.props} params={params} />
            )
        }
    }

    
    return withRouter(WithParams);
}


export default withParams;