import React from 'react';
import Table from './Table';
import FilterMenuContainer from '../../containers/Clients/FilterMenu';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";

import TableMaterialUi from '../TableMaterialUi';
import {rutFormat, patrimonyFormat} from '../../helpers/formatter';
import { AccountCircle, AddCircle, CancelRounded, AccountBalance, Warning, Delete } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import {
	PanelHeader,
	Header
} from "../../components";
import ModalClient from './ModalClient';

const useStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
}));

const headCells = [
	//id - numeric - disablePadding - label - padding(opcional) - align(opcional) - formatter(opcional)       
    { id: 'rut', numeric: false, disablePadding: false, label: 'Rut', formatter:rutFormat }, 
    { id: 'name', numeric: false, disablePadding: true, label: 'Nombre', padding:"none" },
    { id: 'last_name', numeric: false, disablePadding: true, label: 'Apellido', padding:"none" },
    { id: 'email', numeric: false, disablePadding: true, label: 'Email', padding:"none" },
	{ id: 'fantasy_name_profile', numeric: false, disablePadding: true, label: 'Perfil Riesgo'},	
    { id: 'patrimony', numeric: true, disablePadding: false, label: 'Patrimonio', align:"right", formatter:patrimonyFormat }
];

function index({ data, onClick, onClickStaff, onClickStaffOut, message, reset, pending, onClickSendInvitation,
	riskProfileList, handleUpdateRiskProfile, handleAddClient, actionMessage, riskProfileArray,
	cuentasAdministradoras, pendingAccounts, error, handleUpdateClientAccounts, isLoading,
	handleRemoveClient, searchText }) {

	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [text, setText] = React.useState('');
	const [show, setButton] = React.useState(false);
	const [showConfirm, setButtonConfirm] = React.useState(false);
	const [clients, setClients] = React.useState(null);
	const [invitation, setInvitation] = React.useState(null);

	const [accounts, setAccounts] = React.useState([]);
	const [modalTittle, setModalTittle] = React.useState('');
	const [modalOption, setModalOption] = React.useState(0);
	const [openModalClient, setOpenModalClient] = React.useState(false);
	const [riskProfile, setRiskProfile] = React.useState('');

	const [tableState, setTableState] = React.useState({		
        order : "desc",
        orderBy : "patrimonio",
        page : 0,
        dense : false,
        searchValue : '',
        rowsPerPage : 5,
        selected : []
    })
	const [showIconButtons, setShowIconButtons] = React.useState(true);

	React.useEffect(() => {
		if (message) {
			setButtonConfirm(false)
			setButton(true)
			setText(message);
		}
	}, [message]);

	const showButtons = () => {
		setShowIconButtons(false);
	}

	const hideButtons = () => {
		setShowIconButtons(true);
	}

	const getTableState = state => {	
		if (state.selected.length > 0){
			showButtons();
		}else{
			hideButtons();
		}
		setTableState(state)
    }

	const handleOpen = () => {
		setOpen(true);
	};

	const handleAssignOpenModal = (clients) => {
		setClients(clients);
		setButtonConfirm(true);
		setButton(true);
		setText('Confirmar desasignación de los asesores');
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		reset()
	};

	const onClickConfirm = () => {

		console.log('Desasignando Cliente(s)...', clients)

		setButton(false);
		onClickStaffOut(null, clients)
	}

	const handleOnClickSendInvitationOpenModal = data => {
		setInvitation(data);
		setButtonConfirm(true);
		setButton(true);
		setText('Confirmar el envío de invitaciones');
		setOpen(true);
	}

	const handleOnClickSendInvitation = () => {
		setButton(false);
		setText('Enviando invitaciones...');
		onClickSendInvitation(invitation)
	}


	//#region Modal Nuevo Cliente
	const handleAddClientModal = () => {
		console.log("Agregar Cliente")
		setAccounts([])
		setModalTittle('Agregar Cliente')
		setModalOption(0)
		setOpenModalClient(true);
	}

	const handleChangeRiskProfile = event => {
		setRiskProfile(event.target.value);
	};

	const handleAddClientConfirm = () => {
		if (error == 0) {
			handleCloseModal();
			return
		}
		const rut = document.getElementById("rutForm").value;
		const name = document.getElementById("nameForm").value;
		const lastName = document.getElementById("lastNameForm").value;
		const email = document.getElementById("emailForm").value;
		const riskId = riskProfile;
		handleAddClient({ rut, name, lastName, email, riskId });
	}
	//#endregion

	//#region Modal Editar Cuentas
	const handleRowAccountSelected = (rows) => {
		setAccounts(rows)
	}

	const handleAddClientAccount = clientId => {

		const clientData = getClientData(clientId)

		console.log("Agregar cuentas")
		setAccounts([])
		setClients(clientData)
		setModalTittle('Agregar Cuentas a Cliente')
		setModalOption(1)
		setOpenModalClient(true);
	}

	const handleCloseModal = () => {
		setOpenModalClient(false);
		reset()
	};

	const handleAddClientAccountConfirm = () => {
		if (error == 0) {
			handleCloseModal();
			return
		}
		const option = modalOption; //Option 1 = Add accounts, Option 2 = Remove accounts
		handleUpdateClientAccounts({ clients, accounts, option })
	}
	//#endregion

	//#region Modal Quitar Cuentas
	const handleRemoveClientAccount = clientId => {
		
		const clientData = getClientData(clientId)

		console.log("Remover cuentas")
		setAccounts([])
		setClients(clientData)
		setModalTittle('Remover Cuentas a Cliente')
		setModalOption(2)
		setOpenModalClient(true);
	}
	//#endregion

	const getClientData = clientId => data.filter(obj => clientId.includes(obj.id))

	return (
		<div>
			<CardHeader>
				<Breadcrumbs aria-label="breadcrumb">
					<Typography color="textPrimary">Clientes</Typography>
					<Typography color="textPrimary"></Typography>
				</Breadcrumbs>
			</CardHeader>

			<PanelHeader size="sm" content={
				<div style={{padding: "0 4em"}}>
					<h3 style={{
						color: '#fff', marginBottom: '.3em'
					}}>
						Clientes	
					</h3>
					<span style={{color: '#e6dede'}}>Filtrar clientes, asignar a integrantes</span>
				</div>
			}>			
			</PanelHeader>
			
			<div className="content" style={{marginTop: "1em"}}>

				<Collapse in={actionMessage ? true : false}>
					<Alert
						severity={error ? "error" : "success"}
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									reset();
								}}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
					>
						{actionMessage}
					</Alert>
				</Collapse>

				<Row>
					<Col md={9}>
						{/* <Table data={data}
							onClick={onClick}
							onClickStaff={onClickStaff}
							onClickStaffOut={handleAssignOpenModal}
							pending={pending}
							onClickSendInvitation={handleOnClickSendInvitationOpenModal}
							riskProfileList={riskProfileList}
							handleUpdateRiskProfile={handleUpdateRiskProfile}
							handleRemoveClient={handleRemoveClient}
							handleAddClientModal={handleAddClientModal}
							handleAddClientAccount={handleAddClientAccount}
							handleRemoveClientAccount={handleRemoveClientAccount}
							searchText={searchText}
						>
						</Table> */}

						<Tooltip title="Agregar Cliente" >
							<IconButton
								disabled = {false}
								aria-label="Agregar Cliente"
								color="inherit"
								size="medium"
								onClick= {handleAddClientModal}
							>
								<AddCircle fontSize="inherit" />
							</IconButton>
						</Tooltip>

						<Tooltip title="Asignar Asesores" >
							<IconButton
								disabled = {showIconButtons}
								aria-label="Asignar Asesores"
								color="inherit"
								size="medium"
								onClick= {() => {onClickStaff(tableState.selected)}}
							>
								<AccountCircle fontSize="inherit" />
							</IconButton>
						</Tooltip>						

						<Tooltip title="Desasignar Asesores" >
							<IconButton
								disabled = {showIconButtons}
								aria-label="Desasignar Asesores"
								color="inherit"
								size="medium"
								onClick= {() => {handleAssignOpenModal(tableState.selected) }}
							>
								<CancelRounded fontSize="inherit" />
							</IconButton>
						</Tooltip>

						{/* <Tooltip title="Agregar Cuentas a Clientes" >
							<IconButton
								disabled = {showIconButtons}
								aria-label="Agregar Cuentas a Clientes"
								color="inherit"
								size="medium"
								onClick= {() => {handleAddClientAccount(tableState.selected) }}
							>
								<AccountBalance fontSize="inherit" />
							</IconButton>
						</Tooltip>

						<Tooltip title="Remover Cuentas a Clientes" >
							<IconButton
								disabled = {showIconButtons}
								aria-label="Remover Cuentas a Clientes"
								color="inherit"
								size="medium"
								onClick= {() => {handleRemoveClientAccount(tableState.selected) }}
							>
								<Warning fontSize="inherit" />
							</IconButton>
						</Tooltip> */}

						<TableMaterialUi 
							title={'Clientes'}
							rows={data} 
							headCells={headCells} 
							withCheckbox={true} 
							onClickRow={onClick}
							loading={pending}
							updateState={getTableState}    //TableMaterialUi State
							initialState={tableState}
						/>
					</Col>
					<Col md={3}>
						<FilterMenuContainer />
					</Col>
				</Row>

				<ModalClient
					riskProfile={riskProfile}
					handleChangeRiskProfile={handleChangeRiskProfile}
					handleCloseModal={handleCloseModal}
					open={openModalClient}
					modalOption={modalOption}
					modalTittle={modalTittle}
					isLoading={isLoading}
					riskProfileArray={riskProfileArray}

					cuentasAdministradoras={cuentasAdministradoras}
					pendingAccounts={pendingAccounts}
					handleRowAccountSelected={handleRowAccountSelected}
					actionMessage={actionMessage}
					error={error}
					reset={reset}

					handleAddClientConfirm={handleAddClientConfirm}
					handleAddClientAccountConfirm={handleAddClientAccountConfirm}
				/>

				{/* Modal message */}
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					className={classes.modal}
					open={open}
					onClose={handleClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500,
					}}
				>
					<Fade in={open}>
						<div className={classes.paper}>
							<h4 className="text-center" id="transition-modal-title">{text}</h4>
							{show &&
								<div>
									{showConfirm &&
										<button className="btn btn-danger btn-block" type="button"
											onClick={invitation ? handleOnClickSendInvitation : onClickConfirm}>
											Confirmar
					</button>
									}
									<button className="btn btn-primary btn-block" type="button" onClick={handleClose}>
										Volver
					</button>
								</div>
							}
						</div>
					</Fade>
				</Modal>
			</div>
		</div>

	);
}

export default index;