import React from 'react';
// import Select from 'react-select';
import {CardHeader} from "reactstrap";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { NavLink } from "react-router-dom";
import Cartola from '../../components/Cartola';
import { urlCartolaListGroup } from '../../api/urls';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    breadcrumbNavLink: {
        fontSize: '1em', 
        fontWeight: '900', 
        textTransform: 'uppercase',        
    }
}));

function Group({
    getClientCartola, clients, clientName, urlParams, cartolaProps, 
    cartolaId, backgroundColor, fetchBreakdownLoading, fetchBreakdownFailed, consolidated,
    periodStart, periodEnd, cartera, parsedCartera, cash, consolidatedByCurrency, consolidatedByAccount,
    consolidatedByType, movimientos, percentageAssetTypes, percentageCurrency    
}) {

    const classes = useStyles();

    return (
        <div>
            <CardHeader>
                <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>                    
                    <NavLink to={`${urlCartolaListGroup}${urlParams ? '?'.concat(urlParams) : ''}`} >                    
                        <div className={classes.breadcrumbNavLink}>
                            Listado Grupos
                        </div>
                    </NavLink>
                    <Typography color="textPrimary">                        
                        {clientName}
                    </Typography>
                    
                </Breadcrumbs>
            </CardHeader>

            <Cartola             
                cartolaId={cartolaId}
                backgroundColor={backgroundColor}
                fetchBreakdownLoading={fetchBreakdownLoading}
                fetchBreakdownFailed={fetchBreakdownFailed}
                consolidated={consolidated}
                periodStart={periodStart}
                periodEnd={periodEnd}
                cartera={cartera}
                parsedCartera={parsedCartera}
                cash={cash} 
                consolidatedByCurrency={consolidatedByCurrency}
                consolidatedByAccount={consolidatedByAccount}
                consolidatedByType={consolidatedByType}
                movimientos={movimientos}
                percentageAssetTypes={percentageAssetTypes}
                percentageCurrency={percentageCurrency}
                isGroup={true}
            />
        </div>
    );
}

// Client.propTypes = {
//     getClientCartola: PropTypes.func,
//     clients: PropTypes.arrayOf(PropTypes.object),
//     clientName: PropTypes.string,
//     params: PropTypes.object,
//     cartolaProps: PropTypes.object
// }

export default Group;