import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
});

const DataTable = ({info}) => {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="portafolio_actual">
                <TableHead>
                <TableRow>
                    <TableCell>Clases</TableCell>
                    <TableCell>Monedas</TableCell>   
                    <TableCell>Regiones</TableCell>             
                </TableRow>
                </TableHead>
                <TableBody>
                <TableRow>
                    <TableCell>                                            
                        {Object.keys(info.clase).map((key) => 
                            <p key={key}>{key.toUpperCase()}: {info.clase[key]}</p>
                        )}
                    </TableCell>
                    <TableCell>
                        {Object.keys(info.moneda).map((key) => 
                            <p key={key}>{key.toUpperCase()}: {info.moneda[key]}</p>
                        )}
                    </TableCell>                   
                    <TableCell>
                        {Object.keys(info.region).map((key) => 
                            <p key={key}>{key.toUpperCase()}: {info.region[key]}</p>
                        )}                       
                    </TableCell>    
                </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    
        
    );
};

export default DataTable;

