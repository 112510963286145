import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import { green } from '@material-ui/core/colors';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {Card, CardBody,	CardHeader, Row, Col} from "reactstrap";


const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
      '& input': {
        color: 'white',
        fontSize: '1.7em',
        fontFamily: "Montserrat, Helvetica Neue, Arial, sans-serif"
      },
      '& label': {
        color: 'white',
      },
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
      },
      '& .MuiInput-underline:before': {
        borderBottom: "1px solid rgb(210 210 210 / 78%)"
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: "2px solid white"
      }
    
    },
    fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
          backgroundColor: green[600],
        },
    },
  }));
  
const quickEditStyles = {
    actionButton: {
        position: "absolute",
        top: '0',
        right: '0',
        margin: '2em',
        transition: 'all .7s'
    }
}

function QuickEdit({active, content, data, setActive, save, onChangeField, pending}) {
    const classes = useStyles();
    
    if (active) {
        return(
            <div>
                <form className={classes.root} noValidate autoComplete="off">
                    <Row style={{width: '100%', margin: '0'}}>
                        <div style={{display: "block", width: "auto", margin: "0 2em 0 0"}}>
                            <TextField id="standard-basic" name="name" label="" value={data.name} onChange={onChangeField} />
                            <p style={{color: "rgb(126 168 222)"}}>Nombres</p>
                        </div>
                        <div style={{display: "block", width: "auto", margin: "0 2em 0 0"}}>
                            <TextField id="filled-basic" name="last_name" label="" value={data.last_name} onChange={onChangeField}/>
                            <p style={{color: "rgb(126 168 222)"}}>Apellidos</p>
                        </div>
                        <div style={{display: "block", width: "auto", margin: "0 2em 0 0"}}>
                            <TextField id="outlined-basic" name="email" label="" value={data.email} onChange={onChangeField}/>
                            <p style={{color: "rgb(126 168 222)"}}>Email</p>
                        </div>
                    </Row>
                </form>
                <div style={quickEditStyles.actionButton}>
                    <Fab style={{marginRight: '1em'}} size="small" className={classes.fabGreen} color={'inherit'} onClick={()=>{save(data)}}>
                        <SaveIcon />
                    </Fab>
                    <Fab color="secondary" aria-label="add" size="small" onClick={() => {setActive() }}>
                        <ClearIcon />
                    </Fab>
                    
                </div>
            </div>
        )
    } else {
        return(
            <div>
                {content}
                <div style={quickEditStyles.actionButton}>
                    <Fab size="small" aria-label="add" onClick={() => {setActive()}}>
                        <EditIcon />
                    </Fab>
                </div>
            </div>
            
        )
    }
}


export default QuickEdit;