import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { 
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    CardTitle,
    Container,
    Row,
    Col,
    FormGroup,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
} from 'reactstrap'
import { Button } from "../../../components";


const clientCardStyle = {
    header: {
        fontSize: '1.3em',
        fontWeight: '700'
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-evenly'
    }
}

const index = ({
    data = {}, pendingInfo, pagination, nextPage, prevPage, firstPage, lastPage
}) =>  {

    const itemPagination = (onClick, title) => (
        <PaginationItem>
            <PaginationLink href="#" onClick={onClick}>{title}</PaginationLink>
        </PaginationItem>
    )

    return !pendingInfo ?
        <Container>
            <Row>
                {
                    data.map((user, key) => {
                        return(
                            <Col lg={6} md={6} xs={12} className="mr-auto ml-auto" key={key}>
                                <Card className="card-lock text-center">
                                    <CardHeader>
                                        <Row style={clientCardStyle.header}>
                                            <Col style={{textAlign: 'left'}} md={7}>
                                                <h6>{user.nombre}</h6>
                                            </Col>
                                            {/* {
                                                user.rentabilidad > 0 ? <Col style={{color: 'green'}} md={5}><i style={{marginRight: '1em'}} className="now-ui-icons arrows-1_minimal-up"></i>{user.rentabilidad}%</Col> : 
                                                <Col style={{color: 'red'}} md={5}><i style={{marginRight: '1em'}} className="now-ui-icons arrows-1_minimal-down"></i>{user.rentabilidad}%</Col>
                                            } */}
                                        </Row>
                                    </CardHeader>
                                    <CardBody style={{textAlign: 'left'}}>
                                        <p>{user.patrimonio}</p>
                                        <p>{user.rut}</p>
                                    </CardBody>
                                    <hr/>
                                    <CardFooter style={clientCardStyle.footer}>
                                        <Button style={{borderRadius: '200px'}} size="sm" simple icon round>
                                            <i className="now-ui-icons ui-1_simple-add"></i>
                                        </Button>
                                        <Button style={{borderRadius: '200px'}} size="sm" simple icon round>
                                            <i className="now-ui-icons users_single-02"></i>
                                        </Button>
                                        <Button style={{borderRadius: '200px'}} size="sm" simple icon round>
                                            <i className="now-ui-icons loader_gear"></i>
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row> 

            {pagination &&
                <Pagination>                  
                    {pagination.prev_page_url && pagination.current_page > 2 &&
                        itemPagination(firstPage, "1 ...")
                    } 
                    {pagination.prev_page_url &&
                        itemPagination(prevPage, pagination.current_page - 1)
                    }                    
                    <PaginationItem active>
                        <PaginationLink href="#">{pagination.current_page}</PaginationLink>
                    </PaginationItem>

                    {pagination.next_page_url && 
                        itemPagination(nextPage, pagination.current_page + 1)
                    }                   

                    {pagination.current_page < pagination.last_page - 1  &&
                        itemPagination(lastPage, `... ${pagination.last_page}`)
                    }
                </Pagination>       
                
            }
             </Container>
            :
            <div className="text-center mt-5">
                    <CircularProgress size={50} thickness={5}/>
            </div>
    ;
}

export default index;