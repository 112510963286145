import {CARTERA_GET_INIT, CARTERA_GET_SUCCESS, CARTERA_GET_ERROR,
FUNDS_BY_ACCOUNT_GET_INIT, FUNDS_BY_ACCOUNT_GET_SUCCESS, FUNDS_BY_ACCOUNT_GET_ERROR,
REBALANCE_GET_INIT, REBALANCE_GET_SUCCESS, REBALANCE_GET_ERROR, REBALANCE_RESET,
SERIES_BY_FUND_GET_INIT, SERIES_BY_FUND_GET_SUCCESS, SERIES_BY_FUND_GET_ERROR,
ADD_NEW_FUND_REBALANCE, REMOVE_NEW_FUND_REBALANCE,
SET_ACCOUNT_SELECTED_REBALANCE, SET_FILTERS_SELECTED_REBALANCE,
REBALANCE_GET_PDF_INIT, REBALANCE_GET_PDF_SUCCESS, REBALANCE_GET_PDF_ERROR,
REBALANCE_SAVE_SELECTED_PORTAFOLIO_INIT, REBALANCE_SAVE_SELECTED_PORTAFOLIO_SUCCESS,
REBALANCE_SAVE_SELECTED_PORTAFOLIO_ERROR, REBALANCE_ACCOUNT_TABLE_STATE
} from '../constants';

const initialState = {
    accounts        : [],
    filterData      : [],
    filters         : [
        ['admin'          , ''],        
        ['apv'            , ''],        
        ['tipoCuenta'     , ''],
        ['currency'       , ''],
        ['country'        , ''], 
        ['region'         , ''],  
        ['serie'          , ''],  
        ['nombre_instrumento', ''],         
        ['fondo'          , ''],
        ['patrimonio'     , ''],
        ['perfilRiesgo'   , ''],       
        ['edad'           , ''],        
        ['asesor'         , '']
    ],
    tableState: {		
        order : "desc",
		orderBy : "patrimonio",
		page : 0,
		dense : false,
		searchValue : '',
		rowsPerPage : 5,
		selected : []
    }, 
    accountsSelected: [],
    portafolioSelected: [],
    fundsByAccount  : [],
    infoAccount     : null,
    fundsByAdmins   : [],
    seriesByFund    : [],
    newFunds        : [],
    rebalance       : [],
    restrictions    : null,
    error           : null,
    isLoading       : true,
    accountsLoading : true,
    fundsLoading    : true,
    seriesLoading   : true,
    response        : null
}

const reb = (state = initialState, action) => {
    switch (action.type) {
        case CARTERA_GET_INIT:
            return {
                ...state,
                accountsLoading: true,                
                error: null
            };
        case CARTERA_GET_SUCCESS:
            return {
                ...state,
                accounts: action.payload.accounts.data.cartera,
                filterData: action.payload.accounts.data.filterData,
                accountsLoading: false,
                error: null
            };
        case CARTERA_GET_ERROR:
            return {
                ...state,
                // accountsLoading: false,
                error: action.payload.error
            };

        case SET_ACCOUNT_SELECTED_REBALANCE:
            return {
                ...state,
                accountsSelected: action.payload.account,
                portafolioSelected: [],
                newFunds        : [],
                restrictions    : null,
                fundsLoading    : true
            };

        case SET_FILTERS_SELECTED_REBALANCE:
            const newfilter = new Map(state.filters);
            newfilter.set(action.payload.filters.key, action.payload.filters.value);
            return {
                ...state,
                filters: Array.from(newfilter)                
            };


        case REBALANCE_ACCOUNT_TABLE_STATE:
            return {
                ...state,
                tableState: action.payload.state
            }
           

        case FUNDS_BY_ACCOUNT_GET_INIT:
            return {
                ...state,
                fundsByAccount: [],
                fundsByAdmins: [],
                fundsLoading: true,
                error: null
            };
        case FUNDS_BY_ACCOUNT_GET_SUCCESS:
            return {
                ...state,
                fundsByAccount: action.payload.funds.data.fundsCuenta,
                fundsByAdmins: action.payload.funds.data.fundsAdmins,
                infoAccount: action.payload.funds.data.info,
                fundsLoading: false,
                error: null
            };
        case FUNDS_BY_ACCOUNT_GET_ERROR:
            return {
                ...state,
                fundsLoading: false,
                fundsByAccount: [],
                fundsByAdmins: [],
                infoAccount: null,
                error: action.payload.error
            };


        case SERIES_BY_FUND_GET_INIT:
            return {
                ...state,
                seriesByFund: [],
                seriesLoading: true,
                error: null
            };
        case SERIES_BY_FUND_GET_SUCCESS:
            return {
                ...state,
                seriesByFund: action.payload.series.data,
                seriesLoading: false,
                error: null
            };
        case SERIES_BY_FUND_GET_ERROR:
            return {
                ...state,
                seriesLoading: false,
                error: action.payload.error
            };


        case ADD_NEW_FUND_REBALANCE:
            return {
                ...state,
                newFunds: [...state.newFunds, action.payload.fund] ,
                fundsByAdmins: state.fundsByAdmins.filter(x=> x.rut != action.payload.fund.rut),
            };
        case REMOVE_NEW_FUND_REBALANCE:
            return {
                ...state,
                newFunds: state.newFunds.filter(x=> x.rut != action.payload.fund.rut),
                fundsByAdmins:  [...state.fundsByAdmins, action.payload.fund] ,
            };



        case REBALANCE_GET_INIT:
            return {
                ...state,
                restrictions: action.payload.restrictions,
                isLoading: true,
                error: null
            };
        case REBALANCE_GET_SUCCESS:
            return {
                ...state,
                rebalance: action.payload.rebalance.data,
                isLoading: false,
                error: null
            };
        case REBALANCE_GET_ERROR:
            return {
                ...state,
                rebalance: [],
                isLoading: false,
                error: action.payload.error
            };


            
        case REBALANCE_GET_PDF_INIT:
            return {
                ...state,
                error: null,
                response: null
            };
        case REBALANCE_GET_PDF_SUCCESS:
            return {
                ...state,
                response: action.payload.response,                
            };
        case REBALANCE_GET_PDF_ERROR:
            return {
                ...state,
                error: action.payload.error,
                response: action.payload.response
            };



        case REBALANCE_SAVE_SELECTED_PORTAFOLIO_INIT:
            return {
                ...state,
                portafolioSelected: action.payload.portafolio,
                error: null,
                response: null
            };
        case REBALANCE_SAVE_SELECTED_PORTAFOLIO_SUCCESS:
            return {
                ...state,
                response: action.payload.response,                
            };
        case REBALANCE_SAVE_SELECTED_PORTAFOLIO_ERROR:
            return {
                ...state,
                error: action.payload.error,
                response: action.payload.response
            };





        case REBALANCE_RESET:
            return {
                ...state,
                fundsByAccount: [],
                fundsByAdmins: [],
                rebalance: [],
                restrictions: null,
                error: null,
                isLoading: true
            }
        default:
            return state;
    }
}

export default reb;