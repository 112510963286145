import React, {useState} from 'react';
import { LockClosedIcon } from '@heroicons/react/solid'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

function ForgotPasswordForm ({submit, loginForm, pending, forgotPasswordMsg}) {

    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        submit({email});
    }

    return <div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Restablecer contraseña</h2>
         <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
           
            <div className="rounded-md shadow-sm -space-y-px">
                <div>
                    <label htmlFor="email-address" className="sr-only">
                        Correo electronico
                    </label>
                    <input
                        id="email-address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Correo electronico"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>                
            </div>
            
            <div style={{ color: 'red', fontWeight: 'bold', textAlign: 'center' }}>
                {forgotPasswordMsg}
            </div>

            <div className="text-center">
                <div className="text-sm" onClick={loginForm}>
                    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                        Volver
                    </a>
                </div>
            </div>

            <div>
                <button
                    disabled={pending ? 'disabled' : ''}
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Enviar enlace para restablecer contraseña
                </button>
            </div>

        </form> 
    </div>
}

export default ForgotPasswordForm;