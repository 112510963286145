import React from 'react';
import { connect } from 'react-redux';
import Login from '../../components/Login/login';
import {getTenantInfo, login, forgotPassword} from "../../actions/login";
import { Route, Switch, withRouter } from 'react-router-dom';
import { addRouterHistory } from '../../actions/updateUrl'

import LoginForm from '../../components/Login/LoginForm';
import ForgotPasswordForm from '../../components/Login/ForgotPasswordForm';

class LoginContainer extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            loginForm: true,            
        }
        this.showForgotPasswordForm = this.showForgotPasswordForm.bind(this);
    }

    componentDidMount() {
        this.props.actions.addRouterHistory(this.props.history);
        console.log(this.props)
        this.props.actions.getTenantInfo();
    }

    showForgotPasswordForm() {
        this.setState({
            loginForm: !this.state.loginForm,            
        })
    }

    render() {
        return(
            <Login {...this.props.tenant}>
                {this.state.loginForm ? 
                    <LoginForm 
                        {...this.props.tenant}
                        submitLogin={this.props.actions.login} 
                        forgotPasswordForm={this.showForgotPasswordForm}                   
                    />
                    :
                    <ForgotPasswordForm 
                        pending={this.props.tenant.pending}
                        forgotPasswordMsg={this.props.tenant.forgotPasswordMsg}
                        submit={this.props.actions.forgotPassword}
                        loginForm={this.showForgotPasswordForm}
                    />
                }
            </Login>
        )
    }
}


export default withRouter(connect(
    state => ({
        tenant: state.login
    }),
    dispatch => ({
        actions: {
            getTenantInfo: () => dispatch(getTenantInfo()),
            login: (obj) => dispatch(login(obj)),
            addRouterHistory: history => dispatch(addRouterHistory(history)),
            forgotPassword: obj => dispatch(forgotPassword(obj))
        }
    })
)(LoginContainer));