import Rebalancer from "../views/Pages/Rebalancer.jsx";


const pagesRoutes = [
  
  {
    path: "/qip/rebalancer",
    name: "Rebalancer",
    short: "REB",
    mini: "RB",
    icon: "business_money-coins",
    invisible: true,
    component: Rebalancer
  }
  
];

export default pagesRoutes;