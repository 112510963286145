import React from 'react';

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
} from "reactstrap";

import {
    CardCategory,
} from "../../components";

import slide1bg from '../../assets/img/onboarding/SVG/slide1-bg.svg';

export default function Slide3() {

    return (
        <div className="content">
            <Row style={{width: "50vw"}}>
                <Col xs={12} md={8} className="pull-left" style={{marginLeft: "20%"}}>
                    <Card className="card-plain">
                        <CardHeader>
                            <CardCategory>Exportación de Reportes</CardCategory>
                            <CardTitle tag="h3" style={{color: "#27ae60"}}>Ve tu universo de inversion desde una pura parte.</CardTitle>
                       </CardHeader>
                        <CardBody>
                            <img height="500" src={slide1bg}></img>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}