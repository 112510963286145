import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {MATERIAL_TABLE_LOCALIZATION} from './../../../constants';
import { rutFormat, patrimonyFormat } from '../../../helpers/formatter';

const clpFormatter = new Intl.NumberFormat('es-ES', {
    minimumFractionDigits: 0
});


class Table extends Component {

    constructor(props) {
        super(props);
        this.state = {	  
            columns: [
				// { title: 'Id', field: 'id' },
				{ title: 'Rut', field: 'rut', width: 100, render: ({rut}) => <span>{rutFormat(rut)}</span> },
                { title: 'Cuenta', field: 'cuenta', width: 100 },
                { title: 'Administradora', field: 'administradora', width: 100 },
                // { title: 'Tipo', field: 'type' },
                { title: 'Nombre', field: 'name', width: 200 },         
                { title: 'Apellido', field: 'last_name', width: 200 },  
                { title: 'Email', field: 'email', width: 100 }, 
                { title: 'Fecha', field: 'fecha', width: 100 },         
                // { title: 'Monto', field: 'monto_valorizado_clp', type: 'currency' },
                { title: 'Patrimonio', field: 'patrimonio', width: 100, type: 'currency', 
                    render: ({patrimonio}) => (<span>$ {patrimonyFormat(patrimonio)}</span>)                 
                },         
			],
        }
        this.theme = createMuiTheme({
            palette: {
              primary: {
                main: '#4caf50',
              },
              secondary: {
                light: '#01579b',
                main: '#ff9100',
              },       
            },      
        });
        this.handleRowClicked = this.handleRowClicked.bind(this);
        this.handleRowSelected = this.handleRowSelected.bind(this);        
    }

    handleRowClicked(selectedRow){
        // this.props.onClick(selectedRow)
        console.log(selectedRow)
        this.props.onClick(selectedRow)
    }

    handleRowSelected(rows){       
        // this.props.onClick(rows)       
    }
    
    render() {
        return (
        <MuiThemeProvider theme={this.theme}>
            <MaterialTable
                title="Cuentas"
                columns={this.state.columns}
                data = {this.props.data}   
                isLoading= {this.props.isLoading}              
                options={{
                    // selection: true,
                    sorting: true,
                    exportButton: true,
                    rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#37b15933' : '' })
                }}
                // onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}                
                onRowClick={((evt, selectedRow) => this.handleRowClicked(selectedRow) )}                
                // onSelectionChange={(rows) => alert('You selected ' + rows.length + ' rows ')}
                onSelectionChange={(rows) => this.handleRowSelected(rows)}
                localization = {MATERIAL_TABLE_LOCALIZATION}
            />
        </MuiThemeProvider>
        );
    }
}

export default Table;