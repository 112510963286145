export const rutFormat = rut => {
    if(rut){
        const myRut = rut.split('-');
        const mantisa = new Intl.NumberFormat('es-CL').format(myRut[0]);
        const dv = myRut[1];
        return `${mantisa}-${dv}`
    }else{
        return rut
    }    
}

export const patrimonyFormat = patrimony => '$ '.concat(new Intl.NumberFormat('es-CL').format(Math.floor(patrimony)))

export const patrimonyFormatNotSymbol = patrimony => patrimony ? new Intl.NumberFormat('es-CL').format(Math.floor(patrimony)) : '-';

export const quantityFormat = quantity => new Intl.NumberFormat('es-CL').format(quantity)

export const patrimonyFormatWithDecimal = quantity => '$ '.concat(new Intl.NumberFormat('es-CL').format(quantity));

export const dateFormat = date => {
    try {
        return new Intl.DateTimeFormat('es-CL').format(new Date(date));
    } catch (error) {
        return date;
    }    
}; 
// ONLY FOR TABLE PARAMS
export const urlParamsFormat = tableState => {
    const {searchValue, order, orderBy, page, dense, rowsPerPage} = tableState;    
    const paramOrder = order ? `order=${order}` : '';
    const paramOrderBy = orderBy ? `&by=${orderBy}` : '';
    const paramRowsPerPage = rowsPerPage ? `&per=${rowsPerPage}` : '';    
    const paramDense = dense ? `&adjust=${dense}` : '';            
    const paramPage = page ? `&page=${page}` : '';
    const paramSearch = searchValue ? `&search=${searchValue}` : '';
    return `${paramOrder}${paramOrderBy}${paramRowsPerPage}${paramDense}${paramPage}${paramSearch}`;
}
// ONLY FOR TABLE PARAMS
export const urlFormatFromParams = params => {
    const {search, order, by, page, adjust, per} = params;    
    const paramOrder = order ? `order=${order}` : '';
    const paramOrderBy = by ? `&by=${by}` : '';
    const paramRowsPerPage = per ? `&per=${per}` : '';
    const paramDense = adjust ? `&adjust=${adjust}` : ''; 
    const paramPage = page ? `&page=${page}` : '';
    const paramSearch = search ? `&search=${search}` : '';
    return `${paramOrder}${paramOrderBy}${paramRowsPerPage}${paramDense}${paramPage}${paramSearch}`;
}

export const percentFormat = percent => {
    return percent + '%';
}

export const percentFormatOneDigit = percent => percent ? new Intl.NumberFormat('es-ES', {
    maximumFractionDigits: 1
}).format(percent) + '%': '-';