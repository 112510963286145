import React from 'react';
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableAccount from './TableAccount';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 600,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

const Portafolio = ({funds, info, handleExportPdf, pdfData, accountSelected, getResponse,
    seriesNotFound, seriesNotFoundPesos}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        handleExportPdf(pdfData)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const closeButton = () =>(
        <button className="btn btn-primary btn-block" type="button" onClick={handleClose}>
            Cerrar
        </button>
    )

    return (
        <div>            
            <div className="alert alert-primary mb-0 pt-0 pb-0">

                <Row>
                    <Col md={6}>
                        <h5 className="mt-3">Información de Portafolio</h5>
                    </Col>
                    <Col md={6}>
                    <div className="text-right">
                        <button className="btn btn-success m-3" onClick={handleOpen}>EXPORTAR A PDF</button>
                    </div>
                    </Col>
                </Row>
            </div>
            <Row> 
                <Col md={12}>
                    <Card>                        
                        <h3>Cuenta</h3>
                        <CardBody>
                            <TableAccount account={accountSelected} />
                        </CardBody>
                    </Card>

                    <Card>                        
                        <div className="alert alert-info">Portafolio Actual</div>
                        <CardBody>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="portafolio_actual">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Rut</TableCell>
                                        <TableCell>Nombres</TableCell>
                                        <TableCell>Series</TableCell>  
                                        <TableCell>Montos</TableCell>     
                                        <TableCell>Dist.%</TableCell>               
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {funds.map((row, i) => {
                                        if(row.nombre_instrumento != "NEW_FUNDS"){
                                            return (<TableRow key={i}>                                                
                                                <TableCell>{row.f}</TableCell>
                                                <TableCell>{row.rut}</TableCell>                                                
                                                <TableCell>{row.entidad}</TableCell>
                                                <TableCell>{row.serie}</TableCell>   
                                                <TableCell>{row.monto}</TableCell> 
                                                <TableCell>{row.porcentaje}</TableCell>                                             
                                            </TableRow>)
                                        }
                                    })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardBody>
                    </Card>
                                    
                    {seriesNotFound.length > 0 &&
                        <Card>                        
                            <div className="alert alert-info">Fondos no encontrados</div>
                            <CardBody>                                
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} size="small" aria-label="portafolio_actual">
                                        <TableHead>
                                        <TableRow>
                                            <TableCell>Rut</TableCell>
                                            <TableCell>Nombres</TableCell>  
                                            <TableCell>Dist.%</TableCell>              
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {seriesNotFound.map((row, i) => {
                                            return (<TableRow key={i}>  
                                                <TableCell>{row.rut}</TableCell>                                                
                                                <TableCell>{row.entidad}</TableCell>
                                                <TableCell>{row.porcentaje}</TableCell>
                                            </TableRow>)
                                        })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardBody>
                        </Card>                       
                    }

                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Card>                        
                        <div className="alert alert-info">Nuevos Fondos Agregados</div>
                        <CardBody>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="portafolio_actual">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Rut</TableCell>
                                        <TableCell>Nombres</TableCell>
                                        <TableCell>Series</TableCell>               
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {funds.map((row, i) => {                                       
                                        if(row.nombre_instrumento == "NEW_FUNDS"){
                                            return (<TableRow key={i}>
                                                    <TableCell>{row.f}</TableCell>
                                                    <TableCell>{row.rut}</TableCell>                                                   
                                                    <TableCell>{row.entidad}</TableCell>
                                                    <TableCell>{row.serie}</TableCell>                 
                                                </TableRow>)
                                        }                                        
                                    })}                                   
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card>                        
                        <div className="alert alert-info">Información del Portafolio</div>
                        <CardBody>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="portafolio_actual">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>Clases</TableCell>
                                        <TableCell>Monedas</TableCell>   
                                        <TableCell>Regiones</TableCell>             
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    <TableRow>
                                        <TableCell>                                            
                                            {Object.keys(info.clase).map((key) => 
                                                <p key={key}>{key.toUpperCase()}: {info.clase[key]}</p>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {Object.keys(info.moneda).map((key) => 
                                                <p key={key}>{key.toUpperCase()}: {info.moneda[key]}</p>
                                            )}
                                        </TableCell>                   
                                        <TableCell>
                                            {Object.keys(info.region).map((key) => 
                                                <p key={key}>{key.toUpperCase()}: {info.region[key]}</p>
                                            )}                       
                                        </TableCell>    
                                    </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                <div className={classes.paper}>
                    
                    {getResponse == null ?
                        <h4 className="text-center" id="transition-modal-title">Exportando a PDF</h4>
                        :
                        getResponse == '200' ?
                        <div>
                            <h4 className="text-center">El PDF ha sido descargado</h4>
                            {closeButton()}
                        </div>
                        :
                        <div>
                            <h4 className="text-center">Error al descargar PDF</h4>
                            {closeButton()}
                        </div>
                    } 
                   
                </div>
                </Fade>
            </Modal>

        </div>
    );
};

export default Portafolio;