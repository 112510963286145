import React from 'react';
import {Card, CardBody,	CardHeader, Row, Col} from "reactstrap";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import TableClients from './TableClients';
import TableStaff from './TableStaff';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { NavLink } from "react-router-dom";
import { urlClientsHome } from '../../../api/urls';

const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

const index = ({dataClient, dataStaff, onclickAssign, message, pending}) => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
  
    const handleOpen = () => {
        setOpen(true);
    };

    const handleAssignOpenModal = (staff, clients) => {
        setOpen(true);
        onclickAssign(staff, clients)
      };
  
    const handleClose = () => {
        setOpen(false);
    };
    
    return (

        <div>
            <Card>
                <CardHeader>
                    <Breadcrumbs aria-label="breadcrumb">
                        <NavLink to={urlClientsHome}>Clientes</NavLink>
                        <Typography color="textPrimary">Asignar Asesor</Typography>
                    </Breadcrumbs>
                </CardHeader>

                <CardBody>                
                    <Row>
                        <Col md={8}>
                            <Card>
                                <CardHeader className="text-center"><h5>Asignación de Asesores</h5></CardHeader>
                                <CardBody>                                    
                                    <TableStaff data={dataStaff} clients={dataClient} onClick={handleAssignOpenModal} pending={pending}/>
                                </CardBody>
                            </Card>                
                        </Col>
                        <Col md={4}>
                            <Card>
                                <CardHeader className="text-center"><h5>Clientes</h5></CardHeader>
                                <CardBody>
                                    <TableClients data={dataClient} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>                    
                </CardBody>
            </Card>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                <div className={classes.paper}>
                    <h4 className="text-center" id="transition-modal-title">{message}</h4>
                    <NavLink to='/dashboard/clients/home'>
                        <button className="btn btn-primary btn-block" type="button">
                            Volver a Clientes
                        </button>
                    </NavLink>
                </div>
                </Fade>
            </Modal>

        </div>
    );
};

export default index;