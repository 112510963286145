import React from 'react';
import Table from './Table';
import FilterMenu from './FilterMenu';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";
import {rutFormat, patrimonyFormat} from '../../helpers/formatter'
import TableMaterialUi from '../TableMaterialUi'
import { AccountCircle, AddCircle, CancelRounded } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import {
	PanelHeader,
	Header
} from "../../components";



const headCells = [       
    { id: 'rut', numeric: false, disablePadding: false, label: 'Rut', formatter:rutFormat }, 
    { id: 'name', numeric: false, disablePadding: true, label: 'Nombre', padding:"none" },
    { id: 'last_name', numeric: false, disablePadding: true, label: 'Apellido', padding:"none" },
    { id: 'email', numeric: false, disablePadding: true, label: 'Email', padding:"none" },
    { id: 'patrimony', numeric: true, disablePadding: false, label: 'Patrimonio', align:"right", formatter:patrimonyFormat }
    ];

const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

function StaffComponent ({data, onClick, assignClients, 
	// onClickClientsOut, 
	message, reset, pending,
	handleAddStaff, actionMessage, error
}) {	
	
	const [tableState, setTableState] = React.useState({		
        order : "desc",
        orderBy : "patrimonio",
        page : 0,
        dense : false,
        searchValue : '',
        rowsPerPage : 5,
        selected : []
    })

    const getTableState = state => {	
		if (state.selected.length > 0){
			showButtons();
		}else{
			hideButtons();
		}
		setTableState(state)
    }

	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [showIconButtons, setShowIconButtons] = React.useState(true);
    const [text, setText] = React.useState('');
	const [show, setButton] = React.useState(false);
	const [showConfirm, setButtonConfirm] = React.useState(false);
	const [staff, setStaff] = React.useState(null);
	const [openConfirm, setOpenConfirm] = React.useState(false);

	React.useEffect(() => {
        if(message){
            setButtonConfirm(false)
            setButton(true)
            setText(message);
        } 
    }, [message]);

	const showButtons = () => {
		setShowIconButtons(false);
	}

	const hideButtons = () => {
		setShowIconButtons(true);
	}

    // const handleOpen = () => {
    //     setOpen(true);
    // };

    // const handleAssignOpenModal = (staff) => {
	// 	console.log(staff)
	// 	setStaff(staff);
	// 	setButtonConfirm(true);
	// 	setButton(true);	
    //     setText('Confirmar desasignación de los clientes');
    //     setOpenConfirm(true);
    //   };
  
    // const handleClose = () => {
	// 	setOpenConfirm(false);
	// 	reset()
	// };

	// const onClickConfirm = () => {

	// 	console.log('Desasignando Cliente(s)...', staff)

	// 	setButton(false);
	// 	setText('Desasignando Cliente(s)...');
	// 	onClickClientsOut(staff, null)
	// }

	//#region Modal Nuevo Cliente
	const handleAddStaffModal = () => {
		setOpen(true);
	}

	const handleAddStaffConfirm = () => {
		if(error == 0) {
			handleCloseModal();
			return
		}
		const rut = document.getElementById("rutForm").value;
		const name = document.getElementById("nameForm").value;
		const last_name = document.getElementById("last_nameForm").value;
		const email = document.getElementById("emailForm").value;
		
		handleAddStaff({rut, name, last_name, email});        
	}

	const handleCloseModal = () => {
		setOpen(false);	
		reset()
	};
	//#endregion


	return (
	<div>
		<CardHeader>
			<Breadcrumbs aria-label="breadcrumb">
				<Typography color="textPrimary">Asesores</Typography>
				<Typography color="textPrimary"></Typography>
			</Breadcrumbs>
		</CardHeader>

		<PanelHeader size="sm" content={
			<div style={{padding: "0 4em"}}>
				<h3 style={{
					color: '#fff', marginBottom: '.3em'
				}}>
					Equipo	
				</h3>
				<span style={{color: '#e6dede'}}>Asignar clientes, crear integrantes</span>
			</div>
		}>			
		</PanelHeader>

		<div class="content" style={{marginTop: "1em"}}>

			<Collapse in={actionMessage ? true : false}>
				<Alert
					severity={error ? "error": "success"}
					action={
						<IconButton
						aria-label="close"
						color="inherit"
						size="small"
						onClick={() => {
							reset();
						}}
						>
						<CloseIcon fontSize="inherit" />
						</IconButton>
					}
				>
					{actionMessage}
				</Alert>
			</Collapse>
			
			<Row>
				<Col md={12}>
					{/* <Table data={data} 
						onClick={onClick} 
						assignClients={assignClients}
						onClickClientsOut={handleAssignOpenModal}
						pending={pending}
						handleAddStaff={handleAddStaff}
						reset={reset}
						actionMessage={actionMessage}						
						error={error}
						// handleRemoveStaff={handleRemoveStaff}
					></Table> */}
				
					<Tooltip title="Agregar Asesor" >
						<IconButton
							disabled = {false}
							aria-label="Agregar Asesor"
							color="inherit"
							size="medium"
							onClick= {() => {handleAddStaffModal()}}
						>
							<AddCircle fontSize="inherit" />
						</IconButton>
					</Tooltip>

					<Tooltip title="Asignar Clientes" >
						<IconButton
							disabled = {showIconButtons}
							aria-label="Asignar Clientes"
							color="inherit"
							size="medium"
							onClick= {() => {assignClients(tableState.selected)}}
						>
							<AccountCircle fontSize="inherit" />
						</IconButton>
					</Tooltip>						

					{/* <Tooltip title="Desasignar Clientes" >
						<IconButton
							disabled = {showIconButtons}
							aria-label="Desasignar Clientes"
							color="inherit"
							size="medium"
							onClick= {() => {handleAssignOpenModal(tableState.selected) }}
						>
							<CancelRounded fontSize="inherit" />
						</IconButton>
					</Tooltip> */}

					<TableMaterialUi 
						title={'Asesores'}
						rows={data} 
						headCells={headCells} 
						withCheckbox={true} 
						onClickRow={onClick}
						loading={pending}
						updateState={getTableState}    //TableMaterialUi State
						initialState={tableState}
					/>
				</Col>
				{/* <Col md={3}>
					<FilterMenu></FilterMenu>
				</Col> */}
			</Row>
			
			
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={open}
				onClose={handleCloseModal}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
				timeout: 500,
				}}
				>
				<Fade in={open}>
				<div className={classes.paper}>			
					<h5 id="transition-modal-title">Agregar Asesor</h5>
					<hr />

					<Collapse in={actionMessage ? true : false}>
						<Alert
							severity={error ? "error": "success"}
							action={
								<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									reset();
								}}
								>
								<CloseIcon fontSize="inherit" />
								</IconButton>
							}
						>
							{actionMessage}
						</Alert>
					</Collapse>

					<div>
						<form className={classes.root} noValidate autoComplete="off">
							<TextField id="rutForm" label="Rut"/> 
							<TextField id="nameForm" label="Nombre"/>
							<TextField id="last_nameForm" label="Apellido"/>
							<TextField id="emailForm" label="Email" type="email"/>                    
						</form>                
					</div>

					<hr />

					<Row>
						<Col md={12}>
							<Button variant="contained" onClick={handleCloseModal}>Cerrar</Button>
							<Button variant="contained" color="primary" 
								onClick={handleAddStaffConfirm}
								className="float-right"
							>Aceptar</Button>
						</Col>
					</Row>
					
				</div>
				</Fade>
			</Modal>
			
			{/* <Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={openConfirm}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
				timeout: 500,
				}}
				>
				<Fade in={openConfirm}>
				<div className={classes.paper}>
					<h4 className="text-center" id="transition-modal-title">{text}</h4>
					{show &&
						<div>
							{showConfirm &&
							<button className="btn btn-danger btn-block" type="button" onClick={onClickConfirm}>
								Confirmar
							</button>
							}
							<button className="btn btn-primary btn-block" type="button" onClick={handleClose}>
								Volver
							</button>
						</div>
					}
				</div>
				</Fade>
			</Modal> */}
				
		</div>
	</div>
	);
}

export default StaffComponent;