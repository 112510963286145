import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
});

const DataTable = ({account}) => {
    const classes = useStyles();

    console.log(account)
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="fondos">
                <TableHead>
                <TableRow>
                    <TableCell>Rut</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Cuenta</TableCell>
                    <TableCell>Administradora</TableCell>     
                    <TableCell>Tipo</TableCell> 
                    <TableCell>Apv-No Apv</TableCell>  
                    <TableCell>Moneda</TableCell>            
                    <TableCell>País</TableCell> 
                    <TableCell>Región</TableCell> 
                    <TableCell>Patrimonio</TableCell> 
                </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{account.rut}</TableCell>
                        <TableCell>{account.name} {account.last_name}</TableCell>
                        <TableCell>{account.cuenta}</TableCell>
                        <TableCell>{account.administradora}</TableCell> 
                        <TableCell>{account.type}</TableCell>    
                        <TableCell>{account.categoria}</TableCell>    
                        <TableCell>{account.currency}</TableCell> 
                        <TableCell>{account.country}</TableCell> 
                        <TableCell>{account.region}</TableCell> 
                        <TableCell>${account.patrimonio && parseFloat(account.patrimonio).toFixed(2)}</TableCell>                  
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DataTable;

