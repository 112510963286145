import React from 'react';
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";
import TableFunds from './TableFunds';
import TableNewFunds from './TableNewFunds';
import TableAccount from './TableAccount';
import TableAccountInfo from './TableAccountInfo';
import RestrictionInfo from './RestrictionInfo';
import CircularProgress from '@material-ui/core/CircularProgress';

const index = ({accountSelected, restrictionsSelected, funds, newFunds, isLoading, info}) => {  
    
    const [progress, setProgress] = React.useState(0);
    React.useEffect(() => {
        function tick() {
        // reset when reaching 100%
        setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
        }

        const timer = setInterval(tick, 20);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (    
        <Card>
            <Card>
                <CardHeader><h5>Cuenta</h5></CardHeader>
                <CardBody>
                    <TableAccount account={accountSelected} />
                </CardBody>
            </Card>
            <CardBody>
            <Row>                  
                <Card>
                    <CardHeader><h5>Fondos</h5></CardHeader>
                    <CardBody>
                        {isLoading ? 
                        <div className="text-center">
                            <h4>Cargando...</h4>
                            <CircularProgress variant="determinate" value={progress} />
                        </div>
                        :
                            <TableFunds funds={funds}/>
                        }
                    </CardBody>
                </Card>
                <Col md={2}>
                    <Card>
                        <CardHeader><h5 className="mb-0">Restricciones</h5></CardHeader>
                        <CardBody className="pt-0">
                            <RestrictionInfo restrictionsSelected={restrictionsSelected}/>
                        </CardBody>
                    </Card>
                </Col> 
                <Col md={5}>
                    
                    <Card>
                        <CardHeader><h5>Nuevos Fondos</h5></CardHeader>
                        <CardBody>
                        {newFunds.length > 0 ?
                            <TableNewFunds funds={newFunds} />
                        :                            
                            <p className="text-center">Sin información</p>
                        }   
                        </CardBody>
                    </Card>
                    

                    
                </Col>


                <Col md={5}>
                    <Card>        
                        <CardHeader><h5>Información del Portafolio</h5></CardHeader>                
                        {/* <div className="alert alert-info">Información del Portafolio</div> */}
                        <CardBody>
                            {isLoading ? 
                            <div className="text-center">
                                <h4>Cargando...</h4>
                                <CircularProgress variant="determinate" value={progress} />
                            </div>
                            :
                                <TableAccountInfo info={info}/>
                            }
                            
                        </CardBody>
                    </Card>
                </Col>


            </Row>
    

            </CardBody>
        </Card>
    );
};

export default index;