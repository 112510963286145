import React, {Component, useEffect} from 'react';
import Cuentas from './Cuentas';
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const index = ({data, onClick, isLoading, getTableState, tableState}) => {

    let linkFocus = null;
    useEffect(()=>{
        linkFocus.focus();
    }, [])

    return (

        <Card>
            <CardHeader>
                <Breadcrumbs aria-label="breadcrumb">
                    {/* <Typography color="textPrimary">Historial Rebalanceador</Typography> */}
                    <Link style={{pointerEvents: 'none', color:'black'}} 
                    color="inherit" 
                    href="/"                     
                    ref={(link) => { linkFocus = link; }} >
                            Historial Rebalanceador
                    </Link>
                </Breadcrumbs>
            </CardHeader>
            <CardBody>
        
            <Cuentas 
                data={data} 
                onClick={onClick} 
                isLoading={isLoading} 
                getTableState={getTableState}
                tableState={tableState}
            />

            </CardBody>
        </Card>

    );
};

export default index;