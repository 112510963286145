import React, { Component } from 'react';
import { connect } from 'react-redux';
import Rebalance from '../../components/Rebalance';
import SelectedPortafolio from '../../components/Rebalance/SelectedPortafolio';
import {
    getAccounts, 
    rebalanceAccounts, 
    resetRebalance,
    setAccountSelected,
    getPdfRebalance,
    saveSelectedPortafolio, 
    setTableStateAccount
} from '../../actions/rebalance';
import {
    getAllAccounts, 
    getAllFundsByAccount, 
    getRebalanceResponse, 
    isLoading, 
    accountsLoading, 
    accountSelected,
    getNewFundsRebalance,
    fundsLoading,
    getError,
    getCodeRebalance,
    getPortafolioActual,
    getResponse,
    getPortafolioSelected,
    infoAccount
} from '../../selectors/rebalance';

const restrictions = [
    {n_years: [
        {id: 1, name: 1},
        {id: 2, name: 2},
        {id: 3, name: 3},
        {id: 4, name: 4},
        {id: 5, name: 5},
        {id: 6, name: 6},
        {id: 7, name: 7},
        {id: 8, name: 8},
        {id: 9, name: 9},
        {id: 10, name: 10},
    ]},
    {recom_ports: [
        {id: 3, name: 3},
        {id: 4, name: 4},
        {id: 5, name: 5},
        {id: 6, name: 6},
        {id: 7, name: 7},
        {id: 8, name: 8},
        {id: 9, name: 9},
        {id: 10, name: 10},
    ]},
    {n_portafolios: [
        {id: 'Conservadora',name: 'Conservadora'},
        {id: 'Moderada',    name: 'Moderada'},
        {id: 'Balanceada',  name: 'Balanceada'},
        {id: 'Crecimiento', name: 'Crecimiento'},
        {id: 'Agresiva',    name: 'Agresiva'},
    ]},
    {libreRiesgo: [
        {id: 'Opción 1', name: 'Opción 1'},
        {id: 'Opción 2', name: 'Opción 2'},
        {id: 'Opción 3', name: 'Opción 3'},
    ]}
]

class index extends Component {

    constructor(){
        super()
        this.state = {
            page: 0,
            step: 0,
            selectedPortafolio: null,
            comparePortafolio: null
        }
        this.setRebalance = this.setRebalance.bind(this);
        this.reset = this.reset.bind(this);
        this.setAccount = this.setAccount.bind(this);
        this.pdfRebalance = this.pdfRebalance.bind(this);
        this.handleSelectedPort = this.handleSelectedPort.bind(this);
        this.handleReturn = this.handleReturn.bind(this);
        this.handleConfirmSelectedPortafolio = this.handleConfirmSelectedPortafolio.bind(this);
    }

    componentDidMount(){
        this.props.getAccounts()
    }

    componentDidUpdate(prevState){        
        if(prevState.rebalanceData != this.props.rebalanceData){
            this.setState({resultIsLoading: false})
        }
    }

    setRebalance(restrictionsSelected){     
        this.props.rebalanceAccounts(restrictionsSelected)
    }

    reset(){
        this.props.reset()
    }

    setAccount(account){
        this.props.setAccountSelected(account)
    }

    pdfRebalance(data){
        this.props.getPdfRebalance(data);
    }

    handleSelectedPort(selected){ 
        
        const port = []
        const actualPort = [...this.props.portafolioActual]
        let totalActual = 0;
        let totalNew = 0;
        actualPort.forEach(actual => {            
            const newRow = {};
            newRow.f = actual.f;
            newRow.rut = actual.rut;
            //actualPorcentaje: Reemplazar , a . - eliminar simbolo de % - Redondear
            var actualPorcentaje = Math.round( actual.porcentaje.replace(',','.').slice(0, -1) );
            newRow.porcentaje = actualPorcentaje + '%';            
            newRow.actualGraph = actualPorcentaje;
            newRow.entidad = actual.nombre_instrumento === "NEW_FUNDS" ?
                                `${actual.entidad} (NUEVO)`:
                                actual.entidad;
            newRow.serie = actual.serie;
            selected[0].forEach(nuevo => {
                if(actual.rut === nuevo.rut) {
                    newRow.weight = Math.round( (nuevo.weight * 100).toFixed(2) ) + '%';
                    newRow.newGraph = (nuevo.weight * 100).toFixed(0);
                }
            })
            totalActual += parseFloat(newRow.porcentaje.slice(0, -1));
            totalNew += newRow.weight ? parseFloat(newRow.weight.slice(0, -1)) : 0;
            newRow.totalActual = Math.round( totalActual ) + '%';
            newRow.totalNew = Math.round( totalNew ) + '%';
            port.push(newRow)
        })

        this.setState({
            page: 1,
            selectedPortafolio: selected,
            comparePortafolio: port
        })
    }

    handleReturn(e){
        e.preventDefault()
        this.setState({
            page: 0,
            step: 3
        })
    }

    handleConfirmSelectedPortafolio(){  
        const data = {
            account: this.props.accountSelected,
            selected: this.state.selectedPortafolio,
            comparison: this.state.comparePortafolio
        }        
        this.props.saveSelectedPortafolio(data)
    }

    render() {        
        return (
            <div>
                {this.state.page === 0 ?
                    <Rebalance 
                        accounts={this.props.accounts} 
                        restrictions={restrictions}
                        fundsData={this.props.fundsByAccount}
                        setRebalance={this.setRebalance}
                        reset={this.reset}
                        rebalanceData={this.props.rebalanceData}
                        resultIsLoading={this.props.resultIsLoading}
                        accountsLoading={this.props.accountsLoading}
                        setAccountSelected={this.setAccount}
                        newFunds={this.props.newFunds}
                        accountSelected={this.props.accountSelected}
                        fundsLoading={this.props.fundsLoading}
                        error={this.props.error}
                        pdfRebalance={this.pdfRebalance}
                        handleSelectedPort={this.handleSelectedPort}
                        step = {this.state.step}
                        getResponse={this.props.getResponse}
                        getPortafolioSelected={this.props.getPortafolioSelected}
                        infoAccount={this.props.infoAccount}                        
                        getTableState={this.props.setTableStateAccount}
                        tableState={this.props.tableState}
                    />
                :
                    <SelectedPortafolio 
                        handleReturn={this.handleReturn}
                        code={this.props.code}
                        accountSelected={this.props.accountSelected}
                        selectedPortafolio={this.state.selectedPortafolio}
                        comparePortafolio={this.state.comparePortafolio}
                        handleConfirm={this.handleConfirmSelectedPortafolio}
                        getResponse={this.props.getResponse}
                    />
                }

            </div>
        );
    }
}

const filter = "all";
const mapStateToProps = state => ({
    accounts: getAllAccounts(state, filter),
    fundsByAccount: getAllFundsByAccount(state),
    rebalanceData: getRebalanceResponse(state),
    resultIsLoading: isLoading(state),
    accountsLoading: accountsLoading(state),
    accountSelected: accountSelected(state),
    newFunds: getNewFundsRebalance(state),
    fundsLoading: fundsLoading(state),
    error: getError(state),
    code: getCodeRebalance(state),
    portafolioActual: getPortafolioActual(state),
    getResponse: getResponse(state),
    getPortafolioSelected: getPortafolioSelected(state),
    infoAccount: infoAccount(state),
    tableState: state.rebalance.tableState
})

const mapDispatchToProps = dispatch => ({
    getAccounts: () => dispatch(getAccounts()),
    rebalanceAccounts: restrictions => dispatch(rebalanceAccounts(restrictions)),
    reset: () => dispatch(resetRebalance()),
    setAccountSelected: account => dispatch(setAccountSelected(account)),
    getPdfRebalance: data => dispatch(getPdfRebalance(data)),
    saveSelectedPortafolio: data => dispatch(saveSelectedPortafolio(data)),
    setTableStateAccount: state => dispatch(setTableStateAccount(state))
})

export default connect(mapStateToProps, mapDispatchToProps)(index);