import React, { Component } from 'react';
import { connect } from 'react-redux';
import MainStaff from './../../components/Staff/MainStaff';
import {getStaffData, staffAssignClient} from './../../actions/staff';
import {getAllClientsNoFilter, pending as pendingClients} from './../../selectors/client';
import {getAllStaff, getStatus, pending, staffData, pendingInfo,
    actionMessage, 
    error
} from './../../selectors/staff';
import withParams from '../../helpers/withParams'

class MainStaffContainer extends Component {

    constructor(){
        super();
        this.handleClickClientsOut = this.handleClickClientsOut.bind(this);
    }

    componentDidMount(){
        this.props.getStaffData(this.props.params.id);
    }

    handleClick(){
        //  console.log("Click")
    }

    handleClickClientsOut(staff, clients){
        const data = {
            'option': 3,
            'staff': staff,
            'clients':clients
        }
        this.props.staffAssignClient(data) 
    }

    render() {
        return <MainStaff 
                data={this.props.staffSelected} 
                pending={this.props.pendingInfo}
                staffData={this.props.staffData}
                params={this.props.params}
                onClick={this.handleClick}
                staff={this.props.params.id}
                onClickClientsOut={this.handleClickClientsOut}
                message={this.props.messageStaffAssign}
                />
    }
}

const mapStateToProps = state => ({
    pendingInfo: pendingInfo(state),
    staffData: staffData(state),
    staffSelected: state.staff.staffSelected,
    messageStaffAssign: state.staff.messageStaffAssign

})

const mapDispatchToProps = dispatch => ({
    getStaffData: user => dispatch(getStaffData(user)),
    staffAssignClient: (data) => dispatch(staffAssignClient(data)),
})

export default withParams(connect(mapStateToProps, mapDispatchToProps)(MainStaffContainer));