export const getClientCartola = state => state.cartola.clientCartola;

export const getClientName = state => {
    if(state.cartola.clientName){
        return state.cartola.clientName.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
    }else{
        return ""
    }
};

export const listClientsCartolaLoading = state => state.cartola.listClientsCartolaLoading;
export const listClientsCartola = state => state.cartola.listClientsCartola;
export const listClientsCartolaConcatName = state => {
    if(!state.cartola.listClientsCartolaLoading) {
        return state.cartola.listClientsCartola.map((i, k) => ({
            rut : i.rut,
            id : i.id,
            name : `${i.name} ${i.last_name}`,
            email : i.email,
            patrimony : i.patrimony,
        }))
    } else {
        return [];
    }
};

export const getFirstClientId = state => {
    if(state.cartola.listClientsCartola.length > 0){
        return state.cartola.listClientsCartola[0].id;
    }else{
        return 1;
    }

}
