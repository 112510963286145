import React from 'react';


var sidebarUrls = [
    {
      path: "/dashboard/user-page",
      name: "Mi Perfil",
      permission: 'view-client-dashboard',
      invisible: true,
    },
    {
      path: "/dashboard/cartolas/home",
      name: "Cartolas",
      icon: "now-ui-icons files_single-copy-04",
      permission: 'view-client-dashboard',
    },
    /*
 {
     path: "/dashboard/home",
     public: true,
     name: "Dashboard",
     icon: 'now-ui-icons business_bank',
     permission: "view-advisor-dashboard",
    },
    {
     path: "/dashboard/staff/home",
     public: true,
     name: "Equipo",
     icon: 'now-ui-icons business_briefcase-24',
     permission: "view-advisor-dashboard",
    },
    {
     path: "/dashboard/clients/home",
     public: true,
     name: "Clientes",
     icon: 'now-ui-icons users_circle-08',
     permission: "view-advisor-dashboard",
    },
    {
     public: true,
     icon: 'now-ui-icons business_chart-pie-36',
     path: "/dashboard/rebalance",
     collapse: true,
     name: "Rebalanceador",
     permission: "view-advisor-dashboard",
     views: [
       {
         path: "/dashboard/rebalance",
         name: "Rebalanceador", 
         permission: "view-advisor-dashboard"
       },
       {
         path: "/dashboard/rebalancehistorial/home",
         name: "Historial",
         permission: "view-advisor-dashboard"
       }
     ]
    },
    {
     path: "/dashboard/roles-users/roles",
     name: "Roles & Permisos",
     icon: 'now-ui-icons objects_key-25',
     permission: "create-roles",
    }
    */
  
]


export default sidebarUrls;
