import {STAFF_GET_INIT, STAFF_GET_SUCCESS, STAFF_GET_ERROR,
CLIENTS_GET_INIT, CLIENTS_GET_SUCCESS, CLIENTS_GET_ERROR,
STAFF_ASSIGN_CLIENT_INIT,  STAFF_ASSIGN_CLIENT_SUCCESS, STAFF_ASSIGN_CLIENT_ERROR,
RESET_STATUS_RESPONSE, 
STAFFCARD_TOTAL_INIT, STAFFCARD_TOTAL_SUCCESS, STAFFCARD_TOTAL_ERROR,
STAFFCARD_INFO_GET_INIT, STAFFCARD_INFO_GET_SUCCESS, STAFFCARD_INFO_GET_ERROR,
STAFF_DATA_GET_INIT, STAFF_DATA_GET_SUCCESS, STAFF_DATA_GET_ERROR,
ADD_STAFF_INIT, ADD_STAFF_SUCCESS, ADD_STAFF_ERROR,
REMOVE_STAFF_INIT, REMOVE_STAFF_SUCCESS, REMOVE_STAFF_ERROR, CLIENTS_ASSIGN_LIST
} from './../constants';
import httpClient from '../api/httpClient';
import {urlStaff, urlClients, urlStaffAssignClients, urlstaffCard, urlstaffCardInfo} from '../api/urls';
import { updateRouterHistory } from './updateUrl';


export const getStaff = (usersList = []) =>{
    return (dispatch, getState) => {
        dispatch(handleClientsAssign(usersList));
        if(getState().staff.staff.length == 0){  
            dispatch(getStaffInit());
            httpClient.getAll(urlStaff)
            .then(response => dispatch(getStaffSuccess(response)))
            .catch(error => dispatch(getStaffError(error)))
        }
    }
}

export const getStaffData = staffId =>{
    return (dispatch, getState) => {
        httpClient.get(urlStaff, staffId)
        .then(response => dispatch(getStaffDataSuccess(response)))
        .catch(error => dispatch(getStaffDataError(error))) 
    }
}

// export const getStaffData = staffList =>{
//     return (dispatch, getState) => {
//         dispatch(getStaffDataInit(staffList));

//         // httpClient.get(urlStaff, staffList)
//         // .then(response => dispatch(getStaffDataSuccess(response)))
//         // .catch(error => dispatch(getStaffDataError(error))) 
//     }
// }

export const staffAssignClient = (data) =>{
    return (dispatch, getState) => {

        let messageInit     = '';
        let messageSuccess  = '';
        let messageError    = '';
        if(data.option == 1) {
            messageInit     = 'Asignando Asesor(es)...';
            messageSuccess  = 'Asignación satisfactoria.';
            messageError    = 'Error al asignar asesor(es).';
        }
        if(data.option == 2) {
            messageInit     = 'Desasignando Asesor(es)...';
            messageSuccess  = 'Desasignación satisfactoria.';
            messageError    = 'Error al desasignar asesor(es).';
        }
        if(data.option == 3) {
            messageInit     = 'Desasignando Cliente(s)...';
            messageSuccess  = 'Desasignación satisfactoria.';
            messageError    = 'Error al desasignar Cliente(s).';
        }

        dispatch(StaffAssignClientInit(messageInit));
                
        httpClient.create(urlStaffAssignClients, data)
        .then(response => {
            //Actualizar data de staffClients
            //Eliminar clientes desasignados desde la lista de clientes del asesor
            const newStaffClients = getState().staff.staffClients.filter(x=> !data.clients.includes(x.id))
            dispatch(StaffAssignClientSuccess(response.status, messageSuccess, newStaffClients));
            
            //Actualizar data de staff (para recalculo de patrimonio)
            dispatch(getStaffInit());
            httpClient.getAll(urlStaff)
            .then(response => dispatch(getStaffSuccess(response)))
            .catch(error => dispatch(getStaffError(error)))   
            //Actualizar data de cliente (tiene asesor) 
            const client = getState().client.clients; 
            //Des-asignar asesor desde vista asesor           
            if(data.option == 3){
                dispatch(getClientsInit());
                httpClient.getAll(urlClients)
                .then(response => dispatch(getClientsSuccess(response)))
                .catch(error => dispatch(getClientsError(error)))
            }else{
                client.forEach(x=> {
                    data.clients.forEach(z=> {
                        if(data.option == 1 && x.rut == z.rut){//Asignar asesor
                            x.hasStaff = "Si"
                        }else if(data.option >= 2 && x.rut == z.rut){//Des-asignar asesor desde vista cliente 
                            x.hasStaff = "No"
                        }
                    })                    
                })
            }
            
        })
        .catch(error => {
            console.log(error);
            dispatch(StaffAssignClientError(error, messageError))
        })
    }
}

export const setResetStatus = () => {
    return (dispatch) => {
        dispatch(ResetStatus())
    }
}

export const handleAddStaff = data => {
    return (dispatch, getState) => {
        const staff = getState().staff.staff;
        const newStaff = [...staff];
        
        dispatch(handleAddStaffInit())
        httpClient.create(urlStaff, data)
        .then(response => {
            if(response.status == 200){
                data.staffId = response.data.staffId
                data.id = response.data.id
                newStaff.push(data)
            }
            dispatch(handleAddStaffSuccess(response, newStaff))
        })
        .catch(error => dispatch(handleAddStaffError(error)))
    }
}

export const handleRemoveStaff = data => {
    return (dispatch, getState) => {    
        const staff = getState().staff.staff;
        const newStaff = staff.filter(x => x.staffId != data.staffId)
        dispatch(handleRemoveStaffInit())
        httpClient.del(urlStaff, data.staffId)
        .then(response => {
            dispatch(handleRemoveStaffSuccess(response, newStaff))
        })
        .catch(error => dispatch(handleRemoveStaffError(error)))
    }
}

export const selectStaffHomeContainer = (url, staffId, search = '') => {
    return dispatch => {
        if(search){
            dispatch(updateRouterHistory(`${url}?id=${staffId}&search=${search}`));
        }else{
            dispatch(updateRouterHistory(`${url}?id=${staffId}`));
        }        
    }
}


//#region ############# CARD ADVISOR ###############################
export const getStaffCard = () => {
    return dispatch => {        
        dispatch(_getStaffCardInit());
        httpClient.getAll(urlstaffCard)
        .then(res => dispatch(_getStaffCardSuccess(res)))
        .catch(error => dispatch(_getStaffCardError(error)))
    }
}

export const getStaffCardInfo = () => {
    return dispatch => {        
        dispatch(_getStaffCardInfoInit());
        httpClient.getAll(urlstaffCardInfo)
        .then(res => dispatch(_getStaffCardInfoSuccess(res)))
        .catch(error => dispatch(_getStaffCardInfoError(error)))
    }
}
//#endregion

//#region CARD ADVISOR PAGINATION
export const getNextPage = () => {   
    return (dispatch, getState) => {
        dispatch(_getStaffCardInfoInit());
        httpClient.getAll(getState().staff.pagination.next_page_url)
        .then(res => dispatch(_getStaffCardInfoSuccess(res)))
        .catch(error => dispatch(_getStaffCardInfoError(error)))
    }
}

export const getPrevPage = () => {   
    return (dispatch, getState) => {
        dispatch(_getStaffCardInfoInit());
        httpClient.getAll(getState().staff.pagination.prev_page_url)
        .then(res => dispatch(_getStaffCardInfoSuccess(res)))
        .catch(error => dispatch(_getStaffCardInfoError(error)))
    }
}

export const getFirstPage = () => {   
    return (dispatch, getState) => {
        dispatch(_getStaffCardInfoInit());
        httpClient.getAll(getState().staff.pagination.first_page_url)
        .then(res => dispatch(_getStaffCardInfoSuccess(res)))
        .catch(error => dispatch(_getStaffCardInfoError(error)))
    }
}

export const getLastPage = () => {   
    return (dispatch, getState) => {
        dispatch(_getStaffCardInfoInit());
        httpClient.getAll(getState().staff.pagination.last_page_url)
        .then(res => dispatch(_getStaffCardInfoSuccess(res)))
        .catch(error => dispatch(_getStaffCardInfoError(error)))
    }
}
//#endregion

const handleClientsAssign = usersList => ({
    type: CLIENTS_ASSIGN_LIST,
    payload: {
        usersList
    }
})

const handleRemoveStaffInit = () => ({
    type: REMOVE_STAFF_INIT
})

const handleRemoveStaffSuccess = (response, newStaff) => ({
    type: REMOVE_STAFF_SUCCESS,
    payload: {
        response,
        newStaff
    }
})

const handleRemoveStaffError = (error) => ({
    type: REMOVE_STAFF_ERROR,
    payload: {
        error
    }
})

const handleAddStaffInit = () => ({
    type: ADD_STAFF_INIT
})

const handleAddStaffSuccess = (response, newStaff) => ({
    type: ADD_STAFF_SUCCESS,
    payload: {
        response,
        newStaff
    }
})

const handleAddStaffError = error => ({
    type: ADD_STAFF_ERROR,
    payload: {
        error
    }
})



const getStaffDataInit = staff => ({
    type: STAFF_DATA_GET_INIT,
    payload:{
        staff
    }
})

const getStaffDataSuccess = (staffClients, staffSelected) => ({
    type: STAFF_DATA_GET_SUCCESS,
    payload: {
        staffClients,
        staffSelected
    }
})

const getStaffDataError = error => ({
    type: STAFF_DATA_GET_ERROR,
    payload: {
        error
    }
})

const _getStaffCardInfoInit = () => ({
    type: STAFFCARD_INFO_GET_INIT
})

const _getStaffCardInfoSuccess = staff => ({
    type: STAFFCARD_INFO_GET_SUCCESS,
    payload: {
        staff
    }
})

const _getStaffCardInfoError = error => ({
    type: STAFFCARD_INFO_GET_ERROR,
    payload: {
        error
    }
})

const _getStaffCardInit = () => ({
    type: STAFFCARD_TOTAL_INIT
})

const _getStaffCardSuccess = staff => ({
    type: STAFFCARD_TOTAL_SUCCESS,
    payload: {
        staff
    }
})

const _getStaffCardError = error => ({
    type: STAFFCARD_TOTAL_ERROR,
    payload: {
        error
    }
})


const StaffAssignClientInit = message => ({
    type: STAFF_ASSIGN_CLIENT_INIT,
    payload: {
        message
    }
})

const StaffAssignClientSuccess = (status, message, newStaffClients) => ({
    type: STAFF_ASSIGN_CLIENT_SUCCESS,
    payload: {
        status,
        message,
        newStaffClients
    }
})

const ResetStatus = () => ({
    type: RESET_STATUS_RESPONSE,
})

const StaffAssignClientError = (error, message) => ({
    type: STAFF_ASSIGN_CLIENT_ERROR,
    payload: {
        error,
        message
    }
})

const getStaffInit = (usersList) => ({
    type: STAFF_GET_INIT,
    payload: {
        usersList
    }
})

const getStaffSuccess = staff => ({
    type: STAFF_GET_SUCCESS,
    payload: {
        staff
    }
})

const getStaffError = error => ({
    type: STAFF_GET_ERROR,
    payload: {
        error
    }
})

const getClientsInit = () => ({
    type: CLIENTS_GET_INIT
})

const getClientsSuccess = clients => ({
    type: CLIENTS_GET_SUCCESS,
    payload: {
        clients
    }
})

const getClientsError = error => ({
    type: CLIENTS_GET_ERROR,
    payload: {
        error
    }
})