import React from 'react';
import {
    Card,
    CardBody,
    CardFooter,
    Col
} from "reactstrap";

const adminCardStyles = {
    cardCursor: {
        minHeight: '400px',
        cursor: 'pointer', 
        display: "grid"
    },

    cardDefault: {
        minHeight: '400px',
        display: "grid"
    },

    cardBody: {
        display: "flex",
        minHeight: "250px",
        alignItems: "center",
        justifyContent: "center",
        color: "#2074af",
        overflow: 'hidden'
       
        
    },

    cardTitle: {
        margin: "0px",
        fontSize : '30px'
    },
    icon: {
        fontSize: "2em",
        marginRight: ".5em"
    }
};

const index = ({icon, title, onClick, children, width, cursorDefault}) => {

    return (

        <Col sm={12} md={width}>
        <Card style={cursorDefault ? adminCardStyles.cardDefault: adminCardStyles.cardCursor} 
            onClick={() => onClick(title)}
        >
            <CardBody style={adminCardStyles.cardBody} >                
                {children}                    
            </CardBody>
            <hr />
            <CardFooter>
{/*                 {
                    icon ? <i style={adminCardStyles.icon} className={`now-ui-icons ${icon}`} /> : null
                } */}
                <h3 style={adminCardStyles.cardTitle}>{title}</h3>

            </CardFooter>
        </Card>
        </Col>
    );
}

export default index;