import React, { useEffect, useState} from 'react';
import SidePanel from '../../../components/SidePanel';
import Switch from "react-switch";
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import roleInfo from '../../../components/RolesUsers/roleInfo';


const useStyles = makeStyles({
    underline: {
      "&&&:before": {
        borderBottom: "none"
      }
    }
  });

const EditRoles = (props) => {
    console.log('EditRoles!');

    let {
        uid,
        active,
        title,
        permissions,
        editActive,
        openEdit,
        handleUpdateRole,
        setOpenEdit,
        editSuccess,
        // handlewarning,
        // handleconfirm,
        // handlesuccess,
        // showwarning,
        // showsuccess
    } = props;
    // console.log('editActive Roles!', activeRolePermissions);
    let [roleName, setRoleName] = useState();
    let [roleDescription, setRoleDescription] = useState('');
    let [rolePermissions, setRolePermissions] = useState([]);
    let [activeRole, setActiveRole] = useState(editActive);
    useEffect(() => {
        setActiveRole(editActive)
        setRoleName(editActive.name);
        setRoleDescription(editActive.desc ? editActive.desc.desc : '');
    }, [editActive, active])

    
    const classes = useStyles();
    const closeHandler = () => {
        document.getElementById(`${uid}`).style.width = "0";
        document.getElementById(`${uid}`).style.display = "none";
        document.getElementById("main").style.width = "100%";
        setOpenEdit(false);
    }

    const handlePermissionChange = (permission) => {
        console.log(!activeRole.permissions[permission]);
        console.log('rolePermissions', rolePermissions);
        let newRolePerms = {...rolePermissions};
        newRolePerms[permission] = !newRolePerms[permission];
        setRolePermissions(newRolePerms);

        // build role object
        let role = {
            name: editActive.name,
            permissions: Object.keys(rolePermissions),
            desc: roleDescription,
            requires: ''
        }
        handleUpdateRole(role);
    }

    return(
        <SidePanel 
            uid={uid}
            active={active}
            title={title}
            closeHandler={closeHandler}
            body={
                <div>
                    <TextField 
                        style={{width: '40%'}} 
                        id="standard-basic" 
                        // value={editActive.name}
                        value={activeRole.name} 
                        label="Nombre" 
                        InputProps={{
                            style: {
                                fontSize: '1.5em'
                            },
                            classes
                        }}
                    />  
                    <TextField
                        id="filled-multiline-flexible"
                        label="Descripcion de Rol"
                        multiline
                        rowsMax="4"
                        InputProps={{
                            classes
                        }}
                        // value={editActive.desc ? editActive.desc.desc : ''}
                        value={roleDescription}
                    />
                    <div className={'permission-container'}>
                        {permissions ? Object.keys(permissions).map((permission, key) => {
                            
                            return(
                                <div className={'permission-tile'} key={key}>
                                    <div className={'permission-name'}>{roleInfo[permission]}</div>
                                    <div className={'permission-value'} ><Switch onClick={() => handlePermissionChange(permission)} onChange={() => handlePermissionChange(permission)} id={permission} checked={activeRole.permissions[permission]} /></div>
                                </div> 
                            )
                        }) : null}
                    </div>
                </div>
            }
            footer={
                <div>
                    <Button className={'save-button'}  color={'success'}>Guardar</Button>
                    {/* {showwarning ? 
                    <SweetAlert
                        warning
                        style={{display: "block",marginTop: "-100px"}}
                        title="Seguro que deseas cambiar el Rol?"
                        confirmBtnText="Si, deseo modificar el rol."
                        confirmBtnBsStyle="danger"
                        onConfirm={() => handleconfirm(editActive)}
                        onCancel={() => handleconfirm()}
                        confirmBtnBsStyle="info"
                    >
                    </SweetAlert> : null}
                    {showsuccess ? 
                    <SweetAlert
                        success
                        style={{display: "block",marginTop: "-100px"}}
                        title="El rol fue actualizado correctamente!"
                        onConfirm={() => handlesuccess()}
                        onCancel={() => handlesuccess()}
                    >
                        El rol fue actualizado exitosamente!
                    </SweetAlert> : null} */}
                </div>
            }
        />
    )
}

export default EditRoles;
