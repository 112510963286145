import Staff from '../containers/Staff';
import Clients from '../containers/Clients';
import UserContainer from '../containers/UserContainer/UserContainer';
import Rebalance from '../containers/Rebalance';
import RebalanceHistorial from '../containers/RebalanceHistorial';
import RebalanceHistorialHome from '../containers/RebalanceHistorial/HomeContainer';
import RebalanceHistorialAccounts from '../containers/RebalanceHistorial/AccountsContainer';
import RebalanceHistorialRebalance from '../containers/RebalanceHistorial/RebalanceContainer';

import RolesUsersContainer from '../containers/RolesUsers/RolesUsersContainer';
import RolesContainer from '../containers/RolesUsers/RolesContainer';
import UsersContainer from '../containers/RolesUsers/UsersContainer';
import AdminDashboard from '../containers/Admin';
import BulkData from '../containers/BulkData';
import CartolaClientContainer from '../containers/CartolaContainer/CartolaClientContainer/CartolaClientContainer';
import ListClientContainer from '../containers/CartolaContainer/ListClientContainer/ListClientContainer';
import CartolaHomeContainer from '../containers/CartolaContainer/CartolaHomeContainer/CartolaHomeContainer';
import HomeClientContainer from '../containers/Clients/HomeContainer';
import MainClientContainer from '../containers/Clients/MainClientContainer';
import AssignStaffContainer from '../containers/Clients/AssignStaffContainer';
import HomeContainer from '../containers/Staff/HomeContainer'
import MainStaffContainer from  '../containers/Staff/MainStaffContainer'
import AssignClientContainer from '../containers/Staff/AssignClientContainer'
import ListGroupsContainer from '../containers/CartolaContainer/ListGroupsContainer';
import CartolaGroupContainer from '../containers/CartolaContainer/CartolaGroupContainer';

//Reports
import CartolaContainer from "../containers/CartolaContainer/CartolaContainer";


var dashboardRoutes = [
  {
    path: "/dashboard/user-page",
    name: "Mi Perfil",
    permission: 'view-client-dashboard',
    invisible: true,
    component: UserContainer,
    ref: "user"
  },
  {
    path: "/dashboard/cartolas",
    name: "Cartolas",
    icon: "now-ui-icons files_single-copy-04",
    permission: 'view-client-dashboard',
    component: CartolaContainer,
    ref: "cartolas",
    routes: [
      {
        path: "/dashboard/cartolas/home",
        name: "Cartola Consolidada | Cartolas",
        permission: 'view-client-dashboard',
        component: CartolaHomeContainer
      },
      {
        path: "/dashboard/cartolas/client",
        name: "Cartola Consolidada | Cartolas",
        permission: 'view-client-dashboard',
        component: CartolaClientContainer

      },
      {
        path: "/dashboard/cartolas/clientsgroup",
        name: "Listado de Clientes | Cartolas",
        permission: 'view-client-dashboard',
        component: CartolaGroupContainer
      },
      {
        path: "/dashboard/cartolas/list",
        name: "Listado de Clientes | Cartolas",
        permission: "view-advisor-dashboard",
        component: ListClientContainer
      },
      {
        path: "/dashboard/cartolas/groups",
        name: "Listado de Clientes | Cartolas",
        permission: "view-advisor-groups",
        component: ListGroupsContainer
      },
      // {
      //   path: "/dashboard/cartolas/clientsgroup",
      //   name: "Listado de Clientes | Cartolas",
      //   permission: "view-advisor-groups",
      //   component: CartolaGroupContainer
      // }
    ]
  },
  // {
  //   path: "/dashboard/asesor",
  //   name: "Asesor",
  //   permission: "view-advisor-dashboard",
  //   component: Admin
  // },
  {
    path: "/dashboard/home",
    public: true,
    name: "Dashboard",
    icon: 'now-ui-icons business_bank',
    permission: "view-advisor-dashboard",
    component: AdminDashboard
  },
  {
    path: "/dashboard/staff",
    public: true,
    name: "Equipo",
    icon: 'now-ui-icons business_briefcase-24',
    permission: "view-advisor-dashboard",
    component: Staff,
    routes: [
      {
        path: "/dashboard/staff/home",
        name: "Home | Equipo",
        permission: 'view-advisor-dashboard',
        component: HomeContainer
      },
      {
        path: "/dashboard/staff/details",
        name: "Detalle | Equipo",
        permission: 'view-advisor-dashboard',
        component: MainStaffContainer
      },
      {
        path: "/dashboard/staff/assign",
        name: "Detalle | Equipo",
        permission: 'view-advisor-dashboard',
        component: AssignClientContainer
      },

    ]
    },
  {
    path: "/dashboard/clients",
    public: true,
    name: "Clientes",
    icon: 'now-ui-icons users_circle-08',
    permission: "view-advisor-dashboard",
    component: Clients,
    routes: [
      {
        path: "/dashboard/clients/home",
        name: "Home | Clientes",
        permission: 'view-advisor-dashboard',
        component: HomeClientContainer
      },
      {
        path: "/dashboard/clients/details",
        name: "Detalle | Clientes",
        permission: 'view-advisor-dashboard',
        component: MainClientContainer
      },
      {
        path: "/dashboard/clients/assign",
        name: "Detalle | Clientes",
        permission: 'view-advisor-dashboard',
        component: AssignStaffContainer
      },

    ]
  },

  {
    public: true,
    icon: 'now-ui-icons business_chart-pie-36',
    collapse: true,
    name: "Rebalanceador",
    permission: "view-advisor-dashboard",
    views: [
      {
        path: "/dashboard/rebalance",
        name: "Rebalanceador",
        component: Rebalance,
      },
       {
         path: "/dashboard/rebalancehistoric",
         name: "Historial",
         component: RebalanceHistorial,
       }
    ]
  },

  {
    path: "/dashboard/rebalancehistorial",
    public: true,
    name: "Historial",
    icon: 'now-ui-icons business_chart-pie-36',
    permission: "view-advisor-dashboard",
    component: RebalanceHistorial,
    routes: [
      {
        path: "/dashboard/rebalancehistorial/home",
        name: "Home | Historial",
        permission: 'view-advisor-dashboard',
        component: RebalanceHistorialHome
      },
      {
        path: "/dashboard/rebalancehistorial/accounts",
        name: "Cuentas | Historial",
        permission: 'view-advisor-dashboard',
        component: RebalanceHistorialAccounts
      },
      {
        path: "/dashboard/rebalancehistorial/rebalance",
        name: "Rebalanceo | Historial",
        permission: 'view-advisor-dashboard',
        component: RebalanceHistorialRebalance
      }
    ]
  },

  {
    path: "/dashboard/roles-users",
    name: "Roles & Usuarios",
    icon: 'now-ui-icons objects_key-25',
    component: RolesUsersContainer,
    permission: "create-roles",
    routes: [
      {
        path: "/dashboard/roles-users/roles",
        name: "Roles",
        permission: 'create-roles',
        component: RolesContainer
      },
      {
        path: "/dashboard/roles-users/users",
        name: "Usuarios",
        permission: 'create-roles',
        component: UsersContainer
      }
    ]
  },
  // {
  //   path: "/dashboard/dataimport",
  //   name: "CSV",
  //   icon: 'now-ui-icons arrows-1_cloud-upload-94',
  //   component: BulkData,
  //   permission: "create-roles",
  // },

  {
    name: "Cerrar Sesión",
    invisible: true,
    icon: "sport_user-run",
  },
  //{ redirect: true, path: "/", pathTo: "/qip/login", name: "Login" }
];
export default dashboardRoutes;
