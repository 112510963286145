import React from 'react';
import PropTypes from 'prop-types';
import EnhancedTable from './EnhancedTable';



const TableMaterialUi = (props) => {
    const {title, rows, headCells, withCheckbox, onClickRow, loading, updateState, initialState} = props;

    const state = initialState ? initialState : {		
        order : 'asc',
        orderBy : null,
        page : 0,
        dense : false,
        searchValue : '',
        rowsPerPage : 5,
        selected : [], 
        cellCursor: 'pointer',
    }    

    const [cellCursor, SetCellCursor] = React.useState(state.cellCursor ? state.cellCursor : 'pointer');
    const [order, setOrder] = React.useState(state.order ? state.order : 'asc');
    const [orderBy, setOrderBy] = React.useState(state.orderBy ? state.orderBy : '');
    const [page, setPage] = React.useState(state.page ? state.page : 0);
    const [dense, setDense] = React.useState(state.dense ? state.dense : false)
    const [searchValue, setSearchValue] = React.useState(state.searchValue ? state.searchValue : '');
    const [rowsPerPage, setRowsPerPage] = React.useState(state.rowsPerPage ? state.rowsPerPage : 5);
    const [selected, setSelected] = React.useState(state.selected ? state.selected : []);
    const [filteredRows, setFilteredRows] = React.useState([]);
    const [tableState, setTableState] = React.useState(state);

    React.useEffect(()=>{
        let records = [...rows];
        if(searchValue){
            var searchValueFormat = searchValue.replaceAll('.', '');
            records = records.reduce((acc, val) => {
                headCells.forEach(column => {
                    if(val[column.id]){
                        if(val[column.id].toString().toLowerCase().indexOf(searchValueFormat.toLowerCase()) > -1 && !acc.find(row => row === val)){
                            acc.push(val);
                        }              
                    }      
                })
                return acc
            }, []);            
        }        
        setFilteredRows(records);
    }, [searchValue, rows])

    const updateTableState = value => {
        let newState = {...tableState};
        for (const key of Object.keys(newState)) {         
            for (const newKey of Object.keys(value)) {
                if(key === newKey){
                    newState[key] = value[newKey];
                }
            }
        }        
        setTableState(newState);
        updateState(newState);
    }

    return (
        <div>
            <EnhancedTable 
                title={title}
                rows={filteredRows} 
                headCells={headCells} 
                withCheckbox={withCheckbox}
                onClickRow={onClickRow}
                orderByDefault={orderBy}                //Select Column: 'columnName'
                orderDefault={order}                    //Select order: 'desc' or 'asc'                
                selectedDefault={selected}              //Rows previously selected: []
                pageDefault={page}                      //Page number: 0
                denseDefault={dense}                    //Adjust row height: false
                rowsPerPageDefault={rowsPerPage}        //Select: 5 or 10
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                loading={loading}
                setTableState={updateTableState}
                cellCursor={cellCursor}
            />
        </div>
    );
};

TableMaterialUi.propTypes = {
    title: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired, 
    headCells: PropTypes.array.isRequired, 
    withCheckbox: PropTypes.bool.isRequired, 
    onClickRow: PropTypes.func.isRequired, 
    loading: PropTypes.bool.isRequired,
    updateState: PropTypes.func.isRequired,
    initialState: PropTypes.object.isRequired,
    cellCursor: PropTypes.string
}

export default TableMaterialUi;