import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';

const Card = ({data, pending}) => {
    return(
        <div>  
            {!pending ? 
                <div>
                    { data.map((fund, i) => {
                        if(i <= 3){ 
                            return (<div>
                                <h6 className="text-center mb-1"
                                    style={fund.rentabilidad > 0 ? 
                                        { color: 'green' }:
                                        { color: 'red' }
                                }>
                                    <i className={
                                        fund.rentabilidad > 0 ? 
                                        "now-ui-icons arrows-1_minimal-up" :
                                        "now-ui-icons arrows-1_minimal-down"
                                    }></i>
                                    {fund.rentabilidad}% - Serie {fund.serie}
                                </h6>
                                <p>{fund.nombre}</p>
                            </div>)
                        }
                    })}

                </div>
                :
                <div className="text-center mt-5">
                    <CircularProgress size={50} thickness={5}/>
                </div>
            }
        </div>




        // <table>{ data.map(fund => {
        //     return(
        //         <div>
        //         {fund.rentabilidad > 0 ? 
        //             <tr style={{color: 'green'}}>
        //                 <td><i className="now-ui-icons arrows-1_minimal-up"></i></td>
        //                 <td>{fund.rentabilidad}%</td>
        //                 <td>{fund.nombre}</td>                
        //             </tr> :
        //             <tr style={{color: 'red'}}>
        //                 <td><i className="now-ui-icons arrows-1_minimal-down"></i></td>
        //                 <td>{fund.rentabilidad}%</td>
        //                 <td>{fund.nombre}</td>                
        //             </tr>
        //         }
        //         </div>
        //     )
        // }) }</table>
    )
}

export default Card;