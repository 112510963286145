import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getTenantInfo, passwordReset } from "../../actions/login";
import PasswordResetForm from '../../components/Login/PasswordResetForm';
import Login from '../../components/Login/login';
import queryString from 'query-string';


class PasswordResetContainer extends React.Component{

    constructor(){
        super()
        const params = queryString.parse(location.search);               
        this.state={
            email: params['email'] != null ? params['email'] : '',
            token: params['token']
        }
        this.submitPasswordReset = this.submitPasswordReset.bind(this)
        this.redirectToLoginForm = this.redirectToLoginForm.bind(this)
    }

    componentDidMount() {                
        this.props.getTenantInfo();
    }

    submitPasswordReset(obj) {
        obj.token = this.state.token;        
        this.props.passwordReset(obj);
    }

    redirectToLoginForm(){
        this.props.history.push('/login')
    }

    render(){
        return (
            <Login logo={this.props.logo}>
                <PasswordResetForm                     
                    pending={this.props.pending}
                    emailParams={this.state.email}
                    submit={this.submitPasswordReset}  
                    passwordResetMsg={this.props.passwordResetMsg}   
                    loginForm={this.redirectToLoginForm}           
                />
            </Login>
        )            
    }
}

const mapStateToProps = state => ({
    logo: state.login.logo,
    pending: state.login.pending,
    passwordResetMsg: state.login.passwordResetMsg
})

const mapDispatchToProps = dispatch => ({
    getTenantInfo: () => dispatch(getTenantInfo()),
    passwordReset: obj => dispatch(passwordReset(obj))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordResetContainer));