import {ACTIVO_INFO_GET_INIT, ACTIVO_INFO_GET_SUCCESS, ACTIVO_INFO_GET_ERROR
} from '../constants';

const initialState = {
    dataPatrimonio  : null,
    dataMes : null,
    pending : false,
    error   : null    
}

const activos = (state = initialState, action) => {
    switch (action.type) {
        case ACTIVO_INFO_GET_INIT:
            return {
                ...state,
                pending : true,
                error   : null
            };            

        case ACTIVO_INFO_GET_SUCCESS:
            return {
                ...state,
                dataPatrimonio  : action.payload.response.data.dataPatrimonio,
                dataMes : action.payload.response.data.dataMes,
                pending : false,                
            };            

        case ACTIVO_INFO_GET_ERROR:
            return {
                ...state,
                pending : false,
                error   : action.payload.error
            };            
    
        default:
            return state;
    }
}

export default activos;