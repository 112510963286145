import React from 'react';
import { CardHeader, CardBody, FormGroup } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
registerLocale("es", es);
import moment from "moment";

const CustomDatepicker = ({title, value, setDate, lastDateOfMonth = false}) => {

    const transformDate = date => {
        const newDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        return lastDateOfMonth ? setDate(newDate) : setDate(date)
    }

    return (
        <div>
            <CardHeader>{title}</CardHeader>
            <CardBody>
                <FormGroup>
                    <DatePicker
                        locale="es"
                        selected={moment(value).toDate()}
                        onChange={date => transformDate(date)}
                        dateFormat="dd/MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        showTwoColumnMonthYearPicker
                    />
                </FormGroup>
            </CardBody>
        </div>
      
    );
  };

  export default CustomDatepicker;