import {REBHISTORIAL_GET_CARTERA_INIT, REBHISTORIAL_GET_CARTERA_SUCCESS, REBHISTORIAL_GET_CARTERA_ERROR,
REBHISTORIAL_GET_ACCOUNT_INIT, REBHISTORIAL_GET_ACCOUNT_SUCCESS, REBHISTORIAL_GET_ACCOUNT_ERROR,
REBHISTORIAL_ACCOUNT_RESET,
REBHISTORIAL_SELECT_HISTORIAL_INIT,
REBHISTORIAL_SELECT_HISTORIAL_SUCCESS,
REBHISTORIAL_SELECT_HISTORIAL_ERROR,
REBHISTORIAL_HISTORIAL_RESET,
HIST_REBALANCE_GET_PDF_INIT, HIST_REBALANCE_GET_PDF_SUCCESS, HIST_REBALANCE_GET_PDF_ERROR,
REBHISTORIAL_SELECT_PORTAFOLIO, REBHISTORIAL_PORTAFOLIO_RESET,
UPDATE_REBHISTORIAL_DONE_INIT, UPDATE_REBHISTORIAL_DONE_SUCCESS, UPDATE_REBHISTORIAL_DONE_ERROR,
REBALANCE_HISTORIAL_TABLE_STATE
} from '../constants';
import httpClient from '../api/httpClient';
import {urlHistorialRebalance, urlHistorialAccount, urlRebalancePdf, urlUpdateRebHistorialDone, urlHistorialAccountRebalance} from '../api/urls';
import moment from 'moment';
import download from 'downloadjs';

export const getRebalanceHistorial = () => {
    return (dispatch, getState) => {
        if(getState().rebalanceHistorial.accounts.length == 0){        
            dispatch(getRebalanceHistorialInit());
            httpClient.getAll(urlHistorialRebalance)
            .then(response => {
                dispatch(getRebalanceHistorialSuccess(response));
            })
            .catch(error => {
                dispatch(getRebalanceHistorialError(error));
            })
        }
    }
}

export const getAccountHistorial = data => {
    return dispatch => {
        // dispatch(getAccountHistorialInit(data))
        dispatch(getAccountHistorialInit())
        httpClient.create(urlHistorialAccount, data)
        .then(response => {
            dispatch(getAccountHistorialSuccess(response))
        })
        .catch(error => {
            dispatch(getAccountHistorialError(error))
        })
    }
}

export const accountReset = () => {
    return dispatch => {
        dispatch(setAccountReset())
    }
}

export const setSelectedHistorial = data => {
    return dispatch => {
        dispatch(setSelectedHistorialInit())
        httpClient.create(urlHistorialAccountRebalance, data)
        .then(response => {
            dispatch(setSelectedHistorialSuccess(response))
        })
        .catch(error => {
            dispatch(setSelectedHistorialError(error))
        })

        
    }
}

export const resetSelectedHistorial = () => {
    return dispatch => {
        dispatch(_resetSelectedHistorial())
    }
}

export const getPdf = (data) => {
    return (dispatch, getState) => {
        dispatch(getPdfInit())

        const {appColors, logo} = getState().login;
        data.colors = appColors;
        data.logo = logo;    
        data.generated_date = getState().rebalanceHistorial.generated_date; 

        httpClient.downloadPdf(urlRebalancePdf, data)
        .then(response => {
            const content = response.headers['content-type'];                
            download(response.data, 'Rebalanceo '
            .concat(getState().rebalanceHistorial.accountSelected.rut, ' ', moment().format('YYYYMMDD') ,'.pdf'), content);

            dispatch(getPdfSuccess(response.status))
        })
        .catch(error => {
            console.log(error)
            dispatch(getPdfError(error, 500))
        })

    }
}

export const selectedPortafolio = (selected, compare) => {
    return dispatch => {
        dispatch(_selectedPortafolio(selected, compare))
    }
}

export const resetSelectedPortafolio = () => {
    return dispatch => {
        dispatch(_resetSelectedPortafolio())
    }
}

export const handleUpdateRebalanceDone = data => {
    return (dispatch, getState) => {
        const accounts = getState().rebalanceHistorial.accountHistorial;
        const newAccounts = accounts.reduce((acc, elem)=> {
            if(elem.id == data.id) elem.done = data.done;
            acc.push(elem)
            return acc
        }, [])
        dispatch(handleUpdateRebalanceDoneInit(newAccounts))
        //Hacer la logica de fetch para actualizar el registro en la bd
        httpClient.create(urlUpdateRebHistorialDone, data)
        .then(response => dispatch(handleUpdateRebalanceDoneSuccess(response)))
        .catch(error => dispatch(handleUpdateRebalanceDoneError(error)))

    }
}

export const setTableStateHistorial = state => {
    return dispatch => {
        dispatch(_setTableStateHistorial(state))
    }
}

const _setTableStateHistorial = state => ({
    type: REBALANCE_HISTORIAL_TABLE_STATE,
    payload: {
        state
    }
})

const handleUpdateRebalanceDoneInit = newAccounts => ({
    type: UPDATE_REBHISTORIAL_DONE_INIT,
    payload:{
        newAccounts
    }
})

const handleUpdateRebalanceDoneSuccess = response => ({
    type: UPDATE_REBHISTORIAL_DONE_SUCCESS,
    payload: {
        response
    }
})

const handleUpdateRebalanceDoneError = (error) => ({
    type: UPDATE_REBHISTORIAL_DONE_ERROR,
    payload: {
        error        
    }
})


const _resetSelectedPortafolio = () => ({
    type: REBHISTORIAL_PORTAFOLIO_RESET
})

const _selectedPortafolio = (selected, compare) => ({
    type: REBHISTORIAL_SELECT_PORTAFOLIO,
    payload: {
        selected,
        compare
    }
})

const getPdfInit = () => ({
    type: HIST_REBALANCE_GET_PDF_INIT
})

const getPdfSuccess = response => ({
    type: HIST_REBALANCE_GET_PDF_SUCCESS,
    payload: {
        response
    }
})

const getPdfError = (error, response) => ({
    type: HIST_REBALANCE_GET_PDF_ERROR,
    payload: {
        error,
        response
    }
})

const _resetSelectedHistorial = () => ({
    type: REBHISTORIAL_HISTORIAL_RESET
})

const setSelectedHistorialInit = () => ({
    type: REBHISTORIAL_SELECT_HISTORIAL_INIT,
})

const setSelectedHistorialSuccess = response => ({
    type: REBHISTORIAL_SELECT_HISTORIAL_SUCCESS,
    payload: {
        response
    }
})

const setSelectedHistorialError = error => ({
    type: REBHISTORIAL_SELECT_HISTORIAL_ERROR,
    payload: {
        error
    }
})

const setAccountReset = () => ({
    type: REBHISTORIAL_ACCOUNT_RESET
})

const getAccountHistorialInit = account => ({
    type: REBHISTORIAL_GET_ACCOUNT_INIT, 
    payload: {
        account
    }
})

const getAccountHistorialSuccess = response => ({
    type: REBHISTORIAL_GET_ACCOUNT_SUCCESS, 
    payload: {
        response
    }        
})

const getAccountHistorialError = error => ({
    type: REBHISTORIAL_GET_ACCOUNT_ERROR,
    payload: {
        error
    }    
})



const getRebalanceHistorialInit = () => ({
    type: REBHISTORIAL_GET_CARTERA_INIT
})

const getRebalanceHistorialSuccess = response => ({
    type: REBHISTORIAL_GET_CARTERA_SUCCESS,
    payload: {
        response
    }        
})

const getRebalanceHistorialError = error => ({
    type: REBHISTORIAL_GET_CARTERA_ERROR,
    payload: {
        error
    }    
})