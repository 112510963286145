import React from "react";
import {connect} from 'react-redux';
import HeaderPanel from '../../../../components/Cartola/Sections/HeaderPanel/HeaderPanel';
import originalMoment from "moment";
import { extendMoment } from "moment-range";


require('moment/locale/es');
const moment = extendMoment(originalMoment);

class HeaderPanelContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const startPeriod =  moment(this.props.periodStart).locale('es').format('dddd Do [de] MMMM [de] YYYY');
        const endPeriod = moment(this.props.periodEnd).locale('es').format('dddd Do [de] MMMM [de] YYYY');
        
        return(
            <HeaderPanel 
                fetchBreakdownLoading={this.props.fetchBreakdownLoading}
                fetchBreakdownFailed={this.props.fetchBreakdownFailed}
                consolidated={this.props.consolidated}
                montoTotal={this.props.montoTotal}
                startPeriod={startPeriod}
                endPeriod={endPeriod}
                colorSecondary={this.props.colorSecondary}
                equityCurve={this.props.equity_curve}
            />
        )
    }
}


const mapStateToProps = state => {
    return {
        fetchBreakdownLoading: state.cartola.fetchBreakdownLoading,
        fetchBreakdownFailed: state.cartola.fetchBreakdownFailed,
        consolidated: state.cartola.consolidated,
        montoTotal: state.cartola.montoTotal,
        // Start of the selected period.
        periodStart: state.cartola.periodStart,
        // End of the selected period
        periodEnd: state.cartola.periodEnd,
        equity_curve: state.cartola.equity_curve
    }
}

export default connect(mapStateToProps, null)(HeaderPanelContainer);