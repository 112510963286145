import React from "react";
import {Link} from "react-router-dom";
import DateRangePicker from 'react-daterange-picker';
import {
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Container
} from "reactstrap";
import { Button } from "../../components";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import dashboardRoutes from "../../routes/dashboard";
import originalMoment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(originalMoment);

const today = moment();

const stateDefinitions = {
    available: {
        color: null,
        label: 'Disponible',
    },
    enquire: {
        color: '#ffd200',
        label: 'Seleccionado',
    },
    unavailable: {
        selectable: false,
        color: '#78818b',
        label: 'No Disponible',
    },
};

const dateRanges = [
    {
        state: 'enquire',
        range: moment.range(
            moment(),
            moment().subtract(3, 'months')
        ),
    },
];

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            iaDropdownOpen: false,
            pfDropdownOpen: false,
            color: "transparent",

            fiveDays: moment.range(today.clone().subtract(5, "days"), today.clone()),
            tenDays: moment.range(today.clone().subtract(10, "days"), today.clone()),
            fifteenDays: moment.range(today.clone().subtract(15, "days"), today.clone()),
            thirtyDays: moment.range(today.clone().subtract(30, "days"), today.clone()),
            threeMonths: moment.range(today.clone().subtract(3, "months"), today.clone()),
            sixMonths: moment.range(today.clone().subtract(6, "months"), today.clone()),
            lastTwelveMonths: moment.range(today.clone().subtract(12, "months"), today.clone()),
            yearToDate: moment.range(moment().startOf('year'), today.clone()),
            twentyEighteen:  moment.range("2018-01-01", "2018-12-31"),
            twentySeventeen: moment.range("2017-01-01", "2017-12-31"),
            twentySixteen: moment.range("2016-01-01", "2016-12-31"),
        };
        this.toggle = this.toggle.bind(this);
        this.iaDropdownToggle = this.iaDropdownToggle.bind(this);
        this.pfDropdownToggle = this.pfDropdownToggle.bind(this);

    }

    toggle() {
        if (this.state.isOpen) {
            this.setState({
                color: "transparent"
            });
        } else {
            this.setState({
                color: "white"
            });
        }
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    iaDropdownToggle(e) {
        this.setState({
            iaDropdownOpen: !this.state.iaDropdownOpen
        });
    }

    pfDropdownToggle(e) {
        this.setState({
            pfDropdownOpen: !this.state.pfDropdownOpen
        });
    }

    getBrand() {
        var name;
        dashboardRoutes.map((prop, key) => {
            if (prop.collapse) {
                prop.views.map((prop, key) => {
                    if (prop.path === this.props.location.pathname) {
                        name = prop.name;
                    }
                    return null;
                });
            } else {
                if (prop.redirect) {
                    if (prop.path === this.props.location.pathname) {
                        name = prop.name;
                    }
                } else {
                    if (prop.path === this.props.location.pathname) {
                        name = prop.name;
                    }
                }
            }
            return null;
        });
        return name;
    }

    openSidebar() {
        document.documentElement.classList.toggle("nav-open");
        this.refs.sidebarToggle.classList.toggle("toggled");
    }

    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    updateColor() {
        if (window.innerWidth < 993 && this.state.isOpen) {
            this.setState({
                color: "white"
            });
        } else {
            this.setState({
                color: "transparent"
            });
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateColor.bind(this));
        console.log({'Header Props': this.props});
    }

    componentDidUpdate(prevProps) {
        if (
            window.innerWidth < 993 &&
            prevProps.location.pathname !== this.props.location.pathname &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            this.refs.sidebarToggle.classList.toggle("toggled");
        }
        console.log({'Header Props Update': this.props});
    }

    render() {
        const customStyles = {
            valueContainer: (provided, state) => ({
                ...provided,
                textOverflow: "ellipsis",
                maxWidth: "190px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                display: "initial"
            })
        };

        
        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        };
        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        };
        const formatGroupLabel = data => (
            <div style={groupStyles}>
              <span>{data.label}</span>
              <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
          );
        return (
            <Navbar
                color={
                    this.state.color
                }
                expand="lg"
                className={
                   "navbar-absolute " +
                        (this.state.color === "transparent" ? "navbar-transparent " : "")
                }
            >
                <Container fluid>
                    <div className="navbar-wrapper">
                        <div className="navbar-toggle">
                            <button
                                type="button"
                                ref="sidebarToggle"
                                className="navbar-toggler"
                                onClick={() => this.openSidebar()}
                            >
                                <span className="navbar-toggler-bar bar1"/>
                                <span className="navbar-toggler-bar bar2"/>
                                <span className="navbar-toggler-bar bar3"/>
                            </button>
                        </div>
                        <NavbarBrand>{this.getBrand()}</NavbarBrand>
                    </div>
                    <NavbarToggler onClick={this.toggle}>
                        <span className="navbar-toggler-bar navbar-kebab"/>
                        <span className="navbar-toggler-bar navbar-kebab"/>
                        <span className="navbar-toggler-bar navbar-kebab"/>
                    </NavbarToggler>
                    
                </Container>
            </Navbar>
        );
    }
}

export default Header;
