import React from 'react';
import {connect} from 'react-redux';
import { 
    Badge, 
    Card,
    CardBody
} from 'reactstrap';
import { getUsersWithRoles } from '../../actions/permissions';


class UsersContainer extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
        
        }
    }

    componentDidMount() {
        this.props.getUsersWithRoles();
    }

    render() {
        console.log()
        if (this.props.roleUsersLoading) {
            return (
                <div>loading...</div>
            )
        } else {
            return(
                <div className={'users-container'}>
                    {this.props.roleUsers.map(user => {
                        return(
                            <Card className={'role-users-card'}>
                                <div className={'header'}>
                                    <span>
                                        <h5>{user.name + ' ' + user.last_name}</h5>
                                    </span>
                                    
                                    <span>
                                        {user.rut + '-' + user.dv}
                                    </span>
                                </div>
                                <div className={'subheader'}>
                                    {user.email}
                                </div>
                                <CardBody>
                                    <div className={'roles'}>
                                        <span>Roles</span>
                                        <br/>
                                        {user.roles.map(role => {
                                            return(
                                                <Badge color='primary'>{role.name}</Badge>
                                            )
                                        })}
                                    </div>
                                </CardBody>        
                            </Card>
                        )
                    })}
                </div>
            )
        }
    }
}

const mapStateToProps = state => ({
    roleUsers: state.permissions.roleUsers,
    roleUsersLoading: state.permissions.roleUsersLoading
})

const mapDispatchToProps = dispatch => ({
    getUsersWithRoles: () => dispatch(getUsersWithRoles())
})


export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);