import React from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {MATERIAL_TABLE_LOCALIZATION} from './../../../constants';

const theme = createTheme({
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        light: '#01579b',
        main: '#ff9100',
      },       
    },      
});

const Table = ({title, data, pending, handleRowSelected}) => {
    return (
        <div>
            <MuiThemeProvider theme={theme}>
                <MaterialTable
                    title={title}
                    columns={[
                        { title: 'Todas', field: 'name', width: 1000},                                      
                    ]}
                    data = {data}  
                    isLoading= {pending}
                    options={{
                        selection: true,
                        sorting: true,    
                        pageSize: 5,
                        pageSizeOptions: [5],                        
                        rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#37b15933' : '' }),
                    }}
                    onSelectionChange={(rows) => handleRowSelected(rows)}            
                    localization = {MATERIAL_TABLE_LOCALIZATION}                        
                />
            </MuiThemeProvider>
        </div>
    );
};

export default Table;