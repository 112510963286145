import {FONDOMUTUO_INFO_GET_INIT, FONDOMUTUO_INFO_GET_SUCCESS, FONDOMUTUO_INFO_GET_ERROR
} from '../constants';
import httpClient from '../api/httpClient';
import {urlFondoMutuoCardInfo} from '../api/urls';

export const getFondosCardInfo = () => {
    return (dispatch, getState) => {
        if(getState().fondoMutuo.fondos.length == 0){
            dispatch(_getFondosCardInfoInit())
            httpClient.getAll(urlFondoMutuoCardInfo)
            .then(res => {            
                dispatch(_getFondosCardInfoSuccess(res))
            })
            .catch(error => {            
                dispatch(_getFondosCardInfoError(error))
            })
        }
    }
}

const _getFondosCardInfoInit = () => ({
    type: FONDOMUTUO_INFO_GET_INIT
})

const _getFondosCardInfoSuccess = response => ({
    type: FONDOMUTUO_INFO_GET_SUCCESS,
    payload: {
        response
    }
})

const _getFondosCardInfoError = error => ({
    type: FONDOMUTUO_INFO_GET_ERROR,
    payload: {
        error
    }
})