import React from 'react'
import {
    Card,
    CardBody,
    Row,
    Col
} from "reactstrap";
import {
    InfoArea,
} from "../../../../components";
// View components
import ResumenNav from './ResumenNav/ResumenNav'
import ResumenBody from './ResumenBody/ResumenBody'


class Resumen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            hTabs: "ht1",
            modal: false
        };
        this.toggle = this.toggle.bind(this);
        this.setActiveTabHandler = this.setActiveTabHandler.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    componentDidMount() {
        console.log({Resumen: this.props.cartolaState})
    }

    openModal() {
        this.setState({
            modal: !this.state.modal
        })
    }

    /**
     * Toggle collapsable nav 
     * in mobile
     */
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    setActiveTabHandler(value) {
        this.setState({hTabs: value});
    }

    render() {        
        if (!this.props.fetchBreakdownFailed) {
            
            return(
                <Row>
                    <Col xs={12} md={12}>
                        <Card className="card-plain">
                            <ResumenNav 
                                toggle={this.toggle}
                                isOpen={this.state.isOpen}
                                setActiveTabHandler={this.setActiveTabHandler}
                                hTabs={this.state.hTabs}
                                startPeriod={this.props.startPeriod}
                                endPeriod={this.props.endPeriod}
                            />
                            <CardBody>
                                <ResumenBody 
                                    {...this.props}
                                    cartolaState={this.props.cartolaState}
                                    clpFormatter={this.props.clpFormatter}
                                    usdFormatter={this.props.usdFormatter}
                                    hTabs={this.state.hTabs}
                                />

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )
        } else {

            return(
                <Row>
                    <Col xs={12} md={12}>
                        <Card className="card-plain">
                            <ResumenNav 
                                toggle={this.toggle}
                                isOpen={this.state.isOpen}
                                setActiveTabHandler={this.setActiveTabHandler}
                                hTabs={this.state.hTabs}
                                startPeriod={this.props.startPeriod}
                                endPeriod={this.props.endPeriod}
                            />
                            <CardBody>
                                <InfoArea title={"No se encontraron movimientos para este periodo."} description={"Por favor pruebe a consultar en otro rango de fechas."} icon="now-ui-icons travel_info" iconColor="info" />
                            </CardBody>
                            
                                   
                        </Card>
                    </Col>
                </Row>
            )
        }
        
    }
}


export default Resumen;