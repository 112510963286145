import React, {Component, Fragment} from "react";
import { Route, Switch, withRouter } from 'react-router-dom';
import { PanelHeader } from '../../components';
import { NavLink } from "react-router-dom";
import {Card,CardBody, Alert, Button, CardTitle, CardText, Row, Col, Modal } from 'reactstrap';
import BreadcrumbContainer from '../Breadcrumb/BreadcrumbContainer';
import dashboardRoutes from '../../routes/dashboard';
import './roleUsersStyles.css';


class RolesUsersContainer extends Component {

    constructor(props){
        super(props);
        this.activeRoute.bind(this);

    }

    activeRoute(routeName) {
        const myPath = this.props.location.pathname.split('/');
        const myRoute = routeName.split('/');        
        if(myRoute.length > 1 && myPath.length > 1){
            return myPath[2].indexOf(myRoute[2]) > -1 ? "active" : "";
        }else{
            return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
        }
    }

    render() {
        const roleRoutes = dashboardRoutes.filter(route => route.name === 'Roles & Usuarios')[0];
        return(
            <div id={'main'}>
                <PanelHeader size="sm"/>
                <div className={'content'}>
                    
                    <Card className="card-stats card-raised">
                        <CardBody>
                            <div>
                                <h3 style={{
                                    color: '#f96332', marginBottom: '.3em'
                                }}>
                                    Roles y Permisos
                                </h3>
                                <span style={{color: 'gray'}}>Crear, Editar, Asignar</span>
                            </div>
                        </CardBody>
                    </Card>
                    <div className={'horiz-nav-container'}>
                        <ul className={'horiz-nav'}>
                            {roleRoutes.routes.map((route, key) => {
                                return(
                                    <li
                                        className={this.activeRoute(route.path)}
                                        key={key}
                                        
                                    >
                                        <NavLink 
                                            to={route.path}
                                            activeClassName="active"
                                        >
                                            <Button>
                                                {route.name}
                                            </Button>
                                        </NavLink>  
                                    </li>
                                );
                            })}
                                
                        </ul>
                    </div>      
                    <Switch>
                        {this.props.routes.map((route, i) => (
                            <Route path={route.path} render={() => <route.component />} key={i} />
                        ))}
                    </Switch>
                </div>
            </div>
        )
    }
}

export default withRouter(RolesUsersContainer);