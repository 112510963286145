import React from 'react'
import {
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Row,
    Col,
    Table
} from "reactstrap";

const ReactHighcharts = require('react-highcharts');
require('highcharts-3d')(ReactHighcharts.Highcharts);


class ResumenMoneda extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <Row>
                <Col xs={12} md={6}>
                    <Card className="card-plain">
                        <CardHeader>
                            <CardTitle tag="h5">
                                Moneda
                        </CardTitle>
                            <br />
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead style={{
                                    "fontSize": ".8em",
                                    "textTransform": "uppercase"
                                }}>
                                    <tr>
                                        <th>
                                            Moneda
                                </th>
                                        <th>
                                            Monto ($)
                                </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.consolidatedByCurrency === [] ?
                                        <p> Sin datos para el periodo
                                    seleccionado </p>
                                        :
                                        this.props.consolidatedByCurrency.map((prop, i) => {
                                            console.log(prop);
                                            return (
                                                <tr key={i}>
                                                    <td style={{"textTransform": "uppercase"}}>
                                                        {prop['moneda']}
                                                    </td>
                                                    <td>
                                                        ${this.props.clpFormatter.format(Math.trunc(parseFloat(prop['monto'])))}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <ReactHighcharts config={this.props.monedasConfig}>
                    </ReactHighcharts>
                </Col>
            </Row>
        )
    }
}

export default ResumenMoneda;