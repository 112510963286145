import React from 'react'
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    Col,
    Badge,
    ModalBody,
    ModalFooter,
    Modal,
    Button,
    ButtonGroup, ModalHeader
} from "reactstrap";
import List from './List';
import shuffle from 'lodash/shuffle';
import Tooltip from '@material-ui/core/Tooltip';
import DetalleCuentas from "./DetalleCuentas.jsx";

import "./resumenAdminStyles.css";

const ReactHighcharts = require('react-highcharts');

function compareValues(key, order='asc') {
    return function(a, b) {
      if(!a.hasOwnProperty(key) ||
         !b.hasOwnProperty(key)) {
          return 0;
      }

      const varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order == 'desc') ?
        (comparison * -1) : comparison
      );
    };
  }


class ResumenAdmin extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            modal: false,
            titulo: '',
            tipo: '',
            monto: '',
            percentage_assets: '',
            assetTypes: [],
            data: [],
            key: 'name',
            sort_order: 'asc',
            pageSubcategories: "",

            fadeIn: false,

        }

        this.toggleModal = this.toggleModal.bind(this);
        this.toggleCloseModal = this.toggleCloseModal.bind(this);
        this.shuffle = this.shuffle.bind(this);
        this.toggleSort = this.toggleSort.bind(this);
        this.sortAccount = this.sortAccount.bind(this);
        this.onClickAccount = this.onClickAccount.bind(this);
        this.toggleInformation = this.toggleInformation.bind(this);
    }

    componentDidMount() {
        let items = [];
        let i = 0;
        this.props.administradoras.map((admins) => {
            admins.cuentas.map((cuenta) => {
                let acct_obj = {};
                acct_obj['name'] = cuenta.nombre_cuenta[0];
                acct_obj['assets'] = cuenta.assettype;
                acct_obj['num_assets'] = cuenta.assettype.length;
                acct_obj['total'] = Math.trunc(parseFloat(this.props.consolidatedByAccount.filter(prop => prop.cuenta === cuenta.nombre_cuenta[0] && prop.tipo === 'Estandar')[0]['monto']));
                acct_obj['type'] = 'Estandar';
                acct_obj['status'] = acct_obj.total == 0 ? 'inactive' : 'active';
                acct_obj['admin'] = admins.nombre_admin[0];
                acct_obj['key'] = i++;
                items.push(acct_obj);
            })
            admins.cuentas_psh.map(cuenta => {
                let acct_obj = {};
                acct_obj['name'] = cuenta.nombre_cuenta[0];
                acct_obj['assets'] = cuenta.assettype;
                acct_obj['num_assets'] = cuenta.assettype.length;
                acct_obj['total'] = Math.trunc(parseFloat(this.props.consolidatedByAccount.filter(prop => prop.cuenta === cuenta.nombre_cuenta[0] && prop.tipo === 'Psh')[0]['monto']));
                acct_obj['type'] = 'Pershing';
                acct_obj['status'] = acct_obj.total == 0 ? 'inactive' : 'active';
                acct_obj['admin'] = admins.nombre_admin[0];
                acct_obj['key'] = i++;
                items.push(acct_obj);
            })
        });
        this.setState({ data: items });
    }

    toggleInformation(){
        console.log('Toogle')
        this.setState({
            fadeIn: !this.state.fadeIn
        })

    }

    toggleModal(assetTypes, titulo, tipo, monto) {

        const tituloArr = titulo.split('$');
        const newTitle = tituloArr.length === 3 
                        ? `${tituloArr[1]} ${tituloArr[0]} - ${tituloArr[2]}` 
                        : tituloArr[0]

        let assets = [];
        let account_total = monto;
        let selected_asset_type;
        for (var i = 0; i < assetTypes.length; i++) {

            let asset_object = {};
            asset_object['name'] = assetTypes[i]['nombre_assettype'][0];
            if (i == 0) {
                selected_asset_type = assetTypes[i]['nombre_assettype'][0];
            }
            asset_object['total'] = 0;
            assetTypes[i]['currencies'].map(curr => {
                let total = asset_object['total'] + curr.monto
                asset_object['total'] = total
            });
            console.log('ASSET OBJECT', asset_object)
            assets.push(asset_object);
        }

        let asset_percentage = assets.map(asset => [
            asset['name'],
            (parseInt(asset['total']) / account_total) * 100
        ]);

        this.setState(prevState => ({
            titulo: newTitle.toUpperCase(),
            assetTypes: assetTypes,
            tipo: tipo,
            monto: monto,
            percentage_assets: asset_percentage,
            pageSubcategories: selected_asset_type,
            modal: !prevState.modal
        }));
    }

    toggleCloseModal() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    shuffle() {
        this.setState(state => ({
            data: shuffle(this.state.data) }
        ));
    }

    toggleSort(key) {
        this.setState(prevState => ({
            key: key,
            sort_order: prevState.sort_order === 'asc' ? 'desc' : 'asc',
        }));
        this.sortAccount(key);
    }

    sortAccount(key) {
        return(this.state.data.sort(compareValues(key, this.state.sort_order)) )
    }

    onClickAccount(item) {
        console.log('ITEM', item)
        if(item.status !== 'inactive' ){
            return(this.toggleModal(item.assets, item.name, item.type, item.total))
        }
    }

    render() {

        const accountChartConfig = {
            chart: {
                type: 'pie',
                animation: {
                    duration: 1000
                },
                backgroundColor: "transparent"
            },
            title: {
                text: 'Patrimonio por Activo (%)'
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    depth: 35,
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: [{
                type: 'pie',
                name: '% Patrimonio',
                data: this.state.percentage_assets
            }]
        };

        return(
            <div>

            <Col xs={12} md={12}>
                <Card className="card-plain">
                    <span style={{color: "gray"}}>Ordenar por:</span>
                    <br />
                    <ButtonGroup>

                        <Button color={'primary'}  onClick={() => this.toggleSort('name')} >
                            Cuenta
                        </Button>
                        <Button color={'primary'} onClick={() => this.toggleSort('total')} >
                            Monto
                        </Button>
                        <Button color={'primary'} onClick={() => this.toggleSort('num_assets')} >
                            Activos
                        </Button>
                        <Button color={'primary'} onClick={() => this.toggleSort('admin')} >
                            Custodio
                        </Button>
                    </ButtonGroup>

                    <Tooltip title={<span style={{fontSize: "1.3em"}}>Usa los botones para ordenar por cuenta, monto, activos, etc..
                        y selecciona una cuenta para ver su detalle</span>}>
                        <span> <i className="now-ui-icons travel_info"></i></span>
                    </Tooltip>

                    {/* <UncontrolledAlert color="info">
                        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>
                        {' '} <b>HAZ CLICK EN LA CUENTA PARA VER EL DETALLE</b>
                    </UncontrolledAlert> */}

                    <List
                    className="main-list"
                    items={this.state.data}
                    keys={d => d.key}
                    // Check screen resolution for div heights
                    heights={window.innerWidth > 700 ? 100 : 500}
                    config={{ mass: 4, tension: 100, friction: 40 }}>
                            {item =>
                                <div className={item.status == 'inactive' ? "qip-account disabled": "qip-account"} onClick={() => this.onClickAccount(item)}>
                                    <div className="account account-title" >
                                        {/* {item.name} */}
                                        <div>{item.name.split('$')[1]}</div>
                                        <div>{item.name.split('$')[0]}</div>
                                        <div>{item.name.split('$')[2]}</div>
                                    </div>
                                    <div className="account account-name">
                                        <div className="account-name-header">
                                            ${this.props.clpFormatter.format(item.total)}
                                        </div>
                                        <div className="account-name-subtitle">
                                            Monto
                                        </div>
                                    </div>
                                    <div className="account account-assets">
                                        <div className="account-assets-header">
                                            {item.num_assets}
                                        </div>
                                        <div className="account-assets-subtitle">
                                            Clase de Activos
                                        </div>
                                    </div>
                                    <div className="account account-badge">

                                        <div className="account-badge-header">
                                            <Badge color={item.type == 'Pershing' ? "success" : "primary"}>{item.type}</Badge>
                                        </div>
                                        <div className="account-badge-subtitle">
                                            Tipo de Cuenta
                                        </div>
                                    </div>
                                    <div className="account account-admin">
                                        {item.admin == 'Principal' ?
                                            <img className="account-admin-logo" height="50" src="/images/principal-logo.png"  />
                                            : item.admin == 'LarrainVial' ?
                                            <img className="account-admin-logo" height="20" src="/images/lv-logo.png" />
                                              :   <img className="account-admin-logo" height="20" src="/images/fynsa-logo.svg" />
                                        }
                                        {/* <div className="account-admin-subtitle">
                                            Administradora
                                        </div> */}
                                    </div>

                                </div>
                            }
                    </List>
                </Card>
            </Col>


            <Modal style={{maxWidth: "90vw"}} isOpen={this.state.modal} toggle={this.toggleCloseModal} size={'lg'}>
                <div className="account-modal-header">
                    <div className="title" >
                        {this.state.titulo}
                    </div>
                </div>
                <ModalHeader toggle={this.toggleCloseModal}></ModalHeader>
                <ModalBody>
                    <div className="account-details">
                        <div className="account-details-header">
                            <div className="account assets">
                                <div className="value">
                                    {this.state.assetTypes.length}
                                </div>
                                <div className="icon">
                                    <i className="now-ui-icons business_chart-pie-36"></i>
                                </div>
                                <div className="name">
                                    Clase de Activos
                                </div>
                            </div>
                            <div className="account type">
                                <div className="value">{this.state.tipo}</div>
                                <div className="icon">
                                    <i className="now-ui-icons business_bank"></i>
                                </div>
                                <div className="name">
                                    Tipo Cuenta
                                </div>
                            </div>
                            <div className="account total">
                                <div className="value">
                                    ${this.props.clpFormatter.format(this.state.monto)}
                                </div>
                                <div className="icon">
                                    <i className="now-ui-icons business_money-coins"></i>
                                </div>
                                <div className="name">
                                    Monto
                                </div>
                            </div>
                        </div>
                        <div className="account-details-body">
                            <div className="chart">
                                <ReactHighcharts config={accountChartConfig}>
                                </ReactHighcharts>
                            </div>

                                 {this.state.assetTypes ?
                                    <div className="tables">
                                    <Nav
                                        pills
                                        className="nav-pills-primary nav-pills-icons"
                                    >

                                        {this.state.assetTypes.map((asset, i) =>
                                        (<NavItem key={i}>

                                            <NavLink style={{ 'cursor': 'pointer', boxShadow: 'none' }}
                                                className={
                                                    this.state.pageSubcategories === asset.nombre_assettype[0] ? "active" : ""
                                                }
                                                onClick={() =>
                                                    this.setState({ pageSubcategories: asset.nombre_assettype[0] })
                                                }
                                            >
                                                {asset.nombre_assettype[0]}
                                            </NavLink>

                                        </NavItem>)
                                        )}

                                    </Nav>
                                    <TabContent
                                        className="tab-space tab-subcategories"
                                        activeTab={this.state.pageSubcategories}
                                        style={{boxShadow: 'none'}}
                                    >
                                        {this.state.assetTypes.map((asset, i) =>
                                        (<TabPane key={i} tabId={asset.nombre_assettype[0]}>
                                            <div key={i} className='account-details-table'>
                                                <DetalleCuentas data={asset.currencies} />
                                            </div>
                                        </TabPane>)
                                        )}
                                    </TabContent>
                                </div>

                                :
                                <div>Sin información</div>
                            }
                        </div>
                    </div>



                </ModalBody>
                <ModalFooter style={{marginTop: '2em'}}>

                    <Button color="primary" onClick={this.toggleCloseModal}>Cerrar</Button>

                </ModalFooter>

            </Modal>



            </div>
        )
    }
}

export default ResumenAdmin;
