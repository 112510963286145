import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import {fetchTourSteps, tourSave} from '../actions/withTour';
import TourCarousel from '../components/TourCarousel/TourCarousel';

const withTour = WrappedComponent => {
    class HOC extends React.Component {
        constructor(props){
            super(props);
            this.state = {
                tourId: "",
                viewTourSteps: [],
                active: true,
                show: true,
                tourHistory: []
            }
            this.handleTour = this.handleTour.bind(this);
            this.save = this.save.bind(this);
            this.showTour = this.showTour.bind(this);
            this.paneActive = this.paneActive.bind(this);

        }

        componentDidMount() {
            console.log('withTour HOC!', this.props);
            this.handleTour();            
        }

        componentDidUpdate(prevProps) {
         //   console.log(' withTour componentDidUpdate', prevProps)
            if (prevProps.tourSteps != this.props.tourSteps || prevProps.location.pathname != this.props.location.pathname) {
                this.handleTour();
            } else {
         //       console.log('No match!')
            }
        }

        handleTour() {

            console.log('handleTour', this.props, this.state);

            let tourSteps = this.props.tourSteps.filter(tour => tour.tour_view_name === this.props.location.pathname)
            if (tourSteps.length > 0) {
                const tourId= tourSteps[0].id;

                if(this.showTour(tourId)){
                    console.log('tourSteps.tour_steps', tourSteps[0].tour_steps);
                    this.setState({viewTourSteps:tourSteps[0].tour_steps, tourId, show: true})
                }else{
                    this.setState({viewTourSteps:tourSteps[0].tour_steps, tourId, show: false})
                }

            } 
            
        }

        save(tourComplete = true){
            this.props.tourSave(this.state.tourId, tourComplete);
        }

        showTour(tourId){
            const tourCompleteList = this.props.tourHistoryUser.filter(x => x.tour_complete).map(y => y.tour_view_id);
            if(tourCompleteList.length === 0) return true;            
            if(tourCompleteList.includes(tourId)){
                return false;
            }else{
                return true;
            }
        }

        paneActive(){
            this.setState(state=>({
                show: !state.show
            }))
        }

        render() {
            if (this.state.active ){
                return (
                    <div>
                        <TourCarousel 
                            tourSteps={this.state.viewTourSteps} 
                            step={0} 
                            save={this.save} 
                            paneActive={this.state.show}
                            setPaneActive={this.paneActive}
                        />
                        <WrappedComponent {...this.props}  />
                    </div>
                )
            } else {
                return( <div>
                    <WrappedComponent {...this.props}  />
                </div>)
            }
        }
    }

    const mapStateToProps = state => {
        return {
            tourSteps: state.withTour.tourSteps,
            tourHistoryUser: state.user.tourHistory
        }
    }

    const mapDispatchToProps = dispatch => ({
        fetchTourSteps: () => dispatch(fetchTourSteps()),
        tourSave: (view, complete) => dispatch(tourSave(view, complete))
    })

    return connect(mapStateToProps, mapDispatchToProps)(withRouter(HOC));
}

export default withTour;