import React from "react";
import {
    Row,
    Col
} from "reactstrap";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import {
    PanelHeader,
    Statistics
} from "../../../../components";
import { Line } from 'react-chartjs-2';


const pageHeaderStyles = {
    detailsRow: {
        textAlign: "left",
        color: "white",
        marginBottom: "4em",
        paddingLeft: "2.3em"
    },
    detailsHeader: {
        borderBottom: '1px solid #656565',
        fontWeight: '300',
        padding: '.5em 0',
        width: '50%',
        color: 'white'
    },
    detailsSubHeader: {
        fontWeight: '400',
        color: '#fff',
        fontSize: '0.7142em'
    },
    statsRow: {
        textAlign: "center",
        color: "white",
        marginBottom: "4em"
    },
    statsTitle: {
        fontSize: "1.7em",
        textAlign: "center",
        fontWeight: "300",
        color: "white"
    },
    statsSubTitle: {
        fontWeight: '300',
        color: 'white'
    }
}

const chartColor = '#182956';

const options = {
    elements: {
        point:{
            radius: 0
        }
    },
    maintainAspectRatio: false,
    legend: {
        display: false
    },
    tooltips: {
        bodySpacing: 4,
        mode:"nearest",
        intersect: 0,
        position:"nearest",
        xPadding:10,
        yPadding:10,
        caretPadding:10
    },
    responsive: 1,
    scales: {
        yAxes: [{
            display:true,
            ticks: {
                display: false
            },
            gridLines: {
                zeroLineColor: "transparent",
                drawTicks: false,
                display: false,
                drawBorder: false
            }
        }],
        xAxes: [{
            display:true,
            ticks: {
                display: false
            },
            gridLines: {
                zeroLineColor: "transparent",
                drawTicks: false,
                display: false,
                drawBorder: false
            }
        }]
    },
    layout:{
        padding:{left:0,right:0,top:15,bottom:15}
    }
};


class HeaderPanel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }

    }

    render() {
        const data = (canvas) => {
            var ctx = canvas.getContext("2d");

            var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
            gradientStroke.addColorStop(0, '#80b6f4');
            gradientStroke.addColorStop(1, chartColor);

            var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
            gradientFill.addColorStop(0, "rgba(24, 41, 86, 0)");
            gradientFill.addColorStop(1, "rgba(249, 99, 59, 0.40)");
            return {
                labels: this.props.equityCurve.map(d => d.fecha),
                datasets: [{
                    label: "Rentabilidad",
                    borderColor: "#f96332",
                    pointBorderColor: "#FFF",
                    pointBackgroundColor: "#f96332",
                    pointBorderWidth: 2,
                    pointHoverRadius: 3,
                    pointHoverBorderWidth: 3,
                    pointRadius: 0,
                    fill: true,
                    backgroundColor: gradientFill,
                    borderWidth: 2,
                    data: this.props.equityCurve.map(d => d.ec)
                }]
            }
        };
        return (
            <PanelHeader
                colorSecondary= {this.props.colorSecondary}
                size="lg"
                content={
                    <div>
                        <Row style={pageHeaderStyles.detailsRow}>
                            <Col className={"first"} xs={12} md={6}>
                                <p className="h6" style={pageHeaderStyles.detailsHeader}>Periodo Efectivo</p>
                                <p style={pageHeaderStyles.detailsSubHeader}>{this.props.startPeriod} - {this.props.endPeriod}</p>
                            </Col>
                        </Row>
                        {/*
                        <Row style={{height: '200px'}}>
                            <Line data={data} options={options} />
                        </Row>
                        */}
                        <Row style={pageHeaderStyles.statsRow}>
                            <Col className={"sixth"} xs={12} md={4}>
                                <Statistics
                                    hasIcon={true}
                                    iconState="success"
                                    icon="business_money-coins"
                                    title={this.props.fetchBreakdownLoading ?
                                        <SkeletonTheme color="#182956" highlightColor="#294389">
                                            <Skeleton duration={2} height={60} />
                                        </SkeletonTheme>
                                        : this.props.fetchBreakdownFailed ?
                                            <div style={pageHeaderStyles.statsTitle}>
                                                --
                                        </div>
                                            :
                                            <span style={pageHeaderStyles.statsTitle}>
                                                ${this.props.montoTotal}
                                            </span>
                                    }
                                    subtitle={
                                        <p className="h6"  style={pageHeaderStyles.statsSubTitle}>Patrimonio Fin Periodo</p>
                                    }
                                />
                            </Col>
                            <Col className={"seventh"} xs={12} md={4}>
                                <Statistics
                                    hasIcon={true}
                                    iconState="info"
                                    icon="business_chart-bar-32"
                                    title={this.props.fetchBreakdownLoading ?
                                        <SkeletonTheme color="#182956" highlightColor="#294389">
                                            <Skeleton duration={2} height={60} />
                                        </SkeletonTheme>
                                        : this.props.fetchBreakdownFailed ?
                                            <div style={pageHeaderStyles.statsTitle}>
                                                --
                                            </div> :
                                            <span style={pageHeaderStyles.statsTitle}>{this.props.consolidated[0]['rentabilidadReal'] ? (parseFloat(this.props.consolidated[0]['rentabilidadReal']) * 100).toFixed(2) : 0}%</span>
                                    }
                                    subtitle={
                                        <p className="h6"  style={pageHeaderStyles.statsSubTitle}>Rentabilidad Real</p>
                                    }
                                />
                            </Col>
                            <Col className={"eighth"} xs={12} md={4}>
                                <Statistics
                                    hasIcon={true}
                                    iconState="warning"
                                    icon="business_bank"
                                    title={this.props.fetchBreakdownLoading ?
                                        <SkeletonTheme color="#182956" highlightColor="#294389">
                                            <Skeleton duration={2} height={60} />
                                        </SkeletonTheme>
                                        : this.props.fetchBreakdownFailed ?
                                            <div style={pageHeaderStyles.statsTitle}>
                                                --
                                            </div> :
                                            <span style={pageHeaderStyles.statsTitle}>{this.props.consolidated[0]['rentabilidadNominal'] ? (parseFloat(this.props.consolidated[0]['rentabilidadNominal']) * 100).toFixed(2) : 0}%</span>
                                    }
                                    subtitle={
                                        <p className="h6"  style={pageHeaderStyles.statsSubTitle}>Rentabilidad Nominal</p>
                                    }
                                />
                            </Col>
                        </Row>
                        {/*
                            <Row>
                                <Col md={12} xs={12}>

                                    <div id="visualization"
                                        style={{width: "100%", height: "600px", margin: "0 auto",}}></div>
                                </Col>

                            </Row>
                        */}
                    </div>
                } />
        )
    }

}

HeaderPanel.propTypes = {
    fetchBreakdownLoading: PropTypes.bool.isRequired,
    fetchBreakdownFailed: PropTypes.bool,
    startPeriod: PropTypes.string,
    endPeriod: PropTypes.string,
    montoTotal: PropTypes.string,
    consolidated: PropTypes.array,
    colorSecondary: PropTypes.string,
    equityCurve: PropTypes.array
}


export default HeaderPanel;
