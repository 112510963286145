import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: 600,
    },
});

const Fondo = ({data}) => {
    const classes = useStyles();

    //Sort weight column desc
    data.sort((a, b)=> b.weight - a.weight)

    return (
        <div>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="portafolio_actual">
                    <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Rut</TableCell>
                        <TableCell>Fondo</TableCell>                          
                        <TableCell>Serie</TableCell> 
                        <TableCell>Dist.%</TableCell>               
                    </TableRow>
                    </TableHead>
                    <TableBody>   
                    {data.map((row, i) => (
                        <TableRow key={i}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.rut}</TableCell>
                            {/* <TableCell>{row.nombre_instrumento.toLowerCase()}</TableCell> */}
                            <TableCell>{row.nombre_instrumento}</TableCell>
                            <TableCell>{row.serie}</TableCell>
                            <TableCell>{(row.weight * 100).toFixed(2)}%</TableCell>                             
                        </TableRow>                            
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Fondo;