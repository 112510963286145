import React, { Component } from 'react';
import Card from '../../../components/AdminDashboard/RentabilidadCarteras/Card';

const data= '3,7 %'

class CardContainer extends Component {
    render() {
        return (
            <div>
                <Card data={data}/>
            </div>
        );
    }
}

export default CardContainer;