import React from 'react';

function Login({ logo, children }) {

    return (
        <>
            <div className="h-screen relative bg-white overflow-hidden">
                <div className="max-w-7xl h-screen">
                    <div className="h-screen relative flex justify-center z-10 bg-white lg:max-w-2xl lg:w-full">
                        <svg
                            className="hidden lg:block absolute right-0 inset-y-0 h-full w-60 text-white transform translate-x-full"
                            fill="currentColor"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="none"
                            aria-hidden="true"
                        >
                            <polygon points="50,0 100,0 50,100 0,100" />
                        </svg>
                        <main className="mx-auto max-w-full px-4 sm:px-6 lg:px-8">
                            <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">

                                <div className="max-w-md w-full space-y-8">
                                    <div>
                                        <img
                                            className="mx-auto h-12 w-auto"
                                            src={logo}
                                            alt="Workflow"
                                        />
                                    </div>

                                    {children}

                                </div>
                            </div>
                        </main>
                    </div>
                </div>
                <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                    <img
                        className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                        src="/images/santiago-min.jpg"
                        alt=""
                    />
                </div>
            </div>

        </>
    )
}

export default Login;