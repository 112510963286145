import React from 'react';
import { NavLink } from 'react-router-dom';
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Portafolio from './Result/Portafolio';
import Graphic from './Result/Graphic';
import Rebalanceo from './Result/Rebalanceo';
import {urlRebalanceHistorialHome, urlRebalanceHistorialAccounts} from '../../../api/urls';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import SelectedPortafolio from '../SelectedPortafolio';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //   border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 1, 1),
        minWidth: 900,
        minHeight: 610,
        // width: 776,
        // height: 472 
    },
    table: {
        minWidth: 600,        
      },
  }));


const Rebalance = (props) => {

    const {rebalance, pdfRebalance, getResponse, generatedDate, handleSelectedPort, 
        portafolioRebalance, isLoading, params, selectedPortafolio, comparePortafolio, paramsTable } = props
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getPdfData = () => {
        return {
            data: {                    
                account     : rebalance.account,
                fondos      : rebalance.fondos,
                info        : rebalance.info,
                labelMatriz : rebalance.labelMatriz,
                newCor_mat  :  rebalance.newCor_mat,
                portafolioGraph : rebalance.portafolioGraph,
                carteraGraph    : rebalance.carteraGraph,
                weights         : rebalance.weights
            }
        }
    }
    
    const handleSelectedPortafolio = data => {
        handleSelectedPort(data)
        handleOpen()
    }

    return (
        <div>
        <Card>
            <CardHeader>
                <Breadcrumbs aria-label="breadcrumb">
                        <NavLink to={`${urlRebalanceHistorialHome}?${paramsTable}`} >Historial Rebalanceador</NavLink>
                        <NavLink to={`${urlRebalanceHistorialAccounts}?rut_client=${params.rut_client}&cuenta=${params.cuenta}&${paramsTable}`}>
                            Historial de Cuenta
                        </NavLink>                        
                        <Typography color="textPrimary">Rebalanceo</Typography>
                </Breadcrumbs>

            </CardHeader>
            <CardBody>

            {isLoading ? 
                <div className="text-center" style={{height:'600px'}}>
                    <div style={{paddingTop:'200px'}}>
                        <h4>Obteniendo información...</h4>
                        <CircularProgress />
                    </div>                    
                </div>
            :
            rebalance ?

                <div>               
                    <Portafolio 
                        accountSelected={rebalance.account}
                        funds={rebalance.fondos}
                        info ={rebalance.info} 
                        handleExportPdf={pdfRebalance} 
                        pdfData={getPdfData()}
                        getResponse={getResponse}
                        generatedDate={generatedDate}
                        seriesNotFound={rebalance.seriesNotFound}
                        seriesNotFoundPesos={rebalance.seriesNotFoundPesos}
                    />
                    <Graphic 
                        label={rebalance.labelMatriz} 
                        matriz={rebalance.newCor_mat} 
                        portafolioGraph={rebalance.portafolioGraph} 
                        carteraGraph={rebalance.carteraGraph}/>

                    <Rebalanceo weights={rebalance.weights} 
                        handleSelectedPort={handleSelectedPortafolio}
                        portafolioRebalance={portafolioRebalance}
                        />
                </div>

                :
                <div className="text-center" style={{height:'600px'}}>
                    <div style={{paddingTop:'200px'}}>
                        <h4>No existe información asociada</h4>
                    </div>                    
                </div>

            }

            </CardBody>
        </Card>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
            >
            <Fade in={open}>
            <div className={classes.paper}>

                {rebalance &&
                    <SelectedPortafolio                     
                        handleReturn={handleClose}
                        accountSelected={rebalance.account}                        
                        selectedPortafolio={selectedPortafolio}
                        comparePortafolio={comparePortafolio}                        
                        getResponse={getResponse}
                    />
                }
                                                      
            </div>
            </Fade>
        </Modal>
        </div>
    );
};

export default Rebalance;