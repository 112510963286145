import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {Row, Col} from "reactstrap";
import AssignAccount from './AssignAccount';

import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({    
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {      
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '115ch',
	},
	root: {
		width: '100%',
		'& > * + *': {
		  marginTop: theme.spacing(2),
        },
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '25ch',
    },
    // selectEmpty: {
    //     marginTop: theme.spacing(2),
    // },
    // shadows: ["none"]
}));


const ModalClient = ({
    riskProfile,
    handleChangeRiskProfile,
    handleCloseModal,
    open,
    modalOption,
    modalTittle,
    isLoading,
    riskProfileArray,
    cuentasAdministradoras,
    pendingAccounts,
    handleRowAccountSelected,
    actionMessage,
    error,
    reset,            
    handleAddClientConfirm,
    handleAddClientAccountConfirm
}) => {

    const classes = useStyles();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
            >
            <Fade in={open}>
            <div className={classes.paper}>			
                <h5 id="transition-modal-title">{modalTittle}</h5>
                <hr />

                <Collapse in={actionMessage ? true : false}>
                    <Alert
                        severity={error ? "error": "success"}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                reset();
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {actionMessage}
                    </Alert>
                </Collapse>

                {modalOption == 0 &&

                    <div>
                        <form className={classes.root} noValidate autoComplete="off">
                            <TextField id="rutForm" label="Rut"/> 
                            <TextField id="nameForm" label="Nombre"/>
                            <TextField id="lastNameForm" label="Apellido"/>
                            <TextField id="emailForm" label="Email" type="email"/>                   

                            <FormControl className={classes.formControl}>  
                                <InputLabel id="riskId-select-label">Perfil de Riesgo</InputLabel>                  
                                <Select labelId="riskId-select-label" id="riskId"
                                    value={riskProfile}
                                    onChange={handleChangeRiskProfile}
                                >
                                    {!isLoading && riskProfileArray.map(obj=> <MenuItem key={obj.id} value={obj.id}>{obj.name}</MenuItem>)}                    
                                </Select>
                            </FormControl>
                        </form>
                        {/* <h5 id="transition-modal-title">Cuentas del Cliente</h5>
                        <hr /> */}
                    </div>
                }

                {modalOption != 0 && 
                    <AssignAccount 
                        cuentasAdministradoras={cuentasAdministradoras}
                        pendingAccounts={pendingAccounts}
                        handleRowAccountSelected={handleRowAccountSelected}
                        actionMessage={actionMessage}
                        error={error}
                        reset={reset}
                    />
                }                

                <hr />

                <Row>
                    <Col md={12}>
                        <Button variant="contained" onClick={handleCloseModal}>Cerrar</Button>
                        <Button variant="contained" color="primary" 
                            onClick={modalOption == 0 ? handleAddClientConfirm : handleAddClientAccountConfirm}
                            className="float-right"
                        >Aceptar</Button>
                    </Col>
                </Row>
                
            </div>
            </Fade>
        </Modal>    

    );
};

export default ModalClient;