import React, { useState, useEffect } from 'react';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { Statistics } from "../../../components";
import {Card, CardBody,	CardHeader, Row, Col} from "reactstrap";
import './quickEditRisk.css';


function QuickEditRisk({riskProfile, riskProfileList}) {
    
    const [isShown, setIsShown] = useState(false);
    const [_riskProfileList, _setRiskProfileList] = useState(riskProfileList)
    
    useEffect(() => {
        _setRiskProfileList(riskProfileList)
        console.log('_riskProfileList: ', riskProfileList)
    })
    
    return(
        <div>
            <div className="edit-risk" onClick={() => setIsShown(!isShown)}>
            </div>
            <Statistics
                horizontal
                icon={
                    "business_chart-bar-32"
                }
                title={`${riskProfile}`}
                subtitle="perfil de riesgo"
                iconState="default"
            >
            </Statistics>
            
                
            
            
            {isShown && (
                <Card style={{zIndex: '300', borderRadius: '5px'}}>
                    {_riskProfileList.map((item, key) =>  
                        <div>
                            <Statistics
                                key={key}
                                horizontal
                                icon={
                                    "business_chart-bar-32"
                                }
                                title={`${item.name}`}
                                subtitle="perfil de riesgo"
                                iconState="success"
                            >
                            </Statistics>
                            <Divider />
                        </div>
                    )}
            </Card>
            )
            
            }
                
        </div>
    )
}


export default QuickEditRisk;