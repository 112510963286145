const urlBase = 'https://qip-portal-qa.com'

//Url Fetch
export const urlLogin = `/api/login`;
export const urlForgotPassword = '/api/password/email';
export const urlPasswordReset = '/api/password/reset';

//export const urlLogout = `${urlBase}/api/logout_api`;

export const urlLogout = `/api/auth/logout`;
export const urlDoNotLogout = `/api/auth/donotlogout`;

//Advisor card
export const urlReminders = `/api/auth/reminderscard`;
export const urlPatrimony = `/api/auth/patrimonycard`;
export const urlPatrimonyInfo = `/api/auth/patrimonyinfocard`;
export const urlclientsCard = `/api/auth/clientscard`;
export const urlclientsCardInfo = `/api/auth/clientscardinfo`;
export const urlstaffCard = `/api/auth/staffcard`;
export const urlstaffCardInfo = `/api/auth/staffcardinfo`;
export const urlFondoMutuoCardInfo = `/api/auth/fondomutuocardinfo`;
export const urlActivoCardInfo = `/api/auth/activoscardinfo`;
export const urlAdministradoraCard = `/api/auth/administradoracard`;
export const urlAdministradoraCardInfo = `/api/auth/administradoracardinfo`;
export const urlAdministradoras = `/api/auth/administrators`;
export const urlCuentasAdministradora = `/api/auth/administratoraccounts`;

//Cartola Advisor client
export const urlCartolaClientAdvisor = `/api/auth/cartolaclientadvisor`;

// User endpoint
export const fetchUser = `/api/auth/user`;

export const urlClients = `/api/auth/clients`;
export const urlSendInvitation = `/api/auth/sendinvitation`;

export const urlStaff = `/api/auth/staff`;
export const urlStaffAssignClients = `/api/auth/staff/assign_clients`;
export const urlAccounts = '/api/auth/accounts';
export const urlFundsByAccount = '/api/auth/funds_by_account';
export const urlSeriesByFund = '/api/auth/series_by_fund';
export const urlSetRebalance = '/api/auth/set_rebalance';
export const urlRebalancePdf = '/api/auth/rebalance-pdf';
export const urlSaveSelectedPortafolio = '/api/auth/rebalance-saveportafolio';
export const urlHistorialRebalance = '/api/auth/historial_rebalance';
export const urlHistorialAccount = '/api/auth/historial_account';
export const urlHistorialAccountRebalance = '/api/auth/historial_account_rebalance';

export const urlUpdateRebHistorialDone = '/api/auth/update_rebhistorial_done';
export const urlUpdateClientAccounts = '/api/auth/clients/update_accounts';


export const urlUpload     = '/api/auth/upload'; //Resources
export const urlUploadFile = '/api/auth/upload/file';

export const urlListClientsCartola = '/api/auth/listclientscartola';

//Cartola
export const urlFetchComposition = '/api/auth/fetch-composition';
export const urlFetchBreakdown = '/api/auth/fetch-breakdown';
export const urlGroupComposition = '/api/auth/fetch-group-composition';
export const urlGroupBreakdown = '/api/auth/fetch-group-breakdown';

// Grupos
export const urlGroupList = '/api/auth/grouplist';


//Url App Router
export const urlUser  = `/dashboard/cartolas`;
export const urlAdmin = `/dashboard/admin`;

// Cartolas
export const urlCartolasHome = `/dashboard/cartolas/home`;
export const urlCartolasList = `/dashboard/cartolas/list`;
export const urlCartolasClient = `/dashboard/cartolas/client`;

// Cartolas Group
export const urlCartolaListGroup = `/dashboard/cartolas/groups`;

// Roles & Permissions
export const userPermissions = '/api/auth/permissions';
export const userRoles = '/api/auth/role';
export const rolePermissions = '/api/auth/role-permissions';
export const assignToAll = '/api/auth/assign-to-all';
export const usersWithRolesUrl = '/api/auth/roles-users';

// Tours

export const urlTourSteps =  '/api/tour-steps'
export const saveUserTour = '/api/auth/toursave'

// Clients
export const urlClientsHome = '/dashboard/clients/home';
export const urlClientsDetails = '/dashboard/clients/details';
export const urlClientsAssign = '/dashboard/clients/assign';
export const urlUpdateClientUser = '/api/auth/user-client'
// Staff
export const urlStaffHome = '/dashboard/staff/home';
export const urlStaffDetails = '/dashboard/staff/details';
export const urlStaffAssign = '/dashboard/staff/assign';

// Rebalance Historial
export const urlRebalanceHistorialHome = '/dashboard/rebalancehistorial/home';
export const urlRebalanceHistorialAccounts = '/dashboard/rebalancehistorial/accounts';
export const urlRebalanceHistorialRebalance = '/dashboard/rebalancehistorial/rebalance';


// Tenant
export const urlTenantInfo = '/api/tenant-details';
