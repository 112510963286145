import React from 'react';

const Render = ({data}) => {
    if(data){
        return <div className="container">
            <h1 className="ml-5 mt-5">{data}</h1>
        </div>
    }else{
        return <p>Sin datos</p>
    }
}

const Card = ({data}) => {
    return (
        <div>            
            <Render data={data}/>
        </div>
    );
}

export default Card;