import Axios from 'axios';
import auth from './auth';

let windowUrlArr = window.location.host.split('.');
let tenantSubdomain = windowUrlArr[0];
// let baseURL = `https://${tenantSubdomain}.sqa.services.mrvn-internal.net`;
let baseURL = `https://${tenantSubdomain}.localhost:8000`;
const headers = () =>{
    Axios.defaults.headers.common['Authorization'] = `Bearer ${auth.getToken()}`;
    Axios.defaults.headers.common['content-type'] = "application/json";
    Axios.defaults.headers.common['Accept'] = "application/json";
}

const headersNoAuth = () =>{
    Axios.defaults.headers.common['content-type'] = "application/json";
    Axios.defaults.headers.common['Accept'] = "application/json";
}

const headersTest = () =>{Axios.defaults.headers.common['Authorization'] = `Bearer ${document.querySelector("meta[name='access-token']").getAttribute("content")}`;}

const testHeaders = token => {Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;}
/* Axios.interceptors.request.use((config) => {

    const token = auth.getToken();
    console.log(auth.isTokenExpired(token));
    console.log(config);

    if(auth.isTokenExpired(token)){
        //window.location ='/dashboard/cartolas';         
    }
    return config;
  }, function (error) {
    // Do something with request error
    //return Promise.reject(error);
  }); */

  Axios.interceptors.response.use(response => {
    return response
    }, error => {
    const originalRequest = error.config;
    // console.log(originalRequest)
    if (error.response.status === 401 && !originalRequest._retry) {
        //Not repeat action in a new error
        originalRequest._retry = true;
        // window.location = window.location.href;
        console.log("Axios.interceptors LOGOUT")
        auth.logout();                                    
        window.location.href = '/login';
    } 
    // return Error object with Promise
    return Promise.reject(error);
 });

const testGet = (url, token) => {
    testHeaders(token);
    return Axios.get(`${url}`);
}

const login = (url, data) => {   
    return Axios.post(`${baseURL}${url}`, data);
}

const getNoAuth = (url) => {
    headersNoAuth();
    return Axios.get(`${baseURL}${url}`);
}

const getAll = (url) => {
    headers();
    return Axios.get(`${baseURL}${url}`);
}
 
const get = (url, id) => {
    headers();
    return Axios.get(`${baseURL}${url}/${id}`);
}
 
const create = (url, data) => {
    headers ();
    return Axios.post(`${baseURL}${url}`, data);
}
 
const update = (url, id, data) => {
    headers();
    return Axios.put(`${baseURL}${url}/${id}`, data);
}

const del = (url, id) => {
    headers();
    return Axios.delete(`${baseURL}${url}/${id}`);
}

const downloadPdf = (url, data) => {
    headers();
    return Axios.post(`${baseURL}${url}`, data
    , {responseType: 'blob'}
    );
}

export default {getAll, get, create, update, del, login, testGet, downloadPdf, getNoAuth};
