import React from 'react';
import {Card, CardBody,	CardHeader, Row, Col} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import TableFondos from './TableFondos';
import TableSeries from './TableSeries';
import Confirmar from './Confirmar';

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));

const index = ({onConfirm, handleClose, funds, isLoadingfunds, getSeriesFund, seriesByFund, isLoadingSeries}) => {

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = [
        'Seleccionar Fondo', 'Seleccionar Serie', 'Confirmar'
    ];

    const [fundSelected, setFundSelected] = React.useState();
    const [serieSelected, setSerieSelected] = React.useState();
    const [showButtons, setShowButtons] = React.useState(false);

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        if(activeStep === 0){
            setShowButtons(false)
		}else if(activeStep === 1){            
            setShowButtons(false)
        }	  
    };

    const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleReset = () => {
		setActiveStep(0);				  
    };

    // const getSeries = () => {
    //     if(serieSelected){
    //         if(serieSelected[0].rut != fundSelected[0].rut){
    //             getSeriesFund(fundSelected);
    //         }
    //     }else{
    //         getSeriesFund(fundSelected) 
    //     }               
    // }

    const confirm = () => {
        const {id, rut, entidad, tipo, vigencia} = fundSelected;
        const {serie, caracteristicas} = serieSelected;
        onConfirm({
            id,
            rut,
            entidad,
            tipo, 
            vigencia,
            serie,
            caracteristicas
        });
    }

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
			case 0:
                return <TableFondos onClick={selectedFund} 
                                    data={funds} 
                                    isLoading={isLoadingfunds}/>
			case 1:					
                return <TableSeries onClick={selectedSerie} 
                                    // getSeries={getSeries} 
                                    data={seriesByFund}
                                    isLoading={isLoadingSeries}
                                    />
			case 2:							
				return <Confirmar confirm={confirm} fund={fundSelected} serie={serieSelected}/>
            default:
				return 'No especificado';
        }
    }

    const selectedFund = fund => {
        setFundSelected(fund)
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        // setShowButtons(data.length == 1 ? true: false)
        
        if(serieSelected){
            if(serieSelected.rut != fund.rut){
                getSeriesFund(fund);
            }
        }else{
            getSeriesFund(fund) 
        } 
    }

    const selectedSerie = data => {
        setSerieSelected(data)
        setActiveStep(prevActiveStep => prevActiveStep + 1);
		// setShowButtons(data.length == 1 ? true: false)
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <div>   
                                            
                    <Row> 
                        <Col md={4}>
                            <div className="text-center">

                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.backButton}
                                    variant="outlined"
                                >Atras</Button>
                                
                            </div>    
                        </Col>  
                        <Col md={4}>
                            <div className="text-center">

                                <Button variant="outlined" color="secondary" onClick={handleClose}>
                                    Cerrar    
                                </Button> 

                            </div>    
                        </Col>
                        <Col md={4}>
                            <div className="text-center">
                                
                                {showButtons &&
                                <Button variant="contained" color="primary" onClick={handleNext}>                                
                                    {activeStep === steps.length - 1 ? 'Terminar': 'Siguiente'}                            
                                </Button> 
                                }  

                            </div>    
                        </Col>                         
                  
                    </Row>
                                            
                    <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                </div>
            </div>
        </div>
        
    );
};

export default index;