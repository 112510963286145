import React, {useState} from 'react';
import { Button } from "reactstrap";
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Lock from '@material-ui/icons/Lock';
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined';
import './qipCardStyles.css';
import { useEffect } from 'react';



function QipCard(props) {
    const {
        data, 
        actionHandler, 
        deleteHandler,
        assignHandler
    } = props;
    let cardData = data;
    const [activeCard, setActiveCard] = useState('-1');
    let [active, setActive] = useState(false);
    let [cards, setCards] = useState(data);
    
    useEffect(() => {
        setCards(data);
    }, [cardData])

    const toggle = (card, key) => {
        if (activeCard != key ) {
            setActiveCard(key);
            actionHandler(card);
        } 
        
    }

    return(
        <div>
            {cards.map((card, key) => {
                return(
                    <div key={key} className={`qip-card ${activeCard == key ? "active" : ""}`}>
                        {card.name}
                        <Tooltip title={<span style={{fontSize: "1.3em"}}>Creado hace</span>}>
                            {
                                card.created_at ? 
                                <div className={'card-name'}>
                                    {card.created_at}
                                </div>
                            : null}
                            
                        </Tooltip>
                        {card.desc ?  
                            card.desc.requires ?
                                <Tooltip title={<span style={{fontSize: "1.3em"}}>Require permisos especiales</span>}>
                                    <div className={'card-chip'}>
                                        <Lock  style={{color: 'gray'}}/>
                                    </div>
                                </Tooltip>
                            : null
                            : null
                        }
                        
                        <Tooltip title={<span style={{fontSize: "1.3em"}}>Editar Rol</span>}>
                            <Button onClick={() => actionHandler(card)} style={{borderRadius: '0px', height: '100%', margin: '0', alignItems: 'center'}} className={'card-badge'} color="info" size={'sm'}><i className={'now-ui-icons objects_key-25'}></i></Button>
                        </Tooltip>
                        <Tooltip title={<span style={{fontSize: "1.3em"}}>Asignar Rol</span>}>
                            <Button onClick={() => assignHandler(card)} style={{borderRadius: '0px', height: '100%', margin: '0', alignItems: 'center'}} className={'card-admin'} color="success" size={'sm'}><i className={'now-ui-icons users_single-02'}></i></Button>
                        </Tooltip>
                        <Tooltip title={<span style={{fontSize: "1.3em"}}>Borrar Rol</span>}>
                            <Button onClick={() => deleteHandler(card)} style={{borderRadius: '0px', height: '100%', margin: '0', alignItems: 'center'}} className={'card-delete'} color="danger" size={'sm'}><span><DeleteForeverOutlined /></span></Button>
                        </Tooltip>
                    </div>
                )
            })}
        </div>
    );
} 

export default QipCard;