import React from 'react';
import SelectInput from './SelectInput';
import FileUpload from '../CustomUpload/FileUpload';
import {Card, CardBody, CardHeader,	Row, Col,
    Button, Form, FormGroup, Label, Input, FormText
} from "reactstrap";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const index = ({uploadInput, uploadAdmins, upload, preUpload, fileColumn, dataColumn, setDelimiter,
    fileName, fileSize, fileType, label, setInputFile, inputFile, setMapColumn}) => {

    return (
        <div>
            <Card>
                <CardHeader>
                     <Breadcrumbs aria-label="breadcrumb">
						<Typography color="textPrimary">Importar Datos</Typography>
					</Breadcrumbs>   
                    <hr/>    
                    <h5 className="text-center">Importar Archivo a Base de Datos</h5>             
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="adminsSelect">Administradora</Label>
                                <Input type="select" name="adminsSelect" id="adminsSelect">                                    
                                    {uploadAdmins.map(x=> <option key={x.name} value={x.name}>{x.name}</option>)}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="tableSelect">Tabla</Label>
                                <Input type="select" name="tableSelect" id="tableSelect" onChange={setInputFile}>                                    
                                    {uploadInput.map((x, i)=> <option key={x.id} value={i}>{x.label}</option>)}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="delimiterSelect">Delimitador CSV</Label>
                                <Input type="select" name="delimiterSelect" id="delimiterSelect" onChange={setDelimiter}>
                                    <option value=",">coma (,)</option>
                                    <option value=";">punto y coma (;)</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <div className="text-center">
                                <h5 className="mb-0">Archivo CSV</h5>
                                <p>El archivo no debe exceder los 30MB.</p>

                                {uploadInput.map((x, i)=> {                                
                                    if(i == inputFile)
                                        return <FileUpload 
                                            key={x.id} 
                                            id={x.id} 
                                            label={x.label} 
                                            upload={preUpload} 
                                            columns={x.columns} />
                                })}

                            </div>
                        </Col>                        
                    </Row>
                    <Row> 
                        <Col md={12}>
                        {fileColumn &&                        
                        <div>
                            <hr/>
                            <div className="text-center">
                                
                                <h5 className="mb-0">Seleccionar Columnas de Tabla {label}</h5> 
                                    {/* <span style={{fontSize: '70%'}}>                                   
                                                                      
                                    </span> */}
                                <p>Archivo : {fileName} , {fileSize} bytes, tipo : {fileType}</p>                                                                
                               
                            </div>
                            
                            <Row>
                                {dataColumn.map(column => 
                                <Col md={3}>
                                    <SelectInput key={column} name={column} data={fileColumn} setMapColumn={setMapColumn} isLoading={false}/>
                                </Col>
                                )}
                            </Row>
                            
                            <div className="text-center">
                                <button className="btn btn-primary" onClick={upload}>Importar</button>
                            </div>
                        </div>
                        }
                        </Col>
                    </Row>
                    
                </CardBody>
            </Card>
            
        </div>
    );
};

export default index;