import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import HeaderComponent from '../../components/Header/HeaderComponent';

class ClientContainer extends Component {

    componentDidMount() {
        console.log('ClientContainer', this.props);
    }

    render() {
        return (
            <div>
                <Switch>
                    {this.props.routes.map((route, i) => (
                        <Route path={route.path} render={() => <route.component />} key={i} />
                    ))}
                </Switch>
            </div>
        );
    }
}

export default withRouter(ClientContainer);