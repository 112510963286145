import React, { Component } from 'react';
import { connect } from 'react-redux';
import Staff from './../../components/Staff';
import MainStaff from './../../components/Staff/MainStaff';
import AssignStaff from './../../components/Staff/AssignStaff';
import {getClients, setResetMessage } from './../../actions/client';
import {getStaff, staffAssignClient, setResetStatus, getStaffData,
    handleAddStaff, handleRemoveStaff, selectStaffHomeContainer
} from './../../actions/staff';
import {getAllClientsNoFilter, pending as pendingClients} from './../../selectors/client';
import {getAllStaff, getStatus, pending, staffData, pendingInfo,
    actionMessage,
    error
} from './../../selectors/staff';
import { withRouter } from 'react-router-dom';
import { urlStaffDetails, urlStaffAssign } from '../../api/urls';

class HomeContainer extends Component {
    constructor(props){
        super(props)
        //this.state={user:'', usersList:''}        
        this.handleClickUser = this.handleClickUser.bind(this)
        //this.renderView = this.renderView.bind(this)
        //this.handleClickBreadcrumb = this.handleClickBreadcrumb.bind(this)
        this.handleAssignClients = this.handleAssignClients.bind(this)
        this.handleClickAssignClients = this.handleClickAssignClients.bind(this)
        // this.handleClickClientsOut = this.handleClickClientsOut.bind(this);
        this.handleResetStatus = this.handleResetStatus.bind(this);
    }

    componentDidMount() {
        this.props.getStaff();
        this.props.getClients();
    }

    handleClickUser(staff){
        //this.props.getStaffData(staff);
        let inputSearchText = document.querySelector('.MuiInputBase-input').value;
        this.props.selectStaffHomeContainer(urlStaffDetails, staff.staffId, inputSearchText);
    }

    handleAssignClients(staffList){
        console.log('Asignación clientes a Staff');        
        this.props.getClients(staffList);
        this.props.history.push(urlStaffAssign);
    }



    handleClickAssignClients(clients, staff){
        console.log('Asignando clientes a staff')
        console.log(staff)
        console.log(clients)
        const data = {
            'option': 1,
            'staff':staff,
            'clients':clients
        }
        this.props.staffAssignClient(data)        
    }

    // handleClickClientsOut(staff, clients){

    //     const staffData = this.props.staff.filter(obj => staff.includes(obj.id));
        
    //     const data = {
    //         'option': 3,
    //         'staff': staffData,
    //         'clients':clients
    //     }
    //     this.props.staffAssignClient(data) 
    // }

    handleResetStatus(){
        this.props.setResetStatus();
        this.props.setResetMessage();
    }
    
    render () {      
            return <Staff 
                data={this.props.staff} 
                onClick={this.handleClickUser}
                assignClients={this.handleAssignClients}
                // onClickClientsOut={this.handleClickClientsOut}
                message={this.props.messageStaffAssign}
                reset={this.handleResetStatus}
                pending={this.props.pending}
                handleAddStaff={this.props.handleAddStaff} 
                actionMessage={this.props.actionMessage}
                error={this.props.error}
                handleRemoveStaff={this.props.handleRemoveStaff}
            />   
        
    }    
}

const mapStateToProps = state => ({
    clients: getAllClientsNoFilter(state),
    staff: getAllStaff(state),
    status: getStatus(state),
    pending: pending(state),
    pendingInfo: pendingInfo(state),
    staffData: staffData(state),
    pendingClients: pendingClients(state),
    actionMessage: actionMessage(state),
    error: error(state),
    messageStaffAssign: state.staff.messageStaffAssign
})

const mapDispatchToProps = dispatch => ({
    getClients: (staffList) => dispatch(getClients(staffList)),
    getStaff: () => dispatch(getStaff()),
    staffAssignClient: (data) => dispatch(staffAssignClient(data)),
    setResetStatus: () => dispatch(setResetStatus()),
    setResetMessage: () => dispatch(setResetMessage()),
    getStaffData: staff => dispatch(getStaffData(staff)),
    handleAddStaff: data => dispatch(handleAddStaff(data)),
    handleRemoveStaff: data => dispatch(handleRemoveStaff(data)),
    selectStaffHomeContainer: (url, staffId, inputSearchText) => dispatch(selectStaffHomeContainer(url, staffId, inputSearchText))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeContainer));