import React from 'react';
import { PanelHeader, Header } from "../../components";
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";
import PropTypes from "prop-types";
import TableMaterialUi from '../TableMaterialUi';
import { rutFormat, patrimonyFormat } from '../../helpers/formatter';

const headCells = [
    //id - numeric - disablePadding - label - padding(opcional) - align(opcional) - formatter(opcional)
    { id: 'rut', numeric: false, disablePadding: false, label: 'Rut', formatter:rutFormat },
    { id: 'name', numeric: false, disablePadding: true, label: 'Nombre', padding:"none" },
    { id: 'email', numeric: false, disablePadding: true, label: 'Email', padding:"none" },
    { id: 'patrimony', numeric: true, disablePadding: false, label: 'Patrimonio', align:"right", formatter:patrimonyFormat }
];
function ListClient({clients, selectHandler, pending, location, tableState, getTableState, backgroundColor}) {
    return (
        <div>
            <PanelHeader size="sm">

            </PanelHeader>
            <Header location={location} />
            <div className="content" id="listclienttop">
                <Card className="card-stats card-raised">
                    <CardBody>
                        <div>
                            <h3 style={{
                                color: backgroundColor, marginBottom: '.3em'
                            }}>
                                Listado de Clientes
                            </h3>
                            <span style={{color: 'gray'}}>Haz clic en un cliente para visualizar su cartola consolidada</span>
                        </div>
                    </CardBody>
                </Card>

                {/* <Table
                    data={clients}
                    selectHandler={selectHandler}
                    pending={pending}
                    searchText={searchText}
                /> */}

                <TableMaterialUi
                    title={'Clientes'}
                    rows={clients}
                    headCells={headCells}//
                    withCheckbox={false}
                    onClickRow={selectHandler}
                    loading={pending}
                    updateState={getTableState}
                    initialState={tableState}
                />

            </div>
        </div>
    );
}

ListClient.propTypes = {
    clients: PropTypes.arrayOf(PropTypes.object),
    selectHandler: PropTypes.func,
    pending: PropTypes.bool,
    searchText: PropTypes.string,
    location: PropTypes.object,
    backgroundColor: PropTypes.string
}

export default ListClient;

