import React from 'react';
import Table from './Table';
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";
import TableMaterialUi from '../../TableMaterialUi';
import { rutFormat, patrimonyFormat, dateFormat } from '../../../helpers/formatter';

const headCells = [
    //id - numeric - disablePadding - label - padding(opcional) - align(opcional) - formatter(opcional)
    { id: 'rut_client', numeric: false, disablePadding: false, label: 'Rut', formatter:rutFormat }, 
    { id: 'cuenta', numeric: false, disablePadding: true, label: 'Cuenta', padding:"none" },
    { id: 'administradora', numeric: false, disablePadding: true, label: 'Administradora', padding:"none" },
    { id: 'name', numeric: false, disablePadding: true, label: 'Nombre', padding:"none" },
    { id: 'last_name', numeric: false, disablePadding: true, label: 'Apellido', padding:"none" },
    { id: 'email', numeric: false, disablePadding: true, label: 'Email', padding:"none" },
    // { id: 'fecha', numeric: false, disablePadding: true, label: 'Fecha', padding:"none", formatter:dateFormat },
    { id: 'patrimonio', numeric: true, disablePadding: false, label: 'Patrimonio', align:"right", formatter:patrimonyFormat }
];

const index = ({data, onClick, isLoading, getTableState, tableState}) => {
    return (
        <Row>
            <Col>
                {/* <Table data={data} onClick={onClick} isLoading={isLoading}/> */}

                <TableMaterialUi 
                    title={'Historial de Rabalanceos'}
                    rows={data} 
                    headCells={headCells} 
                    withCheckbox={false} 
                    onClickRow={onClick}
                    loading={isLoading}
                    updateState={getTableState}    //TableMaterialUi State
                    initialState={tableState}
                />

            </Col>
        </Row>
    );
};

export default index;