import React from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {AccountCircle, AccountBalance, Warning, CancelRounded} from '@material-ui/icons';
import {MATERIAL_TABLE_LOCALIZATION} from './../../constants';
import { rutFormat, patrimonyFormat } from '../../helpers/formatter';

const theme = createTheme({
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        light: '#01579b',
        main: '#ff9100',
      },       
    },      
});

function Table ({data, onClick, onClickStaff, onClickStaffOut, onClickSendInvitation, pending, 
    riskProfileList, handleUpdateRiskProfile, 
    handleRemoveClient,
    handleAddClientModal,
    handleAddClientAccount,
    handleRemoveClientAccount,
    searchText}) {
   
    const state = {    
        columns: [
            { title: 'Rut', field: 'rut', editable: 'onAdd', width: 150, render: ({rut}) => <span>{rutFormat(rut)}</span>},
            { title: 'Nombre', field: 'name', editable: 'onAdd', width: 300 },
            { title: 'Apellido', field: 'last_name', editable: 'onAdd', width: 300 },
            { title: 'Email', field: 'email', editable: 'onAdd', width: 300 },
            { title: 'Perfil Riesgo', field: 'riskId', editable: 'always',            
            // lookup: {1: 'Bajo', 2: 'Medio Bajo', 3: 'Medio', 4: 'Medio Alto', 5: 'Alto' } },
            lookup: riskProfileList, width: 100 },
            { title: 'Activo', field: 'is_active', editable: 'always', lookup: {0: 'No', 1: 'Si'}, width: 100 },            
            { title: 'Patrimonio', field: 'patrimony', type: 'currency', editable: 'never', width: 100, 
                render: ({patrimony}) => (<span>{patrimonyFormat(patrimony)}</span>) 
            },  
                  
        ],
    }

    const handleRowClicked = (selectedRow) => {
        onClick(selectedRow)
    }

    const handleRowSelected = (rows) => {
        console.log(rows)         
    }

return (
    <div>
        <MuiThemeProvider theme={theme}>
            <MaterialTable
                title=""
                columns={state.columns}
                data = {data}  
                isLoading= {pending}

                editable={{
                    onRowUpdate: (newData, oldData) => 
                        new Promise((resolve, reject) => {                        
                            setTimeout(() => {                      
                                handleUpdateRiskProfile(newData)
                                resolve()
                            }, 1000)
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                handleRemoveClient(oldData)
                                resolve()
                            }, 1000)
                        }),
                }}

                options={{
                    selection: true,
                    sorting: true,
                    exportButton: true,
                    rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#37b15933' 
                        : 
                        rowData.is_active == 1 ? "#ffffff" : "#b4b6b7"                                
                    }),
                    searchText
                }}
                onRowClick={((evt, selectedRow) => handleRowClicked(selectedRow) )}
                onSelectionChange={(rows) => handleRowSelected(rows)}
                actions={[
                    {
                        icon: 'add',
                        tooltip: 'Agregar Cliente',
                        isFreeAction: true,
                        onClick: (event) => handleAddClientModal()
                    },
                    {
                        tooltip: 'Asignar Asesores',
                        icon: () => <AccountCircle />,
                        onClick: (evt, data) => onClickStaff(data)
                        
                    },
                    {
                        tooltip: 'Desasignar Asesores',
                        icon: () => <CancelRounded />,
                        onClick: (evt, data) => onClickStaffOut(data)
                        
                    },
                    // {
                    //     tooltip: 'Enviar invitación',
                    //     icon: () => <Message />,
                    //     onClick: (evt, data) => onClickSendInvitation(data)
                    // },
                    {
                        tooltip: 'Agregar Cuentas a Cliente',
                        icon: () => <AccountBalance />,
                        onClick: (evt, data) => handleAddClientAccount(data)
                    },
                    {
                        tooltip: 'Remover Cuentas a Cliente',
                        icon: () => <Warning />,
                        onClick: (evt, data) => handleRemoveClientAccount(data)
                    },               
                ]}
                localization = {MATERIAL_TABLE_LOCALIZATION}
            />
        </MuiThemeProvider>
    </div>
    );
    
}

export default Table;