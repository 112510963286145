import {LOGIN_INIT, LOGIN_SUCCESS, LOGIN_ERROR, FORGOT_PASSWORD_INIT, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR,
    PASSWORD_RESET_INIT, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_ERROR,
    LOGOUT_INIT, LOGOUT_SUCCESS, LOGOUT_ERROR, REMINDERS_CLEAR, LOGIN_FAKE_INIT, LOGIN_FAKE_SUCCESS,
    NOT_LOGOUT_INIT, NOT_LOGOUT_SUCCESS, NOT_LOGOUT_ERROR, GET_TENANT_INIT, GET_TENANT_SUCCESS, GET_TENANT_ERROR} from '../constants';
import client from '../api/httpClient';
import {urlLogin, urlForgotPassword, urlPasswordReset, urlUser, urlAdmin, urlLogout, urlDoNotLogout, urlTenantInfo, urlCartolasHome} from '../api/urls';
import auth from '../api/auth';
import moment from 'moment';
import { updateRouterHistory } from './updateUrl';



const delay= 10;//seconds

export const passwordReset = obj => {
    return (dispatch) => {  
        dispatch(passwordReset_init())      
        client.login(urlPasswordReset, obj)
        .then(resp =>             
            dispatch(passwordReset_success(
                '¡Tu contraseña ha sido restablecida!'
            )) 
        )
        .catch(err => 
            dispatch(passwordReset_error(
                'La confirmación de contraseña no coincide.'
            )) 
        )
    }
}

export const forgotPassword = (obj) => {    
    return (dispatch) => {
        dispatch(forgotPassword_init());
        client.login(urlForgotPassword, obj)
            .then(() => dispatch(forgotPassword_success(
                "¡Le hemos enviado por correo electrónico su enlace de restablecimiento de contraseña!"
            )))
            .catch(() => dispatch(forgotPassword_error(
                "No podemos encontrar un usuario con esa dirección de correo electrónico."
            )))
    }
}

export const login = (obj) => {
    return (dispatch, getState) => {
        dispatch(login_init());
        // check local storage for token
        client.login(urlLogin, obj)
            .then(response => {
                const user = {
                    isFirstLogin: false,
                    accessToken: response.data.access_token,
                    userLoggedIn: false,
                    appName: getState().login.appName,
                    appColors: getState().login.appColors,
                    linkCompany: getState().login.linkCompany,
                    linkAbout: getState().login.linkAbout,
                    linkContact: getState().login.linkContact,
                    channelBase: getState().login.channelBase,
                    lastSeenAt: response.data.last_seen_at,
                    timeServer: response.data.time_server,
                    logo: getState().login.logo,
                    appVersion: response.data.app_version,
                    rememberMe: response.data.remember_me,
                    sessionTime: response.data.session_time                    
                }

                auth.setToken(response.data.access_token);
                auth.setUser(user);

                const secondsSession = auth.getSecondsExpToken();

                console.log('Iniciando app')
                if (user.rememberMe) 
                    user.sessionTime = secondsSession - delay;                
                
                dispatch(login_success(user));
                dispatch(updateRouterHistory(urlCartolasHome));
                console.log('login response', response);

            })
            .catch(error => {
                console.log(error);
                dispatch(login_error(error))
            });

    }
}

export const logout = () => {
    return (dispatch, getState) =>{
        dispatch(logout_init());
        dispatch(logout_success());

        client.getAll(urlLogout)
        .then(() => {
            auth.logout();
            window.location.href = '/login';
        })
        .catch(error => {
            console.log(error)
            auth.logout();
            dispatch(logout_error());
            window.location.href = '/login';
        })
    }
}

export const doNotLogout = () => {
    return (dispatch) => {
        dispatch(doNotLogout_init())
        client.getAll(urlDoNotLogout)
        .then(res => {
            const accessToken = res.data.access_token;
            auth.setToken(accessToken);
            const sessionTime = auth.getSecondsExpToken() - delay;
            dispatch(doNotLogout_success(sessionTime, accessToken))
        })
        .catch(err => {
            console.log(err)
            dispatch(doNotLogout_error(err))
        })
    }
}

export const login_fake = () => {
    return (dispatch) => {
        dispatch(login_fake_init());
        const accessToken = document.querySelector("meta[name='access-token']").getAttribute("content");
        const lastSeenAt= document.querySelector("meta[name='last_seen_at']").getAttribute("content");
        const timeServer= document.querySelector("meta[name='time_server']").getAttribute("content");
        const appVersion= document.querySelector("meta[name='app_ver']").getAttribute("content");
        const user = {
            isFirstLogin: false,
            accessToken,
            userLoggedIn: false,
            appName: document.querySelector("meta[name='app-name']").getAttribute("content"),
            appColors: document.querySelector("meta[name='app-colors']").getAttribute("content"),
            linkCompany: document.querySelector("meta[name='link_company']").getAttribute("content"),
            linkAbout: document.querySelector("meta[name='link_about']").getAttribute("content"),
            linkContact: document.querySelector("meta[name='link_contact']").getAttribute("content"),
            channelBase: document.querySelector("meta[name='channel_base']").getAttribute("content"),
            lastSeenAt,
            timeServer,
            logo: document.querySelector("meta[name='logo']").getAttribute("content"),
            appVersion: appVersion,
        }

        auth.setToken(accessToken);
        auth.setUser(user);

        const myLastTime = moment(lastSeenAt);
        const myServerTime = moment(timeServer);
        const secondsSession = auth.getSecondsExpToken();
        const secondsDiff = myServerTime.diff(myLastTime, 'seconds');

        console.log(myLastTime.format())
        console.log(myServerTime.format())
        console.log(`${auth.getSecondsExpToken()} Segundos permitidos`);
        console.log(`${secondsDiff} Segundos de diferencia`);

        if(secondsSession > secondsDiff){
            console.log('Iniciando app')
            user.sessionTime = secondsSession - secondsDiff;
            dispatch(login_fake_success(user));
        }
        else{
            // console.log('deslogueando')
            // auth.logout();
            // window.location.href = '/logout';

            const countdown = 60;
            user.sessionTime = countdown;
            dispatch(login_fake_success(user));
        }

    }
}

export const getTenantInfo = () => {
    return (dispatch) => {
        dispatch(getTenantInfo_init());

        client.getNoAuth(urlTenantInfo)
        .then(res => {
            const tenant = res.data;
            dispatch(getTenantInfo_success(tenant));
        })
        .catch(err => {
            console.log(err)
            dispatch(getTenantInfo_error(err))
        })
    }
}


const passwordReset_init = () => ({
    type: PASSWORD_RESET_INIT,
})

const passwordReset_success = msg => ({
    type: PASSWORD_RESET_SUCCESS,
    payload: {
        msg
    }
})

const passwordReset_error = msg => ({
    type: PASSWORD_RESET_ERROR,
    payload: {
        msg
    }
})


const forgotPassword_init = () => ({
    type: FORGOT_PASSWORD_INIT,
})

const forgotPassword_success = msg => ({
    type: FORGOT_PASSWORD_SUCCESS,
    payload: {
        msg
    }
})

const forgotPassword_error = msg => ({
    type: FORGOT_PASSWORD_ERROR,
    payload: {
        msg
    }
})

const getTenantInfo_init = () => {
    return {
        type: GET_TENANT_INIT
    }
}

const getTenantInfo_success = (tenant) => {
    return {
        type: GET_TENANT_SUCCESS,
        payload: tenant
    }
}

const getTenantInfo_error = (err) => {
    return {
        type: GET_TENANT_ERROR,
        payload: err
    }
}

const doNotLogout_init = () => ({
    type: NOT_LOGOUT_INIT
})

const doNotLogout_success = (sessionTime, accessToken) => ({
    type: NOT_LOGOUT_SUCCESS,
    payload: {
        sessionTime,
        accessToken
    }
})

const doNotLogout_error = error => ({
    type: NOT_LOGOUT_ERROR,
    payload: {
        error
    }
})


const login_fake_init = () => ({
    type: LOGIN_FAKE_INIT
})

const login_fake_success = userInfo => ({
    type: LOGIN_FAKE_SUCCESS,
    payload: {
        userInfo
    }
})

const login_init = () => ({
    type: LOGIN_INIT
})

const login_success = userInfo => ({
    type: LOGIN_SUCCESS,
    payload: {
        userInfo
    }
})

const login_error = error => ({
    type: LOGIN_ERROR,
    payload: {
        error
    }
})

const logout_init = () => ({
    type: LOGOUT_INIT
})

const logout_success = () => ({
    type: LOGOUT_SUCCESS
})

const logout_error = () => ({
    type: LOGOUT_ERROR
})

const reminders_clear = () => ({
    type: REMINDERS_CLEAR
})
