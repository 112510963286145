// export const getPatrimonioTotal = state => {
//     const accounts = state.rebalance.accounts;
//     let accountsFiltered = [...accounts];
//     accountsFiltered = accountsFiltered.reduce((accumulator, element) => {
//         if (!accumulator.find(el => el['rut'] === element['rut'] && el['cuenta'] === element['cuenta'])) {
//              accumulator.push(element);
//          }
//         return accumulator;
//       },[]);

//     return accountsFiltered;
// };

export const total      = state => state.patrimony.total;
export const invertido  = state => state.patrimony.invertido;
export const caja       = state => state.patrimony.caja;
export const pending    = state => state.patrimony.pending;
export const pendingInfo    = state => state.patrimony.pendingInfo;
export const dataPatrimonio = state => state.patrimony.dataPatrimonio;
export const dataUsuario    = state => state.patrimony.dataUsuario;
