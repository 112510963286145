import React from 'react'
import {
    Card,
    Col,
    Badge,
    ModalBody,
    ModalFooter,
    Modal,
    Button,
    ButtonGroup,
    Alert,
    ModalHeader
} from "reactstrap";
import Tooltip from '@material-ui/core/Tooltip';
import DetalleMovimientos from "./DetalleMovimientos.jsx"
import List from './List';
import shuffle from 'lodash/shuffle';

import DetalleCuentas from "./DetalleCuentas.jsx";
import ResumenErrorMovimientos from './ResumenErrorBoundary'
import "./resumenAdminStyles.css";
const ReactHighcharts = require('react-highcharts');
require('highcharts-3d')(ReactHighcharts.Highcharts);

function compareValues(key, order='asc') {
    return function(a, b) {
      if(!a.hasOwnProperty(key) ||
         !b.hasOwnProperty(key)) {
          return 0;
      }

      const varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order == 'desc') ?
        (comparison * -1) : comparison
      );
    };
  }

class ResumenMovimientos extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            modal: false,
            titulo: '',
            tipo: '',
            monto: '',
            currencies: [],
            data: [],
            key: 'name',
            sort_order: 'asc',
            pageSubcategories: "",

        }
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleCloseModal = this.toggleCloseModal.bind(this);
        this.toggleSort = this.toggleSort.bind(this);
        this.sortAccount = this.sortAccount.bind(this);
    }

    toggleModal(currencies, titulo, tipo, monto) {

        const tituloArr = titulo.split('$');
        const newTitle = tituloArr.length === 3 
                        ? `${tituloArr[1]} ${tituloArr[0]} - ${tituloArr[2]}` 
                        : tituloArr[0]

        this.setState(prevState => ({
            titulo: newTitle.toUpperCase(),
            currencies: currencies,
            tipo: tipo,
            monto: monto,
            modal: !prevState.modal
        }));
    }

    toggleCloseModal() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    toggleSort(key) {
        this.setState(prevState => ({
            key: key,
            sort_order: prevState.sort_order === 'asc' ? 'desc' : 'asc',
        }));
        this.sortAccount(key);
    }

    sortAccount(key) {
        return(this.state.data.sort(compareValues(key, this.state.sort_order)) )
    }

    componentWillMount() {
        console.log('movimientos - resumen movimientos', this.props);
    }


    componentDidMount() {
        let items = [];
        let i = 0;
        {this.props.movimientos.length === 0 ?
            null
            :
            this.props.movimientos.administradoras.map((prop) => {
                console.log('MVMTS', prop);
                prop.cuentas.map((cuenta) => {
                    let acct_obj = {};
                    acct_obj['name'] = cuenta.nombre_cuenta[0];
                    acct_obj['curr'] = cuenta.currencies;
                    acct_obj['num_curr'] = cuenta.currencies.length;
                    try {
                        acct_obj['total'] = Math.trunc(parseFloat(this.props.consolidatedByAccount.filter(prop => prop.cuenta === cuenta.nombre_cuenta[0] && prop.tipo === 'Estandar')[0]['monto']));
                    } catch(error) {
                        acct_obj['total'] = 0;
                    }
                    acct_obj['type'] = 'Estandar';
                    acct_obj['status'] = acct_obj.total == 0 ? 'inactive' : 'active';
                    acct_obj['admin'] = prop.nombre_admin[0];
                    acct_obj['key'] = i++;
                    items.push(acct_obj);
                })
                prop.cuentas_psh.map(cuenta => {
                    let acct_obj = {};
                    acct_obj['name'] = cuenta.nombre_cuenta[0];
                    acct_obj['curr'] = cuenta.currencies;
                    acct_obj['num_curr'] = cuenta.currencies.length;

                    try {
                        acct_obj['total'] = Math.trunc(parseFloat(this.props.consolidatedByAccount.filter(prop => prop.cuenta === cuenta.nombre_cuenta[0] && prop.tipo === 'Psh')[0]['monto']));
                    } catch(error) {
                        acct_obj['total'] = 0;
                    }

                    acct_obj['type'] = 'Pershing';
                    acct_obj['status'] = acct_obj.total == 0 ? 'inactive' : 'active';
                    acct_obj['admin'] = prop.nombre_admin[0];
                    acct_obj['key'] = i++;
                    items.push(acct_obj);
                })
            })
        };
        console.log('items', items);

        this.setState({
            data: items
        });
    }

    render() {
        return(
            <Col xs={12} md={12}>
                <Card className="card-plain">
                    
                    <span style={{color: "gray"}}>Ordenar por:</span>
                    <br />
                    <ButtonGroup>
                        <Button color={'primary'}  onClick={() => this.toggleSort('name')} >
                            Cuenta
                        </Button>
                        <Button color={'primary'} onClick={() => this.toggleSort('total')} >
                            Monto
                        </Button>
                        <Button color={'primary'} onClick={() => this.toggleSort('num_curr')} >
                            Movimientos
                        </Button>
                        <Button color={'primary'} onClick={() => this.toggleSort('admin')} >
                            Custodio
                        </Button>
                    </ButtonGroup>
                    <Tooltip title={<span style={{fontSize: "1.3em"}}>Usa los botones para ordenar por cuenta, monto, activos, etc..
                        y selecciona una cuenta para ver su detalle</span>}>
                        <span> <i className="now-ui-icons travel_info"></i></span>
                    </Tooltip>
                    

                {this.props.movimientos.length === 0 ?
                    <Alert color="info" isOpen={true}>
                        <i className="fa fa-info-circle fa-lg" aria-hidden="true"></i>{' '}
                        No hay movimientos para el periodo seleccionado.
                    </Alert>
                    :
                    <List
                        className="main-list"
                        items={this.state.data}
                        keys={d => d.key}
                        // Check screen resolution for div heights
                        heights={window.innerWidth > 700 ? 100 : 500}
                        config={{ mass: 4, tension: 100, friction: 40 }}>
                                {item =>
                                    <div className={item.status == 'inactive' ? "qip-account disabled": "qip-account"} onClick={() => {item.status == 'inactive' ? null : this.toggleModal(item.curr, item.name, item.type, item.total)}}>
                                        <div className="account account-title">
                                            {/* {item.name} */}
                                            <div>{item.name.split('$')[1]}</div>
                                            <div>{item.name.split('$')[0]}</div>
                                            <div>{item.name.split('$')[2]}</div>
                                        </div>
                                        <div className="account account-name">
                                            <div className="account-name-header">
                                                ${this.props.clpFormatter.format(item.total)}
                                            </div>
                                            <div className="account-name-subtitle">
                                                Monto
                                            </div>
                                        </div>
                                        <div className="account account-assets">
                                            <div className="account-assets-header">
                                                {item.num_curr}
                                            </div>
                                            <div className="account-assets-subtitle">
                                                Movimientos
                                            </div>
                                        </div>
                                        <div className="account account-badge">

                                            <div className="account-badge-header">
                                                <Badge color={item.type == 'Pershing' ? "success" : "primary"}>{item.type}</Badge>
                                            </div>
                                            <div className="account-badge-subtitle">
                                                Tipo
                                            </div>
                                        </div>
                                        <div className="account account-admin">
                                            {item.admin == 'Principal' ?
                                                <img className="account-admin-logo" height="50" src="/images/principal-logo.png"  />
                                                : item.admin == 'LarrainVial' ?
                                                <img className="account-admin-logo" height="20" src="/images/lv-logo.png" />
                                                    :  <img className="account-admin-logo" height="20" src="/images/fynsa-logo.svg" />

                                            }
                                            {/* <div className="account-admin-subtitle">
                                                Administradora
                                            </div> */}
                                        </div>

                                    </div>
                                }
                        </List>
                    }

                </Card>
                <Modal style={{maxWidth: "90vw"}} isOpen={this.state.modal} toggle={this.toggleCloseModal} size={'lg'}>
                <div className="account-modal-header">
                    <div className="title">
                        {this.state.titulo}
                    </div>
                </div>
                <ModalHeader toggle={this.toggleCloseModal}></ModalHeader>

                <ModalBody>
                    <div className="account-details">
                        <div className="account-details-header">
                            <div className="account assets">
                                <div className="value">
                                    {this.state.currencies.length}
                                </div>
                                <div className="icon">
                                    <i className="now-ui-icons business_chart-pie-36"></i>
                                </div>
                                <div className="name">
                                    Movimientos
                                </div>
                            </div>
                            <div className="account type">
                                <div className="value">{this.state.tipo}</div>
                                <div className="icon">
                                    <i className="now-ui-icons business_bank"></i>
                                </div>
                                <div className="name">
                                    Tipo Cuenta
                                </div>
                            </div>
                            <div className="account total">
                                <div className="value">
                                    ${this.props.clpFormatter.format(this.state.monto)}
                                </div>
                                <div className="icon">
                                    <i className="now-ui-icons business_money-coins"></i>
                                </div>
                                <div className="name">
                                    Monto
                                </div>
                            </div>
                        </div>
                        <div className="account-movimientos-body">
                            {this.state.currencies ?
                            <div>
                                <div className='account-details-table'>
                                    <DetalleMovimientos data={this.state.currencies} />
                                </div>
                            </div>


                            :
                            <div>Sin información</div>
                            }
                        </div>
                    </div>



                </ModalBody>
                <ModalFooter>

                    <Button color="primary" onClick={this.toggleCloseModal}>Cerrar</Button>

                </ModalFooter>

            </Modal>
            </Col>
        )
    }
}

export default ResumenMovimientos;
