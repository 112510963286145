import React from 'react';
import Highcharts from "highcharts";
import Highmaps from 'highcharts/highmaps';
import HighchartsReact from "highcharts-react-official";

const state={ 
    configHeatmap: {    
        chart: {
            type: 'heatmap',
            marginTop: 40,
            marginBottom: 80,
            plotBorderWidth: 1,            
        },
    
        title: {
            text: 'Matriz de Correlación',
            align: 'left',
            x: 70
        },
    
        xAxis: {            
            // categories: data.X,
            visible: true,
        },
    
        yAxis: {            
            // categories: data.Y,
            visible: true,
            title: null,
            reversed: true,
        },
  
        colorAxis: {
            min: -1,
            stops: [
                [0, '#ff2323'],
                [0.5, '#FFFFFF'],
                [0.9, '#70c2c6']
            ],
        },
  
        legend: {
            align: 'center',
            layout: 'horizontal',
            margin: 0,
            // y: -500,
            verticalAlign: 'top',
            floating: true,
            symbolHeight: 15,
        },
 
        tooltip: {
            formatter: function () {
                if(this.point.value != ""){
                    return 'x: <b>' + this.series.xAxis.categories[this.point.x] + '</b> <br>y: <b>' +
                    this.series.yAxis.categories[this.point.y] + '</b> <br><b>' + this.point.value  + '</b>';
                }else{
                    return ""
                }
            }
        },
  
        series: [{
            name: 'Correlación',
            borderWidth: 0,            
            // data: data.DATA,
            dataLabels: {
                enabled: true,
                color: '#000000'
            }
        }],
    },
    configScattermap: {
        chart: {
            type: 'scatter',
        },
        title: {
            text: 'Frontera Eficiente Media Varianza',
            align: 'left',
            x: 70
        },
        yAxis: {
            // min: 0,
            // max: 0.2,
            title: {
                text: 'Retorno'
            }
        },
        xAxis: {
            // min: 0,
            // max: 0.009,
            gridLineWidth: 0,
            title: {
                enabled: true,
                text: 'Volatilidad'
            },
        },
        series: [{
            name: 'Cartera',
            color: 'rgba(251, 188, 5, 1)', 
            marker: {
                radius: 8
            }           
            // data: rebalance.data
        },
        {
            name: 'Portafolio',
            color: 'rgba(66, 133, 244, 1)',
            // data: rebalance.data
        }]
    }
}

const Graphic = ({label, matriz, portafolioGraph, carteraGraph}) => {

    state.configHeatmap.xAxis.categories = label.x;
    state.configHeatmap.yAxis.categories = label.y;
    state.configHeatmap.series[0].data = matriz;
    state.configScattermap.series[0].data = carteraGraph;
    state.configScattermap.series[1].data = portafolioGraph;

    if (label.x.length > 14){
        state.configHeatmap.chart.height = (9 / 16 * 100) + '%' // 16:9 ratio
    }else{
        state.configHeatmap.chart.height = null
    }

    return (
        <div>
            <HighchartsReact highcharts={Highmaps} options={state.configHeatmap} />
            <HighchartsReact highcharts={Highcharts} options={state.configScattermap} />
        </div>
    );
};

export default Graphic;