import React from 'react';
import Table from './Table';
// import FilterMenu from './FilterMenu';
import FilterMenuContainer from '../../../containers/Rebalance/FilterMenu';
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";
import { rutFormat, patrimonyFormat, dateFormat } from '../../../helpers/formatter';

import TableMaterialUi from '../../TableMaterialUi';

const headCells = [
    //id - numeric - disablePadding - label - padding(opcional) - align(opcional) - formatter(opcional)
    { id: 'rut', numeric: false, disablePadding: false, label: 'Rut', formatter:rutFormat }, 
    { id: 'cuenta', numeric: false, disablePadding: true, label: 'Cuenta', padding:"none" },
    { id: 'administradora', numeric: false, disablePadding: true, label: 'Administradora', padding:"none" },
    { id: 'name', numeric: false, disablePadding: true, label: 'Nombre', padding:"none" },
    { id: 'last_name', numeric: false, disablePadding: true, label: 'Apellido', padding:"none" },
    { id: 'email', numeric: false, disablePadding: true, label: 'Email', padding:"none" },
    { id: 'fecha', numeric: false, disablePadding: true, label: 'Fecha', padding:"none", formatter:dateFormat },
    { id: 'patrimonio', numeric: true, disablePadding: false, label: 'Patrimonio', align:"right", formatter:patrimonyFormat }
];

const Cuentas = ({data, onClick, isLoading, getState, initialState}) => {
    return (
        <Row>
            <Col md={9}>
                {/* <Table data={data} onClick={onClick} isLoading={isLoading}/> */}

                <TableMaterialUi 
                    title={'Cuentas'}
                    rows={data} 
                    headCells={headCells} 
                    withCheckbox={false} 
                    onClickRow={onClick}
                    loading={isLoading}
                    updateState={getState}    //TableMaterialUi State
                    initialState={initialState}
                />

            </Col>
            <Col md={3}>
                <FilterMenuContainer />
            </Col>
        </Row>
    );
};

export default Cuentas;