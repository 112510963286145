import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const Confirmar = ({fund, serie, confirm}) => {

    const [show, setShow] = React.useState(true);
    const handleConfirm = () => {
        setShow(false)
        confirm()
    }

    return (
        <div style={{height:'400px'}}>
            <hr className="mt-0 mb-5"/>
           <div className="text-center mt-5">
                <h5>{fund.rut}</h5>
                <h5>{fund.entidad}</h5>
                <h5>{serie.serie}</h5>
                <h5>{serie.caracteristicas}</h5>
                {show &&
                    <button className="btn btn-success btn-lg mt-5" onClick={handleConfirm}>
                        CONFIRMAR
                    </button>
                }
           </div>
        </div>
    );
};

export default Confirmar;