import {createSelector} from 'reselect';

// const clients = state => state.client.clients;
// export const getAllClients = createSelector(clients, obj => Object.values(obj) )

export const totalClients = state => state.client.total; 
export const pending = state => state.client.pending; 
export const pendingFilter = state => state.client.pendingFilter;
export const clientsCard = state => state.client.clientsCard; 
export const pendingInfo = state => state.client.pendingInfo; 
export const actionMessage = state => state.client.actionMessage; 

export const error = state => state.client.error;

export const clientData = state => state.client.clientAccounts;
export const lastRebalance = state => state.client.lastRebalance;
export const sendInvitationResult = state => state.client.sendInvitationResult;
export const message = state => state.client.message;

export const clientStaff = state => JSON.parse(JSON.stringify(state.client.clientStaff));

export const getFilters = state => {
    return new Map(state.client.filters);
};
export const getFilterData = state => state.client.filterData;

export const riskProfileArray = state => state.client.riskProfileList;

export const riskProfileList = state => {
    const result = {};
    if(state.client.riskProfileList){
        const arr = state.client.riskProfileList;        
        for(var i = 0; i < arr.length; i++){
            result[arr[i].id] = arr[i].name;
        }  
    }
    // console.log(result)
    return result  
};

export const getAllClientsNoFilter = state => JSON.parse(JSON.stringify(state.client.clients));

export const getAllClients = state => {
    const filters = new Map(state.client.filters);
    const clients = JSON.parse(JSON.stringify(state.client.clients));
    let clientsFiltered = [...clients]
    let filterBy = [];
    filters.forEach((value, key) => {
        if(value){
            filterBy.push({key, value})                              
        }        
    });

    if(filterBy.length > 0){
        filterBy.forEach(filter=> {

            if(filter.key === 'perfilRiesgo'){
                clientsFiltered = clientsFiltered.filter(x=> x.fantasy_name_profile === filter.value)
            }

            if(filter.key === 'hasStaff'){
                clientsFiltered = clientsFiltered.filter(x=> x.hasStaff == filter.value)
            }

            if(filter.key === 'patrimonio'){
                var a, b = 0
                switch (filter.value) {                    
                    case "1":
                        a=0;            
                        b=2500000;
                        break;
                    case "2":
                        a=2500000;            
                        b=5000000;
                        break;
                    case "3":
                        a=5000000;            
                        b=25000000;
                        break;
                    case "4":
                        a=25000000;            
                        b=50000000;
                        break;
                    case "5":
                        a=50000000;            
                        b=100000000;
                        break;
                    case "6":
                        a=100000000;            
                        b=250000000;
                        break;
                    case "7":
                        a=250000000;            
                        b=500000000;
                        break;
                    case "8":
                        a=500000000;            
                        b=100000000000;
                        break;
                    default:
                        break;
                }
                clientsFiltered = clientsFiltered.
                filter(x=> x.patrimony >= a && x.patrimony <= b)
                
            }

        })        
    }

    //Filtrar registros duplicados
    clientsFiltered = clientsFiltered.reduce((accumulator, element) => {
        if (!accumulator.find(el => el['rut'] === element['rut'])) {
             accumulator.push(element);
         }
        return accumulator;
    },[]);
    
    return clientsFiltered;        
};

export const getUsersList = state => {
    const selected = state.client.usersList;
    return state.client.clients.filter(obj => selected.includes(obj.id));
};

export const clientSelected = state => state.client.clientSelected
