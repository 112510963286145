import React, { Component } from 'react';
import {connect} from 'react-redux';
import Recordatorios from '../../../components/AdminDashboard/Recordatorios';
import {getUserReminders, storeUserReminders} from '../../../actions/reminder';
import LinearProgress from '@material-ui/core/LinearProgress';

class index extends Component {

    constructor(){
        super();
        this.shouldComponentRender = this.shouldComponentRender.bind(this);
        this.handleSaveChanges = this.handleSaveChanges.bind(this);
    }

/*     componentDidMount = () => {
        if(!this.props.reminders) this.props.getUserReminders();
    } */
    
    shouldComponentRender() {
        const {pending} = this.props;
        if(pending === false){ 
            return false
        }else{
            return true;
        }
    }

    handleSaveChanges (events) {        
        this.props.storeUserReminders(events)
    }

    render() {
        let data= [];        
        if(this.shouldComponentRender()){
            return (
                <div>
                    <LinearProgress />
                    <br />
                    <h5 className="text-center">Cargando...</h5>
                    <br />
                    <LinearProgress />
                </div>
            )
        }else if(!this.props.reminders){
            data= []
        }else{
            data= this.props.reminders.map(event => {
                const start= new Date(event.start);
                const end= new Date(event.end);
                const title= event.title;
                return {start, end, title}                
            })           
        }

        return (
            <div>
                <Recordatorios events={data} onClick={this.handleSaveChanges} saving={this.props.saving}/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    reminders: state.reminder.data,
    pending: state.reminder.pending,
    saving: state.reminder.saving
})

const mapDispatchToProps = dispatch => ({
    getUserReminders: () => dispatch(getUserReminders()),
    storeUserReminders: (value) => dispatch(storeUserReminders(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(index);