import React, { Component } from 'react';
import {connect} from 'react-redux';
import Card from '../../../components/AdminDashboard/Asesores/Card';
import { 
    getStaffCard 
} from '../../../actions/staff';
import { 
    totalStaff,
    pending
} from '../../../selectors/staff';

const data = 3;

class CardContainer extends Component {

    componentDidMount(){
        this.props.getStaffCard();
    }

    render() {
        return (
            <div>
                <Card data={this.props.totalStaff} pending={this.props.pending}/>
            </div> 
        );
    }
}

const mapStateToProps = state => ({
    totalStaff: totalStaff(state),
    pending: pending(state)
})

const mapDispatchToProps = dispatch => ({
    getStaffCard: () => dispatch(getStaffCard()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CardContainer);