import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import TablePaginationActions from './TablePaginationActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableMessage from './TableMessage';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTable(props) {
    const {title, rows, headCells, withCheckbox, onClickRow, 
        orderDefault, orderByDefault, selectedDefault, pageDefault, denseDefault, rowsPerPageDefault,
        searchValue, setSearchValue, loading, setTableState, cellCursor} = props;
    const classes = useStyles();
    const [order, setOrder] = React.useState(orderDefault);
    const [orderBy, setOrderBy] = React.useState(orderByDefault);
    const [selected, setSelected] = React.useState(selectedDefault);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(denseDefault);
    const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageDefault);

    React.useEffect(()=>{
        const pagesNeeded = Math.ceil(rows.length / rowsPerPage);
        if(page >= pagesNeeded || pageDefault >= pagesNeeded){
            setPage(0);
            setTableState({page: 0})
        }else{
            setPage(pageDefault);
            setTableState({page: pageDefault})
        }
    }, [rows.length])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);        
        setTableState({order: isAsc ? 'desc' : 'asc', orderBy: property})
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            setTableState({selected: newSelecteds})
            return;
        }
        setSelected([]);
        setTableState({selected: []})
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        setTableState({selected: newSelected})
    };

    const handleChangePage = (event, newPage) => {        
        setPage(newPage);
        setTableState({page: newPage})
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setTableState({rowsPerPage: parseInt(event.target.value, 10), page: 0})
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
        setTableState({dense: event.target.checked})
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleSearchValue = value => {
        setSearchValue(value)
        setTableState({searchValue: value})
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar 
                    title={title}
                    withCheckbox={withCheckbox}
                    numSelected={selected.length} 
                    searchValue={searchValue}
                    setSearchValue={handleSearchValue}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <TableMessage loading={loading} rowsLength={rows.length}/>

                        <EnhancedTableHead
                            headCells={headCells}
                            withCheckbox={withCheckbox}
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />                        
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            // onClick={(event) => handleClick(event, row.name)}  
                                            onClick={withCheckbox ? () => {} : () => onClickRow(row)}
                                            // role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={index}
                                            selected={isItemSelected}
                                            style={{cursor:cellCursor}}
                                        >
                                            {withCheckbox &&
                                                <TableCell padding="checkbox"
                                                    onClick={(event) => handleClick(event, row.id)}
                                                >
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    /> 
                                                </TableCell>
                                            }
                                            {headCells.map((column, i) => 
                                                <TableCell 
                                                    id={i === 0 ? labelId: i}
                                                    padding={column.padding ? column.padding : 'default'} 
                                                    align={column.align ? column.align : 'inherit'}
                                                    key={i}
                                                    onClick={!withCheckbox ? () => {} : () => onClickRow(row)}
                                                >
                                                    {column.formatter ?  column.formatter(row[column.id]) : row[column.id]}
                                                </TableCell>)}

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    
                    </Table>
                </TableContainer>
                             
                <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    page={rows.length === 0 ? 0 : page}
                    count={rows.length === 0 ? 0 : rows.length}                    
                    rowsPerPage={rowsPerPage}                    
                    onPageChange={handleChangePage}
                    // onChangeRowsPerPage={handleChangeRowsPerPage} <-- onChangeRowsPerPage is deprecated
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={'Filas por página:'}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                    ActionsComponent={TablePaginationActions}
                />
                
            </Paper>
            <FormControlLabel
                control={<Switch color="primary" checked={dense} onChange={handleChangeDense} />}
                label="Ajustar altura de filas"
            />
        </div>
    );
}

export default EnhancedTable;