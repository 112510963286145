import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import {useTransition, animated} from 'react-spring'
import './loadingAnimation.css'


const loadingAnimationStyles = {
  root: {
    height: '200px',
    width: '400px',
    display: 'flex',
    position: 'fixed',
    bottom: '3%',
    right: '3%',
    borderRadius: '20px',
    background: 'linear-gradient(-225deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%)',
    boxShadow:  '20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff',
    justifyContent: 'center',
    alignItems: 'center'
  },

  textBox: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  },

  text: {
    fontWeight: '500',
    color: '#ecf0f1'
  }
}

function LoadingAnimation({ loadingMessages }) {

  const [currentIndex, setCurrentIndex] = useState(0);
  const TIMEOUT_DELAY = 700;

  useEffect(
    () => {
      let timeout = setTimeout(() => {
        setCurrentIndex(
          (currentIndex + 1) % loadingMessages.length
        );
      }, TIMEOUT_DELAY );
    return () => clearTimeout(timeout)
  }, [loadingMessages]);

  return (
    <div style={loadingAnimationStyles.root}>
        <div style={loadingAnimationStyles.textBox}>
            <h6 style={loadingAnimationStyles.text}>{ loadingMessages[currentIndex] }</h6>
        </div>
    </div>
    )
}


LoadingAnimation.propTypes = {
  loadingMessages: PropTypes.array
}

export default LoadingAnimation;
