import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';


class Can extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        if (this.props.assignedPermissions.includes(this.props.permission)) {
            return(
                <Fragment>
                    {this.props.children}
                </Fragment>
            )
        } else {
            if (this.props.alt) {
                return(
                    <Fragment>
                        {this.props.alt}
                    </Fragment>
                    )
            } else {
                return(
                    <div className="no-permissions">
                        {" "}
                    </div>
                )
            }
        }
    }
}

Can.propTypes = {
    assignedPermissions: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired,
    alt: PropTypes.node
}


const mapStateToProps = state => {
    return {
        assignedPermissions: state.user.permissions,
    }
}

export default connect(mapStateToProps, null)(Can);
