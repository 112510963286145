import {
    UPDATE_ROUTE_PARAMS
} from '../constants';


const initialState = {
    params: {}
}


const withParams = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ROUTE_PARAMS:
            const {params} = action.payload.paramObj;
            console.log('withParams reducer', params);
            return {
                ...state,
                params
            }
        default:
            return state;
    }

}

export default withParams;