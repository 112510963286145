import {
    USER_INIT,
    USER_SUCCESS,
    USER_FAILED,
    UPDATE_ADDRESS_INIT,
    UPDATE_ADDRESS_SUCCESS,
    HANDLE_USER_MODAL,
    TOUR_SAVE_INIT,
    TOUR_SAVE_SUCCESS,
    TOUR_SAVE_ERROR 
} from '../constants';


const initialState = {
    id: 0,
    name: "",
    email: "",
    email_verified_at: null,
    type: "",
    is_active: 0,
    last_login_at: null,
    login_count: 0,
    rut: 0,
    phone: null,
    dv: 0,
    last_seen_at: "",
    onboarding_sent: 0,
    onboarding_complete: 0,
    deleted_at: null,
    created_at: "",
    updated_at: "",
    address: {
        id: 0,
        name: "",
        user_id: 0,
        created_at: "",
        updated_at: ""
    },
    messages: [],
    settings: null,
    roles: [],
    permissions: [],
    profileUpdated: false,
    loadingUserData: true,
    validatePassword: false,
    msgPasswordInvalid: null,
    modal: false,
    tourHistory: [],
    errors: [],
    groupOwner: null
}


const user = (state = initialState, action) => {
    switch (action.type) {
        case USER_INIT:
            return {
                ...state
            }
        case USER_SUCCESS:
            const {
                id,
                name,
                last_name,
                email,
                email_verified_at,
                type,
                is_active,
                last_login_at,
                login_count,
                rut,
                phone,
                dv,
                last_seen_at,
                onboarding_sent,
                onboarding_complete,
                deleted_at,
                created_at,
                updated_at,
                address,
                messages,
                settings,
                roles,
                permissions,
                tour_history,
                groupOwner
            } = action.payload.user;
            return {
                ...state,
                id,
                name: `${name} ${last_name}`,
                email,
                email_verified_at,
                type,
                is_active,
                last_login_at,
                login_count,
                rut,
                phone,
                dv,
                last_seen_at,
                onboarding_sent,
                onboarding_complete,
                deleted_at,
                created_at,
                updated_at,
                address,
                messages,
                settings,
                roles,
                permissions,
                loadingUserData: false,
                tourHistory: tour_history,
                groupOwner
            }
        case USER_FAILED:
            const error = action.payload.error;
            return {
                ...state,
                error

            }
        case UPDATE_ADDRESS_INIT: 
            
            return {
                ...state,
                ...action.payload.user
            }
        
        case UPDATE_ADDRESS_SUCCESS:
            return {
                ...state,
                ...action.payload.user,
                modal: false
            }
        case HANDLE_USER_MODAL:
            //const {modal, validatePassword, msgPasswordInvalid, errors} = action.payload.user;
            const modal_state = state.modal;
            return {
                ...state,
                modal: !modal_state,
                validatePassword: false,
                msgPasswordInvalid: null,
                errors: []
            }

        case TOUR_SAVE_INIT:
            return {
                ...state
            }
        case TOUR_SAVE_SUCCESS: 
            const tourCompleteList = state.tourHistory.filter(x => x.tour_complete).map(y => y.tour_view_id);  
            if(!tourCompleteList.includes(action.payload.data.tour_view_id)){
                return {...state, tourHistory: [...state.tourHistory, action.payload.data]}
            }else{
                return {...state}
            }        
        case TOUR_SAVE_ERROR:
            return {
                ...state,
                errors: action.payload.error
            }

        default:
            return state;
    }
}

export default user;