import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";
import CartolaHomeCard from '../../../components/Cartola/CartolaHomeCard/CartolaHomeCard';
import ContentWithPanelHeader from '../../../components/ContentWithPanelHeader/ContentWithPanelHeader';
import Can from '../../../helpers/Can';
import {
    selectClientCartolaList,
    getListClientsCartola,
} from '../../../actions/cartola';
import {
    getFirstClientId
} from '../../../selectors/cartola';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import HeaderComponent from '../../../components/Header/HeaderComponent';
import { withRouter } from 'react-router-dom';


const moment = extendMoment(originalMoment);
const focusFirstElementStyle = {border: '0', height: '0px', margin: '0px', padding: '0px'}

class CartolaHomeContainer extends Component{

    constructor(props){
        super(props);
        this.handlerCartola = this.handlerCartola.bind(this);
        this.greeting = this.greeting.bind(this);
        this.focusFirstElement = this.focusFirstElement.bind(this);
        this.focusRef = React.createRef();        
    }

    componentDidMount(){        
        this.focusFirstElement();
    }

    focusFirstElement() {
        this.focusRef.current.focus();
    }

    handlerCartola(){        
        this.props.selectClientCartolaList(this.props.getFirstClientId);
    }

    greeting() {
        var currentHour = moment().format("HH");
        console.log('currentHour', currentHour);
        if (currentHour >= 3 && currentHour < 12){
            return "Buenos días";
        } else if (currentHour >= 12 && currentHour < 18){
            return "Buenas tardes";
        }   else if (currentHour >= 18 && currentHour < 3){
            return "Buenas noches";
        } else {
            return "Bienvenido"
        }
      
    }

    render(){
        let greeting = this.greeting();
        return (
            <div>                
                <HeaderComponent location={this.props.location}/>                
                <ContentWithPanelHeader panelHeaderSize={"md"} panelHeaderContent={
                    <div style={{marginLeft: '2em', color: "#ecf0f1"}}>
                        {/* Set focus on the first element, when navigating from views with a higher page height  */}
                        <input ref={this.focusRef} style={focusFirstElementStyle}/>
                        <h2 style={{fontSize:'40px'}}>{greeting}, {this.props.userName}</h2>
                    </div>
                }>
                    <Card className="card-stats card-raised">
                            <CardBody>
                                <div>
                                    <h3 style={{
                                        color: this.props.colors.split(';')[0], marginBottom: '.3em',
                                    }}>
                                        Cartolas
                                    </h3>
                                    <span style={{color: 'gray'}}>Visualiza cartolas consolidadas</span>
                                </div>
                            </CardBody>
                        </Card>
                    <Row>
                        <Can permission={'view-advisor-dashboard'} alt={                                                         
                                <Col sm={6}>
                                    <CartolaHomeCard                                         
                                        link={this.props.groupOwner === null ? `/dashboard/cartolas/client` : `/dashboard/cartolas/clientsgroup`}
                                        cardTitle={'Cartola Consolidada'}
                                        cardDesc={'Visualiza tu cartola consolidada.'}
                                        buttonText={'Ver Mi Cartola Consolidada'}
                                    />
                                </Col>
                            }>

                            <Can permission={'view-advisor-clients'}>
                                <Col sm={6}>
                                    <CartolaHomeCard 
                                        icon={'now-ui-icons text_align-left'}
                                        link={`/dashboard/cartolas/list`}
                                        cardTitle={'Clientes'}
                                        cardDesc={'Listado de clientes.'}
                                        buttonText={'Ver Listado de Clientes'}
                                        backgroundColor={this.props.colors.split(';')[1]}
                                    />
                                </Col>
                            </Can>

                            <Can permission={'view-advisor-groups'}>
                                <Col sm={6}>
                                    <CartolaHomeCard 
                                        icon={'now-ui-icons text_align-left'}
                                        link={`/dashboard/cartolas/groups`}
                                        cardTitle={'Grupos'}
                                        cardDesc={'Listado de Grupos.'}
                                        buttonText={'Ver Listado de Grupos'}
                                        backgroundColor={this.props.colors.split(';')[1]}
                                    />
                                </Col>
                            </Can>

                        </Can>
                    </Row>
                </ContentWithPanelHeader>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    getFirstClientId: getFirstClientId(state),
    userName: state.user.name,
    colors: state.login.appColors,
    groupOwner: state.user.groupOwner
})

const mapDispatchToProps = dispatch => ({    
    get_clients: () => dispatch(getListClientsCartola()),
    selectClientCartolaList: (clientId) => dispatch(selectClientCartolaList(clientId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CartolaHomeContainer));