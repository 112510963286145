import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import './sidePanelStyles.css';


function SidePanel(props) {

    console.log('SidePanel Props', props);

    let {
        uid,
        active,
        title,
        body,
        footer,
        closeHandler
    } = props;

    const openSidePanel = () => {
        document.getElementById(`${uid}`).style.width = "50%";
        document.getElementById(`${uid}`).style.display = "block";
        document.getElementById("main").style.width = "50%";
    }

    /* Set the width and display of the side panel component to 0 and none and set width of the page content to 100% */
    const closeSidePanel = () => {
        document.getElementById(`${uid}`).style.width = "0";
        document.getElementById(`${uid}`).style.display = "none";
        document.getElementById("main").style.width = "100%";
    }

    useEffect(() => {
        active ? openSidePanel() : closeSidePanel();
    }, [active])

    return(
        <div id={`${uid}`} class="sidepanel">
            {active ? 
            <Col md={12} className={'roles-info-container'}>
                <div className={'roles-header'}>
                    <div className="roles-title">
                        <h3 style={{margin: 0}}>{title}</h3>
                    </div>
                    <div className={'roles-close'}>
                        <a style={{cursor: 'pointer'}} onClick={() => closeHandler()}>&times;</a>
                    </div>
                </div>
                <div className={'roles-body'}>
                    { body }
                </div>
                <div className={'roles-footer'}>
                    { footer }
                </div>
            </Col>
            : null}
        </div>
    )
}

SidePanel.propTypes = {
    uid: PropTypes.string,
    active: PropTypes.bool,
    title: PropTypes.string,
    body: PropTypes.node,
    footer: PropTypes.node,
    closeHandler: PropTypes.func
}

export default SidePanel;