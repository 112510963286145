import React from 'react'
import {connect} from 'react-redux'
import QuickEditRisk from '../../../components/Clients/MainClient/QuickEditRisk'
import {
    getClients,
    handleUpdateRiskProfile
} from '../../../actions/client';
import {riskProfileList, riskProfileArray
} from '../../../selectors/client';


class index extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.getClients()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.riskProfileArray != this.props.riskProfileArray) {
            console.log('riskProfileArray: ',this.props.riskProfileArray)
        }

    }


    render() {
        return(
            <QuickEditRisk
                riskProfile={this.props.riskProfile}
                riskProfileList={this.props.riskProfileArray}
            />
        )
    }
}


const mapStateToProps = state => ({
    riskProfileList: riskProfileList(state),
    riskProfileArray: riskProfileArray(state)
})

const mapDispatchToProps = dispatch => ({
    handleUpdateRiskProfile: data => dispatch(handleUpdateRiskProfile(data)),
    getClients: () => dispatch(getClients())
})

export default connect(mapStateToProps, mapDispatchToProps)(index);