import React from 'react';
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Cuentas from './Cuentas';
import Restricciones from './Restricciones';
import Confirmar from './Confirmar';
import Resultado from './Resultado';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const index = ({accounts, restrictions, 
	fundsData,  
	setRebalance, 
	rebalanceData, resultIsLoading, reset, 
	accountsLoading, 
	setAccountSelected,
	newFunds,
	accountSelected,
	fundsLoading,
	error,
	pdfRebalance,
	handleSelectedPort,
	step,
	getResponse,
	getPortafolioSelected,
	infoAccount,
	getTableState,
	tableState
}) => {

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(step);
    const steps = [
        'Seleccionar Cuentas', 'Restricciones', 'Confirmar', 'Resultado'
    ];

    const [showButtons, setShowButtons] = React.useState(false);
	const [restrictionsSelected, setRestrictionsSelected] = React.useState({
        n_years: '',
        recom_ports: '',
        n_portafolios: '',
        libreRiesgo: ''
	});
		  
    const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1);
		if(activeStep === 1){
			setShowButtons(true)
		}else if(activeStep === 2){
			setShowButtons(false)
			setRebalance(restrictionsSelected)
		}else if(activeStep === 3){
			handleReset();
		}else{
			setShowButtons(false)
		}		
    };
  
    const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
		setShowButtons(false)
    };
  
    const handleReset = () => {
		reset();
		setRestrictionsSelected({
			n_years: '',
			recom_ports: '',
			n_portafolios: '',
			libreRiesgo: ''
		})
		setActiveStep(0);				  
	};

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
			case 0:
				return <Cuentas 
					data={accounts} 
					onClick={selectedAccount} 
					isLoading={accountsLoading}
					getState={getTableState}
					initialState={tableState}		
				/>;
			case 1:					
				return <Restricciones 
						accountSelected={accountSelected}
						data={restrictions} 
						isValidated={validateSelect} 
						restrictionsSelected={restrictionsSelected}      
					/>;
			case 2:							
				return <Confirmar 
						accountSelected={accountSelected}
						restrictionsSelected={restrictionsSelected} 
						funds={fundsData}
						newFunds={newFunds}
						isLoading={fundsLoading}
						info={infoAccount}
						/>;
			case 3:
				return <Resultado 
						accountSelected={accountSelected}
						funds={fundsData} 
						data={rebalanceData} 
						resultIsLoading={resultIsLoading} 
						reset={handleReset}
						error={error}
						pdfRebalance={pdfRebalance}
						handleSelectedPort={handleSelectedPort}
						getResponse={getResponse}
						getPortafolioSelected={getPortafolioSelected}
						/>;
			default:
				return 'No especificado';
        }
    }

    const selectedAccount = data => {		
		setAccountSelected(data)
		setActiveStep(prevActiveStep => prevActiveStep + 1);		
    }

    const validateSelect = state =>{		
        setRestrictionsSelected(state)
		setShowButtons(true)
    }
  
    return (
		<div>
			<CardHeader>
				<Breadcrumbs aria-label="breadcrumb">
					<Typography color="textPrimary">Rebalanceador</Typography>
				</Breadcrumbs>
			</CardHeader>

			<div className="content" style={{overflowY: 'scroll'}}> 
				<div className="space" style={{height: '3em'}}></div>
				<div className={classes.root}>
					<Stepper style={{backgroundColor: "transparent"}} activeStep={activeStep} alternativeLabel>
						{steps.map(label => (
							<Step key={label}>
							<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					<div>
						<div>   
													
							<div className="text-center">                            
								{(resultIsLoading && activeStep !== steps.length - 1) &&
									<Button
										disabled={activeStep === 0}
										onClick={handleBack}
										className={classes.backButton}
									>Atras</Button>
								}
								{((showButtons || !resultIsLoading) && error == null) &&
									<Button variant="contained" color="primary" onClick={handleNext}>                                
										{activeStep === steps.length - 1 ? 'Terminar' : activeStep === steps.length - 2 ? 'Rebalancear' : 'Siguiente'}
									</Button> 							 
								}                         
							</div>
													
							<div className={classes.instructions}>{getStepContent(activeStep)}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	
    );
};

export default index;