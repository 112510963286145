import React, { Component } from 'react';
import { connect } from 'react-redux';
import AssignStaff from './../../components/Staff/AssignStaff';
import {getAllClientsNoFilter, pending as pendingClients} from './../../selectors/client';
import {getAllStaff, getStatus, pending, staffData, pendingInfo, getSelectedStaff,
    actionMessage,
    error
} from './../../selectors/staff';
import {staffAssignClient} from './../../actions/staff'

class AssignClientContainer extends Component {

    constructor(props){
        super(props);
        this.handleClickAssignClients = this.handleClickAssignClients.bind(this);
    }

    handleClickAssignClients(clients, staff){
        console.log('Asignando clientes a staff')
        console.log(staff)
        console.log(clients)
        const data = {
            'option': 1,
            'staff':staff,
            'clients':clients
        }
        this.props.staffAssignClient(data)        
    }

    render() {
        return <AssignStaff 
                    dataStaff={this.props.staffList}
                    dataClient={this.props.clients}
                    onclickAssign={this.handleClickAssignClients}
                    message={this.props.messageStaffAssign}
                    pending={this.props.pendingClients}
                />
    }
}

const mapStateToProps = state => ({
    clients: getAllClientsNoFilter(state),
    pendingClients: pendingClients(state),
    staffList: getSelectedStaff(state),
    messageStaffAssign: state.staff.messageStaffAssign
})

const mapDispatchToProps = dispatch => ({
    staffAssignClient: (data) => dispatch(staffAssignClient(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignClientContainer);
