import {ADMINISTRADORA_TOTAL_INIT, ADMINISTRADORA_TOTAL_SUCCESS, ADMINISTRADORA_TOTAL_ERROR,
    ADMINISTRADORA_INFO_GET_INIT, ADMINISTRADORA_INFO_GET_SUCCESS, ADMINISTRADORA_INFO_GET_ERROR,
    ADMINISTRADORAS_GET_INIT, ADMINISTRADORAS_GET_SUCCESS, ADMINISTRADORAS_GET_ERROR, 
    ACCOUNTS_GET_INIT, ACCOUNTS_GET_SUCCESS, ACCOUNTS_GET_ERROR
} from '../constants';
import httpClient from '../api/httpClient';
import {urlAdministradoraCard, urlAdministradoraCardInfo, urlAdministradoras, urlCuentasAdministradora} from '../api/urls';

export const getAdministradoraCard = () => {
    return (dispatch, getState) => {
        dispatch(_getAdministradoraCardInit())
        httpClient.getAll(urlAdministradoraCard)
        .then(res => {            
            dispatch(_getAdministradoraCardSuccess(res))
        })
        .catch(error => {            
            dispatch(_getAdministradoraCardError(error))
        })
    }
}

export const getAdministradoraCardInfo = () => {
    return (dispatch, getState) => {
        dispatch(_getAdministradoraCardInfoInit())
        httpClient.getAll(urlAdministradoraCardInfo)
        .then(res => {            
            dispatch(_getAdministradoraCardInfoSuccess(res))
        })
        .catch(error => {            
            dispatch(_getAdministradoraCardInfoError(error))
        })
    }
}

export const getAdministradoras = () => {
    return (dispatch, getState) => {
        dispatch(getAdministradorasInit())
        httpClient.getAll(urlAdministradoras)
        .then(res => {            
            dispatch(getAdministradorasSuccess(res))
        })
        .catch(error => {            
            dispatch(getAdministradorasError(error))
        })
    }
}

export const getCuentasAdministradoras = () => {
    return (dispatch, getState) => {
        dispatch(getCuentasAdministradoraInit())
        httpClient.getAll(urlCuentasAdministradora)
        .then(res => {            
            dispatch(getCuentasAdministradoraSuccess(res))
        })
        .catch(error => {            
            dispatch(getCuentasAdministradoraError(error))
        })
    }
}

const getCuentasAdministradoraInit = () => ({
    type: ACCOUNTS_GET_INIT 
})

const getCuentasAdministradoraSuccess = response => ({
    type: ACCOUNTS_GET_SUCCESS,
    payload: {
        response
    }
})

const getCuentasAdministradoraError = error => ({
    type: ACCOUNTS_GET_ERROR,
    payload: {
        error
    }
})


const getAdministradorasInit = () => ({
    type: ADMINISTRADORAS_GET_INIT
})

const getAdministradorasSuccess = response => ({
    type: ADMINISTRADORAS_GET_SUCCESS,
    payload: {
        response
    }
})

const getAdministradorasError = error => ({
    type: ADMINISTRADORAS_GET_ERROR,
    payload: {
        error
    }
})


const _getAdministradoraCardInfoInit = () => ({
    type: ADMINISTRADORA_INFO_GET_INIT
})

const _getAdministradoraCardInfoSuccess = response => ({
    type: ADMINISTRADORA_INFO_GET_SUCCESS,
    payload: {
        response
    }
})

const _getAdministradoraCardInfoError = error => ({
    type: ADMINISTRADORA_INFO_GET_ERROR,
    payload: {
        error
    }
})

const _getAdministradoraCardInit = () => ({
    type: ADMINISTRADORA_TOTAL_INIT
})

const _getAdministradoraCardSuccess = response => ({
    type: ADMINISTRADORA_TOTAL_SUCCESS,
    payload: {
        response
    }
})

const _getAdministradoraCardError = error => ({
    type: ADMINISTRADORA_TOTAL_ERROR,
    payload: {
        error
    }
})