import {PATRIMONY_TOTAL_GET_INIT, PATRIMONY_TOTAL_GET_SUCCESS, PATRIMONY_TOTAL_GET_ERROR,
    PATRIMONY_INFO_GET_INIT, PATRIMONY_INFO_GET_SUCCESS, PATRIMONY_INFO_GET_ERROR
} from '../constants';

const initialState = {
    total       : 0,
    invertido   : 0,
    caja        : 0,
    pending     : false,
    pendingInfo : false,
    error       : null,
    dataPatrimonio: null,
    dataUsuario : null
}

const patrimony = (state = initialState, action) => {
    switch (action.type) {
        case PATRIMONY_TOTAL_GET_INIT:
            return {
                ...state,
                pending : true,
                error   : null
            };
        case PATRIMONY_TOTAL_GET_SUCCESS:
            return {
                ...state,
                total       : action.payload.patrimony.data.total,
                invertido   : action.payload.patrimony.data.invertido,
                caja        : action.payload.patrimony.data.caja,
                pending     : false,
            };
        case PATRIMONY_TOTAL_GET_ERROR:
            return {
                ...state,
                total       : 0,
                invertido   : 0,
                caja        : 0,
                error       : action.payload.error,
                pending     : false,
            };    
        
        case PATRIMONY_INFO_GET_INIT:
            return {
                ...state,  
                pendingInfo: true,              
                error   : null
            };
        case PATRIMONY_INFO_GET_SUCCESS:
            return {
                ...state,
                dataPatrimonio: action.payload.patrimony.data.dataPatrimonio,                
                dataUsuario   : action.payload.patrimony.data.dataUsuario,      
                pendingInfo: false,          
                
            };
        case PATRIMONY_INFO_GET_ERROR:
            return {
                ...state,
                dataPatrimonio: [],                
                dataUsuario   : [],
                pendingInfo: false,          
                error       : action.payload.error,                
            };    

        default:
            return state;
    }
}

export default patrimony;