import React from 'react';
import { connect } from 'react-redux';
import {
    handleUpdateRole,
    setOpenEdit
} from '../../../actions/permissions';

// import {getActiveRolePermissions} from 'selectors/permissions';
import EditRoles from '../../../components/RolesUsers/EditRole/EditRole';


class EditRoleContainer extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return(
            <EditRoles 
                uid='editPanel'
                active={this.props.openEdit}
                title={'Editar Rol'}
                permissions={this.props.permissions}
                editActive={this.props.editActive}
                openEdit={this.props.openEdit}
                handleUpdateRole={this.props.handle_update_role}
                setOpenEdit={this.props.set_open_edit}
                editSuccess={this.props.editSuccess}
                // activeRolePermissions={this.props.activeRolePermissions}   

            />
        )
    }
}

const mapStateToProps = state => {
    return {
        // activeRolePermissions: getActiveRolePermissions(state),
        editActive: state.permissions.editActive,
        openEdit: state.permissions.openEdit,
        editSuccess: state.permissions.editSuccess,
        permissions: state.permissions.permissions
    }
}

const mapDispatchToProps = dispatch => ({
    handle_update_role: role => dispatch(handleUpdateRole(role)),
    set_open_edit: isOpen => dispatch(setOpenEdit(isOpen))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditRoleContainer)