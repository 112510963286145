import React from "react";
import {Link} from "react-router-dom";
import DateRangePicker from 'react-daterange-picker';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Row,
    Col,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container,
    ButtonGroup,
    UncontrolledDropdown, FormGroup

} from "reactstrap";

import dashboardRoutes from "../../routes/dashboard";


class HeaderComponent extends React.Component {    

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            iaDropdownOpen: false,
            pfDropdownOpen: false,
            color: "transparent"
        };
        this.toggle = this.toggle.bind(this);
        
    }    


    toggle() {
        if (this.state.isOpen) {
            this.setState({
                color: "transparent"
            });
        } else {
            this.setState({
                color: "white"
            });
        }
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    iaDropdownToggle(e) {
        this.setState({
            iaDropdownOpen: !this.state.iaDropdownOpen
        });
    }

    pfDropdownToggle(e) {
        this.setState({
            pfDropdownOpen: !this.state.pfDropdownOpen
        });
    }

    getBrand() {
        var name;
        dashboardRoutes.map((prop, key) => {
            if (prop.collapse) {
                prop.views.map((prop, key) => {
                    if (prop.path === window.location.pathname) {
                        name = prop.name;
                    }
                    return null;
                });
            } else {
                if (prop.redirect) {
                    if (prop.path === window.location.pathname) {
                        name = prop.name;
                    }
                } else {
                    if (prop.path === window.location.pathname) {
                        name = prop.name;
                    }
                }
            }
            return null;
        });
        return name;
    }

    openSidebar() {
        document.documentElement.classList.toggle("nav-open");
        this.refs.sidebarToggle.classList.toggle("toggled");
    }

    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    updateColor() {
        if (window.innerWidth < 993 && this.state.isOpen) {
            this.setState({
                color: "white"
            });
        } else {
            this.setState({
                color: "transparent"
            });
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateColor.bind(this));
    }

    componentDidUpdate(prevProps) {
        console.log('componentDidUpdate', prevProps);
        if (
            window.innerWidth < 993 &&
            prevProps.location.pathname !== this.props.location.pathname &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            this.refs.sidebarToggle.classList.toggle("toggled");
        }
    }


    render() {
        return (
            
            // add or remove classes depending if we are on full-screen-maps page or not
            <Navbar
                color={
                    window.location.pathname.indexOf("full-screen-maps") !== -1
                        ? "white"
                        : this.state.color
                }
                expand="lg"
                className={
                    window.location.pathname.indexOf("full-screen-maps") !== -1
                        ? "navbar-absolute "
                        : "navbar-absolute " +
                        (this.state.color === "transparent" ? "navbar-transparent " : "")
                }
            >
                <Container fluid>
                    <div className="navbar-wrapper">
                        <div className="navbar-toggle">
                            <button
                                type="button"
                                ref="sidebarToggle"
                                className="navbar-toggler"
                                onClick={() => this.openSidebar()}
                            >
                                <span className="navbar-toggler-bar bar1"/>
                                <span className="navbar-toggler-bar bar2"/>
                                <span className="navbar-toggler-bar bar3"/>
                            </button>
                        </div>
                        <NavbarBrand>{this.getBrand()}</NavbarBrand>
                    </div>
                </Container>
            </Navbar>
        );
    }
}

export default HeaderComponent;