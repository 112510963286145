import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Render = ({data}) => {
    if(data){
        return <div className="container">
            <h1 className="text-center mt-5">{data}</h1>
        </div>
    }else{
        return <div className="container">
            <h1 className="text-center mt-5">0</h1>
        </div>
    }
}

const Card = ({data, pending}) => {
    return (
        <div>  
            {!pending ? 
				<Render data={data}/>
                :
                <div className="text-center mt-5">
                    <CircularProgress size={50} thickness={5}/>
                </div>
			}
        </div>
    );
}

export default Card;