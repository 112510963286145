import React from 'react';
import {Card, CardBody, Row, Col} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableAccount from '../Rebalance/Result/TableAccount';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 600,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

const index = ({
    handleReturn, 
    accountSelected, 
    selectedPortafolio,
    comparePortafolio,
}) => {
    const classes = useStyles();

    return (
        <div>
            <Card onClick={handleReturn}>

                <CardBody>
                    <h3 className="text-center">{selectedPortafolio[2]}
                        <CancelRoundedIcon className='float-right' fontSize={'large'} onClick={handleReturn} style={{cursor: 'pointer'}}/>
                    </h3>
                    
                    <Card>
                        <div className="alert alert-info">Cuenta</div>
                        <CardBody>
                            <TableAccount account={accountSelected} />
                        </CardBody>
                    </Card>


                    <Card>                        
                        <div className="alert alert-info mb-1 p-0">
                            <Row>
                                <Col md={6}>
                                    <h6 className="m-3 p-1">Portafolio</h6>                                    
                                </Col>
                            </Row>
                        </div>
                        <CardBody>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="portafolio_actual">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell style={{width: `5%`}}>ID</TableCell>
                                        <TableCell style={{width: `10%`}}>Rut</TableCell>
                                        <TableCell style={{width: `35%`}}>Nombres</TableCell>
                                        <TableCell style={{width: `20%`}}><div className="text-right">Actual</div></TableCell>
                                        <TableCell style={{width: `20%`}}>Nuevo</TableCell>                     
                                        <TableCell style={{width: `10%`}}>Dist.%</TableCell>               
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {comparePortafolio.map((row, i) => {
                                        
                                        return (<TableRow key={i}>                                                
                                            <TableCell>{row.f}</TableCell>
                                            <TableCell>{row.rut}</TableCell>                              
                                            <TableCell>{row.entidad}</TableCell>
                                            <TableCell className="pr-0">
                                            
                                                <table style={{width: `100%`}}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{width: `${100 - row.actualGraph}%`, backgroundColor: '#fff'}}></td>
                                                            <td className="text-right" style={{width: `${row.actualGraph}%`, backgroundColor: '#f36868'}}>{row.actualGraph != 0 ? row.actualGraph: ''}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>


                                            </TableCell>

                                            <TableCell className="pl-0">    

                                                <table style={{width: `100%`}}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{width: `${row.newGraph}%`, backgroundColor: '#19bd35'}}>{row.newGraph}</td>
                                                            <td style={{width: `${100 - row.newGraph}%`, backgroundColor: '#fff'}}></td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </TableCell>                
                                            <TableCell>{row.weight ? row.weight : '0%'}</TableCell>                                             
                                        </TableRow>)
                                        
                                    })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardBody>
                    </Card>


                </CardBody>
            </Card>          

        </div>
    );
};

export default index;