import React, { Component } from 'react';
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {ModalHeader, ModalBody, ModalFooter, Modal, Input} from "reactstrap";
import { Button } from '../../../components';
import CircularProgress from '@material-ui/core/CircularProgress';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const messages = {
	allDay: 'Todo el día',
	previous: '<',
	next: '>',
	today: 'Hoy',
	month: 'Mes',
	week: 'Semana',
	day: 'Día',
	agenda: 'Agenda',
	date: 'Fecha',
	time: 'Hora',
	event: 'Recordatorio',
	work_week: 'Semana laboral',
	yesterday: 'Ayer',
	tomorrow: 'Mañana',
	noEventsInRange: 'No hay recordatorios en este rango de fecha',
	showMore: total => `+ Mostrar más (${total})`
};

const ModalCalendar = ({state, func, body, remove}) => {
	return(
	 <Modal isOpen={state} toggle={func} className="modal-dialog modal-dialog-centered">
		<ModalHeader toggle={func}> 
			Recordatorio
		</ModalHeader>  
		<ModalBody>  
			{body}												                                                       
		</ModalBody>
		<ModalFooter>                                
			<Button color="danger" onClick={remove}>Eliminar</Button>
			<Button color="primary" onClick={func}>Aceptar</Button>
		</ModalFooter>                                                     
	 </Modal>
	); 
};

class index extends Component {

	constructor({events}) {
		super();
		this.state = {
			events,
			modal: false,
			modal_create: false,
			modal_title: "",
			modal_start: "",
			modal_end: "",	
			event: null,	
		};
			
		this.handleChange = this.handleChange.bind(this);
		this.onSelectEvent = this.onSelectEvent.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.toggleModalCreate = this.toggleModalCreate.bind(this);
		this.renderModalBody = this.renderModalBody.bind(this);
		this.onSelectSlot = this.onSelectSlot.bind(this);
		this.addEvent = this.addEvent.bind(this);
		this.removeEvent = this.removeEvent.bind(this);
		this.moveEvent = this.moveEvent.bind(this);	
		this.resizeEvent = this.resizeEvent.bind(this);
		this.handleSaveChanges = this.handleSaveChanges.bind(this);
	}

	handleChange(event){
		this.setState({modal_title: event.target.value});
	}

	onSelectEvent(event){	
		this.toggleModal(event)
	}

	toggleModal(event){   
		console.log(event)  
		this.setState(prevState => ({
			modal: !prevState.modal,
			modal_title: event.title, 
			modal_start: event.start,
			modal_end: event.end,
			event: event	
		}));		
	}

	toggleModalCreate(event){     
		this.setState(prevState => ({
			modal_create: !prevState.modal_create,
			modal_start: event.start,
			modal_end: event.end,
			modal_title: "",
			event
		}));		
	}

	renderModalBody(){ 
		const title= this.state.modal_title ? this.state.modal_title.toString() : "";
		const start= this.state.modal_start ? this.state.modal_start.toString() : "";
		const end= this.state.modal_end ? this.state.modal_end.toString() : "";
		return (
			<div>
				<h6>{title}</h6>
				<p><strong>Inicio:</strong> {start}</p>					
				<p><strong>Termino:</strong> {end}</p>	
			</div>
		)
    }

	onSelectSlot(event){
		const slots = event.slots
		console.log(slots);
		this.toggleModalCreate(event);
	}

	addEvent(){
		const {modal_start, modal_end, modal_title} = this.state;
		console.log(modal_title)
		this.setState(prevState => ({
			modal_create: !prevState.modal_create,
			events: [
				...this.state.events,
				{
					start: modal_start,
					end: modal_end,
					title: modal_title,
				},
			],
		}));			

	}

	removeEvent(){
		const {event} = this.state;
		this.setState((prevState) => {
			const events = [...prevState.events]
			const idx = events.indexOf(event)
			events.splice(idx, 1);
			return { events, modal: !prevState.modal };
		});
	}

	moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot }){		
		const { events } = this.state
	
		const idx = events.indexOf(event)
		let allDay = event.allDay
	
		if (!event.allDay && droppedOnAllDaySlot) {
		  allDay = true
		} else if (event.allDay && !droppedOnAllDaySlot) {
		  allDay = false
		}
	
		const updatedEvent = { ...event, start, end, allDay }
	
		const nextEvents = [...events]
		nextEvents.splice(idx, 1, updatedEvent)
	
		this.setState({
		  events: nextEvents,
		})
	
		// alert(`${event.title} was dropped onto ${updatedEvent.start}`)
	}
	
	resizeEvent({ event, start, end }){
		const { events } = this.state	
		const idx = events.indexOf(event)		
		const updatedEvent = { ...event, start, end }	
		const nextEvents = [...events]
		nextEvents.splice(idx, 1, updatedEvent)	
		this.setState({
		  events: nextEvents,
		})

	}

	handleSaveChanges(){			
		this.props.onClick(this.state.events)
	}

	render() {
		return (
			<div className="content">

				{!this.props.saving ? 
				<Button color="success" onClick={this.handleSaveChanges} block>Guardar</Button>
				:
				<Button color="warning" block>Guardando... 
				<CircularProgress size={15} thickness={20}/>
				</Button>
				}

				<DnDCalendar
					selectable
					localizer={localizer}
					messages={messages}
					events={this.state.events}

					onEventDrop={this.moveEvent}

					resizable				    
					onEventResize={this.resizeEvent}
					//onDragStart={console.log}
					//onDragOver={console.log}

					onSelectSlot={this.onSelectSlot}
					onSelectEvent={this.onSelectEvent}

					defaultView={Views.MONTH}
					defaultDate={new Date()}
					style={{ height: "100vh" }}
				/>
				
				<ModalCalendar 
					state={this.state.modal} 
					func={this.toggleModal} 	
					body={this.renderModalBody()}
					remove={this.removeEvent}
				/>

				<Modal isOpen={this.state.modal_create} toggle={this.toggleModalCreate} className="modal-dialog modal-dialog-centered">
					<ModalHeader toggle={this.toggleModalCreate}> 
						Recordatorio
					</ModalHeader>  
					<ModalBody>  
						<Input placeholder="Aqui crea tu recordatorio..." 
							onChange={this.handleChange}
							required
							autoFocus
						/>												                                                       
					</ModalBody>
					<ModalFooter>                                
						<Button color="primary" onClick={this.toggleModalCreate}>Cancelar</Button>
						<Button color="success" onClick={this.addEvent}>Aceptar</Button>
					</ModalFooter>                                                     
				</Modal>

			</div>
		)
	}
}

export default index;