import React, {Component} from "react";
import {connect} from 'react-redux';

import AdminCard from '../../components/AdminDashboard/AdminCard';


import PatrimonioTotalContainer from './PatrimonioTotalContainer';
import PatrimonioTotalCard from './PatrimonioTotalContainer/CardContainer'

import ClientesContainer from './ClientesContainer';
import ClientesCard from './ClientesContainer/CardContainer'

import AsesoresContainer from './AsesoresContainer';
import AsesoresCard from './AsesoresContainer/CardContainer'

import FondosMutuosContainer from './FondosMutuosContainer';
import FondosMutuosCard from './FondosMutuosContainer/CardContainer'

import ActivosContainer from './ActivosContainer';
import ActivosCard from './ActivosContainer/CardContainer';

import AdministradorasContainer from './AdministradorasContainer';
import AdministradorasCard from './AdministradorasContainer/CardContainer';

import RentabilidadCarterasContainer from './RentabilidadCarterasContainer';
import RentabilidadCarterasCard from './RentabilidadCarterasContainer/CardContainer';

import RecordatoriosContainer from './RecordatoriosContainer';
import RecordatoriosCard from './RecordatoriosContainer/CardContainer';
 
import {
    Row,
    ModalHeader, 
    ModalBody, 
    ModalFooter, 
    Modal
} from "reactstrap";

import {
    PanelHeader
} from "../../components";



const modal_bodies = {
    'Patrimonio Total': <PatrimonioTotalContainer />,
    'Clientes': <ClientesContainer />,
    'Asesores': <AsesoresContainer />,
    'Fondos Mutuos': <FondosMutuosContainer />,
    'Activos': <ActivosContainer />,
    'Administradoras': <AdministradorasContainer />,
    'Rentabilidad Carteras': <RentabilidadCarterasContainer />,
    'Recordatorios':< RecordatoriosContainer />
};


const DashboardModal = ({state, func, title, body}) => {
   return(
    <Modal isOpen={state} toggle={func}  
        style={{maxWidth: '1440px'}}
        // className="modal-dialog modal-xl"
    >
        <ModalHeader toggle={func}> 
            {title}
        </ModalHeader>                                        
        <ModalBody>    
            {body}                                                       
        </ModalBody>
        <ModalFooter>                                

        </ModalFooter>                    
    </Modal>
   ); 
}


class index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false, 
            modal_name: ""
        }
        this.toggleModal = this.toggleModal.bind(this);
        this.renderModalBody = this.renderModalBody.bind(this);
        this.renderModalTitle = this.renderModalTitle.bind(this);
    }

    toggleModal(title){ 
        
        this.setState(prevState => ({
            modal: !prevState.modal,
            modal_name: title
        }));
    }

    renderModalBody(){
        
        if (modal_bodies.hasOwnProperty(this.state.modal_name)){
            return modal_bodies[this.state.modal_name];
        }
    }

    renderModalTitle(){
        if (typeof(this.state.modal_name) === "string") {
            return(this.state.modal_name);
        }
    }


    render() {
        return(
            <div>
            <PanelHeader size="sm" />
            <div className="content">
                <Row>
                    <AdminCard width={6} title="Patrimonio Total" onClick={this.toggleModal}>
                        <PatrimonioTotalCard />
                    </AdminCard>
                    <AdminCard width={3} title="Clientes" cursorDefault={true}>
                        <ClientesCard />
                    </AdminCard>
                    <AdminCard width={3} title="Asesores" onClick={this.toggleModal}>
                        <AsesoresCard />
                    </AdminCard>
                    
                </Row>
                <Row>
                    <AdminCard width={6} title="Administradoras" onClick={this.toggleModal}>
                        <AdministradorasCard />
                    </AdminCard>

                    <AdminCard width={6} title="Fondos Mutuos" onClick={this.toggleModal}>
                        <FondosMutuosCard />
                    </AdminCard>
                    {/* <AdminCard title="Activos" onClick={this.toggleModal}>
                        <ActivosCard/>
                    </AdminCard> */}

                    {/* <AdminCard title="Rentabilidad Carteras"  onClick={this.toggleModal}>
                        <RentabilidadCarterasCard />
                    </AdminCard> */}

                    {/* <AdminCard width={3} icon={"travel_info"} title="Recordatorios" onClick={this.toggleModal}>
                        <RecordatoriosCard />
                    </AdminCard> */}
                </Row>

                <DashboardModal state={this.state.modal} func={this.toggleModal} title={this.renderModalTitle()} body={this.renderModalBody()}/>

            </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({

})

const mapDispatchToProps = dispatch => ({
    
})

export default connect(mapStateToProps, mapDispatchToProps)(index);

