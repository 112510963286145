import React from 'react';
import {Card, CardBody,	CardHeader} from "reactstrap";

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function FilterMenu () {

    const classes = useStyles();
    const [state, setState] = React.useState({
        perfilRiesgo: '',
        tipoCuenta: '',
        patrimonio: '',
        edad: '',
        fondo: '',
        admin: '',
        asesor: ''
      });

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth * 2);
    }, []);

    const handleChange = name => event => {
        setState({
          ...state,
          [name]: event.target.value,
        });
    };

    return (
        <Card>
            {/* <CardHeader>Filtros</CardHeader> */}
            <CardBody>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="perfilRiesgoSelect-label">
                        Perfil Riesgo
                    </InputLabel>
                    <Select
                        labelId="perfilRiesgoSelect-label"
                        id="perfilRiesgoSelect"
                        value={state.perfilRiesgo}
                        onChange={handleChange('perfilRiesgo')}
                        labelWidth={labelWidth}
                    >
                        <MenuItem value=""><em>Ninguno</em></MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="tipoCuentaSelect-label">
                        Tipo Cuenta
                    </InputLabel>
                    <Select
                        labelId="tipoCuentaSelect-label"
                        id="tipoCuentaSelect"
                        value={state.tipoCuenta}
                        onChange={handleChange('tipoCuenta')}
                        labelWidth={labelWidth}
                    >
                        <MenuItem value=""><em>Ninguno</em></MenuItem>
                        <MenuItem value={1}>APV</MenuItem>
                        <MenuItem value={2}>A</MenuItem>
                        <MenuItem value={3}>B</MenuItem>
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="patrimonioSelect-label">
                        Patrimonio
                    </InputLabel>
                    <Select
                        labelId="patrimonioSelect-label"
                        id="patrimonioSelect"
                        value={state.patrimonio}
                        onChange={handleChange('patrimonio')}
                        labelWidth={labelWidth}
                    >
                        <MenuItem value=""><em>Ninguno</em></MenuItem>
                        <MenuItem value={1}>$ 15.000.000</MenuItem>
                        <MenuItem value={2}>$ 25.000.000</MenuItem>
                        <MenuItem value={3}>$ 35.000.000</MenuItem>
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="edadSelect-label">
                        Edad
                    </InputLabel>
                    <Select
                        labelId="edadSelect-label"
                        id="edadSelect"
                        value={state.edad}
                        onChange={handleChange('edad')}
                        labelWidth={labelWidth}
                    >
                        <MenuItem value=""><em>Ninguno</em></MenuItem>
                        <MenuItem value={1}>25 - 30 años</MenuItem>
                        <MenuItem value={2}>31 - 40 años</MenuItem>
                        <MenuItem value={3}>41 - 50 años</MenuItem>
                        <MenuItem value={3}>51 - 60 años</MenuItem>
                        <MenuItem value={3}>61 - 70 años</MenuItem>
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="fondoSelect-label">
                        Fondo
                    </InputLabel>
                    <Select
                        labelId="fondoSelect-label"
                        id="fondoSelect"
                        value={state.fondo}
                        onChange={handleChange('fondo')}
                        labelWidth={labelWidth}
                    >
                        <MenuItem value=""><em>Ninguno</em></MenuItem>
                        <MenuItem value={1}>BGF Aria Fund</MenuItem>
                        <MenuItem value={2}>A</MenuItem>
                        <MenuItem value={3}>BC</MenuItem>
                        <MenuItem value={3}>XA</MenuItem>                        
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="adminSelect-label">
                        Admin
                    </InputLabel>
                    <Select
                        labelId="adminSelect-label"
                        id="adminSelect"
                        value={state.admin}
                        onChange={handleChange('admin')}
                        labelWidth={labelWidth}
                    >
                        <MenuItem value=""><em>Ninguno</em></MenuItem>
                        <MenuItem value={1}>Larrain Vial</MenuItem>
                        <MenuItem value={2}>Principal</MenuItem>                       
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel ref={inputLabel} id="asesorSelect-label">
                        Asesor
                    </InputLabel>
                    <Select
                        labelId="asesorSelect-label"
                        id="asesorSelect"
                        value={state.asesor}
                        onChange={handleChange('asesor')}
                        labelWidth={labelWidth}
                    >
                        <MenuItem value=""><em>Ninguno</em></MenuItem>
                        <MenuItem value={1}>Juan Marquez</MenuItem>
                        <MenuItem value={2}>Daniel Cortes</MenuItem>                       
                    </Select>
                </FormControl>


            </CardBody>
        </Card>
    );

}

export default FilterMenu;