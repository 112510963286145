import React from "react";

const placeholder = "Archivo CSV";

class ImageUpload extends React.Component {   

    constructor(props) {
        super(props);
        this.state ={
            file: ''
        }
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    onFormSubmit(e){
        e.preventDefault() 
        this.props.upload(this.props.id, this.state.file, this.props.columns, this.props.label)
    }

    onChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
            return
        };

        document.getElementById(`label-custom-file-input-${this.props.id}`).innerHTML = files[0].name

        this.setState({
            file: files[0]
        })
    }

    render(){
        return(
            <form onSubmit={this.onFormSubmit} className="form-group row">  
                <label className="col-md-3 col-form-label text-md-right">{this.props.label}</label>

                <div className="col-md-6">
                    <div className="custom-file">
                        <input type="file"  
                            className="custom-file-input"
                            onChange={this.onChange} />                    
                        <label id={`label-custom-file-input-${this.props.id}`} 
                            className="custom-file-label" 
                            data-browse="Elegir">
                            {placeholder}
                        </label>                        
                    </div> 
                </div>

                <div className="col-md-3 pl-0">
                    <button className="btn btn-primary btn-sm mt-0 h-75" type="submit">Subir</button>
                </div>  
                                
            </form>
        )
    }
}

export default ImageUpload;
