import React from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {MATERIAL_TABLE_LOCALIZATION} from './../../../constants';
import {CheckCircleRounded} from '@material-ui/icons';
import { rutFormat, patrimonyFormat } from '../../../helpers/formatter';

const theme = createTheme({
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        light: '#01579b',
        main: '#ff9100',
      },       
    },      
});

const state = {	  
    columns: [
        { title: 'Rut', field: 'rut', editable: 'onAdd', width: 100, render: ({rut}) => <span>{rutFormat(rut)}</span> },
        { title: 'Nombre', field: 'name', width: 100 },
        { title: 'Apellido', field: 'last_name',  width: 100 },
        { title: 'Patrimonio', field: 'patrimony', type: 'currency', width: 100, 
            render: ({patrimony}) => (<span>$ {patrimonyFormat(patrimony)}</span>)
        }, 
    ],
}

function TableStaff ({data, clients, onClick, pending}) {
    
    const handleRowClicked = (selectedRow) => {
        console.log(selectedRow)
    }

    const handleRowSelected = (rows) => {
        console.log(rows)     
    }
    
    return (
    <MuiThemeProvider theme={theme}>
        <MaterialTable
            title="Asesores"
            columns={state.columns}
            data = {data}  
            isLoading= {pending}                
            options={{
                selection: true,
                sorting: true,
                rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#37b15933' : '' })
            }}              
            onRowClick={((evt, selectedRow) => handleRowClicked(selectedRow) )}                
            onSelectionChange={(rows) => handleRowSelected(rows)}
            actions={[
                {
                    tooltip: 'Asignar',
                    icon: () => <CheckCircleRounded />,
                    onClick: (evt, data) => onClick(data, clients)
                    
                }
            ]}
            localization = {MATERIAL_TABLE_LOCALIZATION}
        />
    </MuiThemeProvider>
    );
    
}

export default TableStaff;