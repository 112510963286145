import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchField from './SearchField';

function EnhancedTableToolbar(props) {
    const { title, numSelected, withCheckbox, searchValue, setSearchValue } = props;
    const colors = useSelector((state) => state.login.appColors)
    const useToolbarStyles = makeStyles((theme) => ({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            color: '#fff',
            // backgroundColor: '#182956',   
            backgroundColor: colors.split(';')[1],     
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
    }));
    const classes = useToolbarStyles();
    

    return (
        <Toolbar
            className={numSelected > 0 ? classes.highlight : classes.root}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div"
                    align="left">
                    {numSelected} {numSelected > 1 ? `Registros seleccionados` : `Registro seleccionado`}
                </Typography>
            ) : (
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div"
                        align="left">
                        {title}
                    </Typography>
                )}

            <SearchField 
                value={searchValue}
                setSearchValue={setSearchValue}
            />

            {/* {numSelected > 0 ? (
                <Tooltip title="Eliminar">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                withCheckbox ?
                    <Tooltip title="Lista de filtros">
                        <IconButton aria-label="filter list">
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                    :
                    <div></div>
            )} */}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    title: PropTypes.string.isRequired,
    numSelected: PropTypes.number.isRequired,
    withCheckbox: PropTypes.bool.isRequired,
    searchValue: PropTypes.string,
    setSearchValue: PropTypes.func.isRequired

};

export default EnhancedTableToolbar;