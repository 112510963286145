import React, { Fragment } from 'react';
import {
    Col,
    Card,
    CardBody
} from "reactstrap";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';


const RoleUsersLoader = () => {
    return(
        <Fragment>
           
            <Col  style={{margin: '3em 0', padding: '0 10%'}} >
                <Skeleton count={5} />
            </Col>
        </Fragment>
    )
    
}

export default RoleUsersLoader;
