import React, { Component } from 'react';
import { connect } from 'react-redux';
import Rebalance from '../../components/RebalanceHistorial/Rebalance';
import { setSelectedHistorial,
getPdf,
selectedPortafolio
} from '../../actions/rebalanceHistorial'
import { selectedHistorial, getResponse, generated_date, portafolioRebalance, getPortafolioActual, compared, selected
} from '../../selectors/rebalanceHistorial';
import withParams from '../../helpers/withParams';
import { urlFormatFromParams } from '../../helpers/formatter';

class RebalanceContainer extends Component {

    constructor(){
        super();
        this.pdfRebalance = this.pdfRebalance.bind(this);
        this.handleSelectedPort = this.handleSelectedPort.bind(this);        
    }

    componentDidMount(){
        const data = {
            rut_client: this.props.params.rut_client,
            cuenta: this.props.params.cuenta,
            rebalance: this.props.params.rebalance
        }
        this.props.setSelectedHistorial(data)
    }

    pdfRebalance(data){
        console.log('PDF Container')
        console.log(data)
        this.props.getPdf(data);
    }

    handleSelectedPort(selected){ 
        console.log(selected);

        const port = []
        const actualPort = [...this.props.portafolioActual]
        let totalActual = 0;
        let totalNew = 0;
        actualPort.forEach(actual => {            
            const newRow = {};
            newRow.f = actual.f;
            newRow.rut = actual.rut;
            newRow.porcentaje = actual.porcentaje.replace(',','.');            
            newRow.actualGraph = actual.porcentaje.split(',')[0];
            newRow.entidad = actual.nombre_instrumento === "NEW_FUNDS" ?
                                `${actual.entidad} (NUEVO)`:
                                actual.entidad;
            newRow.serie = actual.serie;
            selected[0].forEach(nuevo => {
                if(actual.rut === nuevo.rut) {
                    newRow.weight = (nuevo.weight * 100).toFixed(2) + '%';
                    newRow.newGraph = (nuevo.weight * 100).toFixed(0);
                }
            })
            totalActual += parseFloat(newRow.porcentaje.slice(0, -1));
            totalNew += newRow.weight ? parseFloat(newRow.weight.slice(0, -1)) : 0;
            newRow.totalActual = totalActual.toFixed(0) + '%';
            newRow.totalNew = totalNew.toFixed(0) + '%';
            port.push(newRow)
        })

        this.props.selectedPortafolio(selected, port)
    }

    render() {
        const urlParams = urlFormatFromParams(this.props.params);
        return (
            <div>                
                <Rebalance                           
                    rebalance={this.props.selectedHistorial}
                    pdfRebalance={this.pdfRebalance}
                    getResponse={this.props.getResponse}
                    generatedDate={this.props.generatedDate}
                    handleSelectedPort={this.handleSelectedPort}
                    portafolioRebalance={this.props.portafolioRebalance}
                    isLoading = {this.props.isLoadingSelected}
                    params={this.props.params}
                    selectedPortafolio={this.props.selected}
                    comparePortafolio={this.props.compared}
                    paramsTable={urlParams}
                />                
            </div>
        );
    }
}

const mapStateToProps = state => ({
    selectedHistorial: selectedHistorial(state),
    getResponse: getResponse(state),
    generatedDate: generated_date(state),
    portafolioRebalance: portafolioRebalance(state),
    portafolioActual: getPortafolioActual(state),
    compared: compared(state),
    selected: selected(state),
    isLoadingSelected: state.rebalanceHistorial.isLoadingSelected
})

const mapDispatchToProps = dispatch => ({
    setSelectedHistorial: data => dispatch(setSelectedHistorial(data)),
    getPdf: data => dispatch(getPdf(data)),
    selectedPortafolio: (selected, port) => dispatch(selectedPortafolio(selected, port)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withParams(RebalanceContainer));