import React, { Component } from 'react';
import { connect } from 'react-redux';
import AssignStaff from './../../components/Clients/AssignStaff';
import {staffAssignClient} from './../../actions/staff';
import {getUsersList} from './../../selectors/client';
import {getAllStaff, pending as pendingStaff} from './../../selectors/staff';

class AssignStaffContainer extends Component {

    constructor(props){
        super(props);
        this.handleClickAssignStaff = this.handleClickAssignStaff.bind(this);
    }

    handleClickAssignStaff(staff, clients){
        console.log('Asignando staff a clientes')
        const data = {
            'option': 1,
            'staff':staff,
            'clients':clients
        }
        this.props.staffAssignClient(data)        
    }

    render() {
        return <AssignStaff 
                    dataClient={this.props.usersList}
                    dataStaff={this.props.staff}
                    onclickAssign={this.handleClickAssignStaff}  
                    message={this.props.messageStaffAssign}  
                    pending={this.props.pendingStaff}            
                />
    }
}


const mapStateToProps = state => ({
    staff: getAllStaff(state),
    pendingStaff: pendingStaff(state),
    usersList: getUsersList(state),
    messageStaffAssign: state.staff.messageStaffAssign
})

const mapDispatchToProps = dispatch => ({
    staffAssignClient: (data) => dispatch(staffAssignClient(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignStaffContainer);
