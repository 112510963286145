import React, { Component } from 'react';
import {connect} from 'react-redux';
import Staff from '../../../components/AdminDashboard/Asesores';

import { 
    getStaffCardInfo,
    getNextPage,
    getPrevPage,
    getFirstPage,
    getLastPage
} from '../../../actions/staff';
import { 
    staffCard,
    pendingInfo
} from '../../../selectors/staff';

const data = [
    {
        nombre: "Jose Manuel Gonzalez Tapia",
        patrimonio: "$43.000.000",
        invertido: "$40.000.000",
        caja: "$3.000.000",                
        rut: "11.111.111-K",
        porcent: '48%',
        graphic: {
            labels: ['Invertido', 'Caja'],
            datasets: [75, 25]
        }
    },
    {
        nombre: "Maria Ines Gonzalez Tapia",
        patrimonio: "$10.000.000",
        invertido: "$9.000.000",
        caja: "$1.000.000", 
        rut: "11.111.111-K",
        porcent: '48%',
        graphic: {
            labels: ['Invertido', 'Caja'],
            datasets: [25, 75]
        }
    },

];


class index extends Component {
    
    componentDidMount(){
        this.props.getStaffCardInfo();    
    }

    render() {
        return(
            <Staff 
                data={this.props.staffCard} 
                pendingInfo={this.props.pendingInfo}
                pagination={this.props.pagination}
                nextPage={this.props.getNextPage}
                prevPage={this.props.getPrevPage}
                firstPage={this.props.getFirstPage}
                lastPage={this.props.getLastPage}
            />            
        )
    }
}

const mapStateToProps = state => ({
    staffCard: staffCard(state),
    pendingInfo: pendingInfo(state),
    pagination    : state.staff.pagination
})

const mapDispatchToProps = dispatch => ({
    getStaffCardInfo: () => dispatch(getStaffCardInfo()),
    getNextPage: url => dispatch(getNextPage()),
    getPrevPage: url => dispatch(getPrevPage()),
    getFirstPage: url => dispatch(getFirstPage()),
    getLastPage: url => dispatch(getLastPage()),
})

export default connect(mapStateToProps, mapDispatchToProps)(index);