import React from 'react'
import {
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Row,
    Col,
    Table
} from "reactstrap";

const ReactHighcharts = require('react-highcharts');
require('highcharts-3d')(ReactHighcharts.Highcharts);


class ResumenActivos extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return(
            <Row>
                <Col xs={12} md={6}>
                    <Card className="card-plain">
                        <CardHeader>
                            <CardTitle tag="h5">
                                Activos
                        </CardTitle>
                            <br />
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead style={{
                                    "fontSize": ".8em",
                                    "textTransform": "uppercase"
                                }}>
                                    <tr>
                                        <th>
                                            Activo
                                        </th>
                                        <th>
                                            Monto ($)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.consolidatedByType === [] ?
                                        <p> Sin datos para el periodo
                                    seleccionad </p>
                                        :
                                        this.props.consolidatedByType.map((prop, i) => {
                                            console.log(prop);
                                            return (
                                                <tr key={i}>
                                                    <td style={{  "textTransform": "uppercase" }}>
                                                        {prop['activos']}
                                                    </td>
                                                    <td>
                                                        ${this.props.clpFormatter.format(Math.trunc(parseFloat(prop['monto'])))}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>

                <Col xs={12} md={6}>
                    <Card className="card-plain">
                        <CardHeader>
                            <CardTitle tag="h5">
                                Patrimonio por Activo (%)
                            </CardTitle>                            
                        </CardHeader>
                        <CardBody>
                            <ReactHighcharts config={this.props.activosConfig} />                            
                        </CardBody>
                    </Card>                
                    
                </Col>
            </Row>
        )
    }
}

export default ResumenActivos;