import React, {useState} from 'react';
import { LockClosedIcon } from '@heroicons/react/solid'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

function LoginForm({submitLogin, forgotPasswordForm, error, pending}) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        submitLogin({email, password, remember, passwordShow});
    }

    return <div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Ingresa a tu cuenta</h2>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            {/* <input type="hidden" name="remember" defaultValue="true" /> */}
            <div className="rounded-md shadow-sm -space-y-px">
                <div>
                    <label htmlFor="email-address" className="sr-only">
                        Correo electronico
                    </label>
                    <input
                        id="email-address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Correo electronico"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="password" className="sr-only">
                        Contraseña
                    </label>
                    <input
                        id="password"
                        name="password"
                        type={passwordShow ? 'text' : 'password'}
                        autoComplete="current-password"
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Contraseña"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <div style={{ cursor: "pointer", position: "relative", top: "-30px", height: "0px", left: "300px", zIndex: "300" }}>
                        {passwordShow ?
                            <VisibilityOffIcon onClick={()=> setPasswordShow(!passwordShow)}></VisibilityOffIcon> :
                            <VisibilityIcon onClick={() => setPasswordShow(!passwordShow)}></VisibilityIcon>
                        }
                    </div>
                </div>
            </div>

            {error !== null &&
                <div style={{ color: 'red', fontWeight: 'bold', textAlign: 'center' }}>
                    Credenciales Incorrectas
                </div>}

            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        value={remember}
                        onChange={(e) => setRemember(e.target.checked)}
                    />
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                        Mantenerme ingresado
                    </label>
                </div>

                <div className="text-sm" onClick={forgotPasswordForm}>
                    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                        ¿Olvidaste tu contraseña?
                    </a>
                </div>
            </div>

            <div>
                <button
                    disabled={pending ? 'disabled' : ''}
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                    </span>
                    Ingresar
                </button>
            </div>
        </form>
    </div>
}

export default LoginForm;