import {UPLOAD_FILE_INIT, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_ERROR,
    GET_UPLOAD_INIT, GET_UPLOAD_SUCCESS, GET_UPLOAD_ERROR
} from './../constants';
import httpClient from '../api/httpClient';
import {urlUpload, urlUploadFile} from '../api/urls';

export const uploadFile = (data) =>{
    return (dispatch) => {
        dispatch(uploadFileInit());

        const formData = new FormData();
        formData.append('id', data.id);
        formData.append('file', data.file);
        formData.append('columnMap', JSON.stringify(data.columnMap));

        httpClient.create(urlUploadFile, formData)
        .then(response => dispatch(uploadFileSuccess(response)))
        .catch(error => dispatch(uploadFileError(error)))
    }
}

export const getUpload = () =>{
    return (dispatch) => {
        dispatch(getUploadInit());
        httpClient.getAll(urlUpload)
        .then(response => dispatch(getUploadSuccess(response)))
        .catch(error => dispatch(getUploadError(error)))
    }
}

const getUploadInit = () => ({
    type: GET_UPLOAD_INIT,
})

const getUploadSuccess = (response) => ({
    type: GET_UPLOAD_SUCCESS,
    payload: {
        response        
    }
})

const getUploadError = (error) => ({
    type: GET_UPLOAD_ERROR,
    payload: {
        error
    }
})

const uploadFileInit = () => ({
    type: UPLOAD_FILE_INIT
})

const uploadFileSuccess = (response) => ({
    type: UPLOAD_FILE_SUCCESS,
    payload: {
        response        
    }
})

const uploadFileError = (error) => ({
    type: UPLOAD_FILE_ERROR,
    payload: {
        error
    }
})