import {
    FETCH_COMPOSITION_INIT, 
    FETCH_COMPOSITION_SUCCESS, 
    FETCH_COMPOSITION_ERROR,
    FETCH_BREAKDOWN_INIT, 
    FETCH_BREAKDOWN_SUCCESS, 
    FETCH_BREAKDOWN_ERROR,
    SELECT_ACCOUNT_INIT,
    SELECT_ACCOUNT_SUCCESS,
    SELECT_DATE_INIT,
    SELECT_DATE_SUCCESS,
    CARTOLA_ADVISOR_CLIENT_INIT,
    CARTOLA_ADVISOR_CLIENT_SUCCESS,
    CARTOLA_ADVISOR_CLIENT_ERROR,
    RESET_CARTOLA_CLIENT,
    SET_CLIENT_ID,
    LIST_CLIENTS_CARTOLA_INIT,
    LIST_CLIENTS_CARTOLA_SUCCESS,
    LIST_CLIENTS_CARTOLA_ERROR,
    FETCH_CARTOLAPDF_INIT,
    FETCH_CARTOLAPDF_ERROR,
    FETCH_CARTOLAPDF_SUCCESS,
    LIST_CLIENTS_TABLE_STATE
} from '../constants';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

const today = moment();

const initialState = {
    // Start of the selected period.
    periodStart: today.clone().subtract(3, "months"),
    // End of the selected period
    periodEnd: today.clone(),

    patrimonioFinal: "",
    patrimonioFinalStartDate: "",
    patrimonioFinalEndDate: "",

    cartolaLoading: false,

    // Account balance at the start of the period
    initial_amount: null,
    // Account balance at the end of the period
    final_amount: null,

    // Inflation rate
    ipc: [],
    // Cartera data 
    cartera: [],
    cash: {
        administradoras: []
    },
    parsedCartera: {
        administradoras: []
    },
    // Parsed cartera data, formatted for use in components
    movimientos: [],
    percentageAccount: [],
    parsedMovimientos: [],
    portfolioComposition: [],
    asset_type: [""],
    percentage_type: [],
    currencies: [""],
    percentage_curr: [],
    percentage_admin: [],
    adminSelectValue: [],
    adminSelectOptions: [],
    accountSelectValue: [],
    accountSelectOptions: [],
    adminValues: [""],
    accountValues: [""],
    datePickerValue: moment.range(today.clone().subtract(3, "months"), today.clone()),
    fetchCompositionLoading: false,
    fetchBreakdownLoading: true,
    fetchBreakdownFailed: false,
    fetchCartolaPDFLoading: false,
    fetchCartolaPDFFailed: false,
    saldos: [],
    parsedSaldos: [],
    consolidated: [
        {
            rentabilidadNominal: 0
        }
    ],
    equity_curve: [],
    consolidatedByAccount: [],
    consolidatedByCurrency: [],
    consolidatedByType: [],

    montoTotal: 0,

    montoInicial: 0,            //Revisar si es utilizado por algun container
    groupSelectOption: [],      //Revisar si es utilizado por algun container
    percentageCurrency: [],     //Revisar si es utilizado por algun container                
    percentageAssetTypes: [],   //Revisar si es utilizado por algun container

    clientCartola: null,
    error        : null,
    clientId: null,
    listClientsCartola: [],
    listClientsCartolaLoading: true,
    clientName: "",
    tableState: {		
        order : "desc",
        orderBy : "patrimony",
        page : 0,
        dense : false,
        searchValue : '',
        rowsPerPage : 5,
        selected : []
    }
}


const cartola = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_COMPOSITION_INIT:
            return {
                ...state,
                adminSelectOptions: [],
                adminSelectValue: [],
                accountSelectOptions: [],
                accountSelectValue: [],
                groupSelectOption: [],
                fetchCompositionLoading: true,
                fetchBreakdownLoading: true,
                error: null,
                // clientName: ""
            }
        case FETCH_COMPOSITION_SUCCESS:
            const {
                adminSelectOptions,
                adminSelectValue,
                accountSelectOptions,
                accountSelectValue,
                groupSelectOption,
                clientName
            } = action.payload.composition;
            return {
                ...state,
                adminSelectOptions,
                adminSelectValue,
                accountSelectOptions,
                accountSelectValue,
                groupSelectOption,
                clientName,
                fetchCompositionLoading: false,
                error: null
            }
        case FETCH_COMPOSITION_ERROR:
            return {
                ...state,
                fetchCompositionLoading: false,
                error: action.payload.error
            }

        case FETCH_BREAKDOWN_INIT:
            return {
                ...state,
                fetchBreakdownLoading: true,
                fetchBreakdownFailed: false,
                parsedCartera: {administradoras: []},
                periodStart: today.clone().subtract(3, "months"),
                periodEnd: today.clone(),
                cartera: [],
                consolidated: [{ rentabilidadNominal: 0 }],                
                consolidatedByAccount: [],
                consolidatedByCurrency: [],
                consolidatedByType: [],
                montoTotal: 0,
                montoInicial: 0,
                movimientos: [],
                percentageAccount: [],                
                percentageCurrency: [],                
                percentageAssetTypes: [],
            }
        case FETCH_BREAKDOWN_SUCCESS:
            const {cash, parsedCartera, periodStart, periodEnd, cartera, consolidated, equity_curve, montoTotal,
                montoInicial, consolidatedByAccount, movimientos, percentageAccount, consolidatedByCurrency,
                percentageCurrency, consolidatedByType, percentageAssetTypes} = action.payload.breakdown;
            return {
                ...state,
                cash,
                parsedCartera,
                periodStart,
                periodEnd,
                cartera,
                consolidated,
                equity_curve,
                montoTotal,
                montoInicial,
                consolidatedByAccount,
                consolidatedByCurrency,
                consolidatedByType,
                movimientos,
                percentageAccount,
                percentageCurrency,                
                percentageAssetTypes,
                fetchBreakdownLoading: false
            }
        case FETCH_BREAKDOWN_ERROR:
            // const {
            //     fetchBreakdownLoading,
            //     fetchBreakdownFailed,
            //     error
            // } = action.payload.breakdown;
            return {
                ...state,
                fetchBreakdownLoading: false,
                fetchBreakdownFailed: true,
                error: action.payload.error
            }

        case FETCH_CARTOLAPDF_INIT:
            return {
                ...state,
                fetchCartolaPDFLoading: true,
                fetchCartolaPDFFailed: false,
            }
        case FETCH_CARTOLAPDF_SUCCESS:
            return {
                ...state,
                fetchCartolaPDFLoading: false,
                fetchCartolaPDFFailed: false,
            }
        case FETCH_CARTOLAPDF_ERROR:
            return {
                ...state,
                fetchCartolaPDFLoading: false,
                fetchCartolaPDFFailed: true,
                error: action.payload.error
            }

        case LIST_CLIENTS_CARTOLA_INIT:
            return{
                ...state,
                listClientsCartolaLoading: true
            }
        case LIST_CLIENTS_CARTOLA_SUCCESS:
            return{
                ...state,
                listClientsCartola: action.payload.response.data.client,
                listClientsCartolaLoading: false
            }
        case LIST_CLIENTS_CARTOLA_ERROR:
            return{
                ...state,
                listClientsCartolaLoading: true
            }

        case LIST_CLIENTS_TABLE_STATE:
            return {
                ...state,
                tableState: action.payload.state
            }

        case SELECT_ACCOUNT_INIT:
            return {
                ...state
            }
        case SELECT_ACCOUNT_SUCCESS:
            const acct_selected = action.payload.acct.accountSelectValue;
            return {
                ...state,
                accountSelectValue: acct_selected
            }
        case SELECT_DATE_INIT:
            return {
                ...state
            }
        case SELECT_DATE_SUCCESS:
            const { 
                datePickerValue,
                datePickerStates
            } = action.payload.dates;
            return {
                ...state,
                datePickerValue,
                datePickerStates
            }
                     
        case CARTOLA_ADVISOR_CLIENT_INIT:
            return {
                ...state,                          
            }
        case CARTOLA_ADVISOR_CLIENT_SUCCESS:
        return {
                ...state,
                clientCartola: action.payload.response.data
            }
        case CARTOLA_ADVISOR_CLIENT_ERROR:
            return {
                ...state,
                clientCartola: null,
                error        : action.payload.error
            }
        case RESET_CARTOLA_CLIENT:
            return {
                ...state,
                clientCartola: null,
            }
        case SET_CLIENT_ID:
            return {
                ...state,
                clientId: action.payload.clientId,
                clientName: action.payload.clientName
            }
        default:
            return state
    }    
}

export default cartola;