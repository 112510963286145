import {
    USER_INIT, 
    USER_SUCCESS, 
    USER_FAILED, 
    UPDATE_ADDRESS_INIT, 
    UPDATE_ADDRESS_POST, 
    UPDATE_ADDRESS_SUCCESS, 
    HANDLE_USER_MODAL,
    LOGOUT_INIT
} from '../constants';
import client from '../api/httpClient';
import auth from '../api/auth';
import { fetchUser } from '../api/urls';


export const user = (obj) => {
    return (dispatch, getState) => {
        let state = getState();
        
        dispatch(user_init());
        client.getAll(fetchUser)
        .then(resp => {

            let user_data = {...resp.data.user};
            let permissions = resp.data.permissions.map(p => {
                return p.name
            });
            
            user_data.permissions = permissions;

            if (resp.data.login_count == 1) {
                user_data.isFirstLogin = true;
            }

            const groupOwner = resp.data.groupOwner !== null ? resp.data.groupOwner.id : resp.data.groupOwner;
            user_data.groupOwner = groupOwner;
            
            console.log('User Action', user_data);
            dispatch(user_success(user_data));
            // dispatch(tourUser())
        })
        .catch(error => {
            dispatch(user_failed(error))
        })
        //auth.setToken(response.data.access_token);
        //auth.setUser(user.user);
        //auth.setPermissions(response.data.permissions);
        //auth.setRoles(response.data.role);
    }
}

export const tourUser = () => {
    return (dispatch, getState) => {
        client.getAll('/api/auth/touruser')
        .then(resp => console.log(resp))
        .catch(error => console.log(error))
        
    }
}

export const toggle_modal = () => {
    return (dispatch, getState) => {
        dispatch(handle_modal());
    }
}

// export const logout = () => {
//     return (dispatch, getState) => {
//         dispatch(logout_user_init());
//         auth.logout();
//         window.location.href = '/logout';
//     }
// }

export const handle_address = (userData) => {
    return (dispatch, getState) => {
        let state = getState();
        let update_state = {
            updateUserLoading: true, 
            validatePassword: false, 
            msgPasswordInvalid: null
        }
        dispatch(update_address_init(update_state));
        client.create(fetchUser, userData)
        .then(resp => {
            let update_success_state = {
                updateUserLoading: false,
                validatePassword: false,   
                profileUpdated: true,
                userPageModal: false,
            }
            dispatch(update_address_success(update_success_state));
        })
    }
}

export const logout_user_init = () => ({
    type: LOGOUT_INIT
})

export const handle_modal = () => ({
    type: HANDLE_USER_MODAL,
})

const user_init = () => ({
    type: USER_INIT
})

const user_success = user => ({
    type: USER_SUCCESS,
    payload: {
        user
    }
})

const user_failed = error => ({
    type: USER_FAILED,
    payload: {
        error
    }
})

const update_address_init = user => ({
    type: UPDATE_ADDRESS_INIT,
    payload: {
        user
    }
})

const update_address_success = user => ({
    type: UPDATE_ADDRESS_SUCCESS,
    payload: {
        user
    }
})