import React from 'react';
import { NavLink } from 'react-router-dom';
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Table from './Table';
import {urlRebalanceHistorialHome} from '../../../api/urls';

const index = ({account, historial, onClick, isLoading, doneList, handleUpdateRebalanceDone, paramsTable}) => {
    return (
        <Card>
            <CardHeader>
                <Breadcrumbs aria-label="breadcrumb">
                        <NavLink to={`${urlRebalanceHistorialHome}?${paramsTable}`} >Historial Rebalanceador</NavLink>
                        <Typography color="textPrimary">Historial de Cuenta</Typography>
                </Breadcrumbs>

            </CardHeader>
            <CardBody>
        
            <Table 
                account={account} 
                data={historial} 
                onClick={onClick} 
                isLoading={isLoading} 
                doneList={doneList}
                handleUpdateRebalanceDone={handleUpdateRebalanceDone}
            />

            </CardBody>
        </Card>
    );
};

export default index;