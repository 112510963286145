import React from 'react';
import {Card, CardBody, CardHeader,	Row, Col} from "reactstrap";
import Fondo from './Fondo';
import Caracteristica from './Caracteristica';
import Rendimiento from './Rendimiento'

const style = {
    fontColor: {color: '#404040'}
}

const index = ({data, handleSelectedPort, getPortafolioSelected}) => {

    // console.log('getPortafolioSelected <------------------------------------------')
    // console.log(getPortafolioSelected)

    const rowTitle = (title = "") => (
        <Row>
            <Col md={6}>
                <h6 className="m-3 p-1">{data[2]}{title}</h6>
            </Col>
            <Col md={6}>
            <div className="text-right">
                <button className="btn btn-warning mr-3" 
                    onClick={() => handleSelectedPort(data)}>
                    Ver {data[2]}
                </button>
            </div>
            </Col>
        </Row>
    )

    return (
        <Row>
            <Col md={12}>
                <Card>
                    {getPortafolioSelected == data[2] ?
                        <div className="alert alert-success mb-1 p-0">
                            {rowTitle(" Seleccionado")}
                        </div>
                        :
                        <div className="alert alert-info mb-1 p-0">
                            {rowTitle()}
                        </div>
                    
                    }
                    {/* <div className="alert alert-info mb-1 p-0">
                        <Row>
                            <Col md={6}>
                                <h6 className="m-3 p-1">{data[2]}</h6>
                            </Col>
                            <Col md={6}>
                            <div className="text-right">
                                <button className="btn btn-warning mr-3" 
                                    onClick={() => handleSelectedPort(data)}>
                                    Ver {data[2]}
                                </button>
                            </div>
                            </Col>
                        </Row>
                    </div> */}
                    <CardBody>
                        <Row>
                            <Col md={7}> 
                                <div className="alert alert-dark" style={style.fontColor}>
                                    Fondos del Portafolio
                                </div>                                
                                <Fondo data={data[0]} />
                            </Col>
                            <Col md={5}>
                                <Row>
                                    <Col md={12}>
                                        <div className="alert alert-dark" style={style.fontColor}>
                                            Rendimiento del Portafolio
                                        </div>
                                        <Rendimiento data={data[3]}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div className="alert alert-dark mt-3" style={style.fontColor}>
                                            Características del Portafolio
                                        </div>
                                        <Caracteristica data={data[1]}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>

    );
};

export default index;