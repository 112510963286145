import React from 'react'

import {
    TabContent,
    TabPane,
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Row,
    Col
} from "reactstrap";
import {
    InfoArea,
    CardCategory
} from "../../../../../components";

import Skeleton from 'react-loading-skeleton';

// View Components
import ResumenAdmin from './Sections/ResumenAdmin'
import ResumenMovimientos from './Sections/ResumenMovimientos'
import ResumenCuenta from './Sections/ResumenCuenta'
import ResumenActivos from './Sections/ResumenActivos'
import ResumenMoneda from './Sections/ResumenMoneda'
import ResumenCaja from './Sections/ResumenCaja'
import ResumenErrorBoundary from './Sections/ResumenErrorBoundary'


class ResumenBody extends React.Component {
    constructor(props) {
        super(props);
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.fetchBreakdownLoading !== prevProps.fetchBreakdownLoading) {

        }
    }
    render() {
        const cuentasConfig = {
            chart: {
                type: 'pie',
                animation: {
                    duration: 1000
                },
                options3d: {
                    enabled: true,
                    alpha: 45,
                    beta: 0
                },
                backgroundColor: "transparent"
            },
            title: {
                text: 'Patrimonio por Cuenta (%)'
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    depth: 35,
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: [{
                type: 'pie',
                name: '% Patrimonio',
                data: this.props.percentageAccount
            }]
        };

        const monedasConfig = {
            chart: {
                type: 'pie',
                animation: {
                    duration: 1000
                },
                options3d: {
                    enabled: true,
                    alpha: 45,
                    beta: 0
                },
                backgroundColor: "transparent"
            },
            title: {
                text: 'Patrimonio por Moneda (%)'
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    depth: 35,
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: [{
                type: 'pie',
                name: '% Patrimonio',
                data: this.props.percentageCurrency.slice(0,-1)
            }]
        };

        const activosConfig = {
            chart: {
                type: 'pie',
                animation: {
                    duration: 1000
                },
                options3d: {
                    enabled: true,
                    alpha: 45,
                    beta: 0
                },
                backgroundColor: "transparent"
            },
            title: {
                // text: 'Patrimonio por Activo (%)'
                text: null
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    depth: 35,
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: [{
                type: 'pie',
                name: '% Patrimonio',
                data: this.props.percentageAssetTypes.slice(0,-1)
                
            }]
        };
        

        return(
            <Row>
        
                {this.props.fetchBreakdownLoading ?
                    <Col md={12}>
                        <Row>
                            <Col sm={12} md={6}>
                                <Card className="card-plain">

                                    <CardHeader>
                                        <CardTitle tag="h5">
                                            <Skeleton height={20} width={200} count={1} />
                                        </CardTitle>

                                        <CardCategory>
                                            <Skeleton height={7} width={400} count={1} />
                                            <Skeleton height={7} width={300} count={1} />
                                        </CardCategory>
                                    </CardHeader>
                                    <CardBody>
                                        <Skeleton count={5} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={12} md={6}>
                                <div style={{
                                    "display": "flex",
                                    "alignItems": "center",
                                    "justifyContent": "center"
                                }}>
                                    <Skeleton circle={true} height={300} width={300} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    :
                    <Col md={12}>
                        <TabContent
                            activeTab={this.props.hTabs}
                            className="tab-space"
                        >
                            {/* Administradoras */}
                            <TabPane tabId="ht1">
                                
                                <Row>
                                    <Col xs={12} md={12}>
                                        <ResumenAdmin 
                                            administradoras={this.props.parsedCartera.administradoras}
                                            consolidatedByAccount={this.props.consolidatedByAccount}
                                            clpFormatter={this.props.clpFormatter}
                                        />
                                    </Col>                                    
                                </Row>
                                <Row>                                    
                                    <Col xs={12} md={12}>
                                        <ResumenActivos 
                                            consolidatedByType={this.props.consolidatedByType}
                                            clpFormatter={this.props.clpFormatter}
                                            activosConfig={activosConfig}
                                        />
                                    </Col>
                                </Row>
                                                                
                            </TabPane>
                            {/* Movimientos */}
                            <TabPane tabId="ht2">
                                    <ResumenMovimientos 
                                        movimientos={this.props.movimientos}
                                        consolidatedByAccount={this.props.consolidatedByAccount}
                                        clpFormatter={this.props.clpFormatter}
                                    />
                                
                            </TabPane>
                            {/* Cuentas */}
                            <TabPane tabId="ht3">
                                <ResumenCuenta 
                                    consolidatedByAccount={this.props.consolidatedByAccount}
                                    clpFormatter={this.props.clpFormatter}
                                    cuentasConfig={cuentasConfig}
                                />
                            </TabPane>
                            {/* Moneda */}
                            <TabPane tabId="ht4">
                                <ResumenMoneda 
                                    consolidatedByCurrency={this.props.consolidatedByCurrency}
                                    clpFormatter={this.props.clpFormatter}
                                    monedasConfig={monedasConfig}
                                />
                            </TabPane>
                            {/* Activos */}
                            {/* <TabPane tabId="ht5">
                                <ResumenActivos 
                                    consolidatedByType={this.props.consolidatedByType}
                                    clpFormatter={this.props.clpFormatter}
                                    activosConfig={activosConfig}
                                />
                            </TabPane> */}
                            {/* Caja */}
                            <TabPane tabId="ht6">
                                <ResumenCaja
                                    administradoras={this.props.cash.administradoras}
                                    usdFormatter={this.props.usdFormatter}
                                    clpFormatter={this.props.clpFormatter}
                                />
                            </TabPane>
                            
                        </TabContent>
                    </Col>
                }

            </Row>

        )
    }
}

export default ResumenBody;