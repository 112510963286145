import {UPLOAD_FILE_INIT, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_ERROR,
    GET_UPLOAD_INIT, GET_UPLOAD_SUCCESS, GET_UPLOAD_ERROR
} from './../constants';

const initialState = {
    uploadInput: [],
    uploadAdmins: [],
    message: null,
    status: null,
    error: null, 
    pending: true,
}

const bulkData = (state = initialState, action) => {
    switch (action.type) {
        case GET_UPLOAD_INIT:
            return {
                ...state,
                uploadInput: [],
                status: null,
                error: null, 
                pending: true,               
            };
        case GET_UPLOAD_SUCCESS:
            return {
                ...state,
                uploadInput: action.payload.response.data.inputs,   
                uploadAdmins: action.payload.response.data.admins,   
                pending: false,
                error: null
            };
        case GET_UPLOAD_ERROR:
            return {
                ...state,                
                pending: false,
                error: action.payload.error
            };



        case UPLOAD_FILE_INIT:
            return {
                ...state,
                status: null,
                error: null, 
                pending: true,               
            };
        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                message: action.payload.response.data,                
                pending: false,
                error: null
            };
        case UPLOAD_FILE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.error
            };

       
        default:
            return state;
    }
}

export default bulkData;