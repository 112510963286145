import React from "react";
import TableMaterialUi from '../../TableMaterialUi';
import {quantityFormat, patrimonyFormat, patrimonyFormatWithDecimal} from '../../../helpers/formatter'

const headCells = [       
    { id: 'nombre_instrumento', numeric: false, disablePadding: false, label: 'Nombre' }, 
    { id: 'administradora', numeric: false, disablePadding: true, label: 'Administradora', padding:"none" },
    { id: 'nemo', numeric: false, disablePadding: true, label: 'Nemotecnico', padding:"none" },
    { id: 'serie', numeric: false, disablePadding: true, label: 'Serie', align: "center" },
    { id: 'currency', numeric: false, disablePadding: true, label: 'Moneda', align: "center" },
    { id: 'cantidad', numeric: true, disablePadding: false, label: 'Cantidad', align:"right", formatter:quantityFormat },
    { id: 'precio_promedio_compra', numeric: true, disablePadding: false, label: 'Cotización Compra', align:"right", formatter:patrimonyFormatWithDecimal },
    { id: 'precio_cierre', numeric: true, disablePadding: false, label: 'Cotización Actual', align:"right", formatter:patrimonyFormatWithDecimal },
    { id: 'monto_valorizado', numeric: true, disablePadding: false, label: 'Monto Actual', align:"right", formatter:patrimonyFormat },
    { id: 'deposit_type', numeric: true, disablePadding: false, label: 'Tipo de Ahorro', align: "right"}

    ];

function Detalle({data}){

    const [tableState, setTableState] = React.useState({		
        order : "desc",
        orderBy : "administradora",
        page : 0,
        dense : true,
        searchValue : '',
        rowsPerPage : 5,
        selected : []
    })

    return (
        <TableMaterialUi 
            title={'Cartera Consolidada'}
            rows={data} 
            headCells={headCells} 
            withCheckbox={false} 
            onClickRow={()=>{}}
            loading={false}
            updateState={()=>{}}    //TableMaterialUi State
            initialState={tableState}
        />
    )

}

export default Detalle;
