import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import store from './store';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/now-ui-dashboard.css";
import reportWebVitals from './reportWebVitals';

import AppRouter from './AppRouter';
import './index.css';

// initialize sentry for error tracing
//Sentry.init({
//    dsn: "https://1445a4deee984904bd88acb946840281@o454868.ingest.sentry.io/5445583",
//    integrations: [
//      new Integrations.BrowserTracing(),
//    ],
//    tracesSampleRate: 1.0,
//});

ReactDOM.render(
    <Provider store={store}>
        <AppRouter />
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

