import {
    ROLES_GET_INIT,
    ROLES_GET_SUCCESS,
    ROLES_UPDATE_INIT,
    ROLES_UPDATE_SUCCESS,
    PERMISSIONS_GET_INIT,
    PERMISSIONS_GET_SUCCESS,
    ROLE_PERMISSIONS_INIT,
    ROLE_PERMISSIONS_SUCCESS,
    ROLE_CREATE_INIT,
    ROLE_CREATE_SUCCESS,
    ROLE_DELETE_INIT,
    ROLE_DELETE_SUCCESS,
    SET_SELECTED_ROLE,
    SET_APP_PERMISSIONS,
    SET_EDIT_ACTIVE_ROLE,
    SET_CREATE_ACTIVE_ROLE,
    SET_OPEN_EDIT_ROLE,
    SET_OPEN_CREATE_ROLE,
    SHOW_ROLE_CREATE_SUCCESS,
    SHOW_ROLE_DELETION_WARNING,
    SHOW_ROLE_MODIFICATION_WARNING,
    SHOW_ROLE_MODIFICATION_CONFIRM,
    SET_OPEN_ASSIGN_ROLE,
    ASSIGN_ROLES_TO_USERS,
    ASSIGN_ROLES_TO_USERS_SUCCESS, 
    SET_ASSIGN_ACTIVE,
    GET_USERS_WITH_ROLES,
    GET_USERS_WITH_ROLES_SUCCESS
} from '../constants';

const initialState = {
    roles: [],
    permissions: {},
    rolesLoading: false,
    roleName: "",
    rolePermissions: [],
    roleDescription: "",
    roleDescriptionCreatedAt: "",
    roleDescriptionUpdatedAt: "",
    roleRequires: "",
    rolePermissionsLoading: false,
    selectedRole: {},
    appPermissions: [],
    editActive: {
        name: '',
        desc: {
            desc: ''
        },
        permissions: []
    },
    assignActive: {},
    createActive: {},
    openEdit: false,
    openCreate: false,
    openAssign: false,
    assignSuccess: false,
    createSuccess: {},
    deletionWarning: {},
    modificationWarning: {},
    modificationConfirm: {},
    total: 0,
    roleUsers: [],
    roleUsersLoading: false
}


const permissions = (state = initialState, action) => {
    switch (action.type) {
        case PERMISSIONS_GET_INIT:
            return {
                ...state
            }
        case PERMISSIONS_GET_SUCCESS:
            const permissions = action.payload.permissions;
            return {
                ...state,
                permissions
            }
        case ROLES_GET_INIT:
            return {
                ...state,
                rolesLoading: true
            }
        case ROLES_GET_SUCCESS:
            const { roles, total } = action.payload.roles;
            console.log('ROLES_GET_SUCCESS', total);
            return {
                ...state,
                rolesLoading: false,
                roles,
                total
            }
        case ROLE_PERMISSIONS_INIT:
            return {
                ...state,
                rolePermissionsLoading: true
            }
        case ROLE_PERMISSIONS_SUCCESS:
            const {name, rolePermissions, desc} = action.payload.rolePermissions;
            return {
                ...state,
                roleName: name,
                rolePermissions: rolePermissions,
                roleDescription: desc ? desc.desc : "",
                roleDescriptionCreatedAt: desc ? desc.created_at : "",
                roleDescriptionUpdatedAt: desc ? desc.updated_at : "",
                roleRequires: desc ? desc.requires : "",
                rolePermissionsLoading: false
            }
        case SET_SELECTED_ROLE:
            const { selectedRole } = action.payload.selectedRole;
            return {
                ...state,
                selectedRole
            }
        case SET_APP_PERMISSIONS:
            const { appPermissions } = action.paylod.permissions;
            return {
                ...state,
                appPermissions
            }
        case SET_EDIT_ACTIVE_ROLE:
            const { editActive } = action.payload.activeEdit;
            console.log('activeEdit!', action.payload);
            return {
                ...state,
                editActive
            }
        case SET_CREATE_ACTIVE_ROLE:
            const { createActive } = action.payload.activeCreate;
            console.log('activeCreate', createActive);
            return {
                ...state,
                createActive
            }
        case SET_OPEN_EDIT_ROLE:
            const { openEdit } = action.payload.isEditOpen;
            console.log('editOpen', action.payload);
            return {
                ...state,
                openEdit
            }
        case SET_OPEN_CREATE_ROLE:
            const { openCreate } = action.payload.isCreateOpen;
            console.log('CreateOpen!', openCreate);
            return {
                ...state,
                openCreate
            }
        case SET_ASSIGN_ACTIVE:
            const { assignActive } = action.payload.activeAssign;
            console.log('assignActive', assignActive);
            return {
                ...state,
                assignActive
            }
        case SET_OPEN_ASSIGN_ROLE:
            const { openAssign } = action.payload.isAssignOpen;
            return {
                ...state,
                openAssign
            }
        case ASSIGN_ROLES_TO_USERS_SUCCESS:
            return {
                ...state,
                openAssign: false,
                assignActive: {},
                assignSuccess: true
            }
        case SHOW_ROLE_CREATE_SUCCESS:
            const { isCreateSuccess } = action.payload.isCreateSuccess;
            return { 
                ...state,
                isCreateSuccess
            }
        case SHOW_ROLE_DELETION_WARNING:
            const { warnDeletion } = action.payload.warnDeletion;
            return {
                ...state,
                warnDeletion
            }
        case SHOW_ROLE_MODIFICATION_WARNING:
            const { warnModification } = action.payload.warnModification;
            return {
                ...state,
                warnModification
            }
        case SHOW_ROLE_MODIFICATION_CONFIRM:
            const { confirmModification } = action.payload.confirmModification;
            return {
                ...state,
                confirmModification
            }

        case GET_USERS_WITH_ROLES:
            return {
                ...state,
                roleUsersLoading: true
            }
        case GET_USERS_WITH_ROLES_SUCCESS: 
            console.log('roleUsers', action.payload);
            const { users } = action.payload;
            return {
                ...state,
                roleUsersLoading: false,
                roleUsers: users
            }
        
        default:
            return state;
    }
}

export default permissions;