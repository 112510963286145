import React from "react";
import {connect} from 'react-redux';
import {logout} from '../../actions/login';
import { Sidebar } from '../../components';
import sidebarUrls from '../../routes/sidebarUrls';

class SidebarContainer extends React.Component {

    render() {
        return(
            <Sidebar
                // Passed down from DashboardComponent
                routes={sidebarUrls}
                // Passed down from DashboardComponent
                onUserNavigate={this.props.onUserNavigate}
                // Passed down from DashboardComponent
                location={this.props.location}

                userName={this.props.userName}
                loadingUserData={this.props.loadingUserData}
                assignedPermissions={this.props.assignedPermissions}
                logout={this.props.logout_user}
                appName={this.props.appName}
                appVersion={this.props.appVersion}
            />
        )
    }

}



const mapStateToProps = state => {
    return {
        userName: state.user.name,
        loadingUserData: state.user.loadingUserData,
        assignedPermissions: state.user.permissions,
        appName: state.login.appName,
        appVersion: state.login.appVersion,
    }
}

const mapDispatchToProps = dispatch => ({
    logout_user: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContainer)
