import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { patrimonyFormat } from '../../../helpers/formatter';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
});

const DataTable = ({funds}) => {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="fondos">
                <TableHead>
                <TableRow>
                    <TableCell>Cuenta</TableCell>
                    <TableCell>Administradora</TableCell>
                    <TableCell>Fondo</TableCell>     
                    <TableCell>Serie</TableCell> 
                    <TableCell>Monto</TableCell>
                    <TableCell>%</TableCell>              
                </TableRow>
                </TableHead>
                <TableBody>
                {funds.map((row, i) => (
                    <TableRow key={i}>
                        <TableCell>{row.cuenta}</TableCell>
                        <TableCell>{row.administradora}</TableCell>
                        <TableCell>{row.nombre_instrumento}</TableCell> 
                        <TableCell>{row.serie}</TableCell>                        
                        <TableCell>{patrimonyFormat(row.monto_valorizado_clp)}</TableCell> 
                        <TableCell>{row.porcentaje_fondo ? parseFloat(row.porcentaje_fondo).toFixed(2) : '0.00'}%</TableCell>                          
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DataTable;

