import React from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {MATERIAL_TABLE_LOCALIZATION} from './../../../constants';
import {AssignmentTurnedIn, CheckCircleRounded} from '@material-ui/icons';
import { rutFormat, patrimonyFormat } from '../../../helpers/formatter'

const theme = createTheme({
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        light: '#01579b',
        main: '#ff9100',
      },       
    },      
});

const state = {	  
    columns: [
        { title: 'Rut', field: 'rut', render: ({rut}) => <span>{rutFormat(rut)}</span>, width: 150},
        { title: 'Nombre', field: 'name', width: 250 },
        { title: 'Apellido', field: 'last_name', width: 250 },
        { title: 'Email', field: 'email', width: 200 },
        { title: 'Con Asesor', field: 'hasStaff', width: 50,
            // cellStyle: rowData => {
            //     if (rowData == "Si") {
            //         return {color: "#00ff00"}
            //     } else if (rowData == "No") {
            //         return {color: "#ff0000"}
            //     }
            // }
        },
        { title: 'Patrimonio', field: 'patrimony', type: 'currency', render: ({patrimony}) => (
            <span>{patrimonyFormat(patrimony)}</span>
        ), width: 150 },
    ],
}

function TableClients ({data, staff, onClick, pending}) {

    const handleRowClicked = (selectedRow) => {
        console.log(selectedRow)
    }

    const handleRowSelected = (rows) => {
        console.log(rows)     
    }
    
    return (
    <MuiThemeProvider theme={theme}>
        <MaterialTable
            title="Clientes"
            columns={state.columns}
            data = {data} 
            isLoading= {pending}                 
            options={{
                selection: true,
                sorting: true,
                // exportButton: true,
                rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#37b15933' 
                    : 
                    rowData.hasStaff == "Si" ? "#77f977" : "#ff9f9f"                                
                })
                // headerStyle: {
                //     backgroundColor: '#01579b',
                //     color: '#FFF'
                // }                
            }}
            // onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}                
            onRowClick={((evt, selectedRow) => handleRowClicked(selectedRow) )}                
            // onSelectionChange={(rows) => alert('You selected ' + rows.length + ' rows ')}
            onSelectionChange={(rows) => handleRowSelected(rows)}
            actions={[
                {
                    tooltip: 'Asignar',
                    icon: () => <CheckCircleRounded />,                    
                    onClick: (evt, data) => onClick(data, staff)
                    
                }
            ]}
            localization = {MATERIAL_TABLE_LOCALIZATION}
        />
    </MuiThemeProvider>
    );
    
}

export default TableClients;