import React, { Fragment } from 'react';


class index extends React.Component {
    constructor(props) {
        super(props)

    }

    render() {
        return(
           <Fragment>
                <this.props.component />
           </Fragment> 
        )
    }
}

export default index;