import {PATRIMONY_TOTAL_GET_INIT, PATRIMONY_TOTAL_GET_SUCCESS, PATRIMONY_TOTAL_GET_ERROR,
    PATRIMONY_INFO_GET_INIT, PATRIMONY_INFO_GET_SUCCESS, PATRIMONY_INFO_GET_ERROR
} from '../constants';
import httpClient from '../api/httpClient';
import {urlPatrimony, urlPatrimonyInfo} from '../api/urls';

export const getPatrimony = () => {
    return dispatch => {
        dispatch(_getPatrimonyTotalInit());
        httpClient.getAll(urlPatrimony)
        .then(res => {            
            dispatch(_getPatrimonyTotalSuccess(res));
        })
        .catch(error => {            
            dispatch(_getPatrimonyTotalError(error));
        })
    }
}

export const getPatrimonyInfo = () => {
    return dispatch => {
        dispatch(_getPatrimonyInfoInit());
        httpClient.getAll(urlPatrimonyInfo)
        .then(res => {
            console.log(res)
            dispatch(_getPatrimonyInfoSuccess(res));
        })
        .catch(error => {
            console.log(error)
            dispatch(_getPatrimonyInfoError(error));
        })
    }
}

const _getPatrimonyInfoInit = () => ({
    type: PATRIMONY_INFO_GET_INIT
})

const _getPatrimonyInfoSuccess = (patrimony) => ({
    type: PATRIMONY_INFO_GET_SUCCESS,
    payload: {
        patrimony
    }
})

const _getPatrimonyInfoError = (error) => ({
    type: PATRIMONY_INFO_GET_ERROR,
    payload: {
        error
    }
})

const _getPatrimonyTotalInit = () => ({
    type: PATRIMONY_TOTAL_GET_INIT
})

const _getPatrimonyTotalSuccess = (patrimony) => ({
    type: PATRIMONY_TOTAL_GET_SUCCESS,
    payload: {
        patrimony
    }
})

const _getPatrimonyTotalError = (error) => ({
    type: PATRIMONY_TOTAL_GET_ERROR,
    payload: {
        error
    }
})