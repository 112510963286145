import {FONDOMUTUO_INFO_GET_INIT, FONDOMUTUO_INFO_GET_SUCCESS, FONDOMUTUO_INFO_GET_ERROR
} from '../constants';

const initialState = {
    fondos  : [],
    pending : false,
    error   : null    
}

const fondos = (state = initialState, action) => {
    switch (action.type) {
        case FONDOMUTUO_INFO_GET_INIT:
            return {
                ...state,
                pending : true,
                error   : null
            };            

        case FONDOMUTUO_INFO_GET_SUCCESS:
            return {
                ...state,
                fondos  : action.payload.response.data.fondos,
                pending : false,                
            };            

        case FONDOMUTUO_INFO_GET_ERROR:
            return {
                ...state,
                pending : false,
                error   : action.payload.error
            };            
    
        default:
            return state;
    }
}

export default fondos;