import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const formatter = new Intl.NumberFormat('es-ES', {
    minimumFractionDigits: 0
});


const titleStyles = {
    fontSize: '2rem',
    marginBottom: '0px',
    display: 'flex',
    alignItems: 'center'
}

const Card = ({data, pending}) => {
    return(
        <div>  
            {!pending ? 
                <span style={titleStyles}>
                    <i className={'now-ui-icons business_briefcase-24'} style={{marginRight: '.5em'}}></i>
                    { formatter.format(data) }
                </span>
                :
                <div className="text-center mt-5">
                    <CircularProgress size={50} thickness={5}/>
                </div>
            }
        </div>
        
    )
}

export default Card;