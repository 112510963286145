import React from 'react';
import Chart, { Doughnut } from 'react-chartjs-2';
import {Card, CardTitle, Col, Row} from "reactstrap";
// import { Statistics } from '../../../../components'
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { patrimonyFormat, rutFormat, percentFormat } from '../../../helpers/formatter';
import TableMaterialUi from '../../TableMaterialUi';

const patrimonioCardStyles = { 
    stats: {
        'fontSize': '1.7em',
        'fontWeight': '500'
    },
    sectionTitle: {
        'fontSize': '1.3em',
        'fontWeight': '500'
    }
}

let configPie = {    
    datasets: [{        
        backgroundColor: [
            '#1B1464', 
            '#0652DD', 
            '#e67e22',
            '#2980b9',
            '#e74c3c',
            '#2c3e50',
            '#27ae60',
            '#16a085',
            '#82ccdd',
            '#4a69bd',
            '#8e44ad',
            '#0a3d62',
            '#f1c40f',
            '#b71540'
        ],
        hoverBackgroundColor: [
            '#1B1464', 
            '#0652DD', 
            '#e67e22',
            '#2980b9',
            '#e74c3c',
            '#2c3e50',
            '#27ae60',
            '#16a085',
            '#82ccdd',
            '#4a69bd',
            '#8e44ad',
            '#0a3d62',
            '#f1c40f',
            '#b71540'
        ]
    }]
}

const columns = [{
    Header: 'Nombre',
    accessor: 'name'
}, 
{
    Header: 'Apellido',
    accessor: 'last_name'
},
{
    Header: 'Rut',
    accessor: 'rut',
    Cell: ({row, original}) => <span>{rutFormat(original.rut)}</span>
}, 
{
    Header: 'Correo',
    accessor: 'email',
},
{    
    Header: 'Patrimonio',
    accessor: 'patrimonio',
    Cell: ({row, original}) => <span>${original.patrimonio}</span>
}, 
{
    Header: props => <span className="pull-left">%</span>, 
    accessor: 'percent',
    Cell: ({row, original}) => <span>{original.percent}%</span>
}]

const Grafico = ({data}) =>{
    if(data){
        configPie.labels = data.labels;
        configPie.datasets[0].data = data.datasets;
        return <Doughnut 
                    data={configPie}
                    width={100}
                    height={100}
                    options={{ 
                        maintainAspectRatio: false,
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    var dataLabel = data.labels[tooltipItem.index];                                    
                                    var value = ': ' + patrimonyFormat(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);   

                                    if (Chart.helpers.isArray(dataLabel)) {
                                        dataLabel = dataLabel.slice();
                                        dataLabel[0] += value;
                                    } else {
                                        dataLabel += value;
                                    }
                                    return dataLabel;
                                }
                            }
                        }
                    }}
                />
    } else {
        return <p>Sin datos</p>
    }
}

const headCells = [
    //id - numeric - disablePadding - label - padding(opcional) - align(opcional) - formatter(opcional)
    { id: 'rut', numeric: false, disablePadding: false, label: 'Rut', formatter:rutFormat },
    { id: 'name', numeric: false, disablePadding: true, label: 'Nombre', padding:"none" }, 
    { id: 'last_name', numeric: false, disablePadding: true, label: 'Apellido', padding:"none" },    
    { id: 'email', numeric: false, disablePadding: true, label: 'Email', padding:"none" },
    { id: 'patrimonio', numeric: true, disablePadding: false, label: 'Patrimonio', align:"right", formatter:patrimonyFormat  },
    { id: 'percent', numeric: false, disablePadding: false, label: '%', formatter:percentFormat },
];

const tableState ={		
    order : "desc",
    orderBy : "patrimonio",
    page : 0,
    dense : false,
    searchValue : '',
    rowsPerPage : 5,
    selected : []
}

const Table = ({data}) =>{
    if(data){
        return <TableMaterialUi 
                    rows={data} 
                    headCells={headCells}
                    withCheckbox={false} 
                    onClickRow={() => {}}
                    loading={false} 
                    title="Patrimonio por Cliente"
                    updateState={() => {}}
                    initialState={tableState}
                    />
    }else{
        return <p>Sin datos</p>
    }
}

const index = ({total, invertido, caja, dataPatrimonio, dataUsuario}) =>  {    

    const statInfo = (state, icon, title, subtitle) => {
        return (
            <div className={"info info-horizontal"}>
                <Row>
                    
                    <Col xs={3}>
                        <div
                            className={`icon icon-${state} icon-circle`}
                        >
                            <i className={`now-ui-icons ${icon}`}/>
                        </div>
                    </Col>
                    <Col xs={8} className="text-right">
                        <span style={patrimonioCardStyles.stats}>{patrimonyFormat(title)}</span>
                        <br/>
                        <span>{subtitle}</span>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <div>           

            <Row style={{'margin': '2em 0 6em 0'}}>
                <Col sm="4">
                    {statInfo('success', 'business_bank', total, 'Total')}
                </Col>
                <Col sm="4">
                    {statInfo('warning', 'business_chart-bar-32', invertido, 'Invertido')}
                    
                </Col>
                <Col sm="4">
                    {statInfo('info', 'business_money-coins', caja, 'Caja')}
                </Col>
            </Row>
            <Row>
                <Col sm="4">
                    <Card body style={{height: '25rem'}}>
                        <span style={patrimonioCardStyles.sectionTitle}>Clase de Activos</span>
                        <Grafico data={dataPatrimonio} />
                    </Card>
                </Col>
                <Col sm="8">
                        <Table data={dataUsuario} />
                </Col>
            </Row>

        </div>
    );
}

export default index;