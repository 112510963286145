import React from "react";


class Step1 extends React.Component {
    render() {
        return (

            <div className="image2" >
                <div className="icono"><i class="now-ui-icons business_money-coins"></i></div>
                <div class="monto">$2.754.254.874</div>
                <div className="txtonb1">Silencio oscuro y sabor a tierra</div>
                <div className="txtonb2">El animal y el pozo, llamándose desde el comienzo, antes incluso de conocerse. La bestia asomó la cabeza a la boca negra y sin ver más allá de dos metros lanzó un aullido tan húmedo como las paredes que recogieron su eco. </div>
            </div>

        );
    } 
}

export default Step1;
