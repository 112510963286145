import React from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {MATERIAL_TABLE_LOCALIZATION} from './../../../constants';
import { rutFormat, patrimonyFormat } from '../../../helpers/formatter'

const theme = createTheme({
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        light: '#01579b',
        main: '#ff9100',
      },       
    },      
});

const state = {	  
    columns: [
        { title: 'Rut', field: 'rut', render: ({rut}) => <span>{rutFormat(rut)}</span>, width: 100 },
        { title: 'Nombre', field: 'name', width: 200 },
        { title: 'Apellido', field: 'last_name',  width: 200 },
        // { title: 'Activo', field: 'is_active', type: 'numeric' },
        
    ],
}
function TableStaff({data}) {

    const handleRowClicked = (selectedRow) => {
        console.log(selectedRow)
    }

    const handleRowSelected = (rows) => {
        console.log(rows)     
    }
    
    return (
    <MuiThemeProvider theme={theme}>
        <MaterialTable
            title="Asesores"
            columns={state.columns}
            data = {data}                             
            options={{
                // selection: true,
                sorting: true,
                // exportButton: true,
                rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#37b15933' : '' })
                // headerStyle: {
                //     backgroundColor: '#01579b',
                //     color: '#FFF'
                // }
            }}
            // onRowClick={((evt, selectedRow) => this.setState({ selectedRow }))}                
            onRowClick={((evt, selectedRow) => handleRowClicked(selectedRow) )}                
            // onSelectionChange={(rows) => alert('You selected ' + rows.length + ' rows ')}
            onSelectionChange={(rows) => handleRowSelected(rows)}
            localization = {MATERIAL_TABLE_LOCALIZATION}
        />
    </MuiThemeProvider>
    );
    
}

export default TableStaff;