import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import dashboardRoutes from '../../routes/dashboard';
import BreadcrumbComponent from '../../components/Breadcrumb/BreadcrumbComponent';

class BreadcrumbContainer extends React.Component {


    constructor(props) {
        super(props);
    
        this.state = {
            routes: [
            
                {
                    PAGE: 'cartolas',
                    ROUTES: [

                        {
                            name: 'Cartolas',
                            route: '/dashboard/cartolas/home'
                        },
                        {
                            name: 'Listado',
                            route: '/dashboard/cartolas/list'
                        },
                        {
                            name: 'Cliente',
                            route:'/dashboard/cartolas/client'
                        }
                    ]   
                }
                
            ],
            
        }

        this.filterRoutes = this.filterRoutes.bind(this);
        this.updateCrumbs = this.updateCrumbs.bind(this);
        this.renderBreadcrumbs = this.renderBreadcrumbs.bind(this);
    }

    componentDidMount() {
        this.updateCrumbs();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname != this.props.location.pathname) {
            console.log('componentDidUpdate', this.state)
            this.updateCrumbs();
        }
    }

    filterRoutes(route) {
        if(this.props.location.pathname.includes(route.PAGE)){
            return route;
        }
    }

    

    updateCrumbs() {
        console.log('updateCrumbs pathname', this.props.location.pathname);
        let currRoute = this.state.routes.filter(this.filterRoutes)[0];
        let currDepth = currRoute.ROUTES.findIndex(elm => elm.route === this.props.location.pathname);
        console.log('currDepth', currDepth);
        this.setState({currRoute: currRoute, currDepth: currDepth});
    }

    renderBreadcrumbs() {
        if(this.state.currRoute) {
            return(
                <BreadcrumbComponent 
                    route={this.state.currRoute}
                    currDepth={this.state.currDepth}
                />
            )
        } else {
            return (<div></div>)
        }
        
    }
    

    render() {
        
        return(
            this.renderBreadcrumbs()
        )
    }
}

export default withRouter(BreadcrumbContainer);


