import React from 'react';
// import Select from 'react-select';
import {CardHeader} from "reactstrap";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { NavLink } from "react-router-dom";
import Cartola from '../../components/Cartola';
import { urlCartolasList } from '../../api/urls';
import { value } from 'numeral';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    breadcrumbNavLink: {
        fontSize: '1em', 
        fontWeight: '900', 
        textTransform: 'uppercase',        
    }
}));

function Client({getClientCartola, clients, clientName, params, cartolaProps, cartolaId}) {

    const classes = useStyles();
    const [client, setClient] = React.useState('');
    const handleChange = (event) => {
        setClient(event.target.value); 
        getClientCartola(event.target.value)       
    };

    // let selectOptions = props.clients.map(client=>({value: client.id, label: client.name}));
    return (
        <div>
            <CardHeader>
                <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>                    
                    <NavLink to={`${urlCartolasList}${params ? '?'.concat(params) : ''}`} >
                        <div className={classes.breadcrumbNavLink}>
                            Listado Clientes
                        </div>
                    </NavLink>
                    <Typography color="textPrimary">
                        {clientName}
                    </Typography>
                    
                    {/* <FormControl className={classes.formControl}>
                        <Select
                            labelId="breadcumb-select-label"
                            id="breadcumb-select"                            
                            value={client}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >                            
                            <MenuItem value={""} disabled>{clientName}</MenuItem>
                            {clients.map((x,y)=><MenuItem key={y} value={x.id}>{x.name + ' ' + x.last_name}</MenuItem>)}
                        </Select>
                    </FormControl> */}

                </Breadcrumbs>
            </CardHeader>

            <Cartola             
                {...cartolaProps} cartolaId={cartolaId}
            />
        </div>
    );
}

Client.propTypes = {
    getClientCartola: PropTypes.func,
    clients: PropTypes.arrayOf(PropTypes.object),
    clientName: PropTypes.string,
    params: PropTypes.object,
    cartolaProps: PropTypes.object
}

export default Client;