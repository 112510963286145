import React from "react";
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import hasPermission from "../../components/HasPermission"
import { Button } from "../../components";
import { Group } from "@material-ui/icons";


import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import PropTypes from "prop-types";


var ps;








const testStyle = {
    background: 'orange',
}

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAvatar: false,
            openCartolas: this.activeRoute("/cartolas") !== "" ? true : false,
            openAnalysis: this.activeRoute("/analisis") !== "" ? true : false,
            assignedPermissions: [],
            collapseOpen: false
        };

        this.activeRoute.bind(this);
        this.activeUserPage.bind(this);
        this.minimizeSidebar = this.minimizeSidebar.bind(this);
        this.colorTemplate = this.colorTemplate.bind(this);
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute(routeName) {
        const myPath = this.props.location.pathname.split('/');
        const myRoute = routeName.split('/');
        if (myRoute.length > 1 && myPath.length > 1) {
            return myPath[2] === myRoute[2] ? "active" : "";
        }
        else {
            return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
        }
    }


    colorTemplate(routeName) {
        const color = this.props.colors.split(';')[1];
        const myPath = this.props.location.pathname.split('/');
        const myRoute = routeName.split('/');
        // console.log('myPath',  myPath[2], 'myRoute', myRoute[2], 'indexof', myPath[2].indexOf(myRoute[2]))
        if(myRoute.length > 1 && myPath.length > 1){
            return myPath[2] === myRoute[2] ? color : "white";
        }
        else {
            return this.props.location.pathname.indexOf(routeName) > -1 ? color : "white";
        }
    }

    activeUserPage(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? { backgroundColor: "rgba(255, 255, 255, 0.1)" } : { backgroundColor: "transparent" };
    }

    minimizeSidebar() {

        document.body.classList.toggle("sidebar-mini");

    }

    componentDidMount() {
        // console.log('SideBar Props', this.props);
        if (document.body.clientWidth < 1080) {
            // console.log('small vw');

            document.body.classList.toggle("sidebar-mini");
        }

        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.sidebar, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }

    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
        // to stop the warning of calling setState of unmounted component
        var id = window.setTimeout(null, 0);
        while (id--) {
            window.clearTimeout(id);
        }
    }



    render() {
        
        const appName = this.props.appName !== null ? this.props.appName : 'Asesor Portal'
        const shortAppName = appName.split(" ").length > 1 ? appName.split(" ").reduce((acc, data) => {
            acc = acc + data.substr(0, 1);
            return acc;
        }, "") : appName.substr(0, 1);

        return (
            <div>
                <NotificationAlert ref="notificationAlert" />
                <div className="sidebar" data-color="white">
                    <div className="logo">
                        <NavLink
                            onClick={this.props.onUserNavigate}
                            to="/dashboard/cartolas/home"
                            className="simple-text logo-mini"
                        >
                            <div className="logo-img" style={{ "textTransform": "lowercase" }}>
                                {/* AP */}
                                {shortAppName}
                            </div>
                        </NavLink>
                        <NavLink
                            onClick={this.props.onUserNavigate}
                            to="/dashboard/cartolas/home"
                            className="simple-text logo-normal"
                        >
                            {/* ASESORPORTAL */}
                            {this.props.appName}
                        </NavLink>
                        <div className="navbar-minimize">
                            <Button
                                simple
                                neutral
                                icon
                                round
                                id="minimizeSidebar"
                                onClick={this.minimizeSidebar}
                            >
                                <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
                                <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
                            </Button>
                        </div>
                    </div>

                    <div className="sidebar-wrapper" ref="sidebar">
                        <div className="user">
                            <div className="photo">
                                <span className="sidebar-mini-icon">
                                    <i className={"now-ui-icons users_single-02"} />
                                </span>
                            </div>

                            <div className="info">
                                <a
                                    data-toggle="collapse"
                                    aria-expanded={this.state.openAvatar}
                                    style={{ "cursor": "pointer" }}
                                    onClick={() =>
                                        this.setState({ openAvatar: !this.state.openAvatar })
                                    }
                                >
                                    <span style={{
                                        textTransform: "capitalize",
                                        fontSize: "13px"
                                    }}>
                                        {this.props.loadingUserData ?
                                            <SkeletonTheme color="#fff" highlightColor="#ecf0f1">
                                                <Skeleton duration={2} width={150} />
                                            </SkeletonTheme>
                                            :
                                            this.props.userName ?
                                                this.props.userName.substr(0, 20).toLowerCase() : null
                                        }
                                        <b className="caret" />
                                    </span>
                                </a>
                                <Collapse isOpen={this.state.openAvatar}>
                                    <ul className="nav">
                                        <li>
                                            <NavLink
                                                to="/dashboard/user-page"
                                                style={this.activeUserPage('user-page')}
                                                className="nav-link"
                                                activeClassName="active"
                                                onClick={this.props.onUserNavigate}
                                            >
                                                <span className="sidebar-mini-icon">MP</span>
                                                <span className="sidebar-normal">Mi Perfil</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Collapse>
                            </div>
                        </div>

                        <Nav>
                            {this.props.routes.map((route, key) => {
                                // console.log(this.props.routes);
                                if(hasPermission(this.props.assignedPermissions, route.permission)) {
                                    if (route.redirect) return null;
                                    if (route.invisible) return null;
                                    if (route.collapse) {
                                        var st = {};
                                        st[route["state"]] = !this.state[route.state];
                                        return (
                                            <li className={this.activeRoute(route.path)} key={key}>
                                                <a
                                                    data-toggle="collapse"
                                                    aria-expanded={this.state[route.state]}
                                                    onClick={() => this.setState(st)}
                                                >
                                                    <i className={"now-ui-icons " + route.icon} />
                                                    <p>
                                                        {route.name}
                                                        <b className="caret" />
                                                    </p>
                                                </a>
                                                <Collapse isOpen={this.state[route.state]}>
                                                    <ul className="nav">
                                                        {route.views.map((route, key) => {
                                                            if (route.redirect) return null;
                                                            return (
                                                                <li className={this.activeRoute(route.path)} key={key} >
                                                                    <NavLink
                                                                        onClick={this.props.onUserNavigate}
                                                                        to={route.path}
                                                                        className="nav-link"
                                                                        activeClassName="active"
                                                                        style={{ background: `${this.colorTemplate(route.path)}` }}
                                                                    >
                                                                        {/* <span className="sidebar-mini-icon">
                                                                            {route.mini}
                                                                        </span> */}
                                                                        <span className="sidebar-normal">
                                                                            {route.name}
                                                                        </span>
                                                                    </NavLink>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </Collapse>
                                            </li>
                                        );
                                    } else {
                                        return (
                                            <li className={this.activeRoute(route.path)} key={key}>

                                                <NavLink
                                                    to={route.path}
                                                    className="nav-link"
                                                    activeClassName="active"
                                                    onClick={this.props.onUserNavigate}
                                                    style={{ background: `${this.colorTemplate(route.path)}` }}
                                                >
                                                    <i className={route.icon} />
                                                    <p>{route.name}</p>
                                                </NavLink>
                                            </li>
                                        );
                                    }
                                } else {
                                    return null;
                                }

                            })}
                            <li>
                                <a
                                    className="nav-link"
                                    onClick={this.props.logout}
                                >

                                    <i className={"now-ui-icons sport_user-run"} />
                                    <p>Cerrar Sesión</p>
                                </a>
                            </li>
                        </Nav>
                        <div className={'appVer'} style={{ textAlign: 'center', marginTop: '3em', color: 'rgba(0,0,0,0.1)' }}>
                            v{this.props.appVersion}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

Sidebar.propTypes = {
    routes: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    userName: PropTypes.string.isRequired,
    loadingUserData: PropTypes.bool.isRequired,
    assignedPermissions: PropTypes.array.isRequired,
    logout: PropTypes.func.isRequired,
    onUserNavigate: PropTypes.func,
    appVersion: PropTypes.string.isRequired,
}


const mapStateToProps = state => ({
    colors: state.login.appColors
})

export default connect(mapStateToProps)(Sidebar);
