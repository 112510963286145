import React, {Component} from "react";
import { Route, Switch, withRouter } from 'react-router-dom';


class CartolaContainer extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        console.log('CartolaContainer', this.props);
    }
    
    render() {
        return(
            <div>
                
                {/* <BreadcrumbContainer /> */}
                <Switch>
                    {this.props.routes.map((route, i) => (
                        <Route path={route.path} render={() => <route.component />} key={i} />
                    ))}
                </Switch>
            </div>
        )
    }
}

export default withRouter(CartolaContainer);