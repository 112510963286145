import React, {Fragment, useState, useEffect} from 'react';
import {Card,CardBody, Alert, Button, CardTitle, CardText, Row, Col, Modal } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Add from '@material-ui/icons/Add';
import QipCard from '../../components/QipCard';
import { makeStyles } from "@material-ui/core/styles";
import CreateRoleContainer from '../../containers/RolesUsers/CreateRole/CreateRole';
import EditRoleContainer from '../../containers/RolesUsers/EditRole/EditRole';
import AssignUsersContainer from '../../containers/RolesUsers/AssignUsers/AssignUsers';
import Pagination from '../../components/Pagination/Pagination';
import './roleUsersStyle.css';


const useStyles = makeStyles({
    underline: {
      "&&&:before": {
        borderBottom: "none"
      }
    }
  });


function paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
}

const config = { mass: 5, tension: 2000, friction: 200 };

const RolesUsersComponent = ({permissions, 
    roles, roleName, rolePermissions, 
    roleDescription, create_role, update_role, 
    delete_role, getRolesHandler,
    setEditActive, setCreateActive,
    setOpenCreate, setOpenEdit, rolesLoading, totalRoles,
    fetchRoles, setOpenAssign, setAssignActive, assignSuccess, showNotification}) => {
    let roleData = roles;
    const [selectedRole, setSelectedRole] = useState('');
    const [appRoles, setRoles] = useState(roleData);
    const [appPermissions, setPermissions] = useState(permissions);

    const [activeRolePermissions, setActiveRolePermissions] = useState([]);
    
    const [roleDelete, setDeleteRole] = useState();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [modalLive, setModalLive] = React.useState(false);
    // Sweet alerts
    const [showSuccess, setShowSuccess] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [confirmUpdate, setConfirm] = useState(false);
    const [rolesTotal, setRolesTotal] = useState(totalRoles);
    const [alertVisible, setAlertVisible] = useState(false);
   
    const classes = useStyles();
    useEffect(() => {
        let pag_arr = paginate(roles, 5, 1);

        setRoles(pag_arr);
        setPermissions(permissions);
        setRolesTotal(totalRoles);
        setAlertVisible(assignSuccess);
    }, [roleData, rolePermissions, roleDescription, rolesLoading, totalRoles, assignSuccess]);
    
    /* Set the width of the side navigation to 250px and the left margin of the page content to 250px */
    const actionHandler = (selectedRole) => {
        
        setEditActive(selectedRole);
        setOpenEdit(true);
        console.log('selectedRole.permissions', selectedRole.permissions);
        setActiveRolePermissions(selectedRole.permissions);
        document.getElementById("editPanel").style.width = "50%";
        document.getElementById("editPanel").style.display = "block";
        document.getElementById("main").style.width = "50%";
    }
    
    const assignHandler = (selectedRole) => {
        setOpenAssign(true);
        setAssignActive(selectedRole);
        
        // setEditActive(selectedRole);
    }
    
    const handleChange = event => {
        setSelectedRole(event.target.value);
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            let newRole = { 
                name: selectedRole,
                permissions: appPermissions
            };
            //roleData.unshift(newRole);
            //setRoles([...roleData]);
            setCreateActive(newRole);
            setOpenCreate(true);
            setSelectedRole('');
        }
    } 

    const handleCreateRole = roleObejct => {
        create_role(roleObejct);
        setOpenCreate(false);
        getRolesHandler();
    }

    const handleUpdateRole = roleObject => {
        
        setUpdateSuccess(true);
    }

    const handleSuccess = () => {
        setShowSuccess(!showSuccess);
    }

    const handleWarning = () => {
        setShowWarning(!showWarning);
    }

    const handleConfirm = (roleObject) => {
        update_role(roleObject);
        setShowWarning(!showWarning);
        handleSuccess()
        setConfirm(true);
    }


    // const deleteHandler = () => {
    //     setShowDeleteWarning(true);
    // }

    const pageChangeHandler  = (data) => {
        //const { allCountries } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
        let pag_arr = paginate(roles, pageLimit, currentPage);
        setRoles(pag_arr);
        //const offset = (currentPage - 1) * pageLimit;
        //const currentCountries = allCountries.slice(offset, offset + pageLimit);
        //this.setState({ currentPage, currentCountries, totalPages });
    }

    const deleteHandler = (role) => {
        setModalLive(true);
        setDeleteRole(role);
    }

    const onDeleteConfirm = () => {
        delete_role(roleDelete);
        let filteredRoles = appRoles.filter(function(r){ return r.id != roleDelete.id });
        setRoles(filteredRoles);
        setModalLive(false);
    }

    const onDismiss = () => setAlertVisible(false);

    return(
        <Fragment>
            <Col  style={{margin: '3em 0', padding: '0 10%'}} >
                <TextField 
                    style={{width: '100%'}}
                    id="standard-basic" 
                    value={selectedRole} 
                    placeholder="Crear un rol" 
                    onChange={(e) => handleChange(e)} 
                    onKeyPress={(e) => handleKeyPress(e)}  
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Add style={{color: 'gray'}} />
                            </InputAdornment>
                        ),
                        classes
                        }}
                />
                <QipCard 
                    data={appRoles} 
                    actionHandler={actionHandler} 
                    deleteHandler={deleteHandler}
                    assignHandler={assignHandler}
                />
                <Pagination totalRecords={parseInt(rolesTotal)} pageLimit={5} pageNeighbours={2}  onPageChanged={pageChangeHandler} />
            </Col> 
            <EditRoleContainer />  
            {/** CreateRoleContainer */}  
            <CreateRoleContainer />
            <AssignUsersContainer />
            <Modal toggle={() => setModalLive(false)} isOpen={modalLive}>
                <div className="modal-header">
                <button
                    aria-label="Close"
                    className="close"
                    type="button"
                    onClick={() => setModalLive(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
                </div>
                <div className="modal-body">
                    <p>Estas seguro que quieres borrar el rol {roleDelete ? roleDelete.name : ''}?  </p>
                </div>
                <div className="modal-footer">
                <Button
                    color="secondary"
                    type="button"
                    onClick={() => setModalLive(false)}
                >
                    Cerrar
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={() => onDeleteConfirm()}
                >
                    Borrar Rol
                </Button>
                </div>
            </Modal>
        </Fragment>
    );
}

export default RolesUsersComponent;
