import React, { Fragment } from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import withTour from '../../../helpers/withTour';
import {
    listClientsCartolaLoading,
    listClientsCartola,
    listClientsCartolaConcatName,
} from '../../../selectors/cartola';
import {
    resetClientCartola,
    selectClientCartolaList,
    getListClientsCartola,
    setTableStateListClient
} from '../../../actions/cartola';
import ListClient from '../../../components/Cartola/ListClient';
import HeaderComponent from '../../../components/Header/HeaderComponent';
import withParams from '../../../helpers/withParams';

class ListClientContainer extends React.Component {
    constructor(props) {
        super(props);
        const {adjust, by, order, page, per, search} = this.props.params;
        this.state = {
            tableState: {
                order: order,
                orderBy: by,
                page: page ? parseInt(page) : page,
                dense: adjust,
                searchValue: search,
                rowsPerPage: per ? parseInt(per) : per,
                selected : []
            }
        }
        this.selectHandler = this.selectHandler.bind(this);
    }

    componentDidMount() {
        console.log('ListClientContainer this.props', this.props)
        this.props.resetClientCartola()
        this.props.get_clients();

    }

    selectHandler(val) {
        const name = `${val.name} ${val.last_name}`;
        this.props.selectClientCartolaList(val['id'], name);
    }

    render() {
        return(
        <Fragment>
            <HeaderComponent location={this.props.location}/>
            <ListClient
                clients={this.props.clients}
                clientsConcat={this.props.clientsConcat}
                selectHandler={this.selectHandler}
                pending={this.props.pending}
                location={this.props.location}
                getTableState={this.props.setTableStateListClient}
                tableState={this.props.params.order ? this.state.tableState : this.props.tableState}
                backgroundColor={this.props.backgroundColor.split(';')[0]}
            />
        </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        pending: listClientsCartolaLoading(state),
        clients: listClientsCartolaConcatName(state),
        tableState: state.cartola.tableState,
        backgroundColor: state.login.appColors
    }
}

const mapDispatchToProps = dispatch => ({
    get_clients: () => dispatch(getListClientsCartola()),
    resetClientCartola: () => dispatch(resetClientCartola()),
    selectClientCartolaList: (clientId, clientName) => dispatch(selectClientCartolaList(clientId, clientName)),
    setTableStateListClient: state => dispatch(setTableStateListClient(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withParams(withTour(ListClientContainer))));
