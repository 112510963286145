import React, { Component } from 'react';
import {connect} from 'react-redux';
import { login_fake, login, getTenantInfo } from '../actions/login';
import { selectClientCartolaList, fetch_composition } from '../actions/cartola';
import { user } from '../actions/user';
import { addRouterHistory } from '../actions/updateUrl'
import {fetchTourSteps} from '../actions/withTour';
import DashboardContainer from './DashboardContainer/DashboardContainer'
import {getSessionTime} from '../selectors/login';
import withParams from '../helpers/withParams';
import queryString from 'query-string'
import { withRouter } from 'react-router-dom';
import {urlCartolasClient, urlClientsDetails, urlStaffDetails, urlRebalanceHistorialAccounts, 
    urlRebalanceHistorialRebalance} from '../api/urls';
import { urlFormatFromParams } from '../helpers/formatter';

const urlsWithParams = [urlCartolasClient, urlClientsDetails, urlStaffDetails, 
    urlRebalanceHistorialAccounts, urlRebalanceHistorialRebalance];

class Main extends Component {

    componentDidMount() {
        this.props.addRouterHistory(this.props.history);
        //this.props.login_fake();

        //Cargar info de tenant
        this.props.getTenantInfo();
        
        this.props.user_fetch();
        this.props.fetchTourSteps();

        //Si existe params actualizar url
        if(location.search){
            const params = queryString.parse(location.search);
            this.updateUrl(params)
        }        
    }

    updateUrl(params) { 
        //Redirect URL
        let redirect    = '';
        const id        = params['id'];
        const rut_client= params['rut_client'];
        const cuenta    = params['cuenta'];
        const rebalance = params['rebalance'];
        const pathName  = this.props.location.pathname;
        const urlParamsTable = urlFormatFromParams(params);        

        if (urlsWithParams.includes(pathName)){
            redirect = pathName; 
        }

        if (id) {
            redirect = `${redirect}?id=${id}&${urlParamsTable}`
        }else if(rut_client && cuenta){
            if(rebalance){
                redirect = `${redirect}?rut_client=${rut_client}&cuenta=${cuenta}&rebalance=${rebalance}&${urlParamsTable}`
            }else{
                redirect = `${redirect}?rut_client=${rut_client}&cuenta=${cuenta}&${urlParamsTable}`
            }            
        }else{
            redirect = `${redirect}?${urlParamsTable}`
        }

        this.props.history.push(redirect);
    }

    renderDashboardContainer() {
        const TIME_NOTIFY = 60; //seconds
        let timeSession= 0
        let timeNotify= 0
        if(this.props.sessionTime <= TIME_NOTIFY){
            console.log('Sesión menor a notificación')            
            timeSession = 0;
            timeNotify = this.props.sessionTime;
        }else{
            console.log('Sesion OK')            
            timeSession = this.props.sessionTime - TIME_NOTIFY;
            timeNotify = TIME_NOTIFY;
        }  
        return <DashboardContainer 
                    timeSession={timeSession}   
                    timeNotify={timeNotify}
                    token={this.props.token}//Para actualización de estado
                    // Passed down from AppRouter
                    location={this.props.location}
                    // {...this.props}
                    />
    }

    render() {
        // const classes = useStyles();
        return (
            <div>
                {this.props.sessionTime ? 
                    this.renderDashboardContainer()
                    :
                    <div className="bg-white" style={{height:'100vh'}}>                    
                        <div className="text-center">
                            <h2 style={{paddingTop:'45vh'}}>Cerrando sesión</h2>
                            <h3>Espera un momento</h3>
                        </div>
                    </div>                                     
                }
            </div>
        );
    }
}


const mapStateToProps = state =>({
    sessionTime: state.login.sessionTime,
    userId: state.user.id,
    token: state.login.accessToken
})

const mapDispatchToProps = dispatch => ({
    login_fake: () => dispatch(login_fake()),
    fetch_composition: (userId) => dispatch(fetch_composition(userId)),
    user_fetch: () => dispatch(user()),
    addRouterHistory: history => dispatch(addRouterHistory(history)),
    fetchTourSteps: () => dispatch(fetchTourSteps()),
    login: () => dispatch(login()),
    getTenantInfo: () => dispatch(getTenantInfo()),
});

// const mainWithParams = withParams(Main);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));