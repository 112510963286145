import React from "react";
import { Container } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <nav>
            <ul>
              <li>
                <a href={this.props.linkCompany} target="_blank">{this.props.appName}</a>
              </li>
              <li>
                <a href={this.props.linkAbout} target="_blank">Quienes Somos</a>
              </li>
              <li>
                <a href={this.props.linkContact} target="_blank">Contactenos</a>
              </li>
            </ul>
          </nav>
          <div className="copyright">
            {this.props.appName} &copy; {1900 + new Date().getYear()},
            Coded by{" "}
            <a
              href="https://cuantserv.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              CUANTSERV
            </a>.
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
