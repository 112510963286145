import React from 'react';
import MaterialTable from 'material-table';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {MATERIAL_TABLE_LOCALIZATION} from './../../../constants';
import { rutFormat, patrimonyFormat } from '../../../helpers/formatter';

const theme = createTheme({
    palette: {
      primary: {
        main: '#4caf50',
      },
      secondary: {
        light: '#01579b',
        main: '#ff9100',
      },       
    },      
});

const state = {	  
    columns: [
        { title: 'Rut', field: 'rut', editable: 'onAdd', width: 100, render: ({rut}) => <span>{rutFormat(rut)}</span> },
        { title: 'Nombre', field: 'name', width: 150 }, 
        { title: 'Apellido', field: 'last_name',  width: 150 }, 
    ],
}
function TableClients ({data}) {

    const handleRowClicked = (selectedRow) => {
        console.log(selectedRow)
    }

    const handleRowSelected = (rows) => {
        console.log(rows)     
    }
    
    return (
    <MuiThemeProvider theme={theme}>
        <MaterialTable
            title="Clientes"
            columns={state.columns}
            data = {data}                  
            options={{
                sorting: true,
                rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#37b15933' : '' })
            }}           
            onRowClick={((evt, selectedRow) => handleRowClicked(selectedRow) )}                
            onSelectionChange={(rows) => handleRowSelected(rows)}
            localization = {MATERIAL_TABLE_LOCALIZATION}
        />
    </MuiThemeProvider>
    );
    
}

export default TableClients;