import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

class CardAuthor extends React.Component {
  render() {
    return (
      <div className="author" style={{
        display: "flex",
        marginTop: "15%",
        justifyContent: "center",
        alignItems: "center"
        }} >
        <h5 className="title2">{this.props.title}</h5>
        <p className="description" style={{fontWeight: "500", wordBreak: "break-word"}}>{this.props.description}</p>
      </div>
    );
  }
}

CardAuthor.propTypes = {
  // Where the user to be redirected on clicking the avatar
  link: PropTypes.string,
  avatar: PropTypes.string,
  avatarAlt: PropTypes.string, 
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default CardAuthor;
