import {CLIENTS_GET_INIT, CLIENTS_GET_SUCCESS, CLIENTS_GET_ERROR,
    CLIENTCARD_TOTAL_GET_INIT, CLIENTCARD_TOTAL_GET_SUCCESS, CLIENTCARD_TOTAL_GET_ERROR,
    CLIENTCARD_INFO_GET_INIT, CLIENTCARD_INFO_GET_SUCCESS, CLIENTCARD_INFO_GET_ERROR,
    CLIENT_DATA_GET_INIT, CLIENT_DATA_GET_SUCCESS, CLIENT_DATA_GET_ERROR,
    SET_FILTERS_SELECTED_CLIENT,
    CLIENT_SEND_INVITATION_INIT, CLIENT_SEND_INVITATION_SUCCESS, CLIENT_SEND_INVITATION_ERROR,
    RESET_MESSAGE_CLIENT,
    UPDATE_CLIENT_RISK_PROFILE_INIT, UPDATE_CLIENT_RISK_PROFILE_SUCCESS, UPDATE_CLIENT_RISK_PROFILE_ERROR,
    ADD_CLIENT_INIT, ADD_CLIENT_SUCCESS, ADD_CLIENT_ERROR,
    UPDATE_CLIENT_ACCOUNTS_INIT, UPDATE_CLIENT_ACCOUNTS_SUCCESS, UPDATE_CLIENT_ACCOUNTS_ERROR,
    REMOVE_CLIENT_INIT, REMOVE_CLIENT_SUCCESS, REMOVE_CLIENT_ERROR, CLIENTS_ASSIGN_LIST
} from './../constants';

const initialState = {
    clients       : [],
    clientSelected: {
        name: '',
        rut: '0-0',
        patrimony: '0'
    }, 
    clientStaff   : [],
    filterData    : [],
    riskProfileList: null,
    filters         : [
        ['patrimonio'     , ''],
        ['perfilRiesgo'   , ''], 
        ['hasStaff'   , ''],
    ],
    clientAccounts: [],
    lastRebalance: null,
    sendInvitationResult: null,
    message: null,
    status: null,
    error: null,
    total       : 0,
    pending     : true,
    pendingFilter : true,
    pendingInfo : false,
    actionMessage: null,
    clientsCard : [],
    usersList: [],
    //Pagination
    pagination: {
        current_page    : 0,
        first_page_url  : "",
        from            : 0,
        last_page       : 0,
        last_page_url   : "",
        next_page_url   : "",
        path            : "",
        per_page        : 0,
        prev_page_url   : null,
        to              : 0,
        total           : 0
    }
}

const client = (state = initialState, action) => {
    switch(action.type){
        case CLIENTS_GET_INIT:
            return {
                ...state,
                pending     : true,
                actionMessage: null,
                pendingFilter : true,
            };
        case CLIENTS_GET_SUCCESS:
            return {
                ...state,
                clients: action.payload.clients.data.client,
                filterData: action.payload.clients.data.filterData,
                riskProfileList: action.payload.clients.data.riskProfileList,
                pending     : false,
                pendingFilter : false,
            };
        case CLIENTS_GET_ERROR:
            return {
                ...state,
                error: action.payload.error,
                // pendingFilter : false,
            };

        case CLIENTS_ASSIGN_LIST:
            return {
                ...state,
                usersList: action.payload.usersList
            }
            

        case UPDATE_CLIENT_RISK_PROFILE_INIT:
            return {
                ...state,                
                actionMessage: null,
                status: null,
                error: null
            };
        case UPDATE_CLIENT_RISK_PROFILE_SUCCESS:
            return {
                ...state,
                clients: action.payload.newClients,
                actionMessage: action.payload.response.data.message,
                status: action.payload.response.status                
            };
        case UPDATE_CLIENT_RISK_PROFILE_ERROR:
            return {
                ...state,
                actionMessage: "Error al actualizar el registro",
                status: "500"                
            };


        case ADD_CLIENT_INIT:
            return {
                ...state,                
                actionMessage: null,
                status: null,
                error: null
            };
        case ADD_CLIENT_SUCCESS:
            return {
                ...state,
                clients: action.payload.response.data.errorCount == 0 ? action.payload.newClients: state.clients ,  
                actionMessage: action.payload.response.data.message,
                status: action.payload.response.status,
                error: action.payload.response.data.errorCount              
            };
        case ADD_CLIENT_ERROR:
            return {
                ...state,
                actionMessage: "Error al crear nuevo cliente",
                status: "500",
                error: 1               
            };


           
        case REMOVE_CLIENT_INIT:
            return {
                ...state,                
                actionMessage: null,
                status: null,
                error: null,                
            };
        case REMOVE_CLIENT_SUCCESS:
            return {
                ...state,
                clients: action.payload.newClients,  
                actionMessage: action.payload.response.data.message,
                status: action.payload.response.status,
                error: action.payload.response.data.errorCount              
            };
        case REMOVE_CLIENT_ERROR:
            return {
                ...state,
                actionMessage: "Error al remover cliente",
                status: "500",
                error: 1               
            };
        
        case UPDATE_CLIENT_ACCOUNTS_INIT:
            return {
                ...state,                
                actionMessage: null,
                status: null,
                error: null
            };
        case UPDATE_CLIENT_ACCOUNTS_SUCCESS:
            return {
                ...state,                
                actionMessage: action.payload.response.data.message,
                status: action.payload.response.status,
                error: action.payload.response.data.errorCount              
            };
        case UPDATE_CLIENT_ACCOUNTS_ERROR:
            return {
                ...state,
                actionMessage: "Error al actualizar cuentas de cliente",
                status: "500",
                error: 1               
            };


        case SET_FILTERS_SELECTED_CLIENT:
            const newfilter = new Map(state.filters);
            newfilter.set(action.payload.filters.key, action.payload.filters.value);
            return {
                ...state,
                filters: Array.from(newfilter)                
            };


        case CLIENT_DATA_GET_INIT:
            return {
                ...state,
                pendingInfo : true,
                actionMessage: null,
                clientSelected: {
                    name: '',
                    rut: '0-0',
                    patrimony: '0'
                },
            };
        case CLIENT_DATA_GET_SUCCESS:
            return {
                ...state,
                clientAccounts: action.payload.clientAccounts.data.accounts,
                lastRebalance: action.payload.clientAccounts.data.last_rebalance,  
                clientStaff : action.payload.clientAccounts.data.clientStaff,
                clientSelected: action.payload.clientAccounts.data.client,
                pendingInfo : false,
            };
        case CLIENT_DATA_GET_ERROR:
            return {
                ...state,
                error: action.payload.error,
                clientAccounts: [],
                lastRebalance: '',
                pendingInfo : false,
            };


        case CLIENTCARD_TOTAL_GET_INIT:
            return {
                ...state,
                pending : true,
                error   : null
            };
        case CLIENTCARD_TOTAL_GET_SUCCESS:
            return {
                ...state,
                total       : action.payload.clients.data.total,    
                pending     : false,
            };
        case CLIENTCARD_TOTAL_GET_ERROR:
            return {
                ...state,
                total       : 0,                
                error       : action.payload.error,
                pending     : false,
            };  
            

        case CLIENTCARD_INFO_GET_INIT:
            return {
                ...state,  
                // pendingInfo: true,              
                error   : null
            };
        case CLIENTCARD_INFO_GET_SUCCESS:
            const {current_page, data, first_page_url, from, last_page, last_page_url,
                next_page_url, path, per_page, prev_page_url, to, total } = action.payload.clients.data.info;            
            return {
                ...state,
                clientsCard: data, 
                pendingInfo: false, 
                pagination: {
                    current_page, 
                    first_page_url, 
                    from, 
                    last_page, 
                    last_page_url,
                    next_page_url, 
                    path, 
                    per_page, 
                    prev_page_url, 
                    to, 
                    total
                }                 
            };
        case CLIENTCARD_INFO_GET_ERROR:
            return {
                ...state,
                clientsCard: [],  
                pendingInfo: false,          
                error      : action.payload.error,                
            };  



        case CLIENT_SEND_INVITATION_INIT:
            return {
                ...state,
                error   : null,
                actionMessage: null,
                sendInvitationResult: null,
                message: null,                
            };
        case CLIENT_SEND_INVITATION_SUCCESS:
            return {
                ...state,
                sendInvitationResult: action.payload.response.data.result,
                message: action.payload.response.data.msg,                

            };
        case CLIENT_SEND_INVITATION_ERROR:
            return {
                ...state,              
                error       : action.payload.error,
                sendInvitationResult: null,                
            }; 

        case RESET_MESSAGE_CLIENT:
            return {
                ...state,
                message: null,
                actionMessage: null,
                error: null
            };


        default:
            return state;
    }
}

export default client;