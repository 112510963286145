import {createSelector} from 'reselect';

// const staff = state => state.staff.staff;
// export const getAllStaff = createSelector(staff, obj => Object.values(obj) )
export const getAllStaff = state => JSON.parse(JSON.stringify(state.staff.staff));

export const getStatus = state => state.staff.status;

export const totalStaff = state => state.staff.total; 
export const pending = state => state.staff.pending; 
export const staffCard = state => state.staff.staffCard; 
export const pendingInfo = state => state.staff.pendingInfo; 

export const staffData = state => JSON.parse(JSON.stringify(state.staff.staffClients));

export const actionMessage = state => state.staff.actionMessage; 
export const error = state => state.staff.error;

export const staffSelected = state => state.staff.staffSelected

export const getSelectedStaff = state => {
    const selected = state.staff.staffList;
    return state.staff.staff.filter(obj => selected.includes(obj.id));
};
