import React from "react";


class Step2 extends React.Component {
    render() {
        return (

                <div className="image2">
                    <div className="icono"><i class="now-ui-icons business_chart-bar-32"></i></div>
                    <div class="monto">2.51<h6>Rentabilidad Real</h6></div>
                    
                    <div className="txtonb1">Olor a sal y viento helado</div>
                    <div className="txtonb2">No era un buen día para comenzar la marcha. El cielo pintaba jirones frágiles sobre el entramado negro del cielo. Ni siquiera eran las cinco de la tarde. El frío amenazaba con arrancarte la piel a tiras.
                </div>
                </div>

        );
    }
}

export default Step2;