import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {Card, CardBody, CardHeader} from "reactstrap";
import { NavLink } from "react-router-dom";


const cartolaHomeStyles = {
    card: {
        height: '500px',
        borderRadius: '7px',
        background: 'white',
        boxShadow:  '0 1px 15px 1px rgba(39, 39, 39, 0.1)'
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '200px',
        borderRadius: '7px',
    },
    // iconCircle: {
    //     borderRadius: '200px',
    //     background: '#f96332',
    //     height: '90px',
    //     width: '90px',
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center'
    // },
    icon: {
        fontSize: '2em',
        color: 'white'
    },
    bodyText: {
        textAlign: 'center',
        color: '#2c3e50',
        fontSize: '1.2em'
    }
}

const iconCircle = background => ({
    borderRadius: '200px',
    background: background,
    height: '90px',
    width: '90px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
})


function CartolaHomeCard({cardTitle, cardDesc, link, icon, buttonText, backgroundColor}){
    return (
        <NavLink to={`${link}`} >
            <Card style={cartolaHomeStyles.card}>
                <CardHeader style={cartolaHomeStyles.cardHeader}>
                    <div className='icon-circle' style={iconCircle(backgroundColor)}>
                    <i className={`${icon}`} style={cartolaHomeStyles.icon}></i>
                    </div> 
                </CardHeader>
                <CardBody style={cartolaHomeStyles.bodyText}>
                    <h3 style={{color: '#2c3e50', fontFamily: 'Playfair Display, serif'}}>{cardTitle}</h3>
                    <span style={{display: 'block', color: 'gray', height: '6em'}}>
                        {cardDesc}
                    </span>
                    {buttonText ? 
                    
                        <Button variant="outlined" color="primary">
                            {buttonText}
                        </Button>
                        : null
                    }
                    
                </CardBody>
            </Card>
        </NavLink>
    )
}


CartolaHomeCard.propTypes = {
    cardTitle: PropTypes.string.isRequired,
    cardDesc: PropTypes.string.isRequired,
    link: PropTypes.string,
    icon: PropTypes.node,
    buttonText: PropTypes.string,
    backgroundColor: PropTypes.string,
}


export default CartolaHomeCard;