import {GROUP_LIST_INIT, GROUP_LIST_SUCCESS, GROUP_LIST_ERROR, LIST_GROUPS_TABLE_STATE
} from '../constants';
import {urlGroupList, urlGroupComposition, urlGroupBreakdown} from '../api/urls'
import httpClient from '../api/httpClient';
import { updateRouterHistory } from './updateUrl';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { urlParamsFormat } from '../helpers/formatter';

const moment = extendMoment(originalMoment);
const today = moment();
const formatter = new Intl.NumberFormat('es-ES', {
    minimumFractionDigits: 0
});

export const groupList = () => {    
    return (dispatch, getState)=>{
        dispatch(groupListInit())
        if(getState().group.groupList.length == 0){
            httpClient.getAll(urlGroupList)
            .then(res => dispatch(groupListSuccess(res.data.group)))
            .catch(err => dispatch(groupListError(err)))
        }
    }
}

export const selectGroupCartola = (groupId, name, representante) => {
    return (dispatch, getState) => {    
        const urlParams = urlParamsFormat(getState().group.tableState);    
        dispatch(updateRouterHistory(`/dashboard/cartolas/clientsgroup?id=${groupId}&${urlParams}`));
    }
}

export const setTableStateListGroup = state => {
    return dispatch => {
        dispatch(_setTableStateListGroup(state))
    }
}

const _setTableStateListGroup = state => ({
    type: LIST_GROUPS_TABLE_STATE,
    payload: {
        state
    }
})

const groupListInit = () => ({
    type: GROUP_LIST_INIT
})

const groupListSuccess = (group) => ({
    type: GROUP_LIST_SUCCESS,
    payload: {
        groupList: group
    }    
})

const groupListError = (error) => ({
    type: GROUP_LIST_ERROR,
    payload: {
        error: error
    }    
})

