import React from "react";

import onboard from "../../assets/img/on5.jpg";

class Step3 extends React.Component {
    render() {
        return (

            <div className="image2">
                <div className="image3" style={{backgroundImage:"url("+onboard+")"}}></div>
                <div className="txtonb1">La arena mojada y el aire seco</div>
                <div className="txtonb2">La mañana comenzó igual a todas las anteriores aquella semana. Luces fantasma, fuegos fatuos reverberando tras las arenas ondulantes y un olor picante que nunca pudimos descubrir desde donde llegaba. El mar parecía muerto. Un espejo quebrado abandonado entre los escombros.</div>
            </div>

        );
    }
}

export default Step3;