import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Row,
    Col, ModalHeader, ModalBody, ModalFooter, Modal, Table, FormGroup, Form, FormFeedback, Input, Button
} from "reactstrap";

import {
    PanelHeader,
    FormInputs,
    CardAuthor,
    Statistics,
    Stats
} from "../../components";
import PropTypes from "prop-types";
import userBackground from "../../assets/img/bg5.jpg";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { rutFormat, patrimonyFormat } from '../../helpers/formatter';
import Can from '../../helpers/Can';


const moment = extendMoment(originalMoment);
const numeral = require('numeral');


class User extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            messagesModal: false,
            riskLevelModal: false,
            updateUserLoading: false,
            userData: {
                name: props.user.name,
                email: props.user.email,
                rut: rutFormat(props.user.rut + "-" + props.user.dv),
                address: props.user.address ? props.user.address.name : "",
                // city: "",
                // comuna: "",
                // country: ""
            },
            modal: false,          
            validatePassword: false,
            errors: [],
            msgPasswordInvalid: null,
        };        

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleMessagesModal = this.toggleMessagesModal.bind(this);
        this.toggleRiskLevelModal = this.toggleRiskLevelModal.bind(this);
        this.toggleModalInsertPassword = this.toggleModalInsertPassword.bind(this);
    }

    componentDidMount() {
        console.log({'User Props': this.props})
    }

    componentDidUpdate(e) {
        console.log('event', e);
        const userData = this.state.userData;
        if (this.props.profileUpdated) {
            if (this.props.user.address !== userData.address) {
                e.user.address = userData.address;
                this.props.notify.notify('tr', 'success', 'Tu perfil fue actualizado exitosamente');
                let user = {
                    name: props.user.name,
                    email: props.user.email,
                    rut: props.user.rut + "-" + props.user.dv,
                    address: props.user.address ? props.user.address.name : ""
                }

                this.setState({userData: user});
            }
        }
        
    }

    toggleMessagesModal() {
        this.setState({
            messagesModal: !this.state.messagesModal
        });
    }

    toggleRiskLevelModal() {
        this.setState({
            riskLevelModel: !this.state.riskLevelModal
        })
    }

    handleChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        let newUserState = {...this.state.user};
        newUserState[name] = value;
        console.log({key: name, val: value});
        this.setState({userData: newUserState});
    }
    
    handleSubmit() {                   
        const password = document.getElementById("password").value;       
        if(!password){
            this.setState({ validatePassword: true })
        }else{
            const {address, city, comuna, country} = this.state.userData;  
            const user = {  
                address,
                password
            };          
            let userData = user; 
            
            this.props.onSubmit(userData);
        } 
    }
    
    toggleModalInsertPassword() {      
        const {address, city, comuna, country} = this.state.userData;
        const errors= []        
        if(address.trim() == ""){
            errors.push("Campo Domicilio es requerido")
        }
        /* if(!city){
            errors.push("Campo Ciudad es requerido")
        }
        if(!comuna){
            errors.push("Campo Comuna es requerido")
        }
        if(!country){
            errors.push("Campo País es requerido")
        } */
        //Validate Fields Required
        if(errors.length != 0){
            this.setState({ errors });
            return;
        }
        this.props.toggleModal();
        
        // this.setState(prevState => ({
        //     modal: !prevState.modal,
        //     validatePassword: false,
        //     msgPasswordInvalid: null,
        //     errors: []
        // }));
        
    }    
  

    abbreviate_number(num, fixed) {
        if (num === null) { return null; } // terminate early
        if (num === 0) { return '0'; } // terminate early
        fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
        var b = (num).toPrecision(2).split("e"), // get power
            k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
            c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3) ).toFixed(1 + fixed), // divide by power
            d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
            e = d + ['', 'K', 'M', 'MM', 'T'][k]; // append power
        return e;
    };



    render() {

        const startPeriod = moment(this.props.cartola.periodStart).locale('es').format('DD/MM/YYYY');
        const endPeriod = moment(this.props.cartola.periodEnd).locale('es').format('DD/MM/YYYY');
        return (
            <div>
                <PanelHeader size="sm" />
                <div className="content">
                    <Can permission={'view-advisor-dashboard'}
                        alt={
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <Card className="card-stats">
                                    <CardBody>
                                        <Statistics
                                            horizontal
                                            icon="business_bank"
                                            iconState="warning"
                                            title={
                                                this.props.cartola.fetchBreakdownLoading ?
                                                    <Skeleton count={1}/>
                                                    :this.props.cartola.fetchBreakdownFailed ?
                                                    <div style={{"fontWeight": "bold",
                                                    "color": "grey"}}>--</div>                                                
                                                    : <small>${this.abbreviate_number(parseInt(this.props.cartola.montoTotal.replace(/[.]/g, "")))}</small>
                                                }
                                            subtitle="Patrimonio"
                                        />
                                    </CardBody>
                                    <hr />
                                    <CardFooter>
                                        {this.props.cartola.fetchBreakdownLoading  ?
                                            <Skeleton count={1}/>
                                            :this.props.cartola.fetchBreakdownFailed ?
                                                    <div style={{"fontWeight": "bold",
                                                    "color": "grey"}}>No se registran datos.</div>   
                                            :
                                            <Stats>
                                                {[{
                                                    i: "now-ui-icons ui-1_calendar-60",
                                                    t: "".concat(endPeriod)
                                                    }]}
                                            </Stats>
                                        }
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <Card className="card-stats">
                                    <CardBody>
                                        <Statistics
                                            horizontal
                                            icon="shopping_shop"
                                            iconState="danger"
                                            title={
                                                    this.props.cartola.fetchBreakdownLoading ?
                                                    <Skeleton count={1}/>
                                                    :this.props.cartola.fetchBreakdownFailed ?
                                                    <div style={{"fontWeight": "bold",
                                                                "color": "grey"}}>--</div>
                                                    : 
                                                <small>{ this.props.cartola.adminSelectValue.length}</small>
                                            }
                                            subtitle="Instituciones"
                                        />
                                    </CardBody>
                                    <hr />
                                    <CardFooter>
                                        {
                                            this.props.user.loadingUserData ?
                                                <Skeleton count={1}/>
                                                :
                                                <Stats>
                                                    {[
                                                        {
                                                            i: "now-ui-icons arrows-1_refresh-69",
                                                            t: this.props.cartola.adminSelectOptions.map(adm=>" " + adm.label)
                                                        }
                                                    ]}
                                                </Stats>
                                        }
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                        }
                    >
                        
                    </Can>
                    
                    <Row>
                        <Col md={8} xs={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="title">Editar Perfil</h5>
                                </CardHeader>
                                <form>
                                    <CardBody>
                                        {this.props.user.loadingUserData ?
                                            <Skeleton count={1} height={63}/>
                                            :
                                            <FormInputs
                                                ncols={
                                                    [
                                                        "col-md-4 pr-1",
                                                        "col-md-4 px-1",
                                                        "col-md-4 pl-1"
                                                    ]
                                                }
                                                proprieties={[
                                                    {
                                                        label: "Nombre",
                                                        inputProps: {
                                                            name: "name",
                                                            type: "text",
                                                            placeholder: "Nombre",
                                                            disabled: true,
                                                            defaultValue: this.props.user.name,
                                                            onChange: (event => this.handleChange(event))
                                                        }
                                                    },
                                                    {
                                                        label: "Email",
                                                        inputProps: {
                                                            name: "email",
                                                            type: "email",
                                                            disabled: true,
                                                            placeholder: "Email",
                                                            defaultValue: this.props.user.email,
                                                            onChange: (event => this.handleChange(event))
                                                        }
                                                    },
                                                    {
                                                        label: "RUT",
                                                        inputProps: {
                                                            name: "rut",
                                                            type: "text",
                                                            placeholder: "RUT",
                                                            disabled: true,
                                                            defaultValue: this.state.userData.rut,
                                                            onChange: (event => this.handleChange(event))
                                                        }
                                                    }
                                                ]}
                                            />
                                        }
                                        {this.props.user.loadingUserData ?
                                            <Skeleton count={1} height={63}/>
                                            :
                                            < FormInputs
                                                ncols={["col-md-12"]}
                                                proprieties={[
                                                    {
                                                        label: "Domicilio",
                                                        inputProps: {
                                                            name: "address",
                                                            type: "text",
                                                            placeholder: "Domicilio",
                                                            //defaultValue: this.props.user.address ? this.props.user.address.name: "",
                                                            defaultValue: this.state.userData.address,
                                                            onChange: (event => this.handleChange(event))
                                                        }
                                                    }
                                                ]}
                                            />
                                        }
                                        {this.props.user.loadingUserData ?
                                            <Skeleton count={1} height={63}/>
                                            : ""
                                            /* <FormInputs
                                                ncols={[
                                                "col-md-4 pr-1",
                                                "col-md-4 px-1",
                                                "col-md-4 pl-1"
                                                ]}
                                                proprieties={[
                                                {
                                                    label: "Ciudad",
                                                    inputProps: {
                                                        name: "city",
                                                        type: "text",
                                                        defaultValue: "" ,
                                                        placeholder: "Ciudad",
                                                        onChange: (event => this.handleChange(event))
                                                    }
                                                },
                                                {
                                                    label: "Comuna",
                                                    inputProps: {
                                                        name: "comuna",
                                                        type: "text",
                                                        defaultValue: "" ,
                                                        placeholder: "Comuna",
                                                        onChange: (event => this.handleChange(event))
                                                    }
                                                },
                                                {
                                                    label: "Pais",
                                                    inputProps: {
                                                        name: "country",
                                                        type: "text",
                                                        defaultValue: "" ,
                                                        placeholder: "Pais",
                                                        onChange: (event => this.handleChange(event))
                                                    }
                                                }
                                            ]}
                                            /> */
                                            
                                        }
                                        {this.props.user.errors.map(error => <div className="text-danger" key={error}>{error}</div>)}                                        
                                    </CardBody>
                                    {/* <Col lg={4} md={4} sm={4}> */}
                                    <CardFooter>

                                        {this.props.user.updateUserLoading ?
                                            <Button
                                                block
                                                color="warning"
                                                size="lg"
                                                // href="#"
                                                className="mb-3"
                                                disabled
                                            >
                                                Actualizando... <i className="now-ui-icons loader_refresh spin"></i>
                                            </Button>
                                            :
                                            <Button
                                                block
                                                color="primary"
                                                size="lg"
                                                // href="#"
                                                className="mb-3"
                                                onClick={this.toggleModalInsertPassword}
                                            >
                                                Actualizar
                                            </Button>
                                            
                                        }                                            
                                    </CardFooter>
                                    {/* </Col> */}
                                </form>
                            </Card>
                        </Col>
                        <Col md={4} xs={12}>
                            <Card className="card-user" style={{height:"25em"}}>
                                {this.props.user.loadingUserData ?
                                <Skeleton count={1} height={140}/>
                                :
                                <div className="image" 
                                style={{backgroundSize: "cover",
                                backgroundImage:"linear-gradient(60deg, rgba(0,0,0,0.7), rgba(0,0,0,0.5)), url("+userBackground+")",
                                borderRadius:"3px 3px 0px 0px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                                }}>
                                    <span style={{
                                        fontSize: "1.30em",
                                        lineHeight: "1.4em",
                                        fontWeight: "700",
                                        color: "white",
                                        textAlign: "center",
                                        wordBreak: "break-word",
                                    }}>{this.props.user.name.toUpperCase()}</span>
                                </div> }                               
                                <CardBody>
                                    {this.props.user.loadingUserData ?
                                        <Skeleton count={3} height={63}/>
                                        :
                                        <CardAuthor
                                            /*avatar={defaultAvatar}
                                            avatarAlt="..." */
                                            description={this.props.user.email}
                                        />
                                    }
                                </CardBody>                                
                                {/** 
                                <CardSocials
                                    size="lg"
                                    socials={[
                                        {
                                            icon: "fa fa-facebook-square",
                                            href: "https://www.facebook.com/"
                                        },
                                        {
                                            icon: "fa fa-twitter",
                                            href: "https://www.facebook.com/"
                                        },
                                        {
                                            icon: "fa fa-google-plus-square",
                                            href: "https://plus.google.com/discover"
                                        }
                                    ]}
                                />
                                */}
                            </Card>
                        </Col>
                        <Modal
                            isOpen={this.state.messagesModal}
                            toggle={this.toggleMessagesModal}
                            className="text-center"
                            style={{"width":"70vw", "maxWidth":"70vw"}}
                        >
                            <ModalHeader
                                className="justify-content-center uppercase title"
                                toggle={this.toggleMessagesModal}
                                tag="h3"
                            >
                                Mensajes Recientes
                            </ModalHeader>
                            <ModalBody>
                                <Table responsive>
                                    <thead>
                                    <tr className="text-primary">
                                        <th className="text-right">{" "}</th>
                                        <th className="text-right">Tipo</th>
                                        <th>Mensaje</th>
                                        <th className="text-right">Fecha</th>
                                        <th className="text-right">Acciones</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.user.messages.map((message) => {
                                        return (
                                            <tr>
                                                <td className="text-right">
                                                    <i className={message.icon}/>
                                                </td>
                                                <td className="text-right">
                                                    {message.type}
                                                </td>
                                                <td className="text-right">
                                                    {message.message}
                                                </td>
                                                <td className="text-right">
                                                    {message.created_at}
                                                </td>
                                                <td className="text-right">
                                                    <Button icon color="success" size="sm">
                                                        <i className="now-ui-icons ui-1_check" />
                                                    </Button>{" "}
                                                </td>
                                            </tr>
                                        );
                                    })}

                                    </tbody>
                                </Table>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="danger"
                                    onClick={this.toggleMessagesModal}
                                >
                                    Cerrar
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Row>
                </div>
                {/* Modal Update User */}
                <div>  
                    <Modal isOpen={this.props.modal} toggle={this.toggleModalInsertPassword} className={this.props.className}>                    
                        <ModalHeader toggle={this.toggleModalInsertPassword}>Ingresa tu Password</ModalHeader>
                            <ModalBody>                                                                
                                <FormGroup>                                    
                                    <Input 
                                    type="password" 
                                    name="password" 
                                    id="password" 
                                    placeholder="Ingresa aqui tu password"
                                    />
                                {this.props.user.msgPasswordInvalid && <div className="text-danger">{this.props.user.msgPasswordInvalid}</div>}
                                </FormGroup> 
                                {this.props.user.validatePassword && <div className="text-danger">Campo Requerido</div>}                                
                            </ModalBody>
                            <ModalFooter>                                
                                <Button color="secondary" onClick={this.toggleModalInsertPassword}>Cancelar</Button>{' '}
                                
                                {this.props.user.updateUserLoading ?  
                                    <Button color="warning">
                                        Actualizando<i  style={{marginLeft: "2em"}} className="now-ui-icons loader_refresh spin"></i>
                                    </Button>
                                    :
                                    <Button color="primary" onClick={this.handleSubmit}>Aceptar</Button>
                                }
                            </ModalFooter>                      
                    </Modal>
                </div>


            </div>
        );
    }
}

User.propTypes = {
    user: PropTypes.object.isRequired, 
    notify: PropTypes.object.isRequired, 
    cartola: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired,        
    onSubmit: PropTypes.func.isRequired,
    modal: PropTypes.bool.isRequired,
}

export default User;